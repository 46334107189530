import React from 'react';
import { Paper,Card,makeStyles,createTheme, createMuiTheme,ThemeProvider } from '@material-ui/core';
import { People,LockOpen,Lock,LibraryBooks,Info,Battery0Bar,Battery20,Battery30,Battery50,Battery60,Battery80,Battery90,BatteryFull } from '@mui/icons-material';
import './locks_grid_list.css';
import { Box,Tooltip } from '@mui/material';

const Theme = {
  overrides: {
  },
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    height: '400px',
    color: 'white !important',
    borderRadius: '5%',
    fontWeight: 'bold !important',
    '&:hover': {
      boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.2)',
    },
  },
  name: {fontWeight: 'bold !important'},
  actionIcon: {
    fontSize: 50,
  },
}));

export default function LocksGrid(props) {
  const classes = useStyles();
  const theme = createTheme(Theme);
  return (       
    props.lockData.map((device) => {        
      return (        
        <div style={{margin: '8px'}}>              
          <Tooltip arrow placement="top" title={device.authStatus === "AUTH_SUCCESS" ? device.accessed ? device.accessed === "-" ? "" : "Last Accessed " +device.accessed : "" : "Not Reachable"}>
            <Card className={classes.paper} style={{fontWeight: 'bold',background: device.authStatus === "AUTH_SUCCESS" ? "#14138B" : "gray"}}>
              <Box sx={{fontSize: '25px', paddingTop: '8px'}}> {device.name}</Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  fontSize:"14px",
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{paddingTop: '16px'}} mt={2}>
                  Type #
                </Box>
                <Box sx={{paddingTop: '16px'}} mt={2}>
                  Serial #
                </Box>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  minHeight:"24px"
                }}
              >
                <Box sx={{fontSize:"14px"}}> {device.type || " "}</Box>
                <Box sx={{fontSize:"14px"}}> {device.serialNumber || " "}</Box>
              </Box>

              <Box
                className="device_action"
                sx={{
                  alignItems: 'center',
                  height: '40%',
                  marginTop: '32px',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                {
                  device.batteryLevel ?
                    device.batteryLevel === 0 ?
                      <Box sx={{display: "flex",flexDirection:'column',marginTop:"10px"}}>
                        <Battery0Bar sx={{fontSize: 50}} />
                        <Box sx={{fontSize:"12px"}}>
                          {device.batteryLevel} %
                        </Box>
                      </Box>
                    :
                      device.batteryLevel > 0 && device.batteryLevel <= 20 ?
                        <Box sx={{display: "flex",flexDirection:'column',marginTop:"10px"}}>
                          <Battery20 sx={{fontSize: 50}} />
                          <Box sx={{fontSize:"12px"}}>
                            {device.batteryLevel} %
                          </Box>
                        </Box>
                      :
                        device.batteryLevel > 20 && device.batteryLevel <= 30 ?
                          <Box sx={{display: "flex",flexDirection:'column',marginTop:"10px"}}>
                            <Battery30 sx={{fontSize: 50}} />
                            <Box sx={{fontSize:"12px"}}>
                              {device.batteryLevel} %
                            </Box>
                          </Box>
                        :
                          device.batteryLevel > 30 && device.batteryLevel <= 50 ?
                            <Box sx={{display: "flex",flexDirection:'column',marginTop:"10px"}}>
                              <Battery50 sx={{fontSize: 50}} />
                              <Box sx={{fontSize:"12px"}}>
                                {device.batteryLevel} %
                              </Box>
                            </Box>
                          :
                            device.batteryLevel > 50 && device.batteryLevel <= 60 ?
                              <Box sx={{display: "flex",flexDirection:'column',marginTop:"10px"}}>
                                <Battery60 sx={{fontSize: 50}} />
                                <Box sx={{fontSize:"12px"}}>
                                  {device.batteryLevel} %
                                </Box>
                              </Box>
                            :
                              device.batteryLevel > 60 && device.batteryLevel <= 80 ?
                                <Box sx={{display: "flex",flexDirection:'column',marginTop:"10px"}}>
                                  <Battery80 sx={{fontSize: 50}} />
                                  <Box sx={{fontSize:"12px"}}>
                                    {device.batteryLevel} %
                                  </Box>
                                </Box>
                              :
                                device.batteryLevel > 80 && device.batteryLevel < 100 ?
                                  <Box sx={{display: "flex",flexDirection:'column',marginTop:"10px"}}>
                                    <Battery90 sx={{fontSize: 50}} />
                                    <Box sx={{fontSize:"12px"}}>
                                      {device.batteryLevel} %
                                    </Box>
                                  </Box>
                                :
                                  device.batteryLevel === 100 ?
                                    <Box sx={{display: "flex",flexDirection:'column',marginTop:"10px"}}>
                                      <BatteryFull sx={{fontSize: 50}} />
                                      <Box sx={{fontSize:"12px"}}>
                                        {device.batteryLevel} %
                                      </Box>
                                    </Box>
                                  :
                                    null
                  :
                    null
                }
                {device.status === 'LOCKED' ? (
                    <Lock onClick={() => {
                        if (device.authStatus === 'AUTH_SUCCESS')
                          props.lockToggled(device,"unlock")
                      }} sx={{color: '#C53211',cursor: 'pointer',fontSize: 50}} />
                ) : (
                    <LockOpen onClick={() => {
                      if (device.authStatus === 'AUTH_SUCCESS')
                        props.lockToggled(device,"lock")
                    }} sx={{color: '#fff',cursor:'pointer',fontSize: 50}}/>
                )}
                <People sx={{fontSize: 50,cursor:"pointer"}} onClick={()=> props.userDialogClicked(device)}></People>
                <LibraryBooks sx={{fontSize: 50,cursor:"pointer"}} onClick={() => props.historyClicked(device)}></LibraryBooks>
                <Info sx={{fontSize: 50,cursor:"pointer"}} onClick={()=> props.infoClicked(device)}></Info>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{paddingTop: '16px',fontSize:"14px"}} mt={2}>
                  {device.gatewayMacId}
                </Box>
                <Box sx={{paddingTop: '16px',fontSize:"14px"}} mt={2}>
                  {device.firmwareVersion}
                </Box>
              </Box>
            </Card>
          </Tooltip>
        </div>  
      )
    })
  )
}
