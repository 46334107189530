/* eslint-disable no-undef */
import axios from "axios";
import {jsx} from "@emotion/react";

const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({})
};

let allAbortControlllers = {};

function addAbortController (page) {
    let abortController = new AbortController();
    if (!allAbortControlllers.hasOwnProperty(page)){
        allAbortControlllers[page] = [abortController];
    }else {
        allAbortControlllers[page].push(abortController);
    }
    return abortController;
}

function checkIsAuthorised (response) {
    if (response.hasOwnProperty("message")){
        if (response.message.indexOf("401 Unauthorized:") !== -1){
            sessionStorage.clear();
            window.location.hash = "/login";
            window.location.reload()
        }
    }
    return response;
}

function getIotDevices(scopeId, page) {
    let abortController = addAbortController(page);
    return fetch('/api/free-tier-devices/devices?scopeId=' + scopeId, {signal: abortController.signal})
        .then(res => res.json())
        .then((json) => {
            return checkIsAuthorised(json);
        })
        .catch((err) => {
            console.error(err);
            return err;
        });
}

function getDeviceUsersCount(scopeId, page) {
    let abortController = addAbortController(page);
    return fetch('/api/users-count/locks?scopeId=' + scopeId , {signal: abortController.signal})
            .then(res => res.json())
            .then((json) => {
                return checkIsAuthorised(json);
            })
            .catch((err) => {
                console.error(err);
                return err;
            });
}


function getReachableIotDevices(scopeId,page) {
    let abortController = addAbortController(page);
    return fetch('/api/iot-service/reachable?scopeId=' + scopeId, {signal: abortController.signal})
    .then(res => res.json())
    .then((json) => {
        return checkIsAuthorised(json);
    })
    .catch((err) => {
        return err;
    });
}

function getAllLocks(page) {
    let abortController = addAbortController(page);
    return fetch('/api/iot-service/get-all-locks', {signal: abortController.signal})
        .then(res => res.json())
        .then((json) => {
            return checkIsAuthorised(json);
        }) .catch((err) => {
        return err;
    });
}

function getAllLockUsers(page) {
    let abortController = addAbortController(page);
    return fetch('/api/iot-service/get-all-locks-users', {signal: abortController.signal})
        .then(res => res.json())
        .then((json) => {
            return checkIsAuthorised(json);
        }).catch((err) => {
            return err;
        });
}

function getOnlineOfflineDevicesCount(scopeId, page) {
    let abortController = addAbortController(page);
    return fetch('/api/online-offline/locksCount?scopeId=' + scopeId, {signal: abortController.signal})
    .then((res) => {
        return res.json()
    })
    .then((json) => {          
        return checkIsAuthorised(json);
    })
    .catch((err) => {
        return err;
    });
}

function getIotUsers(scopeId, page) {
    let abortController = addAbortController(page);
    return fetch('/api/iotusers/all?scopeId=' + scopeId, {signal: abortController.signal})
        .then(res => res.json())
        .then((json) => {
            return checkIsAuthorised(json);
        })
        .catch((err) => {
            return err;});
}

function getAllUsersAssociatedDevices(scopeId, page) {
    let abortController = addAbortController(page);
    return fetch('/api/all-users-associated-devices/scopeId/' + scopeId, {signal: abortController.signal})
        .then(res => res.json())
        .then((json) => {
            return checkIsAuthorised(json);
        })
        .catch((err) => {
            return err;
        });
}

function getAllDeviceNames(scopeId, page) {
    let abortController = addAbortController(page);
    return fetch('/api/all-devices-names/scopeId/' + scopeId, {signal: abortController.signal})
        .then(res => res.json())
        .then((json) => {
            return checkIsAuthorised(json);
        })
        .catch((err) => {
            return err;});
}

function getAllUsersAssociatedWithTwoRFIDSharedDevices(scopeId, page) {
    let abortController = addAbortController(page);
    return fetch('/api/getRfidAlertUserNames/scopeId/' + scopeId, {signal: abortController.signal})
        .then(res => res.json())
        .then((json) => {
            return checkIsAuthorised(json);
        })
        .catch((err) => {
            return err;});
}

function getEmailTemplate(scopeId, page) {
    let abortController = addAbortController(page);
    return fetch('/api/emailTemplate/all?scopeId=' + scopeId, {signal: abortController.signal})
        .then(res => res.json())
        .then((json) => {
            return checkIsAuthorised(json);
        })
        .catch((err) => {
            return err;
        });
}

function migrateLocks(migration_details, from, to) {
    return fetch('/api/migrateLocks?from=' + from + '&to=' + to, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(migration_details),
    }).then((response)=>{
        return response.json();
    })
    // .then(data => this.setState({ postId: data.id }));
}

function getLocksUsers(macId, scopeId, page) {
    let abortController = addAbortController(page);
    return fetch('/api/iot-device-user/all?scopeId=' + scopeId +"&macId=" + macId, {signal: abortController.signal})
        .then(res => res.json())
        .then((json) => {
            return checkIsAuthorised(json);
        })
        .catch((err) => {
            return err;
        });
}

function getAssignedUsersFormSerialNumber(from, to, serialNumberList, page) {
    let _url = "/api/migration/scopes/from/" + from + "/to/" + to + "/device-users";
    serialNumberList.forEach(function (itr) {
        addParameterToURL(itr.SerialNumber);
    })

    function addParameterToURL(param){
        _url += (_url.split('?serialNumbers=')[1] ? '&serialNumbers=':'?serialNumbers=') + param;
    }
    let abortController = addAbortController(page);
    return fetch(_url, {signal: abortController.signal})
    .then(res => res.json())
    .then((json) => {
        return json;
    })
    .catch((err) => {
        return err;
    });
}


function getLockSerialNumbersByLockNames(lockNameList, page) {
    let _url = "/api/getLockSerialNumbers/lockName";
    lockNameList.forEach(function (val) {
        addParameterToURL(val);
    })

    function addParameterToURL(param){
        _url += (_url.split('?lockName=')[1] ? '&lockName=':'?lockName=') + param;
    }

    let abortController = addAbortController(page);
    return fetch(_url, {signal: abortController.signal})
    .then(res => res.json())
    .then((json) => {
        return json;
    })
    .catch((err) => {
        return err;
    });
}

function getAssignedUsersForMigration(from, to, page) {
    let abortController = addAbortController(page);
    return fetch("/api/migrate-users/scopes/from/" + from + "/to/" + to, {signal: abortController.signal})
    .then(res => res.json())
    .then((json) => {
        return checkIsAuthorised(json);
    })
    .catch((err) => {
        return err;
    });
}

function getLockHistory(startTime, endTime, macId, scopeId, page) {
    let abortController = addAbortController(page);
    return fetch("/api/lock-history/start/"+ startTime +"/end/" + endTime + "/macId/" + macId +"/scopeId/" + scopeId, {signal: abortController.signal})
    .then(res => res.json())
    .then((json) => {
        return checkIsAuthorised(json);
    })
    .catch((err) => {
        return err;
    })
}

function deleteLock(macId, scopeId) {
    return fetch("api/delete-lock/macId/"+macId+ "/scopeId/"+scopeId)
    .then ((res)=>{
        return res;
    })
    .catch((err)=> {
        return err;
    })
}

function getIotDevicesUsers(scopeId, page) {
    let abortController = addAbortController(page);
    return fetch("/api/iot-devices-users/scopeId/"+scopeId, {signal: abortController.signal})
    .then(res => res.json())
    .then((json) => {
        return checkIsAuthorised(json);
    })
    .catch((err) => {
        return err;
    })
}

function updateIotDeviceUser(userId, user, page) {    
    return fetch("/api/update-iot-device-users/userId/"+userId, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user),
    }).then((response)=>{
        return response.json();
    })
}

function createNewLockUser(user, page) {
    return fetch('/api/create-new-iot-lock-user', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user),
    }).then((response)=>{
        return response.json();
    })
}

function checkCardCSNStatus(user, page) {
    let abortController = addAbortController(page);
    return fetch("/api/check-card-csn-status/scopeId/"+user.scopeId+"/cardCSN/"+user.cardCSN, {signal: abortController.signal})
    .then(res => res.json())
    .then((json) => {
        return checkIsAuthorised(json);
    })
    .catch((err) => {
        return err;
    })
}

function createBulkIotUserForCurrentScope(user, isCardUsers) {
    return fetch('/api/create-bulk-iot-lock-users?isCardUsers=' + isCardUsers, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user),
    }).then((response)=>{
        return response.json();
    })
}

function getUserAssignedLocks(usersId, scopeId, page) {
    let abortController = addAbortController(page);
    return fetch("/api/iot-users-assigned-locks/userId/"+usersId+"/scopeId/"+scopeId, {signal: abortController.signal})
    .then(res => res.json())
    .then((json)=>{
        return checkIsAuthorised(json);
    })
    .catch((err)=> {
        return err;
    })
}

function deleteIotDeviceUser(userId, page) {
    return fetch("/api/delete-iot-device-user/userId/"+userId, {
        method: 'DELETE',
    }).then((response)=>{
        return response.json();
    })
    .catch((err)=>{
        return err;
    })
}

function getPcToolHistory(page) {
    let abortController = addAbortController(page);
    return fetch("/api/get-pc-tool-history", {signal: abortController.signal})
    .then(res => res.json())
    .then((json)=>{
        return checkIsAuthorised(json);
    })
    .catch((err)=> {
        return err;
    })
}

function getIotDeviceAssignedForUser(scopeId, page) {
    let abortController = addAbortController(page);
    return fetch("/api/get-iot-devices-assigned-for-locks/scopeId/"+scopeId, {signal: abortController.signal})
    .then(res => res.json())
    .then((json)=>{
        return checkIsAuthorised(json);
    })
    .catch((err)=>{
        return err;
    })
}

function saveDeviceLogs(info, page) {
    return fetch("/api/save-device-logs",{
        method:"POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info),
    })
    .then((response)=>{
        return response;
    })
    .catch((error)=>{
        return error;
    })
}

function fetchDeviceLogsUrl(fileKey, page) {
    let abortController = addAbortController(page);
    return fetch("/api/get-device-log-url/fileKey/"+fileKey, {signal: abortController.signal})
    .then((response) => {
        return response;
    })
    .catch((error)=>{
        return error;
    })
}


function resendActivationMail(email, page) {
    return fetch("/api/resend-activation-link/emailId/"+email,{
        method:'POST',
        headers: { 'Content-Type': 'application/json' },
        body: null,
    })
    .then((response)=>{
        return response;
    })
}

function getLockInfo(scopeId) {
    return fetch("/api/get-lock-csvData/scopeId/"+scopeId).then((response) => {
        return response.json();
    }).catch((err)=>{
        return err;
    })
}

function getIotAppDetails(page) {
    let abortController = addAbortController(page);
    return fetch("/api/iot-app-details", {signal: abortController.signal}).then((response)=>{
        return response.json();
    }).catch((err)=>{
        return err;
    })
}

function getLocksUsersInfo(scopeId) {
    return fetch("/api/get-lock-users-csvData/scopeId/"+scopeId).then((response) => {
        return response.json();
    }).catch((err)=>{
        return err;
    })
}

function resetUserPassword(callback, user_details) {    
    return fetch('/api/lock-user/resetPassword', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user_details)
    }).then(callback)
}


function sendInvitationEmailWhileConfiguringScheduledAddUser(send_email_payload){
    return fetch("/api/send-email/invitationEmailWhileConfScheduledAddUser", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(send_email_payload)
    }).then((res)=>{
        if(res.status === 201 || res.status === 200){
            return res;
        }else {
            return res;
        }
    });
}

function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }
    const error = new Error(`HTTP Error ${response.statusText}`);
    error.status = response.statusText;
    error.response = response;
    throw error;
}

function parseJSON(response) {
    return response.json();
}

function abortSignal(page) {
    let abortSignals = allAbortControlllers.hasOwnProperty(page) ? allAbortControlllers[page] : [];
    _.forEach(abortSignals, (abortController)=> {
        abortController.abort()
    })
}

const IotServiceClient = {  getIotAppDetails ,getIotDevices, getLocksUsersInfo, getLockInfo, getReachableIotDevices,
                            getOnlineOfflineDevicesCount, getIotUsers, getEmailTemplate, getDeviceUsersCount, getLocksUsers, fetchDeviceLogsUrl ,
                            saveDeviceLogs, getAssignedUsersFormSerialNumber ,migrateLocks, getAssignedUsersForMigration, getLockHistory,
                            deleteLock, getIotDevicesUsers , getUserAssignedLocks, updateIotDeviceUser, deleteIotDeviceUser,
                            getIotDeviceAssignedForUser, getPcToolHistory, createNewLockUser, createBulkIotUserForCurrentScope,
                            resendActivationMail, getAllLocks, getAllLockUsers, getAllUsersAssociatedDevices, getAllDeviceNames, getAllUsersAssociatedWithTwoRFIDSharedDevices, resetUserPassword,
                            sendInvitationEmailWhileConfiguringScheduledAddUser, checkCardCSNStatus, abortSignal,getLockSerialNumbersByLockNames};
export default IotServiceClient;
