
function getFrontendAppDetails() {
    return fetch("/api/frontend-app-details").then((response)=>{
        return response.json();
    }).catch((err)=>{
        return err;
    })
}

const frontendServiceClient = { getFrontendAppDetails};
export default frontendServiceClient;