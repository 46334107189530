import React, {Component} from "react";
import PubSubChannels from "./PubSubChannels";
import AuthClient from "../clients/AuthClient";

// export default class PubSubUtils extends Component {
//     // this.getDevicesEvent(scope) {
//     //     return PubSubChannels.pubsub_channels.DEVICES.replace("scopeId", scope);
//     // }
//     //
//     // this.getDevicesEvent = function() {
//     //     return PubSubChannels.DEVICES.replace("scopeId", SessionService.getCurrentScopeId());
//     // };
// }
const getDevicesEvent = (scope) => {
    return PubSubChannels.pubsub_channels.DEVICES.replace("scopeId", scope);
}

const getDevicesUserMappingsEvent = (scope) => {
    return PubSubChannels.pubsub_channels.DEVICE_USER_MAPPINGS.replace("scopeId", scope)
}



const PubSubUtils = {getDevicesEvent, getDevicesUserMappingsEvent}
export default PubSubUtils;
