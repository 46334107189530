import React from 'react';
import { Button,Table,TableBody,TableCell,TableContainer,TableHead,TablePagination,TableRow,TableSortLabel,Box,Checkbox,Select,MenuItem,Tooltip,LinearProgress,Icon, IconButton, Snackbar } from '@mui/material';
import { PermIdentity,Download,Edit,Close,Search,Refresh,Settings,FirstPage,LastPage,KeyboardArrowLeft,KeyboardArrowRight,Person,PersonAdd,VerifiedUser,Delete,MailOutline,Wifi } from '@mui/icons-material';
import {visuallyHidden} from '@mui/utils';
import '../stylesheet/DashboardGatewayPage.css';
import DraggableComponent from './DraggableComponent';
import '../stylesheet/DashBoardUsersPage.css';
import paginationStyle from "../stylesheet/pagination.module.css";
import "../stylesheet/common.css";
import AuthClient from './../clients/AuthClient';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import MqttEventHandler from './Mqtt/MqttEventHandler';
import PubSubChannels from '../pubsub/PubSubChannels';



function TablePaginationActions(props) {
  const {count, page, rowsPerPage, onPageChange} = props;

  function handleFirstPageButtonClick(event) {
    onPageChange(event, 0);
  }

  function handleBackButtonClick(event) {
    onPageChange(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onPageChange(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={paginationStyle.paginationActionIcons}>
      <FirstPage
        onClick={() => {
          if (page > 0) handleFirstPageButtonClick();
        }}
        sx={{
          color: page === 0 ? 'rgba(0,0,0,0.26)' : 'black',
          cursor: page === 0 ? 'auto' : 'pointer',
          marginRight: '8px'
        }}
      />
      <KeyboardArrowLeft
        onClick={() => {
          if (page > 0) handleBackButtonClick();
        }}
        sx={{
          color: page === 0 ? 'rgba(0,0,0,0.26)' : 'black',
          cursor: page === 0 ? 'auto' : 'pointer',
          marginRight: '8px'
        }}
      />
      <KeyboardArrowRight
        onClick={() => {
          if (page !== Math.ceil(count / rowsPerPage) - 1)
            handleNextButtonClick();
        }}
        sx={{
          color:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0
              ? 'black'
              : 'rgba(0,0,0,0.26)',
          cursor:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0 ? 'pointer' : 'auto',
            marginRight: '8px'
        }}
      />
      <LastPage
        onClick={() => {
          if (page !== Math.ceil(count / rowsPerPage) - 1)
            handleLastPageButtonClick();
        }}
        sx={{
          color:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0
              ? 'black'
              : 'rgba(0,0,0,0.26)',
          cursor:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0 ? 'pointer' : 'auto',
        }}
      />
    </div>
  );
}

function EnhancedTableHead(props) {
  const {onSelectAllClick, order, orderBy, onRequestSort} = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: 'mergedName',
      numeric: false,
      disablePadding: false,
      label: 'Name',
      width: '90px',
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'email',
      width: '90px',
    },
    {
      id: 'permissions',
      numeric: true,
      disablePadding: false,
      label: 'permissions',
      width: '90px',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Status',
      width: '90px',
    },
    {
      id: 'action',
      numeric: true,
      disablePadding: false,
      label: 'Action',
      width: '90px',
    },
  ];

  const [clickCount, setClickCount] = React.useState(1);

  const selectAll = (event) => {
    setClickCount(clickCount + 1);
    onSelectAllClick(clickCount);
  };

  const [changeTextColor,setChangeTextColor] = React.useState('');
  
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="left">
            {headCell.label !== 'Action' && headCell.label !== 'permissions' ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                sx={{
                  width: headCell.width,
                  overflowWrap: 'break-word',
                  color: orderBy !== headCell.id ? '#0000008A !important' : '#000000DE !important',
                }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <Box
                sx={{
                  width: headCell.width,
                  overflowWrap: 'break-word',
                  color: changeTextColor !== "pending instructions" ? '#0000008A !important' : '#000000DE !important',
                }}
              >
                {headCell.label}
              </Box>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function DashBoardUsersPage() {
  const [SearchBar, setSearchBar] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(sessionStorage.dashboardUsersRPP ? Number(sessionStorage.dashboardUsersRPP) : 10);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('mergedName');
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(false);
  const [editMode, setEdiitMode] = React.useState(false);
  const [showEditIcon, setShowEditIcon] = React.useState(true);
  const [searchText, setSearchText] = React.useState('');
  const [checkAll, setCheckAll] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const portalUsersListData = [];
  const [pageNumber, setPageNumber] = React.useState(0);

  const [editPermissionDialog, setEditPermissionDialog] = React.useState(false);

  const [permissionsData, setPermissionsData] = React.useState([]);

  const [createPermissionDialog, setCreatePermissionDialog] = React.useState(false);

  const [viewPermissionDialog, setViewPermissionDialog] = React.useState(false);

  const [totalUsersListCount, setTotalusersListCount] = React.useState(0);

  const [deletePermissionDialog, setDeletePermissionDialog] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();


  function createData(
    id,
    name,
    email,
    firstName,
    lastName,
    permissions,
    status,
    owner
  ) {
    return {
      id,
      name,
      email,
      firstName,
      lastName,
      permissions,
      status,
      owner,
    };
  }

  const getPortalUsers = () => {
    let isRefreshingWithFilter = searchText.length > 0;
    AuthClient.getPortalUsers(sessionStorage.tenantId, "usersPage").then((portalUserData) => {
      _.forEach(portalUserData,(element)=>{
        element.mergedName = element.mergedName = element.firstName ? (element.lastName ? element.firstName+ " " +element.lastName : element.firstName) : (element.lastName ? element.lastName : "No Name");
        element.email = element.email ? element.email : 'No-email';
      })
      window.portalUserData = portalUserData;
      if(!isRefreshingWithFilter) {
        setRows(portalUserData);
        resetPaginationData([]);
      } else {
        setTimeout(function(){
              handleTextCange(searchText);
          }, 2000);
      }
    })
  }

  const handleEvent = (data) => {
    let usersAllData = [];
    let message = data.message ? data.message : "";
    let payload = data.payload ? data.payload : "";
    let updateDataInUI = false;
    if (message === "updateUser" || message === "resetUser") {
      _.forEach(window.portalUserData, (val) => {

        if (val.id === payload.id) {
          val.firstName = payload.firstName;
          val.lastName = payload.lastName;
          val.owner = payload.owner;
          val.permissions = payload.permissions;
          val.status = payload.status;
          val.mergedName = payload.firstName ? (payload.lastName ? payload.firstName+ " " +payload.lastName : payload.firstName) : (payload.lastName ? payload.lastName : "No Name");
          val.email = payload.email ? payload.email : 'No-email';
        }
        usersAllData.push(val);
      })
      updateDataInUI = true;
    }else if (message === "deleteUser") {
      _.forEach(window.portalUserData, (val) => {
        if (val.id !== payload.id) {
          usersAllData.push(val);
        }
      })
      updateDataInUI = true;
    }else if (message === "newUser") {
      payload.mergedName = payload.firstName ? (payload.lastName ? payload.firstName+ " " +payload.lastName : payload.firstName) : (payload.lastName ? payload.lastName : "No Name");
      payload.email = payload.email ? payload.email : 'No-email';
      _.forEach(window.portalUserData, (val) => {
        usersAllData.push(val);
      });
      usersAllData.push(payload);
      updateDataInUI = true;
    }

    if (updateDataInUI){
      window.portalUserData = usersAllData;
      let text = document.getElementById("searchText") ? document.getElementById("searchText").value : "";
      handleTextCange(text);
    }
  }

  const onPortalUsersEvent = (data) => {
    enqueueSnackbar("Refreshing Portal Users ...");
    data = data ? JSON.parse(data.toString()) : "" ;
    if (data.hasOwnProperty("message")) {
      handleEvent(data);
    }
  }

  const subscribeMqttChannels = () => {
    window.portalUsersMqttEventHandler = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.PORTAL_USERS.replace("scopeId",sessionStorage.tenantId), "PortalUSersController", onPortalUsersEvent)
  }

  React.useEffect(() => {
    window.oldPreservedtenantUsersPageNumber = 0;
    getPortalUsers();   
    subscribeMqttChannels()
    enqueueSnackbar("Fetching Portal Users ...")     
    return () => {
      if (window.portalUsersMqttEventHandler){
        window.portalUsersMqttEventHandler.unsubscribe();
      }
      AuthClient.abortSignal("usersPage");
    }
  }, []);

  const refreshData = () => {
    enqueueSnackbar("Refreshing Portal Users ...")
    getPortalUsers();
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleClick = (event, row) => {
    let newSelected = [];

    if (event.target.checked) {
      if (selected.length > 0) {
        selected.forEach((element) => {
          newSelected.push(element);
        });
        if (JSON.stringify(newSelected).indexOf(row.id) === -1) {
          newSelected.push(row);
        }
      } else {
        newSelected.push(row);
      }
    } else {
      selected.forEach((element) => {
        if (element.id !== row.id) {
          newSelected.push(element);
        }
      });
    }

    setSelected(newSelected);
  };

  let emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleSelectAllClick = (checkboxClickCount) => {
    if (checkboxClickCount % 2 !== 0) {
      setCheckAll(true);
      // eslint-disable-next-line array-callback-return
      const newSelecteds = rows.filter((n) => {
        if (
          n.Status !== 'INACTIVE' &&
          typeof n.id !== 'undefined' &&
          n.id !== null
        ) {
          return n;
        }
      });
      setSelected([]);
      setSelected(newSelecteds);
      return;
    }
    setCheckAll(false);
    setSelected([]);
  };

  function numberCharacterOrganizer(array, orderOfsort, orderByOfSort){
      let numbers = [], characters = [];
      _.forEach(array, (element) => {
          if(typeof(element[orderByOfSort]) === 'string' ? Number(element[orderByOfSort] ? element[orderByOfSort].replaceAll(" ","") : element[orderByOfSort]) : Number(element[orderByOfSort])){
            numbers.push(element);
          }
          else{
            orderByOfSort === 'email' && element[orderByOfSort]!== null && Number(element[orderByOfSort].replaceAll(" ","").substring(0,element[orderByOfSort].indexOf('@'))) ? numbers.push(element) : characters.push(element);
          }
      })
      array = orderOfsort === 'asc' ? characters.concat(numbers): numbers.concat(characters);
      return array;
    }

  function stableSort(array, orderOfsort, orderByOfSort) {
    array = numberCharacterOrganizer(array, orderOfsort, orderByOfSort);
    if(orderByOfSort !== 'email'){
        array = _.orderBy(array, [obj => obj[orderByOfSort] !== null ? typeof(obj[orderByOfSort]) === 'string' ? !Number(_.get(obj, orderByOfSort, '').replaceAll(" ","")) ? _.get(obj, orderByOfSort, '').toString().toLowerCase() : Number(_.get(obj, orderByOfSort).replaceAll(" ","")) : Number(_.get(obj, orderByOfSort,'')) : ""], [orderOfsort]);
    }else{
        array = _.orderBy(array, [obj => obj[orderByOfSort] !== null ? typeof(obj[orderByOfSort]) === 'string' ? !Number(_.get(obj, orderByOfSort, '').replaceAll(" ","").substring(0,obj[orderByOfSort].indexOf('@'))) ? _.get(obj, orderByOfSort, '').toString().toLowerCase() : Number(_.get(obj, orderByOfSort).replaceAll(" ","").substring(0,obj[orderByOfSort].indexOf('@'))) : Number(_.get(obj, orderByOfSort,'')) : ""], [orderOfsort]);
    }
    return array;
  }

  function descendingComparator(a, b, orderBy) {
    if (typeof b[orderBy] !== 'undefined' && typeof a[orderBy] !== 'undefined' && b[orderBy] !== null && a[orderBy] !== null){
      if (b[orderBy].toString().toLowerCase() < a[orderBy].toString().toLowerCase()) {
        return -1;
      }
      if (b[orderBy].toString().toLowerCase() > a[orderBy].toString().toLowerCase()) {
        return 1;
      }
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPageNumber(newPage);
    window.oldPreservedtenantUsersPageNumber = newPage;
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChange = (event) => {
    setPageNumber(event.target.value);
    setPage(event.target.value);
    window.oldPreservedtenantUsersPageNumber = event.target.value;
  };

  const handleRowPerPage = (event) => {
    let rowPerPageValue = event.target.value;  
    if (rows.length > 0) {
      let pageNumberCount = Math.ceil((rows.length/rowPerPageValue)) - 1;                
      if (window.oldPreservedtenantUsersPageNumber > pageNumberCount) {
        setPage(pageNumberCount);
        setPageNumber(pageNumberCount);        
        window.oldPreservedtenantUsersPageNumber = pageNumberCount;
      }else {
        setPage(window.oldPreservedtenantUsersPageNumber);
        setPageNumber(window.oldPreservedtenantUsersPageNumber);
      }
    }
    sessionStorage.dashboardUsersRPP = rowPerPageValue;
    setRowsPerPage(rowPerPageValue);
  };

  const checkSearchTextExist = (data, key,  text) => {
    let value = data[key];
    return value !== null && typeof value !== 'undefined' && value !== "" ? value.toString().toLowerCase().replaceAll(" ","").indexOf(text) !== -1 : false;
  };

  const handleTextCange = (textValue) => {
    setSearchText(textValue);
    let sortedDevices = [];
    let searchText = document.getElementById("searchText") ? document.getElementById("searchText").value : "";
    if (searchText !== "" && typeof searchText !== 'undefined' && searchText !==  null){
        let text = searchText.toLowerCase().replaceAll(" ","");
        _.forEach(window.portalUserData,(value)=>{
          if (checkSearchTextExist(value, "mergedName", text) || checkSearchTextExist(value, "email", text)){
            sortedDevices.push(value);
          }
        })
        sortedDevices = _.uniq(sortedDevices);
        setRows(sortedDevices);
    }else {
      setRows(window.portalUserData);
    }
    resetPaginationData(sortedDevices);    
  };

  const resetPaginationData = (data) => {
    if (window.oldPreservedtenantUsersPageNumber) {
      if (data.length > 0) {
        let pageNumberCount = Math.ceil((data.length/rowsPerPage)) - 1;                
        if (window.oldPreservedtenantUsersPageNumber > pageNumberCount) {
          setPage(pageNumberCount);
          setPageNumber(pageNumberCount);
          window.oldPreservedtenantUsersPageNumber = pageNumberCount;
        }
      }else {
        let pageNumberCount = Math.ceil((window.portalUserData.length/rowsPerPage)) - 1;
        if (window.oldPreservedtenantUsersPageNumber > pageNumberCount){
          setPage(pageNumberCount);
          setPageNumber(pageNumberCount);
          window.oldPreservedtenantUsersPageNumber = pageNumberCount;
        }else if (window.oldPreservedtenantUsersPageNumber <= pageNumberCount && pageNumberCount > 0) {
          setPage(window.oldPreservedtenantUsersPageNumber);
          setPageNumber(window.oldPreservedtenantUsersPageNumber);
        }else {
          setPage(0);
          setPageNumber(0);
          window.oldPreservedtenantUsersPageNumber = 0;
        }        
      }
    }else {
      setPageNumber(0);
      setPage(0);   
      window.oldPreservedtenantUsersPageNumber = 0;
    }
  }

  const rowPerPageLabel = (
    <div className={paginationStyle.rowPerPageLabelDiv}>
      <Box className={paginationStyle.page}>
        <span style={{marginRight: '32px'}}>Page</span>
      </Box>
      <Box className={paginationStyle.pageSelectBox}>
        <Select value={pageNumber} onChange={handleChange}>
          {
            // eslint-disable-next-line array-callback-return
            Array(Math.ceil(rows.length / rowsPerPage))
              .fill()
              .map((_, index) => {
                return (
                  <MenuItem value={index} sx={{minWidth: '64px'}}>
                    {index + 1}
                  </MenuItem>
                );
              })
          }
        </Select>
      </Box>
      <Box className={paginationStyle.rowPerPageLabelDiv}>
        <span style={{marginRight: '32px'}}>Rows Per Page</span>
      </Box>
      <Box className={paginationStyle.rowPerPageSelectBox}>
        <Select value={rowsPerPage} onChange={handleRowPerPage}>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={15}>15</MenuItem>
        </Select>
      </Box>
    </div>
  );

  const handleCloseEditPermissionDialog = (value) => {
    getPortalUsers();
    setEditPermissionDialog(value);
    setCreatePermissionDialog(value);
    setViewPermissionDialog(value);
    setDeletePermissionDialog(value);
  };

  const handleResendActivation = (data) => {

    AuthClient.forgotPassword(response => {
      if (response.status === 200) {
        enqueueSnackbar("Activation Link sent successfully")
      } else {
        enqueueSnackbar("Error occurred while sending the activation link")
      }

    }, data);
    
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: '20px 0px 20px 24px',
        }}
      >
        <span style={{fontSize: '20px', marginRight: 'auto'}}>
          Portal Users
        </span>
        <input
          id="searchText"
          type="text"
          onChange={(event)=>handleTextCange(event.target.value)}
          placeholder="Search Portal Users..."
          style={{
            marginRight: '4px',
            minWidth: '37px',
            width: '140px',
            maxWidth: '100%',
            display: SearchBar ? 'flex' : 'none',
          }}
        />
        <Close
          style={{
            marginRight: '4px',
            cursor: 'pointer',
            display: SearchBar ? 'flex' : 'none',
          }}
          onClick={() => {            
            setSearchText('');
            document.getElementById('searchText').value = '';
            setSearchBar(false);
            setRows(window.portalUserData);
          }}
        />
        <Tooltip title="Search" placement="top" arrow>
          <Search
            style={{
              marginRight: '4px',
              cursor: 'pointer',
              display: !SearchBar ? 'flex' : 'none',
            }}
            onClick={() => {setSearchBar(true);
                setTimeout(() => {document.getElementById("searchText").focus();},100);
            }}
          />
        </Tooltip>
        <Tooltip title="Refresh" placement="top" arrow>
          <Refresh style={{marginRight: '14px',cursor:"pointer"}} onClick={()=>refreshData()} />
        </Tooltip>
      </Box>
      <Box style={{width: '100%', height: 'auto', marginBottom: '86px'}}>
        <TableContainer>
          <Table>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              check_all={checkAll}
              onSelectAllClick={(checked) => handleSelectAllClick(checked)}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(rows,order, orderBy)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                          <TableRow
                              hover
                              onClick={(event) => handleClick(event, row.name)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                              className={paginationStyle.tableBodyRow}
                              sx={{borderBottom:'1px solid #c1a6a6'}}
                          >
                            <TableCell align="left">{row.mergedName || "-"}</TableCell>
                            <TableCell align="left">{row.email || "-"}</TableCell>
                            <TableCell align="left">
                              <Tooltip placement="top" title="Permissions" arrow>
                                <PermIdentity
                                  className="cursorPointer"
                                  onClick={() => {
                                    setViewPermissionDialog(true);
                                    setPermissionsData(row);
                                  }}
                                  sx={{color:'#0000008A',fontSize: '20px'}}
                                />
                              </Tooltip>
                            </TableCell>
                            <TableCell align="left">
                                {row.status === 'ACTIVE' ? (
                                <Tooltip placement="top" title="Active" arrow>
                                  <VerifiedUser sx={{color:'#0000008A',fontSize: '20px'}}/>
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  placement="top"
                                  title="Resend activation mail"
                                  arrow
                                >
                                  <MailOutline sx={{color:'#0000008A',fontSize: '20px',cursor:"pointer"}} onClick={()=> handleResendActivation(row)}/>
                                </Tooltip>
                              )}
                            </TableCell>
                            <TableCell align="left">
                              <Box className="usersActions cursorPointer">
                                {
                                  JSON.parse(sessionStorage.userDetails).permissions.includes("PORTAL_USER_WRITE") ?
                                    JSON.parse(sessionStorage.userDetails).user.email !== row.email && !row.owner ?
                                      <Box sx={{margin: '0px 10px 0px 0px'}}>
                                        <Edit
                                          onClick={() => {
                                            setEditPermissionDialog(true);
                                            row.firstNameTextLength = row.firstName !== "" && typeof row.firstName !== 'undefined' && row.firstName ? row.firstName.length + "/20" : "0/20";
                                            row.lastNameTextLength = row.lastName !== "" && typeof row.lastName !== 'undefined' && row.lastName ? row.lastName.length + "/20" : "0/20";
                                            setPermissionsData(row);
                                          }}
                                          sx={{color:'#0000008A',fontSize: '20px'}}
                                      />
                                      </Box>
                                    :
                                      null
                                  :
                                    null
                                }
                                {
                                  JSON.parse(sessionStorage.userDetails).permissions.includes("PORTAL_USER_DELETE") ?
                                    JSON.parse(sessionStorage.userDetails).user.email !== row.email && !row.owner ?
                                      <Box sx={{margin: '0px 10px 0px 0px'}}>
                                        <Delete
                                          className="dashboardDeleteIcon cursorPointer"
                                          onClick={() => {
                                            setDeletePermissionDialog(true);                                            
                                            setPermissionsData(row);
                                          }}
                                          sx={{color:'#0000008A',fontSize: '20px'}}
                                        />
                                      </Box>
                                    :
                                      null
                                  :
                                    null
                                }
                              </Box>
                            </TableCell>
                          </TableRow>
                      );
                    })}
              </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{width: '100%', overflowX: 'hidden'}}
          labelRowsPerPage={rowPerPageLabel}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          editMode={editMode}
          page={pageNumber}
          className="pagination"
          SelectProps={{
            inputProps: {'aria-label': 'rows per page'},
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Box>
      <Button
        onClick={() => {          
          setCreatePermissionDialog(true);
        }}
        style={{
          position: 'fixed',
          bottom: '37px',
          right: '40px',
          height: '62px',
          miWwidth: '55px',
          maxWidth: '55px',
          borderRadius: '50%',
          backgroundColor: 'rgb(20,19,139)',
          display: JSON.parse(sessionStorage.userDetails).permissions.includes("PORTAL_USER_WRITE") ? 'flex' : 'none',
        }}
      >
        <PersonAdd sx={{color: '#fff'}} />
      </Button>

      {/* EDIT PERMISSION DIALOG */}

      {editPermissionDialog ? (
        <>
          <DraggableComponent
            title="Update Portal User"
            disabled={false}
            button_name="Update"
            role={              
              permissionsData.permissions.length >= 27
                ? 'superadmin'
                : permissionsData.permissions.length === 13
                ? 'admin'
                : 'custom'
            }
            data={permissionsData}
            mode="view"
            show_permission_dialog={editPermissionDialog}
            close_permission_dialog={(value) =>
              handleCloseEditPermissionDialog(value)
            }
            dialog="permissionDialog"
          />
        </>
      ) : null}

      {/* CREATE PERMISSION DIALOG */}
      {createPermissionDialog ? (
        <>
          <DraggableComponent
            title="Create Portal User"
            disabled={false}
            button_name="Create"
            role="superadmin"
            data={[]}
            mode="view"
            show_permission_dialog={createPermissionDialog}
            close_permission_dialog={(value) =>
              handleCloseEditPermissionDialog(value)
            }
            dialog="permissionDialog"
          />
        </>
      ) : null}

      {/* EDIT PERMISSION DIALOG */}

      {viewPermissionDialog ? (
        <>
          <DraggableComponent
            title="User Permissions"
            disable={true}
            role={
              permissionsData.permissions.length === 27
                ? 'superadmin'
                : permissionsData.permissions.length === 13
                ? 'admin'
                : 'custom'
            }
            data={permissionsData}
            mode="view"
            show_permission_dialog={viewPermissionDialog}
            close_permission_dialog={(value) =>
              handleCloseEditPermissionDialog(value)
            }
            dialog="permissionDialog"
          />
        </>
      ) : null}

      {/* DELETE USER DIALOG */}

      {deletePermissionDialog ? (
        <>
          <DraggableComponent
            title="Delete Portal User"
            disable={false}
            data={permissionsData}
            mode="view"
            show_delete_permission_dialog={deletePermissionDialog}
            close_delete_permission_dialog={(value) =>
              handleCloseEditPermissionDialog(value)
            }
            dialog="deletePermissionDialog"
          />
        </>
      ) : null}
    </>
  );
}
