import React, { Component } from 'react';
import {EditorState, convertToRaw, ContentState} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { DialogActions,Button,Dialog,DialogContent,DialogTitle,Box } from "@mui/material";
import {convertToHTML} from "draft-convert";
import AuthClient from "../clients/AuthClient";
import IotServiceClient from "../clients/IotServiceClient";
import ArrowBack from '@mui/icons-material/ArrowBack';
import _ from 'lodash';

class MyEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty(),
            open: false,
            convertedContent: null,
            title:"",
            dynamicFIelds: "",
        }
    }
    getTitle(template){
        if (template === "welcomeEmailTemplate") {
            return "Compose Welcome Email";
        }else if (template === "welcomeLockEmailTemplate"){
            return "Compose WelcomeV2 Email";
        }else if (template === "welcomeRfidLockEmailTemplate"){
            return "Compose WelcomeRfid Email"
        }else if (template === "welcomeSelfUserEmailTemplate"){
            return "Compose WelcomeRfid(self) Email";
        }else if (template === "registrationEmailTemplate"){
            return "Compose Registration Email";
        }else if (template === "passwordEmailTemplate"){
            return "Compose Password Email";
        } else if (template === "scheduleLockEmailTemplate") {
            return "Compose Welcome Email for Scheduled Add User"
        }
    }

    getDynamicFields(template) {
        if (template === "welcomeEmailTemplate") {
            return "{{frontendUrl}} {{name}} {{activationUrl}}";
        }else if (template === "welcomeLockEmailTemplate"){
            return "{{lockName}} {{lockPasscode}} {{userEmail}}";
        }else if (template === "welcomeRfidLockEmailTemplate"){
            return "{{lockName}} {{userEmail}}";
        }else if (template === "welcomeSelfUserEmailTemplate"){
            return "{{lockName}} {{userEmail}}";
        }else if (template === "registrationEmailTemplate"){
            return "{{activationUrl}}";
        }else if (template === "passwordEmailTemplate"){
            return "{{activationUrl}} {{name}}";
        }
    } 

    onEditorStateChange: Function = (editorState) => {
        this.setState({
            editorState,
        });
        this.setState({convertedContent: draftToHtml(convertToRaw(editorState.getCurrentContent()))});
    };
    emailData = {};
    fetchTemplate=() => {
        this.setState({editorState:EditorState.createEmpty()});
        IotServiceClient.getEmailTemplate( sessionStorage.tenantId ? sessionStorage.tenantId : this.props.tenant[0], "emailEditor").then((res) => {
            let data = {};
            _.forEach(res,(value,key)=>{
                data[key] = value ? value.replace(/&#10;/g,"").replace(/&#9;/g,"") : value;    
            })
            this.emailData = data;
            if (data[this.props.selectedTemplate]){                    
                const contentBlocks = htmlToDraft(data[this.props.selectedTemplate]);
                const contentState = ContentState.createFromBlockArray(contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                this.setState({editorState});
            }else {                
                this.setState({editorState: ""});
            }         
        });
        this.setState({
            title: this.getTitle(this.props.selectedTemplate),
            dynamicFIelds : this.getDynamicFields(this.props.selectedTemplate)
        });
    }
    handleSave=() => {
        this.emailData[this.props.selectedTemplate] = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
        AuthClient.updateEmailTemplate(response => {
            if(response.status == 200){
                this.setState({open:false});
            }
        }, this.emailData);
    }

    handlePreview=() => {
        this.setState({showPreview:true})
        let previewInterval = null;
        previewInterval = setInterval(() => {
            if (document.getElementById("showpreview")){
                document.getElementById("showpreview").innerHTML = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
                clearInterval(previewInterval);
            }
        }, 1000);        
    }
    componentWillUnmount() {
        this.setState({showPreview:false});
        IotServiceClient.abortSignal("emailEditor")
    }
    render() {
         const { editorState } = this.state;
        
        return (
            <>
                <Button style={{float: 'right'}} onClick={() => {this.setState({open:true});this.fetchTemplate()}}>
                    Next
                </Button>
                <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    fullScreen={false}
                    open={this.state.open}
                    onClose={() =>this.setState({open:false})}
                    aria-labelledby="responsive-dialog-title"
                >
                    {
                        !this.state.showPreview ?
                            <DialogTitle id="responsive-dialog-title">
                                {this.state.title}
                            </DialogTitle>
                        :
                            <DialogTitle id="responsive-dialog-title">
                                <ArrowBack onClick={() => this.setState({showPreview:false})} sx={{cursor:"pointer"}} />
                            </DialogTitle>
                    }                    
                    <DialogContent>
                        <div>
                            {
                                !this.state.showPreview ?
                                    <>
                                        <Box sx={{height:"500px",overflowY:"auto"}}>
                                            <Editor
                                                editorState={editorState}
                                                wrapperClassName="demo-wrapper"
                                                editorClassName="demo-editor"
                                                onEditorStateChange={this.onEditorStateChange}
                                            />
                                        </Box>
                                        <Box sx={{marginRight:"auto",marginTop:"10px"}}>
                                            <Box sx={{color:"#212936",fontSize:"16px",fontWeight:"bold"}}>
                                                Dynamic Email Fields
                                            </Box>
                                            <Box>
                                                {this.state.dynamicFIelds}
                                            </Box>
                                        </Box>
                                    </>
                                :
                                    <Box sx={{display:"flex",flexDirection:"column"}}>
                                        <center>
                                            <img src='/wesko-eLock_logo.jpg' height="68" width="230" />
                                        </center>
                                        {
                                            this.state.title === "Compose WelcomeV2 Email"?
                                            <Box sx={{width:"100%"}}>
                                                <Box className="playStoreIcons" sx={{padding:"4px"}}>
                                                    <a href="https://play.google.com/store/apps/details?id=com.caribou.wesko"style={{textDecoration:"none"}} target="_blank">
                                                        <img alt="Google Play" width="144" height="44" src="/google-play-badge.png" style={{border:0,cursor:"pointer"}} />
                                                    </a>  
                                                    &nbsp;
                                                    <a href="https://itunes.apple.com/us/app/wesko-lock-app/id1023697498?ls=1&mt=8" style={{textDecoration:"none"}} target="_blank">
                                                        <img alt="App Store" width="144" height="44" src="/app-store-badge.png" style={{border:0,cursor:"pointer"}} />
                                                    </a>                                                                
                                                </Box>
                                            </Box>
                                            :
                                                this.state.title === "Compose Welcome Email for Scheduled Add User"?
                                                    <Box sx={{width:"100%"}}>
                                                        <Box className="playStoreIcons" sx={{padding:"4px"}}>
                                                            <a href="https://play.google.com/store/apps/details?id=com.caribou.wesko"style={{textDecoration:"none"}} target="_blank">
                                                                <img alt="Google Play" width="144" height="44" src="/google-play-badge.png" style={{border:0,cursor:"pointer"}} />
                                                            </a>
                                                            &nbsp;
                                                            <a href="https://itunes.apple.com/us/app/wesko-lock-app/id1023697498?ls=1&mt=8" style={{textDecoration:"none"}} target="_blank">
                                                                <img alt="App Store" width="144" height="44" src="/app-store-badge.png" style={{border:0,cursor:"pointer"}} />
                                                            </a>
                                                        </Box>
                                                    </Box>
                                                    :
                                                    this.state.title === "Compose Welcome Email"?
                                                        <Box sx={{width:"100%"}}>
                                                            <Box className="playStoreIcons" sx={{padding:"4px"}}>
                                                                <a href="https://play.google.com/store/apps/details?id=com.caribou.wesko"style={{textDecoration:"none"}} target="_blank">
                                                                    <img alt="Google Play" width="144" height="44" src="/google-play-badge.png" style={{border:0,cursor:"pointer"}} />
                                                                </a>
                                                                &nbsp;
                                                                <a href="https://itunes.apple.com/us/app/wesko-lock-app/id1023697498?ls=1&mt=8" style={{textDecoration:"none"}} target="_blank">
                                                                    <img alt="App Store" width="144" height="44" src="/app-store-badge.png" style={{border:0,cursor:"pointer"}} />
                                                                </a>
                                                            </Box>
                                                        </Box>
                                                        :

                                                        this.state.title === "Compose WelcomeRfid Email"?
                                                            sessionStorage.checkIq === "643d185e7b25920006f901e9" || sessionStorage.checkIq === "640f85fb1e0c3500061e2e55" ?
                                                                <Box sx={{width:"100%"}}>
                                                                    <Box className="playStoreIcons" sx={{padding:"4px"}}>
                                                                        <a href="https://play.google.com/store/apps/details?id=com.caribou.wesko"style={{textDecoration:"none"}} target="_blank">
                                                                            <img alt="Google Play" width="144" height="54" src="/google-play-badge-french.png" style={{border:0,cursor:"pointer"}} />
                                                                        </a>
                                                                        &nbsp;
                                                                        <a href="https://itunes.apple.com/us/app/wesko-lock-app/id1023697498?ls=1&mt=8" style={{textDecoration:"none"}} target="_blank">
                                                                            <img alt="App Store" width="144" height="44" src="/app-store-badge-french.png" style={{border:0,cursor:"pointer"}} />
                                                                        </a>
                                                                    </Box>
                                                                </Box> :
                                                                <Box sx={{width:"100%"}}>
                                                                    <p>{}</p>
                                                                    <Box className="playStoreIcons" sx={{padding:"4px"}}>
                                                                        <a href="https://play.google.com/store/apps/details?id=com.caribou.wesko"style={{textDecoration:"none"}} target="_blank">
                                                                            <img alt="Google Play" width="144" height="44" src="/google-play-badge.png" style={{border:0,cursor:"pointer"}} />
                                                                        </a>
                                                                        &nbsp;
                                                                        <a href="https://itunes.apple.com/us/app/wesko-lock-app/id1023697498?ls=1&mt=8" style={{textDecoration:"none"}} target="_blank">
                                                                            <img alt="App Store" width="144" height="44" src="/app-store-badge.png" style={{border:0,cursor:"pointer"}} />
                                                                        </a>
                                                                    </Box>
                                                                </Box>
                                                            :
                                                            this.state.title === "Compose WelcomeRfid(self) Email"?
                                                                <Box sx={{width:"100%"}}>
                                                                    <Box className="playStoreIcons" sx={{padding:"4px"}}>
                                                                        <a href="https://play.google.com/store/apps/details?id=com.caribou.wesko"style={{textDecoration:"none"}} target="_blank">
                                                                            <img alt="Google Play" width="144" height="44" src="/google-play-badge.png" style={{border:0,cursor:"pointer"}} />
                                                                        </a>
                                                                        &nbsp;
                                                                        <a href="https://itunes.apple.com/us/app/wesko-lock-app/id1023697498?ls=1&mt=8" style={{textDecoration:"none"}} target="_blank">
                                                                            <img alt="App Store" width="144" height="44" src="/app-store-badge.png" style={{border:0,cursor:"pointer"}} />
                                                                        </a>
                                                                    </Box>
                                                                </Box>
                                                                :
                                                null
                                        }
                                        <Box>
                                            {
                                                sessionStorage.checkIq === "643d185e7b25920006f901e9" || sessionStorage.checkIq === "640f85fb1e0c3500061e2e55" ?
                                                    <Box>
                                                        <div id="showpreview"></div>
                                                        <div style={{background:"#e5e5e5",borderCollapse: 'collapse',color: '#757575',fontFamily: 'Roboto,arial',fontSize: '12px',lineHeight: '20px',padding: '27px 40px 20px 40px',paddingTop: '10px',textAlign: 'center'}}>
                                                            <div>Cet e-mail obligatoire a été envoyé à </div>
                                                            <div>CET E-MAIL A ÉTÉ GÉNÉRÉ AUTOMATIQUEMENT - NE PAS RÉPONDRE À CET E-MAIL</div>
                                                            <div>Votre sécurité est notre priorité. Ne partagez jamais votre mot de passe avec qui que ce soit.</div>
                                                        </div>
                                                        <Box sx={{background:"#0053A0",height:"55px"}}>
                                                            <div style={{direction:'ltr',color:'#fff',fontSize:'1em',padding:'1em',fontFamily:'Arial,Helvetica,sans-serif',textAlign:"center"}}>
                                                                <span style={{fontSize:"20px"}}>©</span> 2019 Wesko Locks Ltd.
                                                            </div>
                                                        </Box>
                                                    </Box> :
                                                    <Box>
                                                        <div id="showpreview"></div>
                                                        <div style={{background:"#e5e5e5",borderCollapse: 'collapse',color: '#757575',fontFamily: 'Roboto,arial',fontSize: '12px',lineHeight: '20px',padding: '27px 40px 20px 40px',paddingTop: '10px',textAlign: 'center'}}>
                                                            <div>This mandatory email was sent to </div>
                                                            <div>THIS EMAIL WAS AUTO-GENERATED - DO NOT REPLY TO THIS EMAIL</div>
                                                            <div>Your security is our priority. Never share your Password with anyone.</div>
                                                        </div>
                                                        <Box sx={{background:"#0053A0",height:"55px"}}>
                                                            <div style={{direction:'ltr',color:'#fff',fontSize:'1em',padding:'1em',fontFamily:'Arial,Helvetica,sans-serif',textAlign:"center"}}>
                                                                <span style={{fontSize:"20px"}}>©</span> 2019 Wesko Locks Ltd.
                                                            </div>
                                                        </Box>
                                                    </Box>
                                            }
                                        </Box>
                                    </Box>                                    
                            }
                        </div>
                    </DialogContent>
                    <DialogActions className='emailTemplateFooter'>                        
                        {
                            !this.state.showPreview ?
                                <>
                                    <Button onClick={()=>this.setState({open:false})} autoFocus>
                                        Cancel
                                    </Button>
                                    <Button onClick={()=>this.handleSave()}  autoFocus>
                                        Save
                                    </Button>
                                    <Button onClick={()=>this.handlePreview()}  autoFocus>
                                        Preview
                                    </Button>
                                </>
                            :
                                null
                        }
                    </DialogActions>
                </Dialog>
            </>

        );
    }
}
export default MyEditor;