import * as React from 'react';
import { Box,Dialog,DialogTitle,DialogActions,DialogContent,Radio,RadioGroup,Table,useMediaQuery,useTheme,TableBody,TableCell,TableContainer,TableHead,TablePagination,TableRow, Tooltip, TableSortLabel,Checkbox,LinearProgress,Select,MenuItem } from '@mui/material';
import { Close,CheckCircle,AlarmAdd,ArrowDropDown,ReportProblem,People,Lock,LockOpen,Check,FirstPage,LastPage,KeyboardArrowLeft,KeyboardArrowRight,Search,Refresh,Download,HelpOutline } from '@mui/icons-material';
import { AdapterDateFns,LocalizationProvider,DateTimePicker } from '@mui/x-date-pickers';
import {visuallyHidden} from '@mui/utils';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import styles from '../../stylesheet/users.module.css';
import paginationStyle from '../../stylesheet/pagination.module.css';
import IotServiceClient from "../../clients/IotServiceClient";
import GatewayServiceClient from "../../clients/GatewayServiceClient";
import HistoryDialog from './HistoryDialog';
import "../../stylesheet/common.css";
import _ from "lodash";
import { Button, CircularProgress, DialogContentText, FormControl, Paper, Popover, requirePropFactory, TextField } from '@mui/material';
import moment from 'moment';
import Enums from '../../constants/Enums';
import { useSnackbar } from 'notistack';
import MqttEventHandler from '../Mqtt/MqttEventHandler';
import PubSubChannels from '../../pubsub/PubSubChannels';
import Draggable from 'react-draggable';

function PaperComponent(props) {
return (
    <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
    >
        <Paper {...props} />
    </Draggable>
);
}

export default function ShowLockInstructionStatusDialog(props) {
    
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));    
    const getWindowDimensions = () => {
        const {innerWidth: width, innerHeight: height} = window;
        return {
        width,
        height,
        };
    };

    const [Dimensions, setDimensions] = React.useState(getWindowDimensions());

    const [loader, setLoader] = React.useState(props.closeLoader);

    React.useEffect(()=>{
        if (props.status === "success" || props.status === "failed") {
            setLoader(false);
        }        
    },[props])

  return (
    <Dialog
            fullWidth={true}
            fullScreen={fullScreen}
            open={true}    
            onClose={()=> props.closeDialog()}                            
            PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
            aria-labelledby="draggable-dialog-title"
            >
            <DialogTitle style={{
                background: 'rgb(20,19,139)',
                display: 'flex',
                flexDirection: 'row',
                color: 'white',
            }}>
                <Box sx={{flex: '95'}}>{props.instuctionsData === "unlock" ? "Unlocking" : "Locking"} {window.lockUnlockSelectedData.name}</Box>
                <Box sx={{flex: '5', marginTop: '6px'}}>
                    <Close onClick={() => props.closeDialog()} />
                </Box>
            </DialogTitle>
            <DialogContent sx={{fontSize:"18px !important"}}>
                {
                    !props.closeLoader ?
                        <Box sx={{display:"flex",flexDirection:"row",width:"100%",height:"80px",alignItems:"center",justifyContent:"center"}}>
                            <Box sx={{position:"relative",top:"10px"}}>
                                <CircularProgress thickness="4" sx={{height:'40px !important',width: '40px !important',color: 'rgb(20,19,139) !important'}}/>
                            </Box>
                        </Box>
                    :
                        null
                }
                {
                    props.status !== "" ?
                        props.status === "success" ?
                            <Box sx={{display:"flex",color: 'green',flexDirection:"row",width:"100%",height:"30px",alignItems:"center",justifyContent:"center", marginTop: props.closeLoader ? "44px" : "0px"}}>
                               SUCCESS!
                            </Box>
                        :
                        props.status === "failed" ?
                            <Box sx={{display:"flex",flexDirection:"row",width:"100%",height:"30px",alignItems:"center",justifyContent:"center", marginTop: props.closeLoader ? "44px" : "0px"}}>
                                <>
                                    <Box>
                                        <span style={{color: "red"}}>
                                            Failed.
                                        </span>
                                        The lock is currently unreachable. Please retry after some time.
                                    </Box>
                                </>
                            </Box>
                        :
                            null
                    :
                        null
                }
            </DialogContent>
            <DialogActions>            
            <Button onClick={() => props.closeDialog()}>
                ok
            </Button>                                  
            </DialogActions>
        </Dialog>

  )
}
