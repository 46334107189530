import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import {
    Box,
  CircularProgress,
  DialogContentText, Snackbar, useMediaQuery
} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import {useTheme} from '@mui/material/styles';
import '../../stylesheet/HomePage.css';
import "../../stylesheet/common.css";
import Draggable from 'react-draggable';
import GatewayServiceClient from '../../clients/GatewayServiceClient';
import { useSnackbar } from 'notistack';

function PaperComponent(props) {
  return (
      <Draggable
          handle="#draggable-dialog-title"
          cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
  );
}

function DraggableDialogComponent(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [close, setClose] = React.useState(true);
    const [isLoading,setLoading] = React.useState(false);
    const getWindowDimensions = () => {
        const {innerWidth: width, innerHeight: height} = window;
        return {
          width,
          height,
        };
      };
    
    const [Dimensions, setDimensions] = React.useState(getWindowDimensions());
    const [showSnackBar, setShowSnackBar] = React.useState("");
    const [showSnackBarMessage ,setShowSnackBarMessage] = React.useState("");
    const { enqueueSnackbar } = useSnackbar();
    
    const handleRemovePermanent = () => {        
        setLoading(true);
        var instruction = {};
        instruction.payload = {};
        instruction.payload.removalScopeId = props.tenant.id;
        instruction.type = "REMOVE_TENANT_BY_FORCE";
        GatewayServiceClient.createInstruction(response => {
          if (response.status === 201 || response.status === 200){
            enqueueSnackbar("Successfully created delete customer instruction");
            props.closeDialog(true);
          }else {            
            enqueueSnackbar("Failed to create instruction");
          }
        }, instruction);      
    }

    return (
        props.dialog === "deletePermanantDialog" ?
            <Dialog
                fullWidth={true}
                fullScreen={fullScreen}
                open={props.showDialog}
                onClose={() => props.closeDialog(false)}
                PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle>
                <Box id="draggable-dialog-title" style={{marginRight: 'auto'}}>
                    Delete Customer
                </Box>
                </DialogTitle>
                <DialogContent>
                    {
                        !isLoading ?
                            <DialogContentText>
                                You are trying to delete the customer permanently. All information except any pending instructions, will be removed from our records.
                                You cannot undo this operation. Are you sure, you want to permanently delete this customer?
                            </DialogContentText>
                        :
                            <Box sx={{width:'100%',height:"100%",display:'flex',justifyContent: 'center',alignItems:'center'}}>
                                <CircularProgress thickness="4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                            </Box>
                    }
                </DialogContent>
                <DialogActions>
                <Button
                    onClick={() => props.closeDialog(false)}
                    style={{color: 'red'}}
                >
                    Cancel
                </Button>
                <Button onClick={() => handleRemovePermanent()}>
                    Yes
                </Button>
                </DialogActions>
            </Dialog>
        :
            null    
     );
}

export default DraggableDialogComponent;