import * as React from 'react';
import {styled} from '@mui/material/styles';
import { Box,Paper,Grid,Typography,CircularProgress,Snackbar } from '@mui/material';
import '../../stylesheet/Dashboard.css';
import Chart from 'react-google-charts';
import {Component} from "react";
import AuthClient from "../../clients/AuthClient";
import GatewayServiceClient from "../../clients/GatewayServiceClient";
import IotServiceClient from "../../clients/IotServiceClient";
import _ from 'lodash';
import MqttEventHandler from '../Mqtt/MqttEventHandler';
import PubSubChannels from '../../pubsub/PubSubChannels';
import PubSubUtils from '../../pubsub/PubSubUtils';

export default class Dashboard extends Component{
    constructor(props) {
        document.getElementById("root").scrollTop = 0
        super(props);
        this.state = {
            scopeId: sessionStorage.tenantId,
            tenantName: null,
            lockCount: 0,
            unlockCount: 0,
            onlineCount: 0,
            offlineCount: 0,
            iotDevices: [],
            iotGateways: [],
            batteryStatusPoor: 0,
            batteryStatusGood: 0,
            batteryStatusHealthy: 0,
            gatewayOnlineCount: 0,
            gatewayOfflineCount: 0,
            clickEventCount:0,
            dashboardGatewaysEventHandlerChannel: null,
            lockStatusEventHandlerChannel : null,
            showSnackBar: false,
            snackBarMessage: "",
            firmwareData:[["Version","FM"],[]],
            gatewayAndAssociatedLocks: [['Gateway Name', 'No.of devices']]
        };
        window.dashboardIotGateways = [];
        this.handleGatewayEvent = this.handleGatewayEvent.bind(this);
        this.fetchData = this.fetchData.bind(this)
        this.handleLockEventReceived = this.handleLockEventReceived.bind(this);
        this.handleOnlineStatusEventReceived = this.handleOnlineStatusEventReceived.bind(this);
        this.subscribeChannels = this.subscribeChannels.bind(this);
    }


    getDevicesDataByVersions(devices) {
        _.forEach(devices,(device) => {
            if(device.firmwareVersion !== null && device.firmwareVersion !== undefined) {
                let firmwareVersion = device.firmwareVersion;
                device.versionNum = firmwareVersion.substring(0, 10);
            }
        });
        var data = _.groupBy(devices, function(device) {
            return device.versionNum;
        });
        return data;
    };

    fetchData () {
        AuthClient.getHierarchyScope(this.state.scopeId).then((data) => {
            sessionStorage.tenantName = data.scope.name;
         });
 
         let allLockTypeExist = [];

         IotServiceClient.getReachableIotDevices(this.state.scopeId, "dashboardPage").then((data) => {
            let iotReachableDevices = data;           
            let [lockCounter, unlockCounter, onlineCounter, offlineCounter, poor, good, healthy] = [0, 0, 0, 0, 0, 0, 0];
            let gatewayLockAssociated = [];
            let gatewayLockAssociatedCounts = {};
            
            _.forEach(data,function(iotDevice, index){
                 if (iotDevice.type){
                     allLockTypeExist.push(iotDevice.type);
                 }
                if(iotDevice.status === "OPEN") {
                    unlockCounter += 1;
                } else if(iotDevice.status === "LOCKED") {
                    lockCounter += 1;
                }
 
                if (iotDevice.authStatus === "AUTH_SUCCESS" && !iotDevice.scopeDoesNotMatch) {
                    onlineCounter += 1;
                } else if(iotDevice.authStatus === null || iotDevice.scopeDoesNotMatch) {
                    offlineCounter += 1;
                }
 
                if (iotDevice.batteryLevel < 20) {
                    poor += 1;
                } else if (iotDevice.batteryLevel >= 20 && iotDevice.batteryLevel < 80) {
                    good += 1;
                } else if (iotDevice.batteryLevel >= 80) {
                    healthy += 1;
                }               
            });
            
            this.setState({
                lockCount: lockCounter,
                unlockCount: unlockCounter,                 
                onlineCount: onlineCounter,
                offlineCount: offlineCounter,
                batteryStatusPoor: poor,
                batteryStatusGood: good,
                batteryStatusHealthy: healthy,
                iotDevices: iotReachableDevices,
             })             
 
            allLockTypeExist = _.uniq(allLockTypeExist).sort();
            let versions = this.getDevicesDataByVersions(data);
 
            let firmwareChartHeading = [];
            let firmmwareChartData = [];
 
            firmwareChartHeading.push("Version")
 
            _.forEach(allLockTypeExist,(type)=>{
                 firmwareChartHeading.push(type);
            });
 
            function getDevicesOfType(devices, type) {
                 let filteredDevices = _.filter(devices, function(device) {
                     return device.type === type;
                 });
 
                 return filteredDevices.length;
             };
 
             _.forEach(versions, function(devices, versionNum) {                
                 let resultData = [];      
                 if(versionNum !== "undefined") {
                     resultData.push(versionNum);
                     _.forEach(allLockTypeExist,(type)=>{
                         let count = getDevicesOfType(devices,type);
                         resultData.push(count);
                     })
                     firmmwareChartData.push(resultData);
                 }
             });
 
             let combinedData = [];
 
             combinedData.push(firmwareChartHeading);
 
             _.forEach(firmmwareChartData,(chartData)=>{
                 combinedData.push(chartData)
             })
 
             
             this.setState({
                 firmwareData: combinedData.length === 1 ? ["version",""] : combinedData,
             })
 
            GatewayServiceClient.getGateways(this.state.scopeId, "dashboardPage").then((data) => {
                 let iotAllGateways = data;
                 window.dashboardIotGateways = iotAllGateways;
                 let [online, offline] = [0, 0];
                 _.forEach(data, (gateway) => {
                     if(gateway.status === "CONNECTED") {
                         online += 1;
                     } else {
                         offline += 1;
                     }
                 });
                 this.setState({
                     gatewayOnlineCount: online,
                     gatewayOfflineCount: offline,
                     iotGateways: iotAllGateways
                 })
 
                 _.forEach(this.state.iotDevices, (device)=>{
                     if (device.gatewayMacId !== null){
                        _.forEach(this.state.iotGateways,(gateway)=>{
                            if(device.gatewayMacId === gateway.macId){
                                gatewayLockAssociated.push(gateway.name);
                            }
                        })                        
                     }
                 })                 

                 _.forEach(gatewayLockAssociated, (value) => {
                    if (gatewayLockAssociatedCounts.hasOwnProperty(value)){                        
                        gatewayLockAssociatedCounts[value] += 1;
                    }else {
                        gatewayLockAssociatedCounts[value] = 1;
                    }
                 });

                 let gatewayNames = Object.keys(gatewayLockAssociatedCounts);
 
                 let gatewayNamesAndLocksCountMapping = [];
 
                 gatewayNamesAndLocksCountMapping.push(['Gateway Name', 'No.of devices']);
                 
                 _.forEach(gatewayNames, (value)=>{
                    gatewayNamesAndLocksCountMapping.push([value, gatewayLockAssociatedCounts[value]]);
                 })
                 
                 this.setState({
                    gatewayAndAssociatedLocks: gatewayNamesAndLocksCountMapping
                 })
             });
         });
    }    
    
    handleGatewayEvent(){
        this.setState({
            showSnackBar : true,
            snackBarMessage : "Refreshing Gateways Status ..."
        })
        setTimeout(() => {
            this.setState({
                showSnackBar : false,
                snackBarMessage: ""
            })  
        }, 3000);
        this.fetchData();
    }

    handleLockEventReceived(){
        this.setState({
            showSnackBar : true,
            snackBarMessage : "Refreshing Lock Status ..."
        })
        setTimeout(() => {
            this.setState({
                showSnackBar : false,
                snackBarMessage: ""
            })  
        }, 3000);
        this.fetchData();
    }

    handleOnlineStatusEventReceived(){
        this.setState({
            showSnackBar : true,
            snackBarMessage : "Refreshing Online Status ..."
        })
        setTimeout(() => {
            this.setState({
                showSnackBar : false,
                snackBarMessage: ""
            })  
        }, 3000);
        this.fetchData();
    }

    subscribeChannels () {      
        const fetchOnlineStatus = () => {
            this.handleOnlineStatusEventReceived();
        }
        window.dashboardGatewaysEventHandlerChannel = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.GATEWAYS.replace("scopeId",sessionStorage.tenantId),"dashboadrController",this.handleGatewayEvent);
        window.lockStatusEventHandlerChannel = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.DEVICES.replace("scopeId", sessionStorage.tenantId), "dashboardlockController", this.handleLockEventReceived);        
        window.lockOnlineStatusEventHandlerChannel = MqttEventHandler.subscribe("gateway-service/activityLogs/+", "dashboardlockController", function(message,topic){
            let macId = topic !== "" && topic ? topic.substring(topic.lastIndexOf("activityLogs/")+13,topic.length) : "";
            if (window.dashboardIotGateways ? window.dashboardIotGateways.length > 0 : false){
                _.forEach(window.dashboardIotGateways,(device)=>{
                    if (macId === device.macId){
                        fetchOnlineStatus();
                    }
                })
            }
        });
    }

    componentDidMount() {
        this.fetchData();  
        this.subscribeChannels();      
    }

    componentWillUnmount () {
        if (window.dashboardGatewaysEventHandlerChannel){
            window.dashboardGatewaysEventHandlerChannel.unsubscribe();                                    
        }
        if (window.lockStatusEventHandlerChannel){
            window.lockStatusEventHandlerChannel.unsubscribe();
        }
        if (window.lockOnlineStatusEventHandlerChannel){
            window.lockOnlineStatusEventHandlerChannel.unsubscribe();
        }
        IotServiceClient.abortSignal("dashboardPage");
        GatewayServiceClient.abortSignal("dashboardPage");
    }

    render() {        

        const data=this.props;
        const options = {
            legend: {
                position: 'top',
                maxLines: 3,
                textStyle: {fontSize: 9},
                chartArea: {'width': '90%', 'height': '90%'},
            },
            chart: {
                title: '',
                subtitle: '',
            },
        };       

        return (
            <Box id="dashboard" sx={{flexGrow: 1, margin: '8px', padding: '8px'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={4}>
                        <Paper elevation={1}>
                            <Box sx={{backgroundColor: 'rgb(224,224,224)', padding: '10px'}}>
                                <Typography
                                    mt={2}
                                    style={{
                                        fontWeight: '350',
                                        fontSize: '1em',
                                        margin: '10px',
                                    }}
                                >
                                    Online Status
                                </Typography>
                            </Box>
                            <Box className="onlineStatusChart" sx={{margin: 'auto', height: '240px', width: '320px'}}>
                                <Chart
                                    width={'320px'}
                                    height={'240px'}
                                    chartType="PieChart"
                                    loader={
                                        <Box sx={{width:'100%',display:'flex',justifyContent: 'center',alignItems:'center',height:'100%'}}>
                                            <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                                        </Box>
                                    }                                    
                                    data={[
                                        ['Task', 'Hours per Day'],
                                        ['Online', this.state.onlineCount],
                                        ['Offline', this.state.offlineCount],
                                    ]}
                                    options={{
                                        title: '',
                                        slices: [{color: '#109618'}, {color: '#DC3912'}],
                                        pieSliceText: 'value',
                                        chartArea: {'width': '90%', 'height': '90%'},
                                        margin: 'auto',
                                    }}
                                    chartEvents={[
                                        {
                                            eventName: 'ready',
                                            callback: ({chartWrapper, google}) => {
                                                let handler = function (event) {
                                                    // var  = e.targetID.split('#');
                                                    let targetId = event.targetID;
                                                     window.clickEvent = window.clickEvent ? window.clickEvent : 0; 
                                                    if (targetId && window.clickEvent === 0) {
                                                        if (targetId === "legendentry#0" || targetId === "slice#0") {
                                                            sessionStorage.navigatedFromDashboard = "online";                                                            
                                                            //window.clickEvent +=1;
                                                            sessionStorage.sidenavAlreadySelectedItem = "Locks";
                                                            data.changePage();
                                                        } else if (targetId === "legendentry#1" || targetId === "slice#1"){
                                                            sessionStorage.navigatedFromDashboard = "offline";
                                                            //window.clickEvent +=1;
                                                            sessionStorage.sidenavAlreadySelectedItem = "Locks";
                                                            data.changePage();
                                                        }
                                                    }                                                    
                                                };
                                                google.visualization.events.addListener(
                                                    chartWrapper.getChart(),
                                                    'click',
                                                    handler
                                                );
                                            },
                                        },
                                    ]}
                                    rootProps={{'data-testid': '1'}}
                                />
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4}>
                        <Paper elevation={1}>
                            <Box sx={{backgroundColor: 'rgb(224,224,224)', padding: '10px'}}>
                                <Typography
                                    mt={2}
                                    style={{
                                        fontWeight: '350',
                                        fontSize: '1em',
                                        margin: '10px',
                                    }}
                                >
                                    Lock Status
                                </Typography>
                            </Box>
                            <Box sx={{margin: 'auto', height: '240px', width: '320px'}}>
                                <Chart
                                    width={'320px'}
                                    height={'240px'}
                                    chartType="PieChart"
                                    loader={
                                        <Box sx={{width:'100%',display:'flex',justifyContent: 'center',alignItems:'center',height:'100%'}}>
                                            <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                                        </Box>
                                    }
                                    data={[
                                        ['Task', 'Hours per Day'],
                                        ['Unlocked', this.state.unlockCount],
                                        ['Locked', this.state.lockCount],                                        
                                    ]}
                                    options={{
                                        title: '',
                                        slices: [{color: '#DC3912'},{color: '#109618'}],
                                        chartArea: {'width': '90%', 'height': '90%'},
                                        pieSliceText: 'value',
                                    }}
                                    rootProps={{'data-testid': '1'}}
                                />
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4}>
                        <Paper elevation={1}>
                            <Box sx={{backgroundColor: 'rgb(224,224,224)', padding: '10px'}}>
                                <Typography
                                    mt={2}
                                    style={{
                                        fontWeight: '350',
                                        fontSize: '1em',
                                        margin: '10px',
                                    }}
                                >
                                    Battery Status
                                </Typography>
                            </Box>
                            <Box sx={{margin: 'auto', height: '240px', width: '320px'}}>
                                <Chart
                                    width={'320px'}
                                    height={'240px'}
                                    chartType="PieChart"
                                    loader={
                                        <Box sx={{width:'100%',display:'flex',justifyContent: 'center',alignItems:'center',height:'100%'}}>
                                            <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                                        </Box>
                                    }
                                    data={[
                                        ['Task', 'Hours per Day'],
                                        ['Poor (<20%)', this.state.batteryStatusPoor],
                                        ['Good (20-80%)', this.state.batteryStatusGood],
                                        ['Healthy (>80%)', this.state.batteryStatusHealthy],
                                    ]}
                                    options={{
                                        title: '',
                                        legend: {
                                            maxLines: 6,
                                            textStyle: {fontSize: 10},
                                        },
                                        slices: [
                                            {color: 'rgb(244, 67, 54)'},
                                            {color: 'rgb(255, 152, 0)'},
                                            {color: 'rgb(76, 175, 80)'},
                                        ],
                                        chartArea: {'width': '90%', 'height': '90%'},
                                        pieSliceText: 'value',
                                    }}
                                    rootProps={{'data-testid': '1'}}
                                />
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid sx={{marginTop: '15px'}} container spacing={2}>
                    <Grid item xs={12} md={12} lg={4}>
                        <Paper elevation={1}>
                            <Box sx={{backgroundColor: 'rgb(224,224,224)', padding: '10px'}}>
                                <Typography
                                    mt={2}
                                    style={{
                                        fontWeight: '350',
                                        fontSize: '1em',
                                        margin: '10px',
                                    }}
                                >
                                    Firmware & Associated Devices
                                </Typography>
                            </Box>
                            <Box sx={{margin: 'auto', height: '240px', width: '350px'}}>
                                <Chart
                                    width={'350px'}
                                    height={'240px'}
                                    chartType="ColumnChart"
                                    legendToggle
                                    loader={
                                        <Box sx={{width:'100%',display:'flex',justifyContent: 'center',alignItems:'center',height:'100%'}}>
                                            <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                                        </Box>
                                    }
                                    
                                    data={this.state.firmwareData}

                                    options={options}
                                    // For tests
                                    // rootProps={{ 'data-testid': '2' }}
                                />
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4}>
                        <Paper elevation={1}>
                            <Box sx={{backgroundColor: 'rgb(224,224,224)', padding: '10px'}}>
                                <Typography
                                    mt={2}
                                    style={{
                                        fontWeight: '350',
                                        fontSize: '1em',
                                        margin: '10px',
                                    }}
                                >
                                    Gateways Status
                                </Typography>
                            </Box>
                            <Box sx={{margin: 'auto', height: '240px', width: '320px'}}>
                                <Chart
                                    width={'320px'}
                                    height={'240px'}
                                    chartType="PieChart"
                                    loader={
                                        <Box sx={{width:'100%',display:'flex',justifyContent: 'center',alignItems:'center',height:'100%'}}>
                                            <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                                        </Box>
                                    }
                                    data={[
                                        ['Task', 'Hours per Day'],
                                        ['Online', this.state.gatewayOnlineCount],
                                        ['Offline', this.state.gatewayOfflineCount],
                                    ]}
                                    options={{
                                        title: '',
                                        slices: [{color: '#109618'}, {color: '#DC3912'}],
                                        pieSliceText: 'value',
                                        chartArea: {'width': '90%', 'height': '90%'}
                                    }}
                                    rootProps={{'data-testid': '1'}}
                                />
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4}>
                        <Paper elevation={1}>
                            <Box sx={{backgroundColor: 'rgb(224,224,224)', padding: '10px'}}>
                                <Typography
                                    mt={2}
                                    style={{
                                        fontWeight: '350',
                                        fontSize: '1em',
                                        margin: '10px',
                                    }}
                                >
                                    Gateways and Associated Locks
                                </Typography>
                            </Box>
                            <Box sx={{margin: 'auto', height: '240px', width: '320px'}}>
                                <Chart
                                    width={'320px'}
                                    height={'240px'}
                                    chartType="PieChart"
                                    loader={
                                        <Box sx={{width:'100%',display:'flex',justifyContent: 'center',alignItems:'center',height:'100%'}}>
                                            <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                                        </Box>
                                    }
                                    data={this.state.gatewayAndAssociatedLocks}
                                    options={{
                                        title: '',
                                        pieSliceText: 'value',
                                        chartArea: {'width': '90%', 'height': '90%'},
                                    }}
                                    rootProps={{'data-testid': '1'}}
                                />
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
                {
                    this.state.showSnackBar ?
                        <Snackbar
                            anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
                            open={this.state.showSnackBar}
                            message={this.state.snackBarMessage}
                        />
                    :
                        null
                }
            </Box>
        );
    }
}
