import React from 'react'
import Draggable from 'react-draggable';
import { Button, CircularProgress, Paper} from '@mui/material';
import { Box,Dialog,DialogTitle,DialogActions,DialogContent,useMediaQuery,useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import Papa from 'papaparse';

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export default function DownloadSrialNumbersDialog(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));    
    const getWindowDimensions = () => {
        const {innerWidth: width, innerHeight: height} = window;
        return {
        width,
        height,
        };
    };

    const [Dimensions, setDimensions] = React.useState(getWindowDimensions());

    const [loader, setLoader] = React.useState(props.closeLoader);
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(()=>{
        return () => {
            delete window.filteredCsvDataEngineeringView;
        }
    },[])

    const downloadCsv = () => {
        if (window.hasOwnProperty("filteredCsvDataEngineeringView")){
            const csvData = Papa.unparse(window.filteredCsvDataEngineeringView);        
            const blob = new Blob([csvData], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'SerialNumbers.csv');
            link.click();
            enqueueSnackbar("Serial numbers file downloaded successfully");
        }else {
            enqueueSnackbar("Erron in downloading serial numbers");
        }
        props.closeDialog();
    }

    return (   
        <Dialog
            fullWidth={true}
            fullScreen={fullScreen}
            open={props.openDialog}    
            onClose={()=> props.closeDialog()}                            
            PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
            aria-labelledby="draggable-dialog-title"
            >
            <DialogTitle style={{
                background: 'rgb(20,19,139)',
                display: 'flex',
                flexDirection: 'row',
                color: 'white',
            }}>
                <Box sx={{flex: '95'}}>
                    Lock Serial Numbers
                </Box>
                <Box sx={{flex: '5', marginTop: '6px'}}>
                    <Close sx={{cursor:'pointer'}} onClick={() => props.closeDialog()} />
                </Box>
            </DialogTitle>
            <DialogContent sx={{fontSize:"18px !important"}}>
                {
                    props.closeLoader ?
                        <Box sx={{display:"flex",flexDirection:"row",width:"100%",height:"80px",alignItems:"center",justifyContent:"center"}}>
                            <Box sx={{position:"relative",top:"10px"}}>
                                <CircularProgress thickness="4" sx={{height:'40px !important',width: '40px !important',color: 'rgb(20,19,139) !important'}}/>
                            </Box>
                        </Box>
                    :
                        <Box sx={{display:"flex",flexDirection:"row",width:"100%",height:"80px",alignItems:"center",justifyContent:"center"}}>
                            <Box sx={{position:"relative",top:"10px"}}>                            
                                <Button variant="standard"
                                  sx={{marginLeft: 'auto',
                                  background: 'rgb(20,19,139)',
                                  color: '#fff',
                                  marginRight: '10px',
                                  '&.MuiButtonBase-root:hover': {
                                      background: 'rgb(20,19,139)',
                                  },
                                  width: 'auto',
                                  margin: '15px'}} component="span" 
                                  onClick={() => downloadCsv()}>
                                    Click here to download
                                </Button>
                            </Box>
                        </Box>
                }
            </DialogContent>
            {
                props.closeLoader ?
                    <DialogActions>            
                        <Button onClick={() => props.closeDialog()}>
                            cancel
                        </Button>                                  
                    </DialogActions>
                :
                    null
            }
        </Dialog>
    )
}
