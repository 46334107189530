import React from 'react';
import { Button,Table,TableBody,TableCell,TableContainer,TableHead,TablePagination,TableRow,TableSortLabel,Box,Checkbox,Select,MenuItem,Tooltip,LinearProgress,Icon, IconButton, Snackbar } from '@mui/material';
import { LockOpen,Add,People,LibraryBooks,LocksData,Lock,PeopleAlt,GroupAdd,Download,Edit,Close,Search,Refresh,Settings,FirstPage,LastPage,KeyboardArrowLeft,KeyboardArrowRight,Person,PersonAdd,MoreHoriz,PriorityHigh,FilterList,VerifiedUser,Delete,MailOutline,Wifi } from '@mui/icons-material';
import {visuallyHidden} from '@mui/utils';
import '../stylesheet/DashboardGatewayPage.css';
import '../stylesheet/DashBoardUsersPage.css';
import {CSVLink} from 'react-csv';
import CsvDownload from 'react-json-to-csv';
import LockUsersDraggableDialog from '../components/LockUsersDraggableDialogs';
import IotServiceClient from "../clients/IotServiceClient";
import HistoryDialog from "./locks/HistoryDialog";
import UsersDialog from "./locks/UsersDialog";
import paginationStyle from "../stylesheet/pagination.module.css";
import "../stylesheet/common.css";
import _, { update } from 'lodash';
import DraggableComponent from './DraggableComponent';
import GatewayServiceClient from '../clients/GatewayServiceClient';
import Enums from '../constants/Enums';
import { useSnackbar } from 'notistack';
import MqttEventHandler from './Mqtt/MqttEventHandler';
import PubSubChannels from '../pubsub/PubSubChannels';
import PubSubUtils from '../pubsub/PubSubUtils';


function TablePaginationActions(props) {
  const {count, page, rowsPerPage, onPageChange} = props;

  function handleFirstPageButtonClick(event) {
    onPageChange(event, 0);
  }

  function handleBackButtonClick(event) {
    onPageChange(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onPageChange(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={paginationStyle.paginationActionIcons}>
      <FirstPage
        onClick={() => {
          if (page > 0) handleFirstPageButtonClick();
        }}
        sx={{
          color: page === 0 ? 'rgba(0,0,0,0.26)' : 'black',
          cursor: page === 0 ? 'auto' : 'pointer',
          marginRight: '8px'
        }}
      />
      <KeyboardArrowLeft
        onClick={() => {
          if (page > 0) handleBackButtonClick();
        }}
        sx={{
          color: page === 0 ? 'rgba(0,0,0,0.26)' : 'black',
          cursor: page === 0 ? 'auto' : 'pointer',
          marginRight: '8px'
        }}
      />
      <KeyboardArrowRight
        onClick={() => {
          if (page !== Math.ceil(count / rowsPerPage) - 1)
            handleNextButtonClick();
        }}
        sx={{
          color:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0
              ? 'black'
              : 'rgba(0,0,0,0.26)',
          cursor:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0 ? 'pointer' : 'auto',
            marginRight: '8px'
        }}
      />
      <LastPage
        onClick={() => {
          if (page !== Math.ceil(count / rowsPerPage) - 1)
            handleLastPageButtonClick();
        }}
        sx={{
          color:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0
              ? 'black'
              : 'rgba(0,0,0,0.26)',
          cursor:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0 ? 'pointer' : 'auto',
        }}
      />
    </div>
  );
}

function EnhancedTableHead(props) {
  const {onSelectAllClick, order, orderBy, onRequestSort} = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: 'mergedName',
      numeric: false,
      disablePadding: false,
      label: 'Name',
      width: '100px'
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'Email',
      width: '124px'
    },
    {
      id: 'cardCSN',
      numeric: true,
      disablePadding: false,
      label: 'Card ID',
      width: '100px'
    },
    {
      id: 'edit',
      numeric: false,
      disablePadding: false,
      label: 'Edit',
      width: '60px'
    },
    {
      id: 'locks',
      numeric: true,
      disablePadding: false,
      label: 'Locks',
      width: '90px'
    },
    {
      id: 'noOfUsers',
      numeric: true,
      disablePadding: false,
      label: 'Assigned Locks',
      width: '150px'
    },
    {
      id: 'status',
      numeric: true,
      disablePadding: false,
      label: 'Status',
      width: '90px'
    },
    {
      id: 'employeeId',
      numeric: true,
      disablePadding: false,
      label: 'Employee ID',
      width: '120px'
    },
    {
      id: 'division',
      numeric: true,
      disablePadding: false,
      label: 'Division',
      width: '90px'
    },
    {
      id: 'mobileNo',
      numeric: true,
      disablePadding: false,
      label: 'Mobile No',
      width: '90px'
    },
  ];

  const [changeTextColor,setChangeTextColor] = React.useState('');

  const [clickCount, setClickCount] = React.useState(1);

  const selectAll = (event) => {
    setClickCount(clickCount + 1);
    onSelectAllClick(clickCount);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
            (headCell.id !== 'noOfUsers' && sessionStorage.tenantName === "FreeTier") || sessionStorage.tenantName !== "FreeTier" ?
              <TableCell key={headCell.id} align="left">
                {headCell.label !== 'Edit' && headCell.label !== 'Locks' ? (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                    sx={{
                      width: headCell.width,
                      fontSize: '13px !important',
                      overflowWrap: 'break-word',
                      color: orderBy !== headCell.id ? '#0000008A !important' : '#000000DE !important',
                    }}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  <Box
                    sx={{
                      width: headCell.width,
                      overflowWrap: 'anywhere',
                      color: changeTextColor !== 'history' || changeTextColor !== 'Edit' ? '#0000008A !important' : '#0000008A !important',
                    }}
                  >
                    {headCell.label}
                  </Box>
                )}
              </TableCell>
          :
            null
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function DashBoardLockUsersPage() {
  const [SearchBar, setSearchBar] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(sessionStorage.dashboardLockUsersRPP ? Number(sessionStorage.dashboardLockUsersRPP) : 25);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('mergedName');
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(false);
  const [editMode, setEdiitMode] = React.useState(false);
  const [showEditIcon, setShowEditIcon] = React.useState(true);
  const [searchText, setSearchText] = React.useState('');
  const [iotDevices, setIotDevices] = React.useState([]);
  const [showSnackBar, setShowSnackBar] = React.useState("");
  const [showSnackBarMessage ,setShowSnackBarMessage] = React.useState("");
  const [updateLockUserDialog , setUpdateLockUserDialog] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [updateLockUSerData ,setUpdateLockUSerData] = React.useState([]);
  const [showDeleteLockUserDialog,setShowDeleteLockUserDialog] = React.useState(false);
  const [showDeleteLockUserDialogData, setShowDeleteLockUserDialogData] = React.useState([]);
  const [showUsersAssignedLocksDialog, setShowUsersAssignedLocksDialog] = React.useState(false);
  const [showUsersAssignedLocksDialogData, setShowUsersAssignedLocksDialogData] = React.useState([]);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [openUsersDialog, setOpenUsersDialog] = React.useState(false);
  const [usersData, setUsersData] = React.useState([]);
  const [createLockUserDialog, setCreateLockUserDialog] = React.useState(false);
  const [createLockUserData , setCreateLockUserData] = React.useState([]);
  const [showCannotDeleteLockUserDialog ,setShowCannotDeleteLockUserDialog] = React.useState(false);
  const [ShowCannotDeleteLockUserData ,setShowCannotDeleteLockUserData] = React.useState([]);
  const [uploadRFIDUsersDialog, setUploadRFIDUsersDialog] = React.useState(false)
  const [uploadBulkUsersDialog, setUploadBulkUsersDialog] = React.useState(false)
  const [filterUsersDialog, setFilterUsersDialog] = React.useState(false);
  const [appliedFilter, setAppliedFilter] = React.useState(false);
  const [downloadCSVData,setDownloadCsvData] = React.useState([]);
  const [showCsvDialog, setShowDownloadCsvDialog] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [iotUsersAssociatedDevices, setIotUsersAssociatedDevices] = React.useState([]);
  const [iotDeviceNames, setIotDeviceNames] = React.useState([]);
  const [iotUsersAssociatedWithMoreThanTwoRFIDSharedDevices, setIotUsersAssociatedWithMoreThanTwoRFIDSharedDevices] = React.useState([]);
  const [showCardCsnExistAlertDialog, setShowCardCsnExistAlertDialog] = React.useState(false);
  const [cardCSNErrorData, setCardCSNErrorData] = React.useState([]);
  const [updateCardCSNSuccessDialog, setUpdateCardCSNSuccessDIalog] = React.useState(false);
  const [updateCardCSNSuccessDIalogData, setUpdateCardCSNSuccessDIalogData] = React.useState([]);
  const [oldCardCSN, setOldCardCSN] = React.useState("");
  const [newCardCSN , setNewcardCSN] = React.useState("");
  const [preservedUpdateData, setPreservedUpdateData] = React.useState({});
  let iotUsersResponse = [];
  let iotUsersResponseReceived = false;
  let iotDeviceAssignedForUserResponse = [];
  let iotDeviceAssignedForUserReceived = false;
  let iotUsersAssociatedDevicesResponse = [];
  let iotUsersAssociatedDevicesResponseReceived = false;
  let iotDeviceNamesResponse = [];
  let iotDeviceNamesResponseReceived = false;
  let iotUsersAssociatedWithTwoRFIDSharedDevicesResponse = [];
  let iotUsersAssociatedWithTwoRFIDSharedDevicesResponseReceived = false;


  const refresh = () =>{
    enqueueSnackbar("Fetching Lock Users ...");
    setSearchText(null);
    fetchIotUsers(true);
  };

  const combineLockUsersResponses = (users, assignedDevices, lockNames, usersWithMultipleRFIDDevices) => {
      let alreadyStoredLockNames = [];
  let mergedData = _.merge(_.keyBy(users,"id"), _.merge(_.keyBy(assignedDevices,"userId")),
                            _.merge(_.keyBy(usersWithMultipleRFIDDevices, "userId")));

    let iotUsersData = [];
    if(sessionStorage.navigatedValueOfLUFromGlobalSearch === "gsLU") {
      _.forEach(mergedData,(element)=>{
        if (element.hasOwnProperty("id")){
          element.mergedName = element.firstName ? (element.lastName ? element.firstName.replace(/ /g,"")+ " " +element.lastName.replace(/ /g,"") : element.firstName.replace(/ /g,"")) : (element.lastName ? element.lastName.replace(/ /g,"") : "No Name");
          element.email = element.email ? element.email : 'No-email';
          element.cardCSN = showCardCSN(element);
          element.preserveCardCSN = element.cardCSN;
          element.noOfUsers = element.noOfUsers ? element.noOfUsers : 0;
          element.noOfRFIDSharedDevices = element.macIds ? element.macIds.length : 0;
          let lock_name = null;
          if (element.deviceMacId){
            if (!alreadyStoredLockNames[element.deviceMacId]){
              let matchedObject = _.find(lockNames,{"macId":element.deviceMacId});
              if (matchedObject){
                  lock_name = matchedObject.lockName;
                  alreadyStoredLockNames[element.deviceMacId] = matchedObject.lockName;
              }
            }else {
              lock_name = alreadyStoredLockNames[element.deviceMacId];  
            }
            element.assignedLocksName = lock_name ? lock_name : "no-lock";
          }else {
            element.assignedLocksName = "no-lock";
          }
          if (element.email === sessionStorage.gsLockUsersEmail){
            iotUsersData.push(element);
          }
        }
      })
    } else {
      _.forEach(mergedData,(element)=>{
        if (element.hasOwnProperty("id")){
          element.mergedName = element.firstName ? (element.lastName ? element.firstName.replace(/ /g,"")+ " " +element.lastName.replace(/ /g,"") : element.firstName.replace(/ /g,"")) : (element.lastName ? element.lastName.replace(/ /g,"") : "No Name");
          element.email = element.email ? element.email : 'No-email';
          element.cardCSN = showCardCSN(element);
          element.preserveCardCSN = element.cardCSN;
          element.noOfUsers = element.noOfUsers ? element.noOfUsers : 0;
          element.employeeId = element.employeeId ? element.employeeId : "";
          element.division = element.division ? element.division : "";
          element.mobileNo = element.mobileNo ? element.mobileNo : "";
          element.noOfRFIDSharedDevices = element.macIds ? element.macIds.length : 0;
          let lock_name = null;       
          if (element.deviceMacId){
            if (!alreadyStoredLockNames[element.deviceMacId]){
              let matchedObject = _.find(lockNames,{"macId":element.deviceMacId});
              if (matchedObject){
                  lock_name = matchedObject.lockName;
                  alreadyStoredLockNames[element.deviceMacId] = matchedObject.lockName;
              }
            }else {
              lock_name = alreadyStoredLockNames[element.deviceMacId];  
            }
            element.assignedLocksName = lock_name ? lock_name : "no-lock";
          }else {
            element.assignedLocksName = "no-lock";
          }
          iotUsersData.push(element);
        }
      })
    }
    iotUsersData = _.orderBy(iotUsersData, [(obj) => obj.mergedName ? obj.mergedName.toLowerCase() : ""], ['asc'])
    window.lockUsersData = iotUsersData;
    if(document.getElementById("searchText") ? document.getElementById("searchText").value.length === 0 : true && !window.appliedFilter) {
        setIotDevices(iotUsersData);
        resetPaginationData([]);
    }else {
      let textValue = document.getElementById("searchText") ? document.getElementById("searchText").value : null;
      handleTextCange(textValue)
    }
    
    let csvData = [];
    _.forEach(iotUsersData, (value)=>{
        csvData.push(_.omit(value,['id','scopeId','scopeId2','assignedLocksName','deletePendingScopes','mergedName','noOfUsers',
                            'lockNames', 'macIds', 'name']));
    })
    setDownloadCsvData(csvData);
    iotUsersResponseReceived = false;
    iotDeviceAssignedForUserReceived = false;
    iotUsersAssociatedDevicesResponseReceived = false;
    iotDeviceNamesResponseReceived = false;
    iotUsersAssociatedWithTwoRFIDSharedDevicesResponseReceived = false;
  }

  const segregateFilteredData = (data) => {
    let isRefreshingWithFilter = document.getElementById("searchText") ? document.getElementById("searchText").value.length > 0 : false;
    let searchFilterText = document.getElementById("searchText") ? document.getElementById("searchText").value : "";
    if (sessionStorage.navigatedValueOfLUFromGlobalSearch){
      let matchedObject = _.find(data,{"email":sessionStorage.gsLockUsersEmail})
      if (matchedObject){
        setIotDevices([matchedObject])
      }else {
        setIotDevices([]);
      }
    }else {
      if(!isRefreshingWithFilter && !window.appliedFilter){
        setIotDevices(data);
      } else if (window.appliedFilter) {
        handleApplyFilter();
      } else {
        handleTextCange(searchFilterText);
      }
    }
  }

  const fetchIotUsers = (isMqttEVent) => {
    let res = [];
    sessionStorage.alreadyInSideFetchIotUsers = true;
     IotServiceClient.getIotUsers(sessionStorage.tenantId, "lockUsersPage").then((data) => {
      if (!data.hasOwnProperty("status")){
        iotUsersResponse = data;
        iotUsersResponseReceived = true;           
        if (sessionStorage.tenantId === "5947df4362a1280006cdd54c"){
          _.forEach(data, (element) => {
            element.mergedName = element.firstName ? (element.lastName ? element.firstName.replace(/ /g,"")+ " " +element.lastName.replace(/ /g,"") : element.firstName.replace(/ /g,"")) : (element.lastName ? element.lastName.replace(/ /g,"") : "No Name");
            element.email = element.email ? element.email : 'No-email';
            element.cardCSN = showCardCSN(element);
            element.preserveCardCSN = element.cardCSN;
            element.assignedLocksName = element.lockName ? element.lockName : "no-lock";
            element.noOfUsers = element.noOfUsers ? element.noOfUsers : 0;
          })
        }
        data = _.orderBy(data, [(obj) => obj.firstName ? obj.firstName.toLowerCase() : "zz"], ['asc'])
        if (!isMqttEVent) {
          segregateFilteredData(data);
        }
        window.lockUsersData = data;
      }
    });

    if (sessionStorage.tenantId !== "5947df4362a1280006cdd54c"){
      IotServiceClient.getAllUsersAssociatedDevices(sessionStorage.tenantId, "lockUsersPage").then((data) => {
        if (!data.hasOwnProperty("status")){        
          iotUsersAssociatedDevicesResponse = data;
          iotUsersAssociatedDevicesResponseReceived = true;
          setIotUsersAssociatedDevices(data);
        }
      })

      IotServiceClient.getAllDeviceNames(sessionStorage.tenantId, "lockUsersPage").then((data) => {
        if (!data.hasOwnProperty("status")){
          iotDeviceNamesResponse = data;
          iotDeviceNamesResponseReceived = true;
          setIotDeviceNames(data);
        }
      })

      IotServiceClient.getAllUsersAssociatedWithTwoRFIDSharedDevices(sessionStorage.tenantId, "lockUsersPage").then((data) => {
          if (!data.hasOwnProperty("status")){
            iotUsersAssociatedWithTwoRFIDSharedDevicesResponse = data;
            iotUsersAssociatedWithTwoRFIDSharedDevicesResponseReceived = true;
            setIotUsersAssociatedWithMoreThanTwoRFIDSharedDevices(data);
          }
        })
    
      window.lockUsersDataResponseInterval = setInterval(() => {
        if (iotUsersResponseReceived && iotUsersAssociatedDevicesResponseReceived && iotDeviceNamesResponseReceived
            && iotUsersAssociatedWithTwoRFIDSharedDevicesResponseReceived) {
          combineLockUsersResponses( iotUsersResponse, iotUsersAssociatedDevicesResponse , iotDeviceNamesResponse, iotUsersAssociatedWithTwoRFIDSharedDevicesResponse);
          clearInterval(window.lockUsersDataResponseInterval);          
        }
      }, 1000);
    }        
  }

  const handleEvent = (data) => {
    let message = data.message ? data.message : "";
    let payload = data.payload ? data.payload : "";
    let modifiedData = [];
    let updateUi = false;
    if (message === "updateUser"){
      _.forEach(window.lockUsersData, (value) => {
        if (value.id === payload.id) {
          value.firstName = payload.firstName;
          value.lastName = payload.lastName;
          value.division = payload.division ? payload.division : "";
          value.employeeId = payload.employeeId ? payload.employeeId : "";
          value.mobileNo = payload.mobileNo ? payload.mobileNo  : "";
          value.deletePendingScopes = payload.deletePendingScopes;
          value.status = payload.status;
          value.scopeId = payload.scopeId;
          value.scopeId2 = payload.scopeId2;
          value.mergedName = payload.firstName ? (payload.lastName ? payload.firstName.replace(/ /g,"")+ " " +payload.lastName.replace(/ /g,"") : payload.firstName.replace(/ /g,"")) : (payload.lastName ? payload.lastName.replace(/ /g,"") : "No Name");
          value.cardCSN = showCardCSN(payload);
          value.preserveCardCSN = value.cardCSN;
        }
        modifiedData.push(value);
      })      
      updateUi = true;
    }else if (message === "deleteUser") {
      _.forEach(window.lockUsersData, (value) => {
        if (value.id !== payload.id) {
          modifiedData.push(value);
        }
      })
      updateUi = true;
    }else if (message === "newUser") {
      _.forEach(window.lockUsersData, (value) => {
        modifiedData.push(value);
      })
      payload.firstName = payload.firstName;
      payload.lastName = payload.lastName;
      payload.assignedLocksName = "no-lock";
      payload.division = payload.division ? payload.division : "";
      payload.employeeId = payload.employeeId ? payload.employeeId : "";
      payload.mobileNo = payload.mobileNo ? payload.mobileNo  : "";
      payload.deletePendingScopes = payload.deletePendingScopes;
      payload.scopeId = payload.scopeId;
      payload.scopeId2 = payload.scopeId2;
      payload.mergedName = payload.firstName ? (payload.lastName ? payload.firstName.replace(/ /g,"")+ " " +payload.lastName.replace(/ /g,"") : payload.firstName.replace(/ /g,"")) : (payload.lastName ? payload.lastName.replace(/ /g,"") : "No Name");
      payload.cardCSN = showCardCSN(payload);
      payload.preserveCardCSN = payload.cardCSN;
      modifiedData.push(payload)
      updateUi = true;
    }else if (message === "passwordReset" || message === "unsetPasscode") {
      _.forEach(window.lockUsersData, (value) => {
        if (value.id === payload.id) {
          value.status = payload.status
        }
        modifiedData.push(value);
      })
      updateUi = true;
    }else if (message === "cardCSNChanged") {
      let csnIsSame = false;
      _.forEach(window.lockUsersData, (value) => {
        if (value.id === payload.id) {
          if (value.cardCSN === showCardCSN(payload)) {
            csnIsSame = true;
          }
          value.firstName = payload.firstName;
          value.lastName = payload.lastName;
          value.division = payload.division ? payload.division : "";
          value.employeeId = payload.employeeId ? payload.employeeId : "";
          value.mobileNo = payload.mobileNo ? payload.mobileNo  : "";
          value.deletePendingScopes = payload.deletePendingScopes;
          value.scopeId = payload.scopeId;
          value.scopeId2 = payload.scopeId2;
          value.mergedName = payload.firstName ? (payload.lastName ? payload.firstName.replace(/ /g,"")+ " " +payload.lastName.replace(/ /g,"") : payload.firstName.replace(/ /g,"")) : (payload.lastName ? payload.lastName.replace(/ /g,"") : "No Name");
          value.cardCSN = showCardCSN(payload);
          value.preserveCardCSN = value.cardCSN;
        }
        modifiedData.push(value);
      })
      if (csnIsSame){
        window.lockUsersData = modifiedData;
        let text = document.getElementById("searchText") ? document.getElementById("searchText").value : "";
        handleTextCange(text);
      }else {
        updateUi = true;
      }
    }else if (message === "bulkUpdate"){
      enqueueSnackbar("Fetching Lock Users ...");
      fetchIotUsers(true);
    }else if (message === "replaceData") {
      enqueueSnackbar("Fetching Lock Users ...");
      fetchIotUsers(true);
    }

    if (updateUi) {      
      enqueueSnackbar("Fetching Lock Users ...");
      window.lockUsersData = modifiedData;
      let text = document.getElementById("searchText") ? document.getElementById("searchText").value : "";
      handleTextCange(text);
    }
  }
  const onLockUsersEvent = (data) => {
    data = data ? JSON.parse(data.toString()) : "" ;
    if (data.hasOwnProperty("message")){
      handleEvent(data);
    }    
  }

  const onDevicesEventReceivedOnAddUserMapping = (data) => {
    data = data ? JSON.parse(data.toString()) : "" ;
    if (data.hasOwnProperty("message")){
      if (data.message === "userMapping"){
        enqueueSnackbar("Fetching Lock Users ...");
        fetchIotUsers(true);
      }
    }
  }

  const subScribeToMqttChannels = () => {
    window.lockUsersMqttEventHandler = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.USERS.replace("scopeId",sessionStorage.tenantId),"LockUsersController", onLockUsersEvent)
    window.mqttEventHandlerAddUserMapForLockUsersPage = MqttEventHandler.subscribe(PubSubUtils.getDevicesUserMappingsEvent(sessionStorage.tenantId), "lockUsersController", onDevicesEventReceivedOnAddUserMapping);
  }

  React.useEffect(() => {
     window.oldPreservedLockUsersPageNumber = 0;
     fetchIotUsers(false);
     subScribeToMqttChannels();
     enqueueSnackbar("Fetching Lock Users ...")
     return () => {
        if (window.lockUsersMqttEventHandler){
          window.lockUsersMqttEventHandler.unsubscribe();
        }
        if (window.mqttEventHandlerAddUserMapForLockUsersPage) {
          window.mqttEventHandlerAddUserMapForLockUsersPage.unsubscribe();
        }
       delete sessionStorage.navigatedValueOfLUFromGlobalSearch;
       IotServiceClient.abortSignal("lockUsersPage");
       GatewayServiceClient.abortSignal("lockUsersPage");
     }
  }, []);

  function numberCharacterOrganizer(array, orderOfsort, orderByOfSort){
    let numbers = [], characters = [];
    _.forEach(array, (element) => {
        if(typeof(element[orderByOfSort]) === 'string'  ? Number(element[orderByOfSort] ? element[orderByOfSort].replaceAll(" ","") : element[orderByOfSort]) : Number(element[orderByOfSort])){
          numbers.push(element);
        }
        else{
          orderByOfSort === 'email' && element[orderByOfSort] !== null && Number(element[orderByOfSort].replaceAll(" ","").substring(0,element[orderByOfSort].indexOf('@'))) ? numbers.push(element) : characters.push(element);
        }
    })
    array = orderOfsort === 'asc' ? characters.concat(numbers): numbers.concat(characters);
    return array;
  }

  function stableSort(array, orderOfsort, orderByOfSort) {
    array = numberCharacterOrganizer(array, orderOfsort, orderByOfSort);
    if(orderByOfSort !== 'email'){
        array = _.orderBy(array, [obj => obj[orderByOfSort] !== null ? typeof(obj[orderByOfSort]) === 'string' ? !Number(_.get(obj, orderByOfSort, '').replaceAll(" ","")) ? _.get(obj, orderByOfSort, '').toString().toLowerCase().replaceAll(" ","") : Number(_.get(obj, orderByOfSort).replaceAll(" ","")) : Number(_.get(obj, orderByOfSort,'')) : ""], [orderOfsort]);
    }else{
         array = _.orderBy(array, [obj => obj[orderByOfSort] !== null ? !Number(_.get(obj, orderByOfSort, '').replaceAll(" ","").substring(0,obj[orderByOfSort].indexOf('@'))) ? _.get(obj, orderByOfSort, '').toString().toLowerCase().replaceAll(" ","") : Number(_.get(obj, orderByOfSort,'').replaceAll(" ","").substring(0,obj[orderByOfSort].indexOf('@'))): ""], [orderOfsort]);
    }
    return array;
  }

  function descendingComparator(a, b, orderBy) {
    if (typeof b[orderBy] !== 'undefined' && typeof a[orderBy] !== 'undefined' && b[orderBy] !== null && a[orderBy] !== null){
      if (b[orderBy].toString().toLowerCase() < a[orderBy].toString().toLowerCase()) {
        return -1;
      }
      if (b[orderBy].toString().toLowerCase() > a[orderBy].toString().toLowerCase()) {
        return 1;
      }
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPageNumber(newPage);
    window.oldPreservedLockUsersPageNumber = newPage;
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleClick = (event, row) => {
    let newSelected = [];

    if (event.target.checked) {
      if (selected.length > 0) {
        selected.forEach((element) => {
          newSelected.push(element);
        });
        if (JSON.stringify(newSelected).indexOf(row.id) === -1) {
          newSelected.push(row);
        }
      } else {
        newSelected.push(row);
      }
    } else {
      selected.forEach((element) => {
        if (element.id !== row.id) {
          newSelected.push(element);
        }
      });
    }

    setSelected(newSelected);

  };

  const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - iotDevices.length) : 0;

  const handleSelectAllClick = (checked) => {
    if (checked) {
      // eslint-disable-next-line array-callback-return
      const newSelecteds = iotDevices.filter((n) => {
        if (
            n.Status !== 'INACTIVE' &&
            typeof n.id !== 'undefined' &&
            n.id !== null
        ) {
          return n;
        }
      });
      setSelected([]);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);

  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChange = (event) => {
    setPageNumber(event.target.value);
    setPage(event.target.value);
    window.oldPreservedLockUsersPageNumber = event.target.value;
  };

  const handleRowPerPage = (event) => {
    let rowPerPageValue = event.target.value;   
    if (iotDevices.length > 0){ 
      let pageNumberCount = Math.ceil((iotDevices.length/rowPerPageValue)) - 1;                
      if (window.oldPreservedLockUsersPageNumber > pageNumberCount) {
        setPage(pageNumberCount);
        setPageNumber(pageNumberCount);        
        window.oldPreservedLockUsersPageNumber = pageNumberCount;
      }else {
        setPage(window.oldPreservedLockUsersPageNumber);
        setPageNumber(window.oldPreservedLockUsersPageNumber);
      }
    }
    sessionStorage.dashboardLockUsersRPP = rowPerPageValue;
    setRowsPerPage(rowPerPageValue);
  };

  const checkSearchTextExist = (data, key,  text) => {
    let value = data[key];
    return value !== null && typeof value !== 'undefined' && value !== "" ? value.toString().toLowerCase().replaceAll(' ','').indexOf(text) !== -1 : false;
  };

  const checkIfUserPresentInTwoSharedRFIDLocks = (data, key) => {
      let value = data[key];
      return value !== null && typeof value !== 'undefined' && value > 1 ? true : false;
    };

  const handleApplyFilter = () => {
        let sortedDevices = [];
        _.forEach(window.lockUsersData,(value)=>{
          if (checkIfUserPresentInTwoSharedRFIDLocks(value, "noOfRFIDSharedDevices")){
            sortedDevices.push(value);
          }
        });
        sortedDevices = _.uniq(sortedDevices);
        window.lockUsersData = sortedDevices;
        let csvData = [];
          _.forEach(sortedDevices, (value)=>{
              csvData.push(_.omit(value,['id','scopeId','scopeId2','assignedLocksName','deletePendingScopes','mergedName','noOfUsers', 'lockNames', 'macIds', 'name']));
          })
          setDownloadCsvData(csvData);
        setIotDevices(sortedDevices);
  }

  const handleTextCange = (textValue) => {
    setSearchText(textValue);
    textValue = document.getElementById("searchText") ? document.getElementById("searchText").value : "";
    let sortedDevices = [];
    if (textValue !== "" && textValue !== undefined && textValue !==  null){
        let text = textValue.toLowerCase().replaceAll(' ','');
        _.forEach(window.lockUsersData,(value)=>{
          if (checkSearchTextExist(value, "mergedName", text) || checkSearchTextExist(value, "email", text) ||
              checkSearchTextExist(value, "cardCSN", text) || checkSearchTextExist(value, "employeeId", text)|
              checkSearchTextExist(value, "division", text) || checkSearchTextExist(value, "assignedLocksName", text)  || checkSearchTextExist(value, "mobileNo", text) ){
            sortedDevices.push(value);
          }
        })
        sortedDevices = _.uniq(sortedDevices);
        let csvData = [];
        _.forEach(sortedDevices, (value)=>{
            csvData.push(_.omit(value,['id','scopeId','scopeId2','deletePendingScopes','assignedLocksName','mergedName','noOfUsers', 'lockNames', 'macIds', 'name']));
        })
        setDownloadCsvData(csvData);
        setIotDevices(sortedDevices);
    }else {
      let csvData = [];
      _.forEach(window.lockUsersData, (value)=>{
          csvData.push(_.omit(value,['id','scopeId','scopeId2','assignedLocksName','deletePendingScopes','mergedName','noOfUsers', 'lockNames', 'macIds', 'name']));
      })
      setDownloadCsvData(csvData);
      setIotDevices(window.lockUsersData);
    }
    resetPaginationData(sortedDevices);  
  };

  const resetPaginationData = (data) => {
    if (window.oldPreservedLockUsersPageNumber) {
      if (data.length > 0) {
        let pageNumberCount = Math.ceil((data.length/rowsPerPage)) - 1;                
        if (window.oldPreservedLockUsersPageNumber > pageNumberCount) {
          setPage(pageNumberCount);
          setPageNumber(pageNumberCount);
          window.oldPreservedLockUsersPageNumber = pageNumberCount;
        }
      }else {
        let pageNumberCount = Math.ceil((window.lockUsersData.length/rowsPerPage)) - 1;
        if (window.oldPreservedLockUsersPageNumber > pageNumberCount) {
          setPage(pageNumberCount);
          setPageNumber(pageNumberCount);        
          window.oldPreservedLockUsersPageNumber = pageNumberCount;
        }else if (window.oldPreservedLockUsersPageNumber <= pageNumberCount && pageNumberCount > 0){
          setPage(window.oldPreservedLockUsersPageNumber);
          setPageNumber(window.oldPreservedLockUsersPageNumber);        
        }else {
          setPage(0);
          setPageNumber(0);
          window.oldPreservedLockUsersPageNumber = 0;
        }        
      }
    }else {
      setPageNumber(0);
      setPage(0);   
      window.oldPreservedLockUsersPageNumber = 0;
    }
  }

  const rowPerPageLabel = (
      <div className={paginationStyle.rowPerPageLabelDiv}>
        <Box className={paginationStyle.page}>
          <span style={{marginRight: '32px'}}>Page</span>
        </Box>
        <Box className={paginationStyle.pageSelectBox}>
          <Select id="numberOfPages" value={pageNumber} onChange={handleChange}>
            {
              Math.ceil(iotDevices.length /rowsPerPage) > 0 ?
              // eslint-disable-next-line array-callback-return
              Array(Math.ceil(iotDevices.length / rowsPerPage))
                .fill()
                .map((_, index) => {
                  return (
                    <MenuItem value={index} sx={{minWidth: '64px'}}>
                      {index + 1}
                    </MenuItem>
                  );
                })
              :
                <MenuItem value={0} sx={{minWidth: '64px'}}>
                  1
                </MenuItem>
            }
          </Select>
        </Box>
        <Box className={paginationStyle.rowPerPageLabelDiv}>
          <span style={{marginRight: '32px'}}>Rows Per Page</span>
        </Box>
        <Box className={paginationStyle.rowPerPageSelectBox}>
          <Select value={rowsPerPage} onChange={handleRowPerPage}>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Box>
      </div>
    );

  const [openHistoryDialog, setOpenHistoryDialog] = React.useState(false);
  const historyDialog = (data) => {
    setOpenHistoryDialog(true);
  };

  const usersDialog = (data) => {
    setUsersData(data);
    setOpenUsersDialog(true);
  };

  const handleClose = () => {
    setOpenHistoryDialog(false);
  };

  const handleUsersClose = () => {
    setOpenUsersDialog(false);
  };

  const showCardCSN = (user) => {
        if(user.cardCSN === null) {
            return '-';
        } else {
            return user.cardCSN.substring(0, 10).toString().toUpperCase();
        }
  };

  const handleUpdateLockUser = (data) => {    
    if (data){
      data.cardCSN = data.cardCSN == "-" ? "" : data.cardCSN;
      data.firstNameTextLength = data.firstName !== "" && data.firstName && typeof data.firstName !== 'undefined' ? data.firstName.length + "/20" : "0/20";
      data.divisionTextLength = data.lastName !== "" && data.lastName && typeof data.lastName !== 'undefined' ? data.lastName.length + "/20" : "0/20";
      data.divisionTextLength = data.division !== "" && data.division && typeof data.division !== 'undefined' ? data.division.length +"/20" : "0/20";
      data.employeeIdTextLength = data.employeeId !== "" && data.employeeId && typeof data.employeeId !== 'undefined' ? data.employeeId.length +"/20" : "0/20";
      data.mobileNoTextLength = data.mobileNo !== "" && data.mobileNo && typeof data.mobileNo !== 'undefined' ? data.mobileNo.length +"/14" : "0/14";
      data.cardCSNTextLength = data.cardCSN !== "" && data.cardCSN && typeof data.cardCSN !== 'undefined' ? data.cardCSN.length + "/10" : "0/10";
      setUpdateLockUSerData(data);
      setUpdateLockUserDialog(true);    
    }else {      
      setCreateLockUserData([{
        firstName:"",
        firstNameTextLength: "0/20",
        lastNameTextLength: "0/20",
        lastName:"",
        email:"",
        divisiion:"",
        divisionTextLength: "0/20",
        employeeId:"",
        employeeIdTextLength: "0/20",
        mobileNoTextLength: "0/20",
        cardCSNTextLength: "0/10",
        scopeid: sessionStorage.tenantId
      }]);
      setCreateLockUserDialog(true);
    }   
  }

  const createUser = (data) => {    
    IotServiceClient.createNewLockUser(data).then((response)=> {      
      if (response.hasOwnProperty("error")){
        enqueueSnackbar("Failed to add user");
      }else {
        enqueueSnackbar("User added successfully");
      }      
    }).catch(()=>{
      enqueueSnackbar("Failed to add user");
    });
    setCreateLockUserDialog(false);
  };

  const createNewLockUser = (data) => {
    if (data.cardCSN !== "" && data.cardCSN !== null && typeof data.cardCSN !== 'undefined'){
      let alreadyCardCsnExist = false;
      let alreadyCSNExistData = [];
      let allCardCSNs = [];
      let allCardCSNwithEmailMapping = {};
      _.forEach(iotDevices, (value) => {
        if (value.cardCSN !== "-" && value.cardCSN !== "" && value.cardCSN !== null && typeof value.cardCSN !== 'undefined'){
          let csn = value.cardCSN.substring(0,10);
          allCardCSNs.push(csn);
          allCardCSNwithEmailMapping[csn] = value.email;
        }
      });

      if (allCardCSNs.length > 0){
        alreadyCardCsnExist = allCardCSNs.includes(data.cardCSN);        
      }

      if (alreadyCardCsnExist) {
        setShowCardCsnExistAlertDialog(true);        
        let errorData = {
          "cardCSN" : data.cardCSN,
          "email": allCardCSNwithEmailMapping[data.cardCSN]
        }
        setCardCSNErrorData([errorData]);
      }else {
        createUser(data);
      }      
    }else {
      data.cardCSN = null;
      createUser(data);
    }
  };

  const handleDeleteLockUserDialog = (data) => {
    
    if (data.noOfUsers === 0 || !data.noOfUsers){
      setShowDeleteLockUserDialog(true);
      setShowDeleteLockUserDialogData(data);
    }else {      
      let anyGatewayIsActive = false;
      GatewayServiceClient.getGateways(sessionStorage.tenantId, "lockUsersPage").then((gateways) => {        
        _.forEach(gateways,function(gateway){
           if (gateway.status === "CONNECTED"){
              anyGatewayIsActive = true;
           }
        });
        if (anyGatewayIsActive){
          setShowDeleteLockUserDialog(true);
          setShowDeleteLockUserDialogData(data);
        }else {
          setShowCannotDeleteLockUserDialog(true);
          setShowCannotDeleteLockUserData(data);
        }
      });      
    }
  }

  const handleDeleteLockUser = (user) => {   
    var removeIotUserInstructionPayload = {
      "payload": {
        "userId": user.id,
        "email": user.email,
        "emailHash": user.emailHash,
        "scopeId": sessionStorage.tenantId,
      },
        "type": Enums.instruction_enums.REMOVE_IOT_USER
    }      
    setShowDeleteLockUserDialog(false);
    addTenantIdToPendingScopes(true, user);
    GatewayServiceClient.createInstruction(response => {
      if (response.status === 201 || response.status === 200){
        setShowSnackBar(true)
        setShowSnackBarMessage("Successfully created delete user instruction");
        setTimeout(() => {
          setShowSnackBar(false);  
        }, 3001);
      }else {
        setShowSnackBar(true)
        setShowSnackBarMessage("Failed to create delete user instruction");
        addTenantIdToPendingScopes(false, user);
        setTimeout(() => {
          setShowSnackBar(false);  
        }, 3001);
      }
    }, removeIotUserInstructionPayload); 
    // IotServiceClient.deleteIotDeviceUser(user.id).then((response)=>{        
    // });    
  };

  const addTenantIdToPendingScopes = (shouldAdd, user) => {
    let modifiedData = [];
    _.forEach(window.lockUsersData, (value) => {
        if (value.id === user.id) {
            let pendingScopes = user.deletePendingScopes ? user.deletePendingScopes : [];
            if(shouldAdd) {
                pendingScopes.push(sessionStorage.tenantId);
            } else {
                const index = pendingScopes.indexOf(sessionStorage.tenantId);
                if (index !== -1) {
                  pendingScopes.splice(index, 1);
                }
            }
          value.deletePendingScopes = pendingScopes;
        }
        modifiedData.push(value);
      })
     window.lockUsersData = modifiedData;
  }

  const showDownloadCSVDialog = () => {
    setShowDownloadCsvDialog(true);
  }

  const handleCloseCsvDialog = (data) => {
    setShowDownloadCsvDialog(false);
    document.getElementById("downloadLockUsersCsv").click();
  }

  const handleUserAssignedLocksDialog = (data) => {
    setShowUsersAssignedLocksDialog(true);
    setShowUsersAssignedLocksDialogData(data);
  };

  const getAllLockName = (data) => {
    let allLocks = "";    
    _.forEach(data,(value)=>{      
      allLocks += value + ", ";
    })    
    return allLocks !== "" ? allLocks.substring(0,allLocks.length-1) : "";
  }

  const updateUser = (user) => {
    if (user.cardCSN && typeof user.cardCSN !== 'undefined'){
        if (user.cardCSN.trim() === ""){
            user.cardCSN = null;
        }
    }
    IotServiceClient.updateIotDeviceUser(user.id, user).then((response)=> {
      if (!response.hasOwnProperty("id")){
        if (response.message !== "" && response.message !== 'undefined'){
          if (response.hasOwnProperty("message") ? response.message.indexOf("Please remove user assigned in a lock and remove it") !== -1 : null){
            setShowCardCsnExistAlertDialog(true);
            setPreservedUpdateData(user);            
            setCardCSNErrorData([]);
          }else{
            enqueueSnackbar("Failed to update user");
            setUpdateLockUserDialog(false);
          }
        }else {
          enqueueSnackbar("Failed to update user");
          setUpdateLockUserDialog(false);
        }
      }else {
        setShowSnackBar(true)
        setShowSnackBarMessage("Updated successfully");
        setPreservedUpdateData({});
        setTimeout(() => {
          setShowSnackBar(false);  
        }, 3001);
        setUpdateLockUserDialog(false);
        setPreservedUpdateData({});
      }
      setUpdateCardCSNSuccessDIalogData([]);
    });
  };

  const getOldCardCSN = (user) => {
    let oldcardCsn = "";
    _.forEach(iotDevices,(val)=> {
      if (val.id === user.id) {
        oldcardCsn = val.cardCSN;
      };
    })
    return oldcardCsn;
  }

  const handleUpdateCardCSN = (user) => {    
    let oldcardCsn = "";
    _.forEach(iotDevices,(val)=> {
      if (val.id === user.id) {
        oldcardCsn = val.cardCSN;
      };
    })
    let checkCardCSNStatusPayload = {
      "scopeId": user.scopeId,
      "cardCSN": oldcardCsn
    }
    if (newCardCSN !== ""){
        IotServiceClient.checkCardCSNStatus(checkCardCSNStatusPayload, "lockUsersPage").then((response) => {
          if (response.id === null || response.id === user.id){
            if (response.assignedLocks ? response.assignedLocks.length === 0  : false){
              updateUser(user);
            }else {
              setShowCardCsnExistAlertDialog(true);
              setCardCSNErrorData([response]);
              setPreservedUpdateData(user);
            }
          }else {
            setShowCardCsnExistAlertDialog(true);
            setCardCSNErrorData([response]);
            setPreservedUpdateData(user);
          }
        }).catch(()=>{
          enqueueSnackbar("Failed to create User");
        })
    }else {
        user.cardCSN = user.cardCSN ? user.cardCSN.trim() === "" ? null : user.cardCSN : null;
        updateUser(user);
    }
  }

  const updateIotDeviceUser = (user) => {
    let oldcard = "";
    _.forEach(iotDevices,(val)=> {
      if (val.id === user.id) {
        oldcard = val.cardCSN;
      };
    })
//    if (user.cardCSN === "" ){
//      user.cardCSN = null;
//    }

    if (user.cardCSN !== "-" && user.cardCSN !== "" && user.cardCSN !== null && typeof user.cardCSN !== 'undefined'){      
      let alreadyCardCsnExist = false;
      let alreadyCSNExistData = [];
      let allCardCSNs = [];
      let allCardCSNwithEmailMapping = {};
      _.forEach(iotDevices, (value) => {
        if (value.cardCSN !== "-" && value.cardCSN !== "" && value.cardCSN !== null && typeof value.cardCSN !== 'undefined'){
          let csn = value.cardCSN.substring(0,10);
          allCardCSNs.push(csn);
          allCardCSNwithEmailMapping[csn] = value.email;
        }
      });

      if (allCardCSNs.length > 0){
        alreadyCardCsnExist = allCardCSNs.includes(user.cardCSN);        
      }
      if (!alreadyCardCsnExist){
        IotServiceClient.checkCardCSNStatus(user, "lockUsersPage").then((response) => {
          if (response.id === null && oldcard !== "" && oldcard !== "-"){
            setUpdateCardCSNSuccessDIalog(true);
            setUpdateCardCSNSuccessDIalogData([user]);
            setNewcardCSN(user.cardCSN);
            let oldcardCsn = "";
            _.forEach(iotDevices,(val)=> {
              if (val.id === user.id) {
                oldcardCsn = val.cardCSN;
              };
            })
            setOldCardCSN(oldcardCsn)
            // updateUser(user);
          }else if ((oldcard === "" || oldcard === "-" ) && response.id === null){
            updateUser(user);
          }else {
            setShowCardCsnExistAlertDialog(true);
            setCardCSNErrorData([response]);
          }
        }).catch(()=>{
          enqueueSnackbar("Failed to create User");
        })        
      }else {
        
        setShowCardCsnExistAlertDialog(true);        
        let errorData = {
          "cardCSN" : user.cardCSN,
          "email": allCardCSNwithEmailMapping[user.cardCSN]
        }
        setCardCSNErrorData([errorData]);
      }    
    }else if( user.cardCSN === "" && oldcard ){
          IotServiceClient.checkCardCSNStatus(user, "lockUsersPage").then((response) => {
              if (response.id !== null){
                setUpdateCardCSNSuccessDIalog(true);
                setUpdateCardCSNSuccessDIalogData([user]);
                setNewcardCSN(user.cardCSN);
                let oldcardCsn = "";
                _.forEach(iotDevices,(val)=> {
                  if (val.id === user.id) {
                    oldcardCsn = val.cardCSN;
                  };
                })
                setOldCardCSN(oldcardCsn);
              }
        }).catch(()=>{
          enqueueSnackbar("Failed to create User");
        })
    }else {
      updateUser(user);
    }
  }

  const handleRFIDDIalog = () => {
    setUploadRFIDUsersDialog(true);
  }

  const handleBulkDialog = () => {
    setUploadBulkUsersDialog(true);
  }

  const handleFilterDIalog = () => {
      setFilterUsersDialog(true);
    }

   const applyFilterChanges = () => {
       setSearchText('');
       document.getElementById('searchText').value = '';
       handleTextCange("")
       setSearchBar(false);
       setFilterUsersDialog(false);
       setAppliedFilter(true);
       window.appliedFilter = true;
       handleApplyFilter();
     }

  const removeFilter = () => {
    delete sessionStorage.navigatedValueOfLUFromGlobalSearch;
    fetchIotUsers(true);
  };

  const removeAppliedFilter = () => {
      window.appliedFilter = false;
      setAppliedFilter(false);
      fetchIotUsers(true);
    };

  const resendActivationMail = (data) => {
    setShowSnackBar(true)
    setShowSnackBarMessage("Sending mail to user");
    setTimeout(() => {
      setShowSnackBar(false);  
    }, 2001);
    IotServiceClient.resendActivationMail(data.email).then((res)=>{
      if (res.status === 200){
        setShowSnackBar(true)
        setShowSnackBarMessage("Sent mail successfully");
        setTimeout(() => {
          setShowSnackBar(false);  
        }, 2001);
      }
    }).catch((error)=>{})
  }

  const segregatedData = () => {
    let copyOfDevices = Object.assign([],iotDevices);
    const [id,emailHash,deletePendingScopes,scopeId,scopeId2,noOfUsers,mergedName,lockNames,macIds,name, ...filteredCopyOfDevices] = copyOfDevices;
    return filteredCopyOfDevices;
  }

  return (
      <>
        {sessionStorage.navigatedValueOfLUFromGlobalSearch === "gsLU" ?
              <Box sx={{display:"flex",flexDirection:"row",padding: '10px',background:"#ffcc80"}}>
                <Box>
                  Filtered by :
                </Box>
                <Box sx={{marginRight: "auto", marginLeft: "6px",height:"22px",background:"#ede8e8",width:"auto"}}>
                  <Box sx={{display:"flex",flexDirection:"row",marginLeft:"9px"}}>
                    <Tooltip placement='top' title={sessionStorage.navigatedFromDashboard ? sessionStorage.navigatedFromDashboard : sessionStorage.gsLockUsersEmail} arrow>
                      <Box className='filterNameForSmallScreens'>
                        {sessionStorage.navigatedFromDashboard ? sessionStorage.navigatedFromDashboard : sessionStorage.gsLockUsersEmail}
                      </Box>
                    </Tooltip>
                    <Box sx={{padding:"0px 3px 0px 3px"}}>
                      <Close sx={{cursor:"pointer",fontSize:"21px",paddingLeft:"4px",marginTop:"1px"}} onClick={()=> removeFilter()} />
                    </Box>
                  </Box>
                </Box>
              </Box>
              :
              null
        }
        {appliedFilter ?
                      <Box sx={{display:"flex",flexDirection:"row",padding: '10px',background:"#ffcc80"}}>
                        <Box>
                          Filtered by :
                        </Box>
                        <Box sx={{marginRight: "auto", marginLeft: "6px",height:"22px",background:"#ede8e8",width:"auto"}}>
                          <Box sx={{display:"flex",flexDirection:"row",marginLeft:"9px"}}>
                            <Tooltip placement='top' title='Multiple Shared Lockers Access' arrow>
                              <Box className='filterNameForSmallScreens'>
                                Multiple Shared Lockers Access
                              </Box>
                            </Tooltip>
                            <Box sx={{padding:"0px 3px 0px 3px"}}>
                              <Close sx={{cursor:"pointer",fontSize:"21px",paddingLeft:"4px",marginTop:"1px"}} onClick={()=> removeAppliedFilter()} />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      :
                      null
                }
        <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              padding: '20px 0px 20px 24px',
            }}
        >
          <span style={{fontSize: '20px', marginRight: 'auto'}}>Lock Users</span>
          <div style={{display: 'flex',alignItems:'baseline'}} id="iconsBlock">
            <input
                id="searchText"
                type="text"
                onChange={(event)=>handleTextCange(event.target.value)}
                placeholder="Search Lock Users..."
                style={{
                  marginLeft: 'auto',
                  marginRight: '10px',
                  minWidth: '37px',
                  position:"relative",
                  top:"-8px",
                  width: '140px',
                  maxWidth: '100%',
                  visibility: SearchBar ? 'visible' : 'hidden',
                }}
            />
            <Close
                style={{
                  marginRight: '4px',
                  cursor: 'pointer',
                  display: SearchBar ? 'flex' : 'none',
                  color: '#0000008A',
                }}
                onClick={() => {                  
                  setSearchText('');
                  document.getElementById('searchText').value = '';
                  handleTextCange("")
                  setSearchBar(false);
                }}
            />
            <Tooltip title="Search" placement="top" arrow>
              <Search
                  style={{
                    marginRight: '10px',
                    cursor: 'pointer',
                    color: '#0000008A',
                    display: !SearchBar ? 'flex' : 'none',
                    marginTop:"1px"
                  }}
                  onClick={() => {setSearchBar(true);
                    setTimeout(() => {document.getElementById("searchText").focus();},100);
                  }}
              />
            </Tooltip>
            <Tooltip title="Refresh" placement="top" arrow>
              <Refresh sx={{ color: '#0000008A',marginRight: '10px', cursor: 'pointer'}} onClick={()=>refresh()} />
            </Tooltip>
            {
              sessionStorage.tenantName !== "FreeTier" ?
                <Tooltip title="Filter users" placement="top" arrow>
                  <FilterList sx={{ color: '#0000008A',marginRight: '10px', cursor: 'pointer'}} onClick={()=>handleFilterDIalog()} />
                </Tooltip>
              :
                null
            }
            {
              sessionStorage.tenantName !== "FreeTier" ?
                <Tooltip title="Upload RFID users" placement="top" arrow>
                  <img src="/add_rfid_user.png" height='19px'width='30px' style={{position:"relative",top:"-2px",marginRight:"10px",cursor:"pointer"}}  onClick={()=>handleRFIDDIalog()}/>
                </Tooltip>
              :
                null
            }
            {
              sessionStorage.tenantName !== "FreeTier" ?
                <Tooltip title="Upload users" placement="top" arrow>                    
                    <img src="/upload_users.png" height='24px'width='40px' style={{marginTop:"2px",marginRight:"2px",cursor:"pointer"}}  onClick={()=>handleBulkDialog()}/>
                </Tooltip>
              :
                null
            }
             <Tooltip title="Download CSV" placement="top" arrow>
                <Download style={{marginRight: '14px', cursor: 'pointer',color: '#0000008A'}} onClick={()=> showDownloadCSVDialog()} />
              </Tooltip>
            <CsvDownload
                data={downloadCSVData}
                filename={sessionStorage.tenantName + '-LockUsers.csv'}
                style={{
                  border: 'transparent',
                  background: 'transparent',
                  paddingLeft: '0px',
                  visibility: 'hidden',
                  position:"absolute",
                  top:"-50%"
                }}           
                id="downloadLockUsersCsv"     
            >
              <Tooltip title="Download CSV" placement="top" arrow>
                <Download style={{marginRight: '14px', cursor: 'pointer',color: '#0000008A'}} />
              </Tooltip>
            </CsvDownload>
          </div>
        </Box>
        <Box style={{width: '100%', height: 'auto', marginBottom: '86px'}}>
          <TableContainer>
            <Table>
              <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(checked) => handleSelectAllClick(checked)}
                  onRequestSort={handleRequestSort}
                  rowCount={iotDevices.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(iotDevices,order, orderBy)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                          <TableRow
                              hover
                              onClick={(event) => handleClick(event, row.name)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                              className={paginationStyle.tableBodyRow}
                              sx={{borderBottom:'1px solid #c1a6a6'}}
                          >
                            <TableCell align="left" style={{overflowWrap:'anywhere'}}>{row.mergedName ? row.mergedName : row.firstName ? (row.lastName ? row.firstName.replace(/ /g,"")+ " " +row.lastName.replace(/ /g,"") : row.firstName.replace(/ /g,"")) : (row.lastName ? row.lastName.replace(/ /g,"") : "No Name")}</TableCell>
                            <TableCell align="left" style={{overflowWrap:'anywhere'}}>{row.email || "-"}</TableCell>
                            <TableCell align="left">{row.cardCSN || "-"}</TableCell>
                            <TableCell align="left">
                                <Edit sx={{
                                    display: JSON.parse(sessionStorage.userDetails).permissions.includes("IOT_USER_WRITE") ? 'flex' : 'none',
                                    cursor:"pointer",color:'#0000008A',fontSize:"20px"}} onClick={()=>handleUpdateLockUser(row)}/>
                            </TableCell>
                            <TableCell align="left">
                                <>
                                      <Lock sx={{cursor:"pointer",color:'#0000008A',marginRight:'10px',fontSize:"20px"}} onClick={()=> handleUserAssignedLocksDialog(row)}/>
                                      {
                                        row.deletePendingScopes.includes(sessionStorage.tenantId) ?
                                          <Tooltip title="Please wait. User is being deleted." placement='top' arrow>
                                            <Delete sx={{color:'#4b45458a',fontSize:"20px"}}/>
                                          </Tooltip>
                                        :
                                          <Delete sx={{cursor:"pointer",color:'#0000008A',fontSize:"20px"}} onClick={()=>handleDeleteLockUserDialog(row)}/>
                                        
                                      }                                      
                                </>
                            </TableCell>
                            {
                                sessionStorage.tenantName !== "FreeTier" ?
                                    <TableCell align="left">
                                          {row.assignedLocksName}                         
                                        {
                                          row.noOfUsers > 1 ?
                                              <MoreHoriz  sx={{cursor:"pointer",position:'relative',top:"8px",left:'4px'}} onClick={()=> handleUserAssignedLocksDialog(row)}/>
                                          :
                                            null
                                        }
                                        {
                                        row.noOfRFIDSharedDevices > 1 ?
                                        <Tooltip title="Accessing Multiple Shared Lockers" placement="top" arrow>
                                            <PriorityHigh  sx={{color: '#FF0000',cursor:"pointer",position:'relative',top:"8px",left:'4px'}} />
                                        </Tooltip>
                                        :
                                             null
                                        }
                                    </TableCell>
                                :
                                    null
                            }
                            <TableCell align="left">
                            {
                                row.status === "ACTIVE" ?
                                    <Tooltip placement="top" title="Active">
                                        <VerifiedUser  sx={{color:'#0000008A',fontSize:"20px"}}/>
                                    </Tooltip>
                                :
                                    row.status === "ACTIVATION_PENDING" ?
                                        <Tooltip placement="top" title="Resend activation mail">
                                            <MailOutline sx={{color: '#0000008A',fontSize:"20px"}} onClick={()=> resendActivationMail(row)}/>
                                        </Tooltip>
                                    :
                                        row.status === "ASSIGNMENT_PENDING" ?
                                          <Tooltip placement="top" sx={{cursor:"pointer"}} title="Assignment pending">
                                              <span style={{marginLeft:"9px"}}>
                                                -
                                              </span>
                                          </Tooltip>
                                        :
                                            row.status === "PASSWORD_RESET_PENDING" ?
                                              <Tooltip placement="top" title="Password Reset Pending">
                                                  <IconButton aria-label="passwordResetPending" sx={{height:'24px',width:'24px'}}>
                                                    <img src="/password-reset.png" height='24px'width='24px' />
                                                  </IconButton>
                                              </Tooltip>

                                            :
                                              row.status === "REGISTRATION_PENDING" ?
                                                <Tooltip placement="top" title="Registration pending">
                                                  <MailOutline sx={{color: '#0000008A',fontSize:"20px"}}/>
                                                </Tooltip>
                                              :
                                                <span style={{marginLeft:"9px"}}>
                                                  -
                                                </span>

                            }
                            </TableCell>
                            <TableCell align="left">{row.employeeId || '-'}</TableCell>
                            <TableCell align="left">
                              {row.division || '-'}
                            </TableCell>
                            <TableCell align="left">
                              {row.mobileNo || "-"}
                            </TableCell>
                          </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
              sx={{width: '100%', overflowX: 'hidden'}}
              labelRowsPerPage={rowPerPageLabel}
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={iotDevices.length}
              rowsPerPage={rowsPerPage}
              className="pagination"
              page={pageNumber}
              SelectProps={{
                inputProps: {'aria-label': 'rows per page'},
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
          />
        </Box>
        {
          sessionStorage.tenantName !== "FreeTier" ?
            <Button
            onClick={() => {
              handleUpdateLockUser()
            }}
            style={{
              position: 'fixed',
              bottom: '37px',
              right: '40px',
              height: '62px',
              miWwidth: '55px',
              maxWidth: '55px',
              borderRadius: '50%',
              backgroundColor: 'rgb(20,19,139)',
              display: JSON.parse(sessionStorage.userDetails).permissions.includes("IOT_USER_WRITE") ? 'flex' : 'none',
            }}
          >
            <PersonAdd sx={{color: '#fff'}} />
          </Button>
        :
            null
      }
        {
          showSnackBar ?
          <Snackbar
            anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
            open={showSnackBar}
            autoHideDuration={3000}
            message={showSnackBarMessage}
          />
        :
          null
        }
        {
          updateLockUserDialog ?
            <DraggableComponent
              data={updateLockUSerData}
              dialog="updateOrCreateDialog"
              showUpdateOrCreateLockUser={updateLockUSerData}
              title="Update User"
              updateIotDeviceUser={(data) => updateIotDeviceUser(data)}
              closeDialog={()=>setUpdateLockUserDialog(false)}
            />
          :
            null
        }
        {
          createLockUserDialog ?
            <DraggableComponent
              dialog="updateOrCreateDialog"
              data={createLockUserData}
              showUpdateOrCreateLockUser={createLockUserDialog}
              title="Create New User"
              closeAndOpenUploadCsvDialog = {() => {
                setCreateLockUserDialog(false);
                setUploadBulkUsersDialog(true);                
              }}
              closeDialog={()=>setCreateLockUserDialog(false)}
              createIotUser={(data) => createNewLockUser(data)}
            />
          :
            null
        }
        {
          showDeleteLockUserDialog ?
            <DraggableComponent
              data={showDeleteLockUserDialogData}
              dialog="deleteLockUserDialog"
              showLockUserDeleteDialog={showDeleteLockUserDialog}
              deleteLockUser = {()=> handleDeleteLockUser(showDeleteLockUserDialogData)}
              closeDeleteLockUserDialog={()=>setShowDeleteLockUserDialog(false)}
            />
          :
            null
        }
        {
          showUsersAssignedLocksDialog ?
            <DraggableComponent
              data={showUsersAssignedLocksDialogData}
              dialog="showUserAssignedLocksDialog"
              showAssignedLocksDialog={showUsersAssignedLocksDialog}
              closeAssignedLocksDialog={()=>setShowUsersAssignedLocksDialog(false)}
            />
          :
            null
        }
        {
          showCannotDeleteLockUserDialog ?
            <DraggableComponent
              data={ShowCannotDeleteLockUserData}
              dialog="cannotDeleteLockUser"
              cannotDeleteUserDialog={showCannotDeleteLockUserDialog}
              closeCannotDeleteUserDialog={()=>setShowCannotDeleteLockUserDialog(false)}
            />
          :
            null
        }
        {
          showCsvDialog ?
            <DraggableComponent 
              dialog="downloadLockUsersCSVDialog"
              data={[]}
              showDialog={showCsvDialog}
              closeProcess={() => setShowDownloadCsvDialog(false)}
              closeCsvDialog={(data)=> handleCloseCsvDialog(data)}
            />
          :
            null
        }
        {
          uploadRFIDUsersDialog ?
            <DraggableComponent
              data={[]}
              RFIDUsersDialog={uploadRFIDUsersDialog}
              dialog="uploadRFIDUsersDialog"
              closeUploadRFIDUsersDialog = {()=> setUploadRFIDUsersDialog(false)}
            />
          :
            null
        }
        {
          uploadBulkUsersDialog ?
            <DraggableComponent
              data={[]}
              bulkUsersDialog={uploadBulkUsersDialog}
              dialog="uploadBulkUsersDialog"
              closeUploadBulkUsersDialog = {()=> setUploadBulkUsersDialog(false)}
            />
          :
            null
        }
        {
          filterUsersDialog ?
            <DraggableComponent
              data={[]}
              openFilterUsersDialog={filterUsersDialog}
              dialog="filterUsersDialog"
              applyFilter = {applyFilterChanges}
              closeFilterUsersDialog = {()=> setFilterUsersDialog(false)}
            />
          :
            null
        }
        {
          showCardCsnExistAlertDialog ?
            <DraggableComponent
              data={[]}
              title="Warning!"
              email={cardCSNErrorData.length > 0 ? cardCSNErrorData[0].email : null}
              lockNames = {cardCSNErrorData.length > 0 ? getAllLockName(cardCSNErrorData[0].assignedLocks) : ""}
              openCardCsnErrorDialog={showCardCsnExistAlertDialog}
              dialog="cardCSNErrorDialog"              
              closeCardCsnErrorDialog = {() => {
                setShowCardCsnExistAlertDialog(false);
                setCardCSNErrorData([]);
              }}
              updateCardCSNData = {() => {
                updateUser(preservedUpdateData)
                setShowCardCsnExistAlertDialog(false);
                setCardCSNErrorData([]);
              }}
            />
          :
            null
        }
        {
          updateCardCSNSuccessDialog ?
            <DraggableComponent
              data={[]}
              title="Warning!"
              openUpdateCardCSNSuccessDialog={updateCardCSNSuccessDialog}
              dialog="updateCardCSNSuccessDialog"              
              closeOpenUpdateCardCSNSuccessDialog = {() => {
                setUpdateCardCSNSuccessDIalog(false);
                setUpdateCardCSNSuccessDIalogData([])
              }}
              oldCSN={oldCardCSN}
              newCSN={newCardCSN}
              updateUserData={() => {
                setUpdateCardCSNSuccessDIalog(false);
                setOldCardCSN("");
                setNewcardCSN("");
                if (updateCardCSNSuccessDIalogData.length > 0){
                  handleUpdateCardCSN(updateCardCSNSuccessDIalogData[0]);
                }              
              }}
            />
          :
            null
        }
      </>
  );
}
