import * as React from 'react';
import { Box,Snackbar,Dialog,DialogTitle,Switch,DialogActions,DialogContent,Radio,RadioGroup,Table,useMediaQuery,useTheme,TableBody,TableCell,TableContainer,TableHead,TablePagination,TableRow,TableSortLabel,Checkbox,LinearProgress,Tooltip,Select,MenuItem } from '@mui/material';
import { Close,CheckCircle,Edit,Error,LibraryBooks,Delete,SyncLock,PriorityHigh,PersonAdd,AlarmAdd,ArrowDropDown,ReportProblem,People,Lock,LockOpen,Check,FirstPage,LastPage,KeyboardArrowLeft,KeyboardArrowRight,Search,Refresh,Download,HelpOutline } from '@mui/icons-material';
import IotServiceClient from "../../clients/IotServiceClient";
import GatewayServiceClient from "../../clients/GatewayServiceClient";
import CsvDownload from 'react-json-to-csv';
import {visuallyHidden} from '@mui/utils';
import HistoryDialog from './HistoryDialog';
import UsersDialog from './UsersDialog';
import paginationStyle from "../../stylesheet/pagination.module.css";
import "../../stylesheet/common.css";
import Enums from "../../constants/Enums";
import _ from 'lodash';
import PubSubUtils from "../../pubsub/PubSubUtils";
import PubSubChannels from "../../pubsub/PubSubChannels";
import DraggableComponent from '../DraggableComponent';
import { Button, Grid } from '@mui/material';
import MqttEventHandler from "../Mqtt/MqttEventHandler";
import {useSnackbar} from "notistack";
import LocksGrid from "./locks_grid_view"
import ShowAddUserLoadingDialog from './ShowAddUserLoadingDialog';
import ShowLockInstructionStatusDialog from './ShowLockInstructionStatusDialog';
import ShowRemoveUserLoadingDialog from "./ShowRemoveUserLoadingDialog";
import SwitchLockModeDialog from "./SwitchLockModeDialog";


function TablePaginationActions(props) {

  const {count, page, rowsPerPage, onPageChange} = props;

  function handleFirstPageButtonClick(event) {
    onPageChange(event, 0);
  }

  function handleBackButtonClick(event) {
    onPageChange(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onPageChange(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }



  return (
      <div className={paginationStyle.paginationActionIcons}>
        <FirstPage
            onClick={() => {
              if (page > 0) handleFirstPageButtonClick();
            }}
            sx={{
              color: page === 0 ? 'rgba(0,0,0,0.26)' : 'black',
              cursor: page === 0 ? 'auto' : 'pointer',
              marginRight: '8px'
            }}
        />
        <KeyboardArrowLeft
            onClick={() => {
              if (page > 0) handleBackButtonClick();
            }}
            sx={{
              color: page === 0 ? 'rgba(0,0,0,0.26)' : 'black',
              cursor: page === 0 ? 'auto' : 'pointer',
              marginRight: '8px'
            }}
        />
        <KeyboardArrowRight
            onClick={() => {
              if (page !== Math.ceil(count / rowsPerPage) - 1)
                handleNextButtonClick();
            }}
            sx={{
              color:
                  page !== Math.ceil(count / rowsPerPage) - 1 && count > 0
                      ? 'black'
                      : 'rgba(0,0,0,0.26)',
              cursor:
                  page !== Math.ceil(count / rowsPerPage) - 1 && count > 0 ? 'pointer' : 'auto',
              marginRight: '8px'
            }}
        />
        <LastPage
            onClick={() => {
              if (page !== Math.ceil(count / rowsPerPage) - 1)
                handleLastPageButtonClick();
            }}
            sx={{
              color:
                  page !== Math.ceil(count / rowsPerPage) - 1 && count > 0
                      ? 'black'
                      : 'rgba(0,0,0,0.26)',
              cursor:
                  page !== Math.ceil(count / rowsPerPage) - 1 && count > 0 ? 'pointer' : 'auto',
            }}
        />
      </div>
  );
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    editMode,
    onRequestSort,
  } = props;

  const [changeTextColor,setChangeTextColor] = React.useState('');
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
    setChangeTextColor('');
  };

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
      width: '90px'
    },
    {
      id: 'serialNumber',
      numeric: false,
      disablePadding: false,
      label: 'Serial #',
      width: '90px'
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: 'Type',
      width: '100px'
    },
    {
      id: 'mode',
      numeric: false,
      disablePadding: false,
      label: 'Mode',
      width: '80px'
    },
    {
      id: 'inUse',
      numeric: false,
      disablePadding: false,
      label: 'In Use',
      width: '90px'
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Status',
      width: '60px'
    },
    {
      id: 'userCount',
      numeric: false,
      disablePadding: false,
      label: 'Users',
      width: '60px'
    },
    {
      id: 'delete',
      numeric: false,
      disablePadding: false,
      label: null,
      width: '80px'
    },
    {
      id: 'accessedTime',
      numeric: false,
      disablePadding: false,
      label: 'Accessed',
      width: '80px'
    },
    {
      id: 'history',
      numeric: false,
      disablePadding: false,
      label: 'History',
      width: '60px'
    },
    {
      id: 'batteryLevel',
      numeric: false,
      disablePadding: false,
      label: 'Battery',
      width: '90px'
    },
    {
      id: 'rssi',
      numeric: false,
      disablePadding: false,
      label: 'Rssi',
      width: '60px'
    },
    {
      id: 'gatewayName',
      numeric: false,
      disablePadding: false,
      label: 'Gateway',
      width: '100px'
    },
    {
      id: 'firmwareVersion',
      numeric: false,
      disablePadding: false,
      label: 'Firmware',
      width: '100px'
    },
  ];

  const selectAll = (event) => {
    onSelectAllClick(event.target.checked);
  };

  return (
      <TableHead>
        <TableRow>
          {
            editMode ?
                <TableCell
                    sx={{
                      padding: '16px',
                      fontSize: '13px'
                    }}
                >
                  <Checkbox
                      color="error"
                      checked={props.headerCheckBox}
                      onClick={(event) => selectAll(event)}
                      inputProps={{
                        'aria-label': 'select all desserts',
                      }}
                      id="tableHeadCheckBox"
                      sx={{margin: '0px', padding: '0px',left: "-3px"}}
                  />
                </TableCell>
                :
                null
          }
          {headCells.map((headCell) => (
              (editMode && headCell.id !== "inUse" && headCell.id !== "userCount" && headCell.id !== "history") || (!editMode)  ?
                  <TableCell key={headCell.id} align="left">
                    {headCell.label !== 'History' && headCell.id !== 'delete' ? (
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            sx={{
                              width: headCell.width,
                              maxWidth: headCell.width,
                              color: orderBy !== headCell.id ? '#0000008A !important' : '#000000DE !important',
                              overflowWrap: 'break-word',
                            }}
                        >

                          {headCell.label}
                          {orderBy === headCell.id ? (
                              <Box className="styles.lockSortIcon" component="span" sx={visuallyHidden}>
                                {order === 'desc'
                                    ? 'sorted descending'
                                    : 'sorted ascending'}
                              </Box>
                          ) : null}
                        </TableSortLabel>
                    ) : (
                        <Box
                            sx={{
                              width: headCell.width,
                              maxWidth: headCell.width,
                              overflowWrap: 'break-word',
                              color: changeTextColor !== 'history' || changeTextColor !== 'delete' ? '#0000008A !important' : '#000000DE !important',
                            }}
                            onClick={()=>setChangeTextColor('history')}
                        >
                          {headCell.label}
                        </Box>
                    )}
                  </TableCell>
                  :
                  null
          ))}
        </TableRow>
      </TableHead>
  );
}

export default function Locks() {
  const [SearchBar, setSearchBar] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(sessionStorage.locksRPP ? Number(sessionStorage.locksRPP) : 25);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('Name');
  const [selected, setSelected] = React.useState([]);
  const [editMode, setEditMode] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [devices, setDevices] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [showsnackbar, setShowSnackBar] = React.useState(false);
  const [showSnackBarMessage, setShowSnackBarMessage] = React.useState("");
  const [deleteLockData, setDeleteLockData] = React.useState([]);
  const [selectedCount, setSelectedCount] = React.useState(0);  
  const { enqueueSnackbar } = useSnackbar();
  let mqttEventHandler, mqttEventHandlerAddUserMap, mqttEventHandlerFailedOrSuccessUserAddition;
  const [downloadCSVData,setDownloadCsvData] = React.useState([]);
  const [showCsvDialog, setShowDownloadCsvDialog] = React.useState(false);
  const [createLockUserDialog,setCreateLockUserDialog] = React.useState(false);
  const [uploadBulkUsersDialog, setUploadBulkUsersDialog] = React.useState(false)
  const [pageNumber, setPageNumber] = React.useState(0);
  const [openHistoryDialog, setOpenHistoryDialog] = React.useState(false);
  const [infoData, setInfoData] = React.useState([]);
  const [openUsersDialog, setOpenUsersDialog] = React.useState(false);
  const [usersData, setUsersData] = React.useState([]);
  const [snackBar, setSnackBar] = React.useState(false);
  const [lockDeleteDialog ,setLockDeleteDialog] = React.useState(false);
  const [addUserDialog, setAddUserDialog] = React.useState(false);
  const [selectedBulkAddUsersData, setSelectedBulkAddUsersData] = React.useState([]);
  const [showLockView, setShowLockView] = React.useState(true);
  const [lockInfoDialog, setLockInfoDialog] = React.useState(false);
  const [lockInfoDialogData, setlockInfoDialogData] = React.useState([]);
  let iotReachableDevices = [];
  let iotReachableDevicesResponseReceived = false;
  let iotReachableDeviceUserCount = [];
  let iotReachableDeviceUserCountResponseReceived = false;
  let iotReachableDeviceUsers = [];
  let iotReachableDeviceUsersResponseReceived = false;
  const [responseReceivedCount, setResponseReceivedCount] = React.useState(0);
  const [headersCheckBox, setHeadersCheckBox] = React.useState(false);
  const [showAddingUserDialog, setShowAddingUserDialog] = React.useState(false);
  const [showAddingUserDialogData, setShowAddingUserDialogData] = React.useState(0);
  const [showAddingUserDialogCompletedData, setShowAddingUserDialogCompletedData] = React.useState(0);
  const [showAddUserFailedData, setShowAddUserFailedData] = React.useState(0);
  const [closeAddingLoader, setCloseAddingLoader] = React.useState(false);
  const [lockInstructionType, setLockInstructionType] = React.useState("");
  const [showLockInstructionDialog, setShowLockInstructionDialog] = React.useState(false);
  const [lockInstructionResponseStatus, setLockInstructionResponseStatus] = React.useState("");
  const [closeLockInstructionDialogLoader, setCloseLockInstructionDialogLoader] = React.useState(false);
  const [switchLockModeData, setSwitchLockModeData] = React.useState(null);
  const [showSwitchLockModeDialog, setShowSwitchLockModeDialog] = React.useState(false);
  const [multiClick, setMultiClick] = React.useState(false);
  const [failedUserAdditionOrDeletionUsers,setFailedUserAdditionOrDeletionUsers] = React.useState(null);

  const handleRemoveLock = (val) => {
    IotServiceClient.deleteLock(deleteLockData.macId, sessionStorage.tenantId).then((response)=>{
      if (response.status === 200){
        setLockDeleteDialog(false);
        enqueueSnackbar("Successfully lock removed");
      }else {
        enqueueSnackbar("Error in removing lock");
      }
    })
  };

  let lockViewInterval = setInterval(() => {
    if (sessionStorage.lockView === "listView" || sessionStorage.tenantName === "FreeTier" || !sessionStorage.lockView){
      setShowLockView(true);
    }else {
      setShowLockView(false);
    }
  }, 1000);

  const createNewLockUser = (data) => {
    IotServiceClient.createNewLockUser(data).then((response)=> {
      enqueueSnackbar("User added successfully");
    }).catch(()=>{
      enqueueSnackbar("Failed to add user");
    });
    setCreateLockUserDialog(false);
  }

  const handleDeleteLockDialog = (data) =>{
    setDeleteLockData(data);
    setLockDeleteDialog(true);
  };
  
  const mergeAllData = (mergedData, navigatedValue, isMqttEVent) => {
    let locksData = [];
    if (navigatedValue === "online" || navigatedValue === "offline" || sessionStorage.navigatedValueFromGlobalSearch === "gs") {
      _.forEach(mergedData,(lock)=>{
        if (lock.hasOwnProperty("mode")){
            lock.accessed = getAccessedTime(lock.lastAccessedTime)
            lock.accessedTime = lock.lastAccessedTime;
            lock.serialNumber = lock.serialNumber ? lock.serialNumber.replace(/\u0000/g,"") : lock.serialNumber;
            lock.mode = lock.mode === "residential" ? "resident" : lock.mode;
            lock.gateway_MacId = lock.gatewayMacId;
            lock.gatewayMacId = lock.gatewayMacId !== null && lock.gatewayMacId !== undefined && lock.gatewayMacId !== "" ? lock.gatewayMacId.toUpperCase().replace(/:/g,"")  : "-" ;
            lock.gatewayName = lock.gatewayName !== null && lock.gatewayName !== undefined && lock.gatewayName !== "" ? lock.gatewayName : "-" ;
            lock.userCount = lock.count;
            lock.version = lock.version ? lock.version : 0;
            lock.inUse = lock.email ? lock.email : "no-email";
            if (lock.scopeDoesNotMatch) {
              lock.authStatus = null
            }
            if (navigatedValue === "online" && lock.authStatus === "AUTH_SUCCESS"){
              locksData.push(lock);
            }
            if (navigatedValue === "offline" && lock.authStatus !== "AUTH_SUCCESS"){
              locksData.push(lock);
            }            
          if (sessionStorage.navigatedValueFromGlobalSearch === "gs" && lock.macId === sessionStorage.gsMacId){
            locksData.push(lock);
          }
        }
      });
    }else {
      _.forEach(mergedData,(lock)=>{
        if (lock.hasOwnProperty("mode")){
            lock.accessed = getAccessedTime(lock.lastAccessedTime)
            lock.accessedTime = lock.lastAccessedTime;
            lock.serialNumber = lock.serialNumber ? lock.serialNumber.replace(/\u0000/g,"") : lock.serialNumber;
            lock.mode = lock.mode === "residential" ? "resident" : lock.mode;
            lock.gateway_MacId = lock.gatewayMacId;
            lock.gatewayMacId = lock.gatewayMacId !== null && lock.gatewayMacId !== undefined && lock.gatewayMacId !== "" ? lock.gatewayMacId.toUpperCase().replace(/:/g,"")  : "-" ;
            lock.gatewayName = lock.gatewayName !== null && lock.gatewayName !== undefined && lock.gatewayName !== "" ? lock.gatewayName : "-" ;
            lock.userCount = lock.count;
            lock.inUse = lock.email ? lock.email : "no-email";
            if (lock.scopeDoesNotMatch) {
              lock.authStatus = null
            }
            locksData.push(lock);
        }
      });
    }
    window.allLocks = Object.assign([],locksData);
    if(document.getElementById("searchText") ? document.getElementById("searchText").value.length === 0 : true){
        setDevices(locksData);
        resetPaginationData([])
    }else {
      let searchFilteredData = [];
      let searchedFilterText = document.getElementById("searchText") ? document.getElementById("searchText").value : null;
      segregateFilteredData(locksData, searchedFilterText);
    }
    setSelectedCount(0);
    setHeadersCheckBox(false);
    let csvData = [];
    _.forEach(locksData, (value)=>{
      csvData.push(_.omit(value,['id','scopeId','location','isOneTimeUserpresent','authStatus','scopeDoesNotMatch','selected']));
    })
    setDownloadCsvData(csvData);
  }

  const combineResponses = (devicesResponse, devicesUserCountResponse, devicesUsersResponse, isMqttEvent) => {
    let navigatedValue = sessionStorage.navigatedFromDashboard;
    let mergedData = _.merge(_.keyBy(devicesResponse, "macId"), _.keyBy(devicesUserCountResponse, "deviceMacId"));
    mergedData = _.merge(_.keyBy(mergedData,"macId"), _.keyBy(devicesUsersResponse, "macId"));
    mergeAllData(mergedData, navigatedValue, isMqttEvent);
    iotReachableDevicesResponseReceived = false;
    iotReachableDeviceUserCountResponseReceived = false;
    iotReachableDeviceUsersResponseReceived = false;
    sessionStorage.alreadyInSideFetchDevices = false;
  }

  const segregateFilteredData = (data) => {
    let isRefreshingWithFilter = document.getElementById("searchText") ? document.getElementById("searchText").value.length > 0 : false;
    let searchFilterText = document.getElementById("searchText") ? document.getElementById("searchText").value : null;
    if (sessionStorage.navigatedValueFromGlobalSearch){
      let matchedObject = _.find(data,{"macId":sessionStorage.gsMacId})
      matchedObject = matchedObject ? matchedObject : _.find(data,{"deviceMacId":sessionStorage.gsMacId});
      if (matchedObject){
        setDevices([matchedObject])
      }else {
        setDevices([]);
      }
    }else if (sessionStorage.navigatedFromDashboard) {
      let filteredValue = sessionStorage.navigatedFromDashboard === "online" ? "AUTH_SUCCESS" : null;
      let filteredData = _.filter(data,(value)=> value.authStatus === filteredValue);
      if(!isRefreshingWithFilter){
        setDevices(filteredData)
        resetPaginationData([])
      } else {
        filterDataBasedOnSearchTerm(searchFilterText);
      }
    }else{
      if(!isRefreshingWithFilter){
        setDevices(data)
        resetPaginationData([])
      } else {
        filterDataBasedOnSearchTerm(searchFilterText);
      }
    }
  }

  const fetchDevices = (isMqttEVent) => {
    sessionStorage.alreadyInSideFetchDevices = true;
    IotServiceClient.getReachableIotDevices(sessionStorage.tenantId, "locksPage").then((data) => {
      iotReachableDevices = data;
      iotReachableDevicesResponseReceived = true;
      if (!isMqttEVent) {
        segregateFilteredData(data);
      }
    });
    
    IotServiceClient.getDeviceUsersCount(sessionStorage.tenantId, "locksPage").then((usersData) => {
      iotReachableDeviceUserCount = usersData;
      iotReachableDeviceUserCountResponseReceived = true;
    });

    IotServiceClient.getIotDevicesUsers(sessionStorage.tenantId, "locksPage").then((users)=>{
      iotReachableDeviceUsers = users;
      iotReachableDeviceUsersResponseReceived = true;
    })

    window.dataResponseInterval = setInterval(() => {
      if (iotReachableDevicesResponseReceived && iotReachableDeviceUserCountResponseReceived && iotReachableDeviceUsersResponseReceived) {
        combineResponses( iotReachableDevices, iotReachableDeviceUserCount, iotReachableDeviceUsers, isMqttEVent)
        clearInterval(window.dataResponseInterval)
      }
    }, 1000);
    GatewayServiceClient.getGateways(sessionStorage.tenantId, "locksPage").then((data) => {
      window.gateways = data;
    });
  }

  React.useEffect(() => {
    fetchDevices(false);
    window.oldPreservedLocksPageNumber = 0;
    window.addingUserCompletedData = 0;
    window.addingUserFailedData = 0;
    enqueueSnackbar("Fetching Locks ...");
    subscribeGatewayStatusEvent(PubSubUtils.getDevicesEvent(sessionStorage.tenantId));
    mqttEventHandlerAddUserMap = MqttEventHandler.subscribe(PubSubUtils.getDevicesUserMappingsEvent(sessionStorage.tenantId), "lock", onDevicesEventReceivedOnAddUserMapping);    
    return () => {
      window.addingUserCompletedData = 0;
      window.addingUserDIalogData = 0;
      window.addingUserFailedData = 0;
      if (mqttEventHandler){
        mqttEventHandler.unsubscribe();
      }
      if (window.gatewaysEventHandlerChannel) {
        window.gatewaysEventHandlerChannel.unsubscribe();
      }
      if (lockViewInterval) {
        clearInterval(lockViewInterval);
      }
      if (mqttEventHandlerAddUserMap) {
        mqttEventHandlerAddUserMap.unsubscribe();
      }
      if (mqttEventHandlerFailedOrSuccessUserAddition) {
        mqttEventHandlerFailedOrSuccessUserAddition.unsubscribe()
      }
      if (window.mqttEventHandlerFailedOrSuccessUserAddition) {
        if (Array.isArray(window.mqttEventHandlerFailedOrSuccessUserAddition)) {
          _.forEach(window.mqttEventHandlerFailedOrSuccessUserAddition,(mqttChannel) => {
            mqttChannel.unsubscribe();
          })
        }
        window.mqttEventHandlerFailedOrSuccessUserAddition = [];
      }
      delete sessionStorage.navigatedValueFromGlobalSearch;
      delete sessionStorage.navigatedFromDashboard;
      delete sessionStorage.alreadyInSideFetchDevices;

      IotServiceClient.abortSignal("locksPage");
      GatewayServiceClient.abortSignal("locksPage");
    }
  }, []);

  const handleRefresh = () => {
    enqueueSnackbar("Fetching Locks ...");
    let handleRefeshDevices = setInterval(() => {
      let alreadyExistInNetworkCall = sessionStorage.alreadyInSideFetchDevices ? JSON.parse(sessionStorage.alreadyInSideFetchDevices) : null;
      if (!alreadyExistInNetworkCall) {
        clearInterval(handleRefeshDevices);
        fetchDevices(true);
      }
    }, 1000);
  };

  function numberCharacterOrganizer(array, orderOfsort, orderByOfSort){
    let numbers = [], characters = [];
    _.forEach(array, (element) => {
        if(typeof(element[orderByOfSort]) === 'string' ? Number(element[orderByOfSort] ? element[orderByOfSort].replaceAll(" ","") : element[orderByOfSort]) : Number(element[orderByOfSort])){
          numbers.push(element);
        }
        else{
          characters.push(element);
        }
    })
    array = orderOfsort === 'asc' ? characters.concat(numbers): numbers.concat(characters);
    return array;
  }

  function stableSort(array, orderOfsort, orderByOfSort) {
    array = numberCharacterOrganizer(array, orderOfsort, orderByOfSort);
    array = _.orderBy(array, [obj => obj[orderByOfSort] !== null ? typeof(obj[orderByOfSort]) === 'string' ? !Number(_.get(obj, orderByOfSort, '').replaceAll(" ","")) ? _.get(obj, orderByOfSort, '').toString().toLowerCase().replaceAll(" ","") : Number(_.get(obj, orderByOfSort).replaceAll(" ","")) : Number(_.get(obj, orderByOfSort,'')) : ""], [orderOfsort]);
    return array;
  }

  function descendingComparator(a, b, orderBy) {
    if (typeof b[orderBy] !== 'undefined' && typeof a[orderBy] !== 'undefined' && b[orderBy] !== null && a[orderBy] !== null){
      if (b[orderBy].toString().toLowerCase() < a[orderBy].toString().toLowerCase()) {
        return -1;
      }
      if (b[orderBy].toString().toLowerCase() > a[orderBy].toString().toLowerCase()) {
        return 1;
      }
    }
    return -1;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPageNumber(newPage);
    window.oldPreservedLocksPageNumber = newPage;
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleClick = (event, row) => {
    let newSelected = [];

    if (event.target.checked) {
      if (selected.length > 0) {
        selected.forEach((element) => {
          newSelected.push(element);
        });
        if (JSON.stringify(newSelected).indexOf(row.id) === -1) {
          newSelected.push(row);
        }
      } else {
        newSelected.push(row);
      }
    } else {
      selected.forEach((element) => {
        if (element.id !== row.id) {
          newSelected.push(element);
        }
      });
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (checked) => {
    let selectedData = [];
    let count = 0;
    _.forEach(window.allLocks, (device) => {
      if (device.authStatus === "AUTH_SUCCESS"){
        device.selected = checked;
      }else {
        device.selected = false;
      }
      if (device.selected){
        count +=1;
      }
      selectedData.push(device);
    })
    setSelectedCount(count);
    setHeadersCheckBox(checked);
    setDevices(selectedData);
    window.allLocks = selectedData;
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChange = (event) => {
    setPageNumber(event.target.value);
    setPage(event.target.value);
    window.oldPreservedLocksPageNumber = event.target.value;
  };

  const handleRowPerPage = (event) => {
    let rowPerPageValue = event.target.value;
    if (devices.length > 0) {    
      let pageNumberCount = Math.ceil((devices.length/rowPerPageValue)) - 1;                
      if (window.oldPreservedLocksPageNumber > pageNumberCount) {
        setPage(pageNumberCount);
        setPageNumber(pageNumberCount);        
        window.oldPreservedLocksPageNumber = pageNumberCount;
      }else {
        if (window.oldPreservedLocksPageNumber) {
          setPage(window.oldPreservedLocksPageNumber);
          setPageNumber(window.oldPreservedLocksPageNumber);        
        }else {
          setPage(0);
          setPageNumber(0);
          window.oldPreservedLocksPageNumber = 0;
        }
      } 
    } 
    sessionStorage.locksRPP = rowPerPageValue;
    setRowsPerPage(rowPerPageValue);
  };

  const checkSearchTextExist = (data, key,  text) => {
    let value = data[key];
    return value !== null && typeof value !== 'undefined' && value !== "" ? value.toString().toLowerCase().replaceAll(" ","").indexOf(text) !== -1 : false;
  };

  const filterDataBasedOnSearchTerm = (searchFilterText) => {
    let sortedDevices = [];
    if (searchFilterText !== "" && searchFilterText !== undefined && searchFilterText !==  null){
      searchFilterText = searchFilterText.toLowerCase().replaceAll(" ","");
      _.forEach(window.allLocks,(value)=>{
        if (checkSearchTextExist(value, "name", searchFilterText) || checkSearchTextExist(value, "serialNumber", searchFilterText) || checkSearchTextExist(value, "inUse", searchFilterText) ||
            checkSearchTextExist(value, "type", searchFilterText) || checkSearchTextExist(value, "mode", searchFilterText) || checkSearchTextExist(value, "accessed", searchFilterText)||
            checkSearchTextExist(value, "batteryLevel", searchFilterText) || checkSearchTextExist(value, "rssi", searchFilterText) || checkSearchTextExist(value, "gatewayName", searchFilterText) ||
            checkSearchTextExist(value, "firmwareVersion", searchFilterText)){
          sortedDevices.push(value);
        }
      })
      sortedDevices = _.uniq(sortedDevices);
      let csvData = [];
      _.forEach(sortedDevices, (value)=>{
        csvData.push(_.omit(value,['id','scopeId','location','isOneTimeUserpresent','authStatus','scopeDoesNotMatch','selected']));
      })
      setDownloadCsvData(csvData);
      setDevices(sortedDevices);
    } else {
      let csvData = [];
      _.forEach(window.allLocks, (value)=>{
          csvData.push(_.omit(value,['id','scopeId','location','isOneTimeUserpresent','authStatus','scopeDoesNotMatch','selected']));
      })
      setDownloadCsvData(csvData);
      setDevices(window.allLocks);
    }
    
    resetPaginationData(sortedDevices);            
  }

  const resetPaginationData = (data) => {
    if (window.oldPreservedLocksPageNumber) {
      if (data.length > 0) {
        let pageNumberCount = Math.ceil((data.length/rowsPerPage)) - 1;                
        if (window.oldPreservedLocksPageNumber > pageNumberCount) {
          setPage(pageNumberCount);
          setPageNumber(pageNumberCount);
          window.oldPreservedLocksPageNumber = pageNumberCount;
        }
      }else {
        let pageNumberCount = Math.ceil((window.allLocks.length/rowsPerPage)) - 1;                
        if (window.oldPreservedLocksPageNumber > pageNumberCount){
          setPage(pageNumberCount);
          setPageNumber(pageNumberCount);
          window.oldPreservedLocksPageNumber = pageNumberCount;
        }else if (window.oldPreservedLocksPageNumber <= pageNumberCount && pageNumberCount > 0) {
          setPage(window.oldPreservedLocksPageNumber);
          setPageNumber(window.oldPreservedLocksPageNumber);        
        }else {
          setPage(0);
          setPageNumber(0);
          window.oldPreservedLocksPageNumber = 0;
        }        
      }
    }else {
      setPageNumber(0);
      setPage(0);   
      window.oldPreservedLocksPageNumber = 0;
    }
  }

  const handleTextCange = (event) => {
    setSearchText(event.target.value);
    filterDataBasedOnSearchTerm(event.target.value);
  };

  const rowPerPageLabel = (
      <div className={paginationStyle.rowPerPageLabelDiv}>
        <Box className={paginationStyle.page}>
          <span style={{marginRight: '32px'}}>Page</span>
        </Box>
        <Box className={paginationStyle.pageSelectBox}>
          <Select value={pageNumber} onChange={handleChange}>
            {
              Math.ceil(devices.length / rowsPerPage) > 0 ?
                  // eslint-disable-next-line array-callback-return
                  Array(Math.ceil(devices.length / rowsPerPage))
                      .fill()
                      .map((_, index) => {
                        return (
                            <MenuItem value={index} sx={{minWidth: '64px'}}>
                              {index + 1}
                            </MenuItem>
                        );
                      })
                  :
                  <MenuItem value={0} sx={{minWidth: '64px'}}>
                    1
                  </MenuItem>
            }
          </Select>
        </Box>
        <Box className={paginationStyle.rowPerPageLabelDiv}>
          <span style={{marginRight: '32px'}}>Rows Per Page</span>
        </Box>
        <Box className={paginationStyle.rowPerPageSelectBox}>
          <Select value={rowsPerPage} onChange={handleRowPerPage}>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Box>
      </div>
  );

  const historyDialog = (data) => {
    setInfoData(data);
    setOpenHistoryDialog(true);
  };

  const usersDialog = (data) => {
    setUsersData(data);
    setOpenUsersDialog(true);
    window.openedDialogData = data;    
  };

  const handleClose = () => {
    setOpenHistoryDialog(false);
  };

  const [showRemoveUserDialog, setShowRemoveUserDialog] = React.useState(false);

  const handleUsersClose = (event, data, dialog) => {    
    setOpenUsersDialog(false);
    if (data !== "" && typeof data !== 'undefined' && data !== null) {
      // add instruction done
      if (Array.isArray(data) ? data.length > 0 : false){             
        let totalInstructionsCreated = data.length;                
        if (dialog !== "remove"){
          setShowAddingUserDialog(true);
        }else {
          setShowRemoveUserDialog(true);
        }
        setCloseAddingLoader(false);
        setShowAddingUserDialogData(totalInstructionsCreated);
        window.addingUserDIalogData = totalInstructionsCreated;
      }else {
        window.addingUserDIalogData = 0;
        setShowAddingUserDialog(false);
        setShowRemoveUserDialog(false);
        setShowAddingUserDialogData(0);
      }
    }else {
      window.addingUserDIalogData = 0;
      setShowAddingUserDialog(false);
      setShowRemoveUserDialog(false);
      setShowAddingUserDialogData(0);
    }    
  };

  const getAccessedTime = (pastTimestamp)=> {
    var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    if (pastTimestamp === null) return '-';
    else {
      var currentDate = new Date();
      var days = Math.round(Math.abs((currentDate.getTime() - pastTimestamp) / (oneDay)));

      if (days === 0) return 'Today';
      else if (days === 1) return 'Yesterday';
      else if (days > 1) return days + ' Days';
    }
  };

  const getAccessedbyDays = (pastTimestamp)=> {
    var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    if (pastTimestamp === null) return "-";
    else {
      var currentDate = new Date();
      var days = Math.round(Math.abs((currentDate.getTime() - pastTimestamp) / (oneDay)));
      return days;
    }
  }

  const publishInstructionCHannel = () => {
    MqttEventHandler.publish(PubSubChannels.pubsub_channels.INSTRUCTIONS.replace("scopeId",sessionStorage.tenantId),"created",{qos:2, retain:false},function(){})
  }

  const createEntryInActivityFeed = (instruction, data, deviceData, gatewayId) => {
    let payload = {
      "macId" : gatewayId,
      "message" : instruction === "unlock" ? deviceData.name +" Unlock Initiated"  : deviceData.name + " Lock Initiated",
      "date": new Date().getTime(),
      "type": "event",      
    }

    GatewayServiceClient.createLockUnlockEntry(payload).then((response) => {})
  }

  const toggleLockUnlock = (device, instrcutionIssueType) => {

      var lockUnlockInstructionPayload = {
        "payload": {
          "macId": device.macId,
          "locked": instrcutionIssueType === "unlock" ? true : false,
          "time": new Date().toUTCString(),
          "name": device.name,
          "scopeId": device.scopeId
        },
        "gatewayMacId": device.gateway_MacId,
        "type": Enums.instruction_enums.LOCK_UNLOCK_INSTRUCTION
      }
      
      GatewayServiceClient.createInstruction(response => {
        window.lockUnlockSelectedData = device;
        if (response.status === 201 || response.status === 200) {
          if (instrcutionIssueType === "unlock"){
            setLockInstructionType("unlock")
            setShowLockInstructionDialog(true);
            createEntryInActivityFeed("unlock",lockUnlockInstructionPayload.payload,device, device.gateway_MacId);
            enqueueSnackbar("Successfully Created Unlock Instruction");
          }else if (instrcutionIssueType === "lock"){
            setLockInstructionType("lock")
            setShowLockInstructionDialog(true);
            createEntryInActivityFeed("lock",lockUnlockInstructionPayload.payload, device, device.gateway_MacId);
            enqueueSnackbar("Successfully Created Lock Instruction");
          }
          response.json().then((responseData)=> {
            window.mqttEventHandlerFailedOrSuccessLockUnlock = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.GATEWAY_LOCK_UNLOCK_INSTRUCTION_SUCCESS_OR_FAILURE.replace("scopeId",sessionStorage.tenantId).replace("gatewayMacId",device.gateway_MacId).replace("lockMacId",lockUnlockInstructionPayload.payload.macId).replace("instructionId",responseData.instructionId), "lock", handleGatewayLockUnlockInstructionSuccessOrFailure)
          })          
        } else {
          enqueueSnackbar("Failed To Create Instruction");
        }
        publishInstructionCHannel();
      }, lockUnlockInstructionPayload);
  };

  const subscribeGatewayStatusEvent = (topic, dialog) => {
    mqttEventHandler = MqttEventHandler.subscribe(topic, "lock", onDevicesEventReceived);
  };

  const handleEvent = (data) => {
    let lockData = [];
    let message = data.message ? data.message : "";
    let payload = data.payload ? data.payload[0] : "";
    let updateUi = false;
    if (message === "gatewayOffline") {
      _.forEach(window.allLocks, (val) => {
          if (val.gateway_MacId === data.macId) {
            val.gatewayMacId = "-";
            val.gatewayName = "-";
            val.gateway_MacId = null;
            val.authStatus = null;
          }
          lockData.push(val);
      })
      updateUi = true;
    }else if(message === "lockStatus"){
      _.forEach(window.allLocks, (val) => {
        if (val.macId === payload.macId) {
          val.status = payload.status;
        }
        lockData.push(val);
      })
      updateUi = true;
    }else if(message === "userMapping"){
      if (payload !== "" && payload !== null){
        _.forEach(window.allLocks, (val) => {
          if (val.macId === payload.macId) {
            val.count = payload.userCount;
            val.userCount = payload.userCount;
            val.inUse = payload.inUse !== null && payload.inUse !== "" ? payload.inUse : "no-email";
          }
          lockData.push(val);
        })
        updateUi = true;
      }
    }else if (message === "deleteLock") {
      _.forEach(window.allLocks, (val) => {
        if (val.macId !== data.macId) {
            lockData.push(val);
        }
      })
      updateUi = true;
    }else if (message === "bulkUpdate") {
      window.handleMqttEventFetchDevices = setInterval(() => {
        let alreadyExistInNetworkCall = sessionStorage.alreadyInSideFetchDevices ? JSON.parse(sessionStorage.alreadyInSideFetchDevices) : null;
        if (!alreadyExistInNetworkCall) {
          fetchDevices(true);
          clearInterval(window.handleMqttEventFetchDevices);
        }
      }, 1000);
    }else if (message === "lockMode") {
      _.forEach(window.allLocks, (val) => {
        if (val.macId === payload.macId) {
          val.mode = payload.mode === "residential" ? "resident" : payload.mode;;
          val.count = payload.userCount;
          val.userCount = payload.userCount;
          val.inUse = payload.inUse !== null && payload.inUse !== "" ? payload.inUse : "no-email";
        }
        lockData.push(val);
      })
      updateUi = true;
    }else if (message === "lockData") {
      _.forEach(window.allLocks, (val) => {
        if (val.macId === payload.macId) {
          val.mode = payload.mode === "residential" ? "resident" : payload.mode;;
          val.count = payload.userCount;
          val.userCount = payload.userCount;
          val.inUse = payload.inUse !== null && payload.inUse !== "" ? payload.inUse : "no-email";
          val.name = payload.name;
          val.mode = payload.mode;
          val.accessed = getAccessedTime(payload.lastAccessedTime);
          val.accessedTime = payload.lastAccessedTime;
        }
        lockData.push(val);
      })
      updateUi = true;
    }else if(message === "history"){
      _.forEach(window.allLocks, (val) => {
        if (val.macId === payload.macId) {
          val.accessed = getAccessedTime(payload.lastAccessedTime);
          val.accessedTime = payload.lastAccessedTime;
        }
        lockData.push(val);
      })
      updateUi = true;
    }else if (message === "forceProbeData") {
      _.forEach(window.allLocks, (val) => {        
          if (val.macId === payload.macId) {

          }
        lockData.push(val);
      })
      updateUi = true
    }

    if (updateUi){
      window.allLocks = lockData;
      enqueueSnackbar("Refreshing Locks ...");
      segregateFilteredData(lockData);
    }
  }

  var onDevicesEventReceived = function (data) {
    data = data ? JSON.parse(data.toString()) : "" ;
    if (data.hasOwnProperty("message")){
      handleEvent(data);
    }

  };

  var onDevicesEventReceivedOnAddUserMapping = function (data) {
    enqueueSnackbar("Refreshing Locks ...");
    data = data ? JSON.parse(data.toString()) : "" ;
    if (data.hasOwnProperty("message")){
      handleEvent(data);
    }
  }

  const handleGatewayLockUnlockInstructionSuccessOrFailure = (message) => {
    message = message.toString();
    if (message === "failed") {
      setLockInstructionResponseStatus("failed");
      setCloseLockInstructionDialogLoader(true)
    }else if (message === "success") {
      setLockInstructionResponseStatus("success");
      setCloseLockInstructionDialogLoader(true)
    }
  }

  const handleAddOrRemoveUserEvent = (data) => {    
      let userDetails = _.find(window.allUsers, {"emailHash": data.payload.payload.emailHash});
      if (userDetails){
        let name = userDetails.firstName ? userDetails.lastName ? userDetails.firstName + ' ' + userDetails.lastName : userDetails.firstName : "No Name";
        
        if (window.failedUserAdditionOrDeletionUsers) {
          window.failedUserAdditionOrDeletionUsers+= ", " +name;
        }else {
          window.failedUserAdditionOrDeletionUsers = name;
        }
        setFailedUserAdditionOrDeletionUsers(window.failedUserAdditionOrDeletionUsers);        
      }
  }

  const handleGatewayAddInstructionSuccessOrFailure = (data) => {        
    data = data ? data.toString().indexOf("message") !== -1 ? JSON.parse(data.toString()) : "" : "" ;
    let message = data.message;
    if (data.hasOwnProperty("message")){
      if (message === "failed"){
        handleAddOrRemoveUserEvent(data);
      }
    }    
    if (message === "failed") {
      window.addingUserFailedData += 1; 
    }else if (message === "success"){
      window.addingUserCompletedData += 1;
    }

    if (window.addingUserDIalogData){
      if (window.addingUserDIalogData > 0) {
        if ((window.addingUserCompletedData + window.addingUserFailedData) <= window.addingUserDIalogData){
          setShowAddingUserDialogCompletedData(window.addingUserCompletedData);
          setShowAddUserFailedData(window.addingUserFailedData);
          if ((window.addingUserCompletedData + window.addingUserFailedData) === window.addingUserDIalogData) {
            setCloseAddingLoader(true);
          }
        }
      }else {
        setShowAddingUserDialogCompletedData(0);
      }
    }
  }

  const unCheck = (device) => {
    let uncheckDevice = [];
    let count = 0;
    _.forEach(window.allLocks,(value)=>{
      if (device.macId === value.macId && value.authStatus === "AUTH_SUCCESS"){
        value.selected = !device.selected;
      }
      if (value.selected){
        count+=1;
      }
      uncheckDevice.push(value);
    });
    setSelectedCount(count);
    if (!(searchText.length > 0)) {
        setDevices(uncheckDevice);
    }
    window.allLocks = uncheckDevice;
  }

  const handleEditMode = () => {
    let removeSelectedDevices = [];
    if (editMode) {
      _.forEach(window.allLocks, (value) => {
        value.selected = false;
        removeSelectedDevices.push(value);
      });
      setDevices(removeSelectedDevices);
      setSelectedCount(0);
      window.allLocks = removeSelectedDevices;
    }
    setEditMode(!editMode);
  };

  const handleBulkToggleInstruction = () => {
    let data = [];
    setEditMode(false);
    _.forEach(window.allLocks,(value) => {
      if (value.selected) {
        let instruction = value.status === "LOCKED" ? "unlock" : "lock";
        toggleLockUnlock(value, instruction);
        value.selected = false;
      }
      data.push(value);
    });
    setSelectedCount(0);
    setDevices(data);
    window.allLocks = data;
  };

  const handleBulkAddUsers = () => {
    let selectedLocks = [];
    let selectedLockTypes = [];
    _.forEach(window.allLocks,(device)=>{
      if (device.selected){
        selectedLockTypes.push(device.type);
        selectedLocks.push(device);
      }
    })
    setSelectedBulkAddUsersData(selectedLocks);
    let uniqSelectedLockTypes = _.uniq(selectedLockTypes);
    if (uniqSelectedLockTypes.length !== 1){
      enqueueSnackbar("Please select same type of Locks.");
    }else {
      setAddUserDialog(true);      
    }

  };

  const removeFilter = () => {
    delete sessionStorage.navigatedFromDashboard;
    delete sessionStorage.navigatedValueFromGlobalSearch;
    delete sessionStorage.navigatedLockName;
    fetchDevices(false);
  };

  const showDownloadCSVDialog = () => {
    setShowDownloadCsvDialog(true);
  }

  const handleCloseCsvDialog = (data) => {
    setShowDownloadCsvDialog(false);
    setDownloadCsvData(data);
    document.getElementById("downloadLockCsv").click()
  }

  const showLockInfo = (lockData) => {
    setLockInfoDialog(true);
    setlockInfoDialogData(lockData);
  }

  const getAnimationCOndition = (device) => {
    return device.scopeDoesNotMatch &&  (sessionStorage ? JSON.parse(sessionStorage.userDetails).permissions ?  JSON.parse(sessionStorage.userDetails).permissions.length > 0 ? JSON.parse(sessionStorage.userDetails).permissions.indexOf("TOP_LEVEL_SCOPE_READ") !== -1  : false : false : false);
  }

  const onSwitchModeDialog = (data) => {
    if (data.gateway_MacId){
      setShowSwitchLockModeDialog(true);
      setSwitchLockModeData(data);
    }
  }

  const publishForceProbe = (devices) => {
    let allDevicesNameMapping = {};
    let data = [];
    if (devices !== null){
      let deviceMacId = [devices.macId];
      MqttEventHandler.publish("gateways/"+devices.gateway_MacId+"/receive/devices/forceProbe", JSON.stringify(deviceMacId) , {qos: 2, retain: false}, function() {});
      enqueueSnackbar("Successfully published force probe")
    }else {
        let showErrorToastMessage = false;
      _.forEach(window.allLocks, (device) => {
        if (device.selected) {
          device.selected = false;        
          if (!device.scopeDoesNotMatch){            
            let gatewayMacId = device.gateway_MacId;
            if (allDevicesNameMapping.hasOwnProperty(gatewayMacId)) {
              allDevicesNameMapping[gatewayMacId].push(device.macId);
            }else {
              allDevicesNameMapping[gatewayMacId] = [device.macId];
            }
          }else {
            showErrorToastMessage = true;
          }
        }
        data.push(device);
      })
      setEditMode(false);
      setSelectedCount(0);
      setDevices(data);
      setHeadersCheckBox(false)
      window.allLocks = data;
      if (!showErrorToastMessage) {
        let allGatewayIds = _.keys(allDevicesNameMapping);
        _.forEach(allGatewayIds,(gatewayId)=> {
          let forceProbeLocksPayload = JSON.stringify(allDevicesNameMapping[gatewayId]);
          MqttEventHandler.publish("gateways/"+gatewayId+"/receive/devices/forceProbe", forceProbeLocksPayload , {qos: 2, retain: false}, function() {});
        })
        if (allGatewayIds.length > 0){        
          enqueueSnackbar("Successfully published force probe")
        }
      }else {
        enqueueSnackbar("Please select valid locks for force probe");
      }
    }        
  }

  return (
      <>
        {
          sessionStorage.navigatedFromDashboard === "online" || sessionStorage.navigatedFromDashboard === "offline"
              || sessionStorage.navigatedValueFromGlobalSearch === "gs" ?
              <Box sx={{display:"flex",flexDirection:"row",padding: '10px',background:"#ffcc80"}}>
                <Box>
                  Filtered by :
                </Box>
                <Box sx={{marginRight: "auto", marginLeft: "6px",height:"22px",background:"#ede8e8",width:"auto"}}>
                  <Box sx={{display:"flex",flexDirection:"row",marginLeft:"9px"}}>                    
                    <Tooltip placement='top' title={sessionStorage.navigatedFromDashboard ? sessionStorage.navigatedFromDashboard : sessionStorage.navigatedLockName} arrow>
                      <Box className={sessionStorage.navigatedFromDashboard ? "" : 'filterNameForSmallScreens'}>
                        {sessionStorage.navigatedFromDashboard ? sessionStorage.navigatedFromDashboard : sessionStorage.navigatedLockName}
                      </Box>
                    </Tooltip>                    
                    <Box sx={{padding:"0px 3px 0px 3px"}}>
                      <Close sx={{cursor:"pointer",fontSize:"21px",paddingLeft:"4px",marginTop:"1px"}} onClick={()=> removeFilter()} />
                    </Box>
                  </Box>
                </Box>
              </Box>
              :
              null
        }
        <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              padding: '20px 0px 20px 24px',
            }}
        >
        <span style={{fontSize: '20px', marginRight: 'auto'}}>Locks {
          editMode ?
              selectedCount > 0  ?
                  <>
                    selected - {selectedCount}
                  </>
                  :
                  null
              :
              null
        }</span>
          <div style={{display: 'flex'}} id="iconsBlock">
            <input
                id="searchText"
                type="text"
                onChange={handleTextCange}
                placeholder="Search Locks .."
                style={{
                  marginLeft: 'auto',
                  marginRight: '4px',
                  minWidth: '37px',
                  width: '140px',
                  maxWidth: '100%',
                  visibility: SearchBar ? 'visible' : 'hidden',
                }}
            />
            <Close
                style={{
                  marginRight: '4px',
                  cursor: 'pointer',
                  display: SearchBar ? 'flex' : 'none',
                  color: '#0000008A'
                }}
                onClick={() => {
                  setSearchBar(false);
                  setSearchText('');
                  document.getElementById('searchText').value = '';
                  let csvData = [];
                  _.forEach(window.allLocks, (value)=>{
                    csvData.push(_.omit(value,['id','scopeId','location','isOneTimeUserpresent','authStatus','scopeDoesNotMatch','selected']));
                  })
                  setDownloadCsvData(csvData);
                  setDevices(window.allLocks);
                }}
            />
            <Tooltip title="Search" placement="top" arrow>
              <Search
                  style={{
                    marginRight: '10px',
                    cursor: 'pointer',
                    display: !SearchBar ? 'flex' : 'none',
                    color: '#0000008A'
                  }}
                  onClick={() => {setSearchBar(true);
                    setTimeout(() => {document.getElementById("searchText").focus();},100);
                  }}
              />
            </Tooltip>
            {
              selectedCount > 0 ?
                  <>
                    <Tooltip title="Add User" placement="top" arrow>
                      <PersonAdd style={{marginRight: '10px', cursor: 'pointer',color: '#0000008A'}} onClick={()=> handleBulkAddUsers()} />
                    </Tooltip>
                    <Tooltip title="Toggle Status" placement="top" arrow>
                      <Lock style={{marginRight: '10px', cursor: 'pointer',color: '#0000008A'}} onClick={()=> handleBulkToggleInstruction()}/>
                    </Tooltip>
                    {
                      JSON.parse(sessionStorage.userDetails).permissions.includes("TOP_LEVEL_SCOPE_READ") ?
                        <Tooltip
                          title="Force Probe"
                          placement="top"
                          arrow
                          style={{cursor: 'pointer'}}
                        >
                          <SyncLock style={{cursor: 'pointer',color: '#0000008A',fontSize:"22px",marginRight: '10px',
                              display: JSON.parse(sessionStorage.userDetails).user.permissions.includes("TOP_LEVEL_SCOPE_READ") ? null : 'none'}}                                          
                            onClick={() => publishForceProbe(null)} />
                        </Tooltip>
                      :
                        null
                    }
                  </>
                  :
                  null
            }
            <Tooltip title="Refresh" placement="top" arrow>
              <Refresh style={{marginRight: '10px', cursor: 'pointer',color: '#0000008A'}} onClick={()=> handleRefresh()} />
            </Tooltip>
            <Tooltip title="Download CSV" placement="top" arrow>
                <Download style={{marginRight: '14px', cursor: 'pointer',color: '#0000008A'}} onClick={()=> showDownloadCSVDialog()} />
              </Tooltip>
            <CsvDownload
                data={downloadCSVData}
                filename={sessionStorage.tenantName + '-Locks.csv'}
                style={{
                  border: 'transparent',
                  background: 'transparent',
                  paddingLeft: '0px',
                  visibility: 'hidden',
                  position:"absolute",
                  top:"-50%"
                }}           
                id="downloadLockCsv"     
            >
              <Tooltip title="Download CSV" placement="top" arrow>
                <Download style={{marginRight: '14px', cursor: 'pointer',color: '#0000008A'}} />
              </Tooltip>
            </CsvDownload>
          </div>
        </Box>
        <Box style={{width: '100%', height: 'auto', marginBottom: '86px'}}>
        {
          showLockView  ?
              <TableContainer>
                <Table>
                  <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={(checked) => handleSelectAllClick(checked)}
                      onRequestSort={handleRequestSort}
                      rowCount={devices.length}
                      headerCheckBox={headersCheckBox}
                      rowSelected={selectedCount}
                      editMode={editMode}
                  />
                  <TableBody>
                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                      rows.slice().sort(getComparator(order, orderBy)) */}
                    {stableSort(devices,order, orderBy)
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((device, index) => {
                          const isItemSelected = isSelected(device.name);
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                              <TableRow
                                  onClick={(event) => handleClick(event, device.name)}
                                  role="checkbox"
                                  aria-checked={isItemSelected}
                                  tabIndex={-1}
                                  key={device.id}
                                   className={
                                    getAnimationCOndition(device) ? 'showAnimation ' : null
                                   }
                                  sx={{lineHeight: '48px',fontSize:'13px',
                                    background: (device.authStatus === 'AUTH_SUCCESS' && !device.scopeDoesNotMatch) ? "#fff" : '#e0e0e0'}}
                              >
                                {
                                  editMode ?
                                      <TableCell padding="checkbox">
                                        <Checkbox
                                            color="error"
                                            disabled={!device.authStatus}
                                            checked={device.selected === true}
                                            onClick={()=>unCheck(device)}
                                        />
                                      </TableCell>
                                      :
                                      null
                                }
                                <TableCell align="left" sx={{alignItems:'center',justifyContent:'center',lineHeight:'0.99',fontSize: '13px'}}>{device.name}</TableCell>
                                <TableCell align="left" sx={{alignItems:'center',justifyContent:'center',lineHeight:'0.99',fontSize: '13px'}}>{device.serialNumber ? device.serialNumber.replace(/\u0000/g,"") : ""}</TableCell>
                                <TableCell align="left" sx={{alignItems:'center',justifyContent:'center',lineHeight:'0.99',fontSize: '13px'}}>{device.type}</TableCell>
                                <TableCell align="left" sx={{alignItems:'center',justifyContent:'center',lineHeight:'0.99',fontSize: '13px',textTransform:'capitalize',paddingRight:"0px"}}>                                  
                                  {
                                    device.mode ?
                                      <Box className="modeSwitchButton" sx={{display:"flex",flexDirection:"column"}}>
                                          {
                                            JSON.parse(sessionStorage.userDetails).permissions.includes("IOT_DEVICE_WRITE") ?
                                                device.version > 182 ?
                                                  device.mode === "LO_SL" || device.mode === "RFID_LO_SL" ?
                                                    <Tooltip placement='top' title="Switching to Shared mode is not allowed for a Slam type locker.">
                                                      <Box sx={{position:"relative",left:"4px"}}>
                                                        <Switch size='small' disabled={true} checked={device.mode === "shared"} />
                                                      </Box>
                                                    </Tooltip>
                                                  :
                                                    device.type && device.type !== "" ?
                                                      <Box sx={{position:"relative",left:"4px"}}>
                                                        <Switch size='small' disabled={device.authStatus !== "AUTH_SUCCESS" || device.mode === "LO_SL" || device.mode === "RFID_LO_SL"} checked={device.mode === "shared"} onClick={() => onSwitchModeDialog(device)} />
                                                      </Box>
                                                    :
                                                      <Tooltip placement='top' title="This operation is not supported for unknown lock types">
                                                        <Box sx={{position:"relative",left:"4px"}}>
                                                          <Switch size='small' disabled={true} checked={device.mode === "shared"} />
                                                        </Box>
                                                      </Tooltip>
                                                :
                                                  <Tooltip placement='top' title="Please upgrade your Gateway to the latest firmware to enable this feature.">
                                                    <Box sx={{position:"relative",left:"4px"}}>
                                                      <Switch size='small' disabled={true} checked={device.mode === "shared"} />
                                                    </Box>
                                                  </Tooltip>
                                            :
                                                <Tooltip placement='top' title="You do not have required permissions to perform this action. Please contact your admin.">
                                                    <Box sx={{position:"relative",left:"4px"}}>
                                                      <Switch size='small' disabled={true} checked={device.mode === "shared"} />
                                                    </Box>
                                                </Tooltip>
                                          }
                                              <Box sx={{textAlign:"center",position:"relative",left:"-24px"}}>
                                                {device.mode !== null  && device.mode !== "" && typeof device.mode !== 'undefined' ? device.mode.substring(0,8) : device.mode}
                                              </Box>
                                      </Box>
                                    :
                                      null
                                  }
                                </TableCell>
                                {
                                  !editMode ?
                                      <TableCell align="left" sx={{alignItems:'center',justifyContent:'center',lineHeight:'0.99',fontSize: '13px', overflowWrap:'anywhere'}}>{device.inUse}</TableCell>
                                      :
                                      null
                                }
                                <TableCell align="left" sx={{alignItems:'center',justifyContent:'center',lineHeight:'0.99',fontSize: '13px'}}>
                                {
                                  JSON.parse(sessionStorage.userDetails).permissions.includes("IOT_DEVICE_WRITE") ?
                                  device.status === 'LOCKED' ? (
                                      <Lock onClick={() => {
                                          if (device.authStatus === 'AUTH_SUCCESS' && !device.scopeDoesNotMatch && !multiClick){
                                              setMultiClick(true);
                                              toggleLockUnlock(device,"unlock")
                                          }
                                        }} sx={{color: !device.authStatus ? '#a9a9a9' : '#0000008A',cursor: 'pointer'}} />
                                  ) : (
                                      <LockOpen onClick={() => {
                                        if (device.authStatus === 'AUTH_SUCCESS' && !device.scopeDoesNotMatch && !multiClick){
                                          setMultiClick(true);
                                          toggleLockUnlock(device,"lock")
                                        }
                                      }} sx={{color: !device.authStatus ? '#a9a9a9' : '#0000008A',cursor:'pointer'}}/>
                                  )
                                  :
                                     device.status === 'LOCKED' ? (
                                        <Tooltip placement='top' title="You do not have required permissions to perform this action. Please contact your admin.">
                                           <Lock sx={{color: !device.authStatus ? '#a9a9a9' : '#0000008A',cursor: 'pointer'}} />
                                        </Tooltip>
                                   ) : (
                                       <Tooltip placement='top' title="You do not have required permissions to perform this action. Please contact your admin.">
                                          <LockOpen sx={{color: !device.authStatus ? '#a9a9a9' : '#0000008A',cursor:'pointer'}}/>
                                       </Tooltip>
                                   )
                                }
                                </TableCell>
                                {
                                  !editMode ?
                                      <TableCell align="left" sx={{alignItems:'center',justifyContent:'center',lineHeight:'0.99',fontSize: '13px'}}>
                                        {
                                          !device.scopeDoesNotMatch ?
                                            <Tooltip title={device.userCount === undefined ? 0 + " users": device.userCount + " users"} placement="top" arrow>
                                              <People
                                                  sx={{cursor: 'pointer',color: device.userCount > 0 ? '#0000008A' : '#ff8c00'}}
                                                  onClick={() => {
                                                    if (!device.scopeDoesNotMatch){
                                                        usersDialog(device) 
                                                    }
                                                  }}
                                              />
                                            </Tooltip>
                                          :
                                            <People sx={{cursor: 'pointer',color: '#ff8c00'}} />
                                        }
                                      </TableCell>
                                      :
                                      null
                                }
                                <TableCell align="left">
                                  <Box>
                                   <Delete
                                        onClick={()=>handleDeleteLockDialog(device)}
                                        style={{cursor: 'pointer',color: '#0000008A',
                                            display: device.authStatus !== "AUTH_SUCCESS" || device.scopeDoesNotMatch ? '' : 'none',
                                    }}  />
                                    {
                                      !editMode && JSON.parse(sessionStorage.userDetails).permissions.includes("TOP_LEVEL_SCOPE_READ") ?
                                        <Tooltip
                                          title="Force Probe"
                                          placement="top"
                                          arrow
                                          style={{cursor: 'pointer'}}
                                        >
                                          <SyncLock style={{cursor: 'pointer',color: '#0000008A',fontSize:"22px",
                                              display: device.authStatus === "AUTH_SUCCESS" && !getAnimationCOndition(device) && JSON.parse(sessionStorage.userDetails).user.permissions.includes("TOP_LEVEL_SCOPE_READ") ? null : 'none'}}                                          
                                            onClick={() => publishForceProbe(device)} />
                                        </Tooltip>
                                      :
                                        null 
                                    }
                                    {
                                      getAnimationCOndition(device) ?                                    
                                        <Tooltip title="Associated to different Tenant" placement="top"
                                            style={{
                                            display: !device.scopeDoesNotMatch ? 'none' : '',
                                            }} arrow >
                                          <Error
                                            sx={{cursor: 'pointer',color: '#cc0000',marginRight:'10px'}}
                                          />
                                        </Tooltip>
                                      :
                                        null
                                    }
                                  </Box>
                                </TableCell>
                                <TableCell align="left" sx={{alignItems:'center',justifyContent:'center',lineHeight:'0.99',fontSize: '13px'}}>
                                  {device.accessed}
                                </TableCell>
                                {
                                  !editMode ?
                                      <TableCell align="left" sx={{alignItems:'center',justifyContent:'center',lineHeight:'0.99',fontSize: '13px'}}>
                                        {
                                          <Tooltip title="History" placement="top" arrow>
                                            <LibraryBooks
                                                sx={{cursor: 'pointer',color: '#0000008A'}}
                                                onClick={() => {
                                                  if (!device.scopeDoesNotMatch) {
                                                    historyDialog(device)
                                                  }
                                                }}
                                            />
                                          </Tooltip>
                                        }
                                      </TableCell>
                                      :
                                      null
                                }
                                <TableCell align="left" sx={{alignItems:'center',justifyContent:'center',lineHeight:'0.99',fontSize: '13px'}}>
                                  <span>{device.batteryLevel}</span>
                                  <LinearProgress
                                      color="inherit"
                                      variant="determinate"
                                      value={device.batteryLevel}
                                      sx={{background:"rgba(255,0,0,0.61)",color: "#6CC644 !important"}}
                                  />
                                </TableCell>
                                <TableCell align="left" sx={{alignItems:'center',justifyContent:'center',lineHeight:'0.99',fontSize: '13px'}}>{device.rssi}</TableCell>
                                <TableCell align="left" sx={{alignItems:'center',justifyContent:'center',lineHeight:'0.99',fontSize: '13px'}}>{device.gatewayName ? device.gatewayName : "-"}</TableCell>
                                <TableCell align="left" sx={{alignItems:'center',justifyContent:'center',lineHeight:'0.99',fontSize: '13px'}}>{device.firmwareVersion}</TableCell>
                              </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
            :
              sessionStorage.lockView === "gridView" ?
                <Grid container spacing={1}>
                  <LocksGrid
                    lockData={stableSort(devices,order, orderBy).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                    lockToggled={(device,type)=>{
                      toggleLockUnlock(device,type)
                    }}
                    historyClicked={(device)=>historyDialog(device)}
                    userDialogClicked={(device)=>usersDialog(device)}
                    infoClicked={(device)=> showLockInfo(device)}
                  />
                </Grid>
              :
                null
        }
          <TablePagination
              sx={{width: '100%', overflowX: 'hidden',position: sessionStorage.lockView === "gridView" ? 'fixed' : null,bottom: sessionStorage.lockView === "gridView" ? '0px' : null,right: sessionStorage.lockView === "gridView" ? '0px' : null,background: sessionStorage.lockView === "gridView" ? '#fff' : null}}
              labelRowsPerPage={rowPerPageLabel}
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              className="pagination"
              count={devices.length}
              rowsPerPage={rowsPerPage}
              page={pageNumber}              
              SelectProps={{
                inputProps: {'aria-label': 'rows per page'},
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
          />
          {
            sessionStorage.lockView === "listView" || sessionStorage.tenantName === "FreeTier" || !sessionStorage.lockView ?
            <Button
                onClick={() => handleEditMode()}
                style={{
                  position: 'fixed',
                  bottom: '37px',
                  right: '40px',
                  height: '62px',
                  miWwidth: '55px',
                  maxWidth: '55px',
                  borderRadius: '50%',
                  backgroundColor: 'rgb(20,19,139)',
                  display: JSON.parse(sessionStorage.userDetails).permissions.includes("IOT_DEVICE_WRITE") ? 'flex' : 'none',
                }}
            >
              {
                !editMode ?
                    <Edit sx={{color: '#fff'}} />
                    :
                    <Close sx={{color: '#fff'}} />
              }
            </Button>
            :
              null
          }
        </Box>
        {
          openHistoryDialog ?
              <HistoryDialog
                  data={infoData}
                  show_history_dialog={openHistoryDialog}
                  close_history_dialog={()=>setOpenHistoryDialog(false)}
              />
              :
              null
        }
        {
          openUsersDialog ?
              <UsersDialog
                  data={usersData}
                  show_users_dialog={openUsersDialog}
                  close_users_dialog={(event, data, dialog) =>  handleUsersClose(event, data, dialog)}
                  openCreateUserDialog={()=> {
                    handleUsersClose();
                    setCreateLockUserDialog(true);
                  }}
                  handleGatewayAddInstruction={(message)=> handleGatewayAddInstructionSuccessOrFailure(message)}
                  snack_bar = {()=>{
                    setShowSnackBar(true);
                    setShowSnackBarMessage("Successfully created instruction");
                    setTimeout(() => {
                      setShowSnackBar(false);
                    }, 3001);
                  }}
              />
              :
              null
        }

        {
          addUserDialog ?
            <UsersDialog
              data={selectedBulkAddUsersData.length > 0 ? selectedBulkAddUsersData[0] : []}
              allData={selectedBulkAddUsersData}
              show_users_dialog={addUserDialog}
              close_users_dialog={() => {
                setAddUserDialog(false);
                handleSelectAllClick(false);
                setSelectedCount(0);
                setEditMode(false);
              }}
              showDirectlyAddUsersDialog={true}
              openCreateUserDialog={()=> {
                handleUsersClose();
                setCreateLockUserDialog(true);
              }}
              snack_bar = {()=>{
                setShowSnackBar(true);
                setShowSnackBarMessage("Successfully created instruction");
                setTimeout(() => {
                  setShowSnackBar(false);
                }, 3001);
              }}
          />
          :
          null
        }

        {
          lockDeleteDialog ?
              <DraggableComponent
                  dialog="lockDeleteDialog"
                  data={deleteLockData}
                  showLockDeleteDialog={lockDeleteDialog}
                  closeLockDeleteDialog={()=>setLockDeleteDialog(false)}
                  removeLock={(value)=> handleRemoveLock(value)}
              />
              :
              null
        }
        {
          snackBar ?
              <Snackbar
                  anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
                  open={snackBar}
                  autoHideDuration={3000}
                  message="Fetching Locks ,,,"
              />
              :
              null
        }
        {
          showCsvDialog ?
            <DraggableComponent 
              dialog="downloadLockCSVDialog"
              data={[]}
              showDialog={showCsvDialog}
              closeProcess={() => setShowDownloadCsvDialog(false)}
              closeCsvDialog={(data)=> handleCloseCsvDialog(data)}
            />
          :
            null
        }
        {
          showsnackbar ?
              <Snackbar
                  anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
                  open={showsnackbar}
                  autoHideDuration={3000}
                  message={showSnackBarMessage}
              />
              :
              null
        }
        {
          createLockUserDialog ?
            <DraggableComponent
              dialog="updateOrCreateDialog"
              data={[]}
              showUpdateOrCreateLockUser={createLockUserDialog}
              title="Create New User"
              closeAndOpenUploadCsvDialog = {() => {
                setCreateLockUserDialog(false);
                setUploadBulkUsersDialog(true);                
              }}
              closeDialog={()=>setCreateLockUserDialog(false)}
              createIotUser={(data) =>  createNewLockUser(data)}
            />
          :
            null
        }
        {
          uploadBulkUsersDialog ?
            <DraggableComponent
              data={[]}
              bulkUsersDialog={uploadBulkUsersDialog}
              dialog="uploadBulkUsersDialog"
              closeUploadBulkUsersDialog = {()=> setUploadBulkUsersDialog(false)}
            />
          :
            null
        }
        {
          lockInfoDialog ?
            <DraggableComponent
              data={lockInfoDialogData}
              showDialog={lockInfoDialog}
              dialog="lockInfoDalog"
              closeDialog={()=> setLockInfoDialog(false)}
            />
          :
            null
        }
        {
          showAddingUserDialog ?
            <ShowAddUserLoadingDialog
              instuctionsData={showAddingUserDialogData}
              completedData={showAddingUserDialogCompletedData}
              openDialog={showAddingUserDialog}
              failedData={showAddUserFailedData}
              closeLoader={closeAddingLoader}
              addOrRemoveUserName={failedUserAdditionOrDeletionUsers}
              changeLoader={() => setCloseAddingLoader(true)}
              closeAndAssignDefaultValue= { () => {
                setShowAddingUserDialogData(0);
                setShowAddingUserDialog(false);
                window.addingUserCompletedData = 0;
                window.addingUserFailedData = 0;                
                setShowAddingUserDialogCompletedData(0);
                setShowAddUserFailedData(0);
                if (window.mqttEventHandlerFailedOrSuccessUserAddition) {
                  _.forEach(window.mqttEventHandlerFailedOrSuccessUserAddition,(mqttChannel) => {
                    mqttChannel.unsubscribe();
                  })
                  window.mqttEventHandlerFailedOrSuccessUserAddition = [];
                }
              }}
              closeDialog= {() => {
                setShowAddingUserDialogData(0);
                setShowAddingUserDialog(false);
                setShowAddingUserDialogCompletedData(0);
                setShowAddUserFailedData(0);
                if (window.mqttEventHandlerFailedOrSuccessUserAddition) {
                  _.forEach(window.mqttEventHandlerFailedOrSuccessUserAddition,(mqttChannel) => {
                    mqttChannel.unsubscribe();
                  })
                  window.mqttEventHandlerFailedOrSuccessUserAddition = [];
                }
              }}
            />

          :
            null
        }
        {
          showLockInstructionDialog ?
            <ShowLockInstructionStatusDialog
              instuctionsData={lockInstructionType}
              openDialog={showLockInstructionDialog}
              status={lockInstructionResponseStatus}
              closeLoader={closeLockInstructionDialogLoader}
              changeLoader={() => setCloseLockInstructionDialogLoader(true)}
              closeAndAssignDefaultValue= { () => {   
                setShowLockInstructionDialog(false);             
                setLockInstructionType("")
                setLockInstructionResponseStatus("")
                setCloseLockInstructionDialogLoader(false);
                if (window.mqttEventHandlerFailedOrSuccessLockUnlock) {
                  window.mqttEventHandlerFailedOrSuccessLockUnlock.unsubscribe();
                  setMultiClick(false);
                }
              }}
              closeDialog= {() => {
                setLockInstructionType("")
                setShowLockInstructionDialog(false);
                setCloseLockInstructionDialogLoader(false);
                setLockInstructionResponseStatus("");
                if (window.mqttEventHandlerFailedOrSuccessLockUnlock) {
                  window.mqttEventHandlerFailedOrSuccessLockUnlock.unsubscribe();
                  setMultiClick(false);
                }
              }}
            />
          :
            null
        }
        {
          showRemoveUserDialog ?
            <ShowRemoveUserLoadingDialog
              instuctionsData={showAddingUserDialogData}
              completedData={showAddingUserDialogCompletedData}
              openDialog={showRemoveUserDialog}
              failedData={showAddUserFailedData}
              closeLoader={closeAddingLoader}
              addOrRemoveUserName={failedUserAdditionOrDeletionUsers}
              changeLoader={() => setCloseAddingLoader(true)}
              closeAndAssignDefaultValue= { () => {
                setShowAddingUserDialogData(0);
                setShowRemoveUserDialog(false);
                window.addingUserCompletedData = 0;
                window.addingUserFailedData = 0;                
                setShowAddingUserDialogCompletedData(0);
                setShowAddUserFailedData(0);
                if (window.mqttEventHandlerFailedOrSuccessUserAddition) {
                  _.forEach(window.mqttEventHandlerFailedOrSuccessUserAddition,(mqttChannel) => {
                    mqttChannel.unsubscribe();
                  })
                  window.mqttEventHandlerFailedOrSuccessUserAddition = [];
                }
              }}
              closeDialog= {() => {
                setShowAddingUserDialogData(0);
                setShowRemoveUserDialog(false);
                setShowAddingUserDialogCompletedData(0);
                setShowAddUserFailedData(0);
                if (window.mqttEventHandlerFailedOrSuccessUserAddition) {
                  _.forEach(window.mqttEventHandlerFailedOrSuccessUserAddition,(mqttChannel) => {
                    mqttChannel.unsubscribe();
                  })
                  window.mqttEventHandlerFailedOrSuccessUserAddition = [];
                }
              }}
            />

          :
            null
        }
        {
          showSwitchLockModeDialog ?
            <SwitchLockModeDialog 
              openDialog={showSwitchLockModeDialog}
              data={switchLockModeData}
              closeDialog={()=>{
                setSwitchLockModeData([])
                setShowSwitchLockModeDialog(false)
              }}
            />
          :
            null
        } 
      </>
  );
}
