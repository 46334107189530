import React, {Component, useEffect} from "react";
import mqtt from 'mqtt';
import UserDetailService from "../../services/UserDetailService";
import AppConfigService from "../../services/AppConfigService";
import _ from "lodash";
import UserSessionStorage from "../../userSession/UserSessionStorage";


var mqttClient;
var event_callbacks_map = {};
var isConnecting = false;
var retryCount = 0;
var maxRetryCount;
var host;
var protocol;
var mqttUrl;

export default class Mqtt_Service extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSubed: false,
            payload: {},
            mqttStatus: false
        };
    }

    static connect = function() {
        return new Promise(function(resolve, reject) {
            if (mqttClient === undefined && isConnecting === false) {
                isConnecting = true;
                AppConfigService.getMqttConfiguration().then(function(config) {
                    isConnecting = false;
                    host = config.mqttHost;
                    protocol = config.mqttProtocol;
                    mqttUrl = protocol+"://"+host;
                    var options = {
                        clientId: "frontend-" + Math.random().toString(16).substr(2, 8),
                        port: config.mqttPort,
                        keepalive:5,
                        username: UserDetailService.getMqttAuthToken(),
                        password: UserDetailService.getMqttUserName(),

                    };
                    maxRetryCount = config.retryCount;
                    mqttClient = mqtt.connect(mqttUrl, options);

                    // called when the client connects
                    mqttClient.on('connect', function() {
                        // Once a connection has been made, make a subscription and send a message.
                        console.log("Successfully connected to MQTT");
                        UserSessionStorage.setMqttStatus(true);
                        retryCount = 0;
                        // this.setState({mqttStatus: true});
                        //  useEffect(()=>{
                        _.forEach(event_callbacks_map,function(value, key){
                            mqttClient.subscribe(key);
                        });
                        // });
                        // $rootScope.$apply($rootScope.$broadcast("mqttStatus", true));
                        // $rootScope.$apply(function() {
                        //     _.forEach(event_callbacks_map, function(value, key) {
                        //         mqttClient.subscribe(key);
                        //     });
                        // });
                        resolve(mqttClient);
                    });

                    // called when the client loses its connection
                    mqttClient.on('close', () => {
                        console.log("mqtt connection closed");
                        UserSessionStorage.setMqttStatus(false);
                        // this.setState({mqttStatus: false});

                        // $rootScope.$broadcast("mqttStatus", false);
                        // $rootScope.$apply($rootScope.$broadcast("mqttStatus", false));
                    });

                    // called when a message arrives
                    mqttClient.on('message', function(topic, message) {
                        // useEffect(()=>{
                        console.log("Received event :" + topic + " with message : " + message);
                        var plusCallBacks = [];
                        // Following code is for the case where there is a + in mqtt topic
                        _.forEach(event_callbacks_map, function(value, key) {
                            if (key.indexOf("+") !== -1) {
                                var pattern = key.replace("+", ".*").replace("/", "\\/");
                                pattern = new RegExp(pattern);
                                if (pattern.test(topic)) {
                                    _.forEach(value, function(v) {
                                        plusCallBacks.push(v);
                                    });
                                }
                            }
                        });
                        var specificCallbacks = event_callbacks_map[topic] || [];
                        var baseTopic = topic.substring(0, topic.lastIndexOf("/") + 1) + "#";
                        var baseCallbacks = event_callbacks_map[baseTopic] || [];
                        var callbackspre = specificCallbacks.concat(baseCallbacks);
                        var callbacks = callbackspre.concat(plusCallBacks);
                        _.forEach(callbacks, function(obj) {
                            _.forEach(obj, function(value) {
                                value(message, topic);
                            });
                        });

                        // })
                    });

                    mqttClient.on('reconnect', function() {
                        retryCount++;
                        if (retryCount > maxRetryCount) {
                            mqttClient.end(true, function() {
                                console.log("MQTT client has been disconnected");
                            });
                        } else {
                            console.log("Reconnecting to MQTT");
                        }
                    });

                    mqttClient.on('offline', function() {
                        console.log("MQTT went offline");
                    });

                    mqttClient.on('error', function() {
                        console.log("MQTT connect error  ");
                    });

                }, function() {
                    console.log("failed to get mqtt host config, error : ");
                    isConnecting = false;
                    reject("failed to connect");
                });
            } else {
                if (mqttClient !== undefined && mqttClient.connected) {
                    resolve(mqttClient);
                } else {
                    var stopTimer = setInterval(function() {
                        if (mqttClient !== undefined && mqttClient.connected) {
                            clearInterval(stopTimer);
                            resolve(mqttClient);
                        }
                    }, 1000);
                }
            }

        });

    };

    static mapCallbackToEvent = function(topic, ctrl, callback) {
        //console.log("Adding call back for topic : " + topic + " for controller : " + ctrl);
        var callbacks = event_callbacks_map[topic] || [];
        var keyValue = {};
        keyValue[ctrl] = callback;
        callbacks.push(keyValue);
        event_callbacks_map[topic] = callbacks;
    };

    static removeCallbackToEvent = function(topic, ctrl) {
        // console.log("Removing callback for topic : " + topic + " for controller : " + ctrl);
        var callbacks = event_callbacks_map[topic] || [];
        var copyOfCallbacks = callbacks;
        _.forEach(callbacks, function(value, key) {
            if (value){
                if (value.hasOwnProperty(ctrl)) {
                    copyOfCallbacks.splice(key, 1);
                }
            }
        });
        event_callbacks_map[topic] = copyOfCallbacks;
    };

    static getCallbacksForEvent = function(topic) {
        return event_callbacks_map[topic];
    };

    static isConnected = function() {
        return mqttClient !== undefined && mqttClient.connected;
    };

    static disconnect = function() {
        if (mqttClient) {
            mqttClient.end(true, function() {
                //console.log("MQTT client has been disconnected");
            });
            event_callbacks_map = {};
            mqttClient = undefined;
            isConnecting = false;
            retryCount = 0;
        }
    };

    render(){
        return(
            <></>

        );

    }

}