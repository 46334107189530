import * as React from 'react';
import { Box,Dialog,DialogTitle,DialogActions,DialogContent,Radio,RadioGroup,Table,useMediaQuery,useTheme,TableBody,TableCell,TableContainer,TableHead,TablePagination,TableRow, Tooltip, TableSortLabel,Checkbox,LinearProgress,Select,MenuItem } from '@mui/material';
import { Close,CheckCircle,AlarmAdd,ArrowDropDown,ReportProblem,People,Lock,LockOpen,Check,FirstPage,LastPage,KeyboardArrowLeft,KeyboardArrowRight,Search,Refresh,Download,HelpOutline } from '@mui/icons-material';
import {visuallyHidden} from '@mui/utils';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { AdapterDateFns,LocalizationProvider,DateTimePicker } from '@mui/x-date-pickers';
import styles from '../../stylesheet/users.module.css';
import paginationStyle from '../../stylesheet/pagination.module.css';
import HistoryDialog from './HistoryDialog';
import "../../stylesheet/common.css";
import IotServiceClient from "../../clients/IotServiceClient";
import GatewayServiceClient from "../../clients/GatewayServiceClient";
import _ from "lodash";
import { Button, CircularProgress, DialogContentText, FormControl, Popover, requirePropFactory, TextField } from '@mui/material';
import moment from 'moment';
import Enums from '../../constants/Enums';
import { useSnackbar } from 'notistack';
import MqttEventHandler from '../Mqtt/MqttEventHandler';
import PubSubChannels from '../../pubsub/PubSubChannels';

function TablePaginationActions(props) {

  const {count, page, rowsPerPage, onPageChange} = props;

  function handleFirstPageButtonClick(event) {
    onPageChange(event, 0);
  }

  function handleBackButtonClick(event) {
    onPageChange(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onPageChange(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={paginationStyle.paginationActionIcons}>
      <FirstPage
        onClick={() => {
          if (page > 0) handleFirstPageButtonClick();
        }}
        sx={{
          color: page === 0 ? 'rgba(0,0,0,0.26)' : 'black',
          cursor: page === 0 ? 'auto' : 'pointer',
          marginRight: '8px'
        }}
      />
      <KeyboardArrowLeft
        onClick={() => {
          if (page > 0) handleBackButtonClick();
        }}
        sx={{
          color: page === 0 ? 'rgba(0,0,0,0.26)' : 'black',
          cursor: page === 0 ? 'auto' : 'pointer',
          marginRight: '8px'
        }}
      />
      <KeyboardArrowRight
        onClick={() => {
          if (page !== Math.ceil(count / rowsPerPage) - 1)
            handleNextButtonClick();
        }}
        sx={{
          color:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0
              ? 'black'
              : 'rgba(0,0,0,0.26)',
          cursor:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0 ? 'pointer' : 'auto',
            marginRight: '8px'
        }}
      />
      <LastPage
        onClick={() => {
          if (page !== Math.ceil(count / rowsPerPage) - 1)
            handleLastPageButtonClick();
        }}
        sx={{
          color:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0
              ? 'black'
              : 'rgba(0,0,0,0.26)',
          cursor:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0 ? 'pointer' : 'auto',
        }}
      />
    </div>
  );
}

function AddUserTablePaginationActions(props) {

  const {count, page, rowsPerPage, onPageChange} = props;

  function handleFirstPageButtonClick(event) {
    onPageChange(event, 0);
  }

  function handleBackButtonClick(event) {
    onPageChange(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onPageChange(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={paginationStyle.paginationActionIcons}>
      <FirstPage
        onClick={() => {
          if (page > 0) handleFirstPageButtonClick();
        }}
        sx={{
          color: page === 0 ? 'rgba(0,0,0,0.26)' : 'black',
          cursor: page === 0 ? 'auto' : 'pointer',
          marginRight: '8px'
        }}
      />
      <KeyboardArrowLeft
        onClick={() => {
          if (page > 0) handleBackButtonClick();
        }}
        sx={{
          color: page === 0 ? 'rgba(0,0,0,0.26)' : 'black',
          cursor: page === 0 ? 'auto' : 'pointer',
          marginRight: '8px'
        }}
      />
      <KeyboardArrowRight
        onClick={() => {
          if (page !== Math.ceil(count / rowsPerPage) - 1)
            handleNextButtonClick();
        }}
        sx={{
          color:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0
              ? 'black'
              : 'rgba(0,0,0,0.26)',
          cursor:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0 ? 'pointer' : 'auto',
            marginRight: '8px'
        }}
      />
      <LastPage
        onClick={() => {
          if (page !== Math.ceil(count / rowsPerPage) - 1)
            handleLastPageButtonClick();
        }}
        sx={{
          color:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0
              ? 'black'
              : 'rgba(0,0,0,0.26)',
          cursor:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0 ? 'pointer' : 'auto',
        }}
      />
    </div>
  );
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    addUserDialog,
    showCheckBox
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  let headCells = [];
  
  if (addUserDialog){
    headCells = [
      {
        id: 'Email',
        numeric: false,
        disablePadding: false,
        label: 'Email',
        width: '60px'
      },
      {
        id: 'Role',
        numeric: false,
        disablePadding: false,
        label: 'Type',
        width: '150px'
      },
      {
        id: 'RemovalType',
        numeric: false,
        disablePadding: false,
        label: 'Removal Type',
        width: '150px'
      },
      {
        id: 'Passcode',
        numeric: false,
        disablePadding: false,
        label: 'Passcode*',
        width: '140px'
      },
      {
        id: 'AddType',
        numeric: false,
        disablePadding: false,
        label: 'Add Type',
        width: '150px'
      },

      {
        id: 'scheduleTiming',
        numeric: false,
        disablePadding: false,
        label: 'Schedule Timings',
        width: '190px'
      },
    ];
  }else {
    headCells = [
      {
        id:'fullName',
        numeric: false,
        disablePadding: false,
        label: 'Name',
        width: '60px'
      },
      {
        id: 'Email',
        numeric: false,
        disablePadding: false,
        label: 'Email',
        width: '60px'
      },
      {
        id: 'Passcode',
        numeric: false,
        disablePadding: false,
        label: 'Passcode',
        width: '56px'
      },
      {
        id: 'Role',
        numeric: false,
        disablePadding: false,
        label: 'Type',
        width: '60px'
      },
      {
        id: 'AdditionStatus',
        numeric: false,
        disablePadding: false,
        label: 'Addition Status',
        width: '130px'
      },
      {
        id: 'RemovalType',
        numeric: false,
        disablePadding: false,
        label: 'Removal Type',
        width: '130px'
      },
      {
        id: 'Removal Status',
        numeric: false,
        disablePadding: false,
        label: 'Removal Status',
        width: '130px'
      },
    ];
  }

  const selectAll = (event) => {
    onSelectAllClick(event.target.checked);
  };

  return (
    <TableHead>
      <TableRow>
        {
          showCheckBox && !addUserDialog ?
            <TableCell
              sx={{
                padding: '16px',
                fontSize: '13px',
                width: "10px !important"
              }}
            >
              <Checkbox
                color="error"
                onClick={(event) => selectAll(event)}
                sx={{margin: '0px', padding: '0px',left: "-3px",
                       display: JSON.parse(sessionStorage.userDetails).permissions.includes("GATEWAY_INSTRUCTIONS_WRITE") ? 'flex' : 'none',
                       position:'relative',
                       left: '-11px'
                    }}
              />
            </TableCell>
          :
            null
        }
        {
          addUserDialog ?
            <TableCell
                sx={{
                  padding: '16px',
                  fontSize: '13px',
                  width: "10px !important"
                }}
              >
                <Checkbox
                  color="warning"

                  sx={{margin: '0px', padding: '0px',left: "-3px",visibility:'hidden',
                       display: JSON.parse(sessionStorage.userDetails).permissions.includes("GATEWAY_INSTRUCTIONS_WRITE") ? 'flex' : 'none'
                    }}
                />
              </TableCell>
            :
              null
        }
        {headCells.map((headCell) => (
          <TableCell sx={{width:headCell.width,minWidth:headCell.width}}  key={headCell.id} align="center">
            {headCell.label}
            {
              addUserDialog && (headCell.id === "Passcode" || headCell.id === "scheduleTiming") ?
                <Tooltip placement='top' title={headCell.id === "Passcode" ? "Select the user to enter the passcode" : "Change type to schedule to set user addition and removal time"} arrow>
                  <HelpOutline sx={{fontSize:'14px !important', cursor:"help",position:"relative",top:"3px",left:"6px"}}/>
                </Tooltip>
              :
                null
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function UsersDialog(props) {

    const [SearchBar, setSearchBar] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState( sessionStorage.usersdialogRPP ? Number(sessionStorage.usersdialogRPP) : 10);
    const [addUserRowsPerPage, setAddUserRowsPerPage] = React.useState(sessionStorage.addUsersRPP ? Number(sessionStorage.addUsersRPP) : 10);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('email');
    const [selected, setSelected] = React.useState([]);
    const [dense, setDense] = React.useState(false);
    const [editMode, setEdiitMode] = React.useState(false);
    const [showEditIcon, setShowEditIcon] = React.useState(true);
    const [searchText, setSearchText] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [addUserPage, setAddUserPage] = React.useState(0);
    const [LocksData, setLocksData] = React.useState([]);
    const [LocksDataIsLoading, setLocksDataIsLoading] = React.useState(false);
    const [selectedCount, setSelectedCount] = React.useState(0);
    const [removalType, setRemovalType] = React.useState("immediate");
    const [localTime, setLocalTime] = React.useState(new Date());
    const [pageNumber, setPageNumber] = React.useState(0);
    const [addUserPageNumber, setAddUserPageNumber] = React.useState(0);
    const [showAddUserDialog, setShowAddUserDialog] = React.useState(false);
    const [isAddUserDialogDataLoading , setIsAddUserDialogDataLoading] = React.useState(true);
    const [allUsersData, setAllUsersData] = React.useState([]);
    const [addUserType, setAddUserType] = React.useState(null);
    const [addUserAddType, setAddUserAddType] = React.useState(null);
    const [addUserPasscode, setAddUserPasscode] = React.useState("");
    const [addUserRemoveType, setAddUserRemoveType] = React.useState("normal");
    const [addUsersSelectedCount ,setAddUsersSelectedCount] = React.useState(0);
    const [passcodeConditionFailed ,setPasscodeConditionFailed] = React.useState(false);
    const [addUserAddTime ,setAddUserAddTime] = React.useState(new Date());
    const [addUserRemoveTime ,setAdduserRemoveTime] = React.useState(new Date(new Date(addUserAddTime).getTime()+3600000));
    const [addUserSearchFilterText , setAddUserSearchFilterText] = React.useState("");
    const { enqueueSnackbar } = useSnackbar();
    const [currentTime, setCurrentTime] = React.useState(new Date().getTime())
    const [scheduleAddTimeIsPast, setScheduleAddTimeIsPast] = React.useState(false);
    const [schduleAddTimeIsGraterThanRemoveTime, setSchduleAddTimeIsGraterThanRemoveTime] = React.useState(false);
    const [scheduleRemoveTimeIsCurretTime, setScheduleRemoveTimeIsCurretTime] = React.useState(false);
    const [invalidDataOnCheckAUser, setInvalidDataOnCheckAUser] = React.useState(false);
    const [showCardCsnExistAlertDialog, setShowCardCsnExistAlertDialog] = React.useState(false);
    const [cardCSNErrorData, setCardCSNErrorData] = React.useState([]);

    let mqttDevicesMappingEventHandler =  null;

    const generatePasscode = (type) => {
      let password = "";
      if (type === 'LO_DB' || type === 'LO_SL' ||
          type === 'RFID' || type === 'RFID_FM' ||
          type === 'RFID_LO_DB' || type === 'RFID_LO_SL'||
          type === 'RFID_SM') {
          password = Math.floor(Math.random()*9000) + 1000;
      } else {
          var randomPasscode = "";
          for (var i = 0; i < 4; i++) {
              randomPasscode += Math.floor(Math.random()*4) + 1;
          }
          password =  randomPasscode;
      }
      return password;
    }

    const handleAddUserType = (event, modifiedRow) => {      
      let users = [];
      let count = 0;
      let error = null;
      let invalid = false;
      let conditionFailed = false;
      _.forEach(window.addUsersAllData,(data)=> {
          if(data.id === modifiedRow.id){
            data.addUserType = event.target.value;
            if (event.target.value === "keypadUser"){
              data.addUserPasscode = generatePasscode(props.data.type);
              data.passcodeError= false;
            }else if (event.target.value === "self"){
              data.addUserPasscode = "SELF";
              data.passcodeError= false;
            }else if (event.target.value === "cardUser"){
              data.addUserPasscode = data.cardCSN !== null ? data.cardCSN.toString().substring(0,10).toUpperCase() : "";
              if (data.addUserPasscode === ""){
                data.passcodeError= true;
                conditionFailed = true;
              }else {
                data.passcodeError= false;
              }              
            }else {
              data.addUserPasscode = "";
              data.passcodeError= true;
              conditionFailed = true;
            }
          }
          if (data.selected){
            count+=1;
            if (data.addUserType === "role") {
              invalid = true;
            }
            if (data.passcodeError) {
              conditionFailed = true;
            }            
          }
          users.push(data)
      })
      setPasscodeConditionFailed(conditionFailed);
      setInvalidDataOnCheckAUser(invalid);
      setAllUsersData(users);
      window.addUsersAllData = users;
      setSelectedCount(count);
    };



    const rows = [];

    const createLockUserData = (data,type) => {      
      let combinedLocksData = [];
      let skipScheduleRemoveUserIds = [];
      _.forEach(window.lockAssignedUsers,(val)=>{        
        if (type === "immediate"){
          combinedLocksData.push(val);
        }else {
          _.forEach(data,(user)=>{            
            if (val.deviceMacId === user.deviceMacId && val.email === user.email && val.role === user.role && val.passcode === user.passcode){
              if (user.type === "REMOVE_LOCKDEVICE_USER"){
                val.scheduleRemoveTime = user.scheduledTime;                                                                
                skipScheduleRemoveUserIds.push(user.instructionId);
              }
              val.email = val.email ? val.email : "No Email";
              val.passcode = val.passcode ? val.passcode : "-";          
              val.selected = false;
              if (type === "schedule"){
                val.isSchedule = true;                
              }
              val.addRemovalType = type;
              val.emailHash = user.emailHash;
            }
          })
          combinedLocksData.push(val);
        }
      })

      let allScheduleFileteredData = []

      _.forEach(data, (value) => {
        value.enabledCheckBox = false;
        if (type === "immediate"){
          value.email = value.email ? value.email : "No Email";
          value.passcode = value.passcode ? value.passcode : "-";          
          value.selected = false;
          if (type === "schedule"){
            value.isSchedule = true;
          }
          value.addRemovalType = type;
          value.removeRemovalType = type;
          combinedLocksData.push(value);
        }else {              
            if (!skipScheduleRemoveUserIds.includes(value.instructionId)){
              if (allScheduleFileteredData.length === 0) {
                if (value.type === "REMOVE_LOCKDEVICE_USER"){
                  value.scheduleRemoveTime = value.scheduledTime;
                }else if (value.type === "ADD_LOCKDEVICE_USER"){
                  value.scheduleAddTime = value.scheduledTime;
                }
                allScheduleFileteredData.push(value);
              }else {
                let combineScheduleUser = null;
                _.forEach(allScheduleFileteredData,(val,index)=>{
                  if (value.email === val.email && val.role === value.role && val.passcode === value.passcode) {
                    if (value.type === "REMOVE_LOCKDEVICE_USER"){
                      val.scheduleInstructionId = value.instructionId;
                      val.scheduleRemoveTime = new Date(value.scheduledTime).getTime();
                    }else if (value.type === "ADD_LOCKDEVICE_USER"){                      
                      val.scheduleAddTime = new Date(value.scheduledTime).getTime();
                    }    
                    allScheduleFileteredData.splice(index,1,val);                
                  }else {
                    if (value.type === "REMOVE_LOCKDEVICE_USER"){
                      value.scheduleRemoveTime = new Date(value.scheduledTime).getTime();
                    }else if (value.type === "ADD_LOCKDEVICE_USER"){
                      value.scheduleAddTime = new Date(value.scheduledTime).getTime();
                    }
                    combineScheduleUser = value;
                  }                   
                })           
           
              if (combineScheduleUser){
                allScheduleFileteredData.push(combineScheduleUser);
              }

            } 
          }
        }
      })    

      if (type === "schedule"){
      //rempve user if schedule user have only remove time and does not exist in add user
        let users = [];

        _.forEach(allScheduleFileteredData,(scheduleUser)=>{
            if (scheduleUser.hasOwnProperty("scheduleAddTime") && scheduleUser.hasOwnProperty("scheduleRemoveTime")){
              users.push(scheduleUser);
            }else {
              if (scheduleUser.type === "ADD_LOCKDEVICE_USER"){
                users.push(scheduleUser);
              }else {
                let removeLockUserHasCorresspondingAddSchedule = false; 
                _.forEach(allScheduleFileteredData,(val) => {
                  if (val.email === scheduleUser.email && val.role === scheduleUser.role && val.passcode === scheduleUser.role){
                    removeLockUserHasCorresspondingAddSchedule = val.type === "ADD_LOCKDEVICE_USER";
                  }
                })

                if (removeLockUserHasCorresspondingAddSchedule){
                  users.push(scheduleUser);
                }else {
                  let removeLockUserHasCorresspondingAddImmediate = false; 
                  _.forEach(combinedLocksData,(val) =>{
                    if (val.email === scheduleUser.email && val.role === scheduleUser.role && val.passcode === scheduleUser.role){
                      removeLockUserHasCorresspondingAddImmediate = true;
                    } 
                  })
                  if (removeLockUserHasCorresspondingAddImmediate) {
                    users.push(scheduleUser);
                  }
                }
              }
            }
        })

        _.forEach(users, (scheduleUsers)=>{
          scheduleUsers.email = scheduleUsers.email ? scheduleUsers.email : "No Email";
          scheduleUsers.passcode = scheduleUsers.passcode ? scheduleUsers.passcode : "-";          
          scheduleUsers.selected = false;
            if (type === "schedule"){
              scheduleUsers.isSchedule = true;
            }
            scheduleUsers.addRemovalType = type;
            scheduleUsers.removeRemovalType = type;          
            combinedLocksData.push(scheduleUsers);
        })
      }

      _.forEach(combinedLocksData,(user) => {
        user.prservedOldAddRemovalType = user.addRemovalType;
        user.preservedOldRemoveRemovalType = user.removeRemovalType;
        user.preserveOldscheduleRemoveTime = user.scheduleRemoveTime;
        user.preservedOldScheduleAddTime = user.scheduleAddTime;
        let emails=_.find(window.allUsers,{"email":user.email});
        if(emails){
          user.fullName=emails.firstName ? (emails.lastName ? emails.firstName+" "+emails.lastName : emails.lastName) : (emails.lastName ? emails.lastName : "No Name");
        }
        else{
          user.fullName="No Name";
        }
      })

      window.lockAssignedUsers = combinedLocksData;
      setLocksData(combinedLocksData);
      setLocksDataIsLoading(false);
    }

    const fetchLockUsers = (isMqtt) => {
      if (!isMqtt){
        setLocksDataIsLoading(true);
      }
      IotServiceClient.getLocksUsers(props.data.macId, sessionStorage.tenantId, "usersDialog").then((immediateData)=> {          
        window.lockAssignedUsers = [];                
        GatewayServiceClient.getIotScheduledUsers(props.data.macId,sessionStorage.tenantId).then((data)=>{        
          if(!data.status){
            createLockUserData(immediateData,"immediate");
            createLockUserData(data,"schedule");  
            window.allScheduleData = data;          
          }
        })
      });      
      IotServiceClient.getIotUsers(sessionStorage.tenantId, "usersDialog").then((allUsers) => {
        window.allUsers = allUsers;
      });  
    };

    let currentTimeInterval = null
    const [scheduleRemoveTimeIsIncorrect, setScheduleRemoveTimeIsIncorrect] = React.useState(false);
    const fetchCurrentTime = () => {
      let addUsersAllDataIsModified = false;
      currentTimeInterval = setInterval(() => {
        setCurrentTime(new Date().getTime());
        window.currentTime = new Date().getTime();
        if (showAddUserDialog){
          let passcodeFailedCount = 0;
          _.forEach(window.addUsersAllData,(user)=>{            
            if (user.selected && user.addUserAddType === "schedule"){              
              let userAddYear = new Date(user.addUserAddTime).getFullYear();
              let userAddMonth = new Date(user.addUserAddTime).getMonth() + 1;
              let userAddDate = new Date(user.addUserAddTime).getDate();
              let userAddHour = new Date(user.addUserAddTime).getHours();
              let userAddMin = new Date(user.addUserAddTime).getMinutes();
              let currentYear = new Date().getFullYear();
              let currentMonth = new Date().getMonth() + 1;
              let currentDate = new Date().getDate();
              let currentHour = new Date().getHours();
              let currentMin = new Date().getMinutes();
              let userRemoveYear = new Date(user.addUserRemoveTime).getFullYear();
              let userRemoveMonth = new Date(user.addUserRemoveTime).getMonth() + 1;
              let userRemoveDate = new Date(user.addUserRemoveTime).getDate();
              let userRemoveHour = new Date(user.addUserRemoveTime).getHours();
              let userRemoveMin = new Date(user.addUserRemoveTime).getMinutes();
              let finalUserAddTime = new Date(userAddYear +"-"+ userAddMonth + "-" + userAddDate + " "+ userAddHour + ":" + userAddMin).getTime();
              let finalUserRemoveTime = new Date(userRemoveYear +"-"+ userRemoveMonth + "-" + userRemoveDate + " "+ userRemoveHour + ":" + userRemoveMin).getTime();
              let currentFinalTime = new Date(currentYear +"-"+ currentMonth + "-" + currentDate + " "+ currentHour + ":" + currentMin).getTime();
              if (finalUserAddTime < currentFinalTime) {
                addUsersAllDataIsModified = true;
                user.addUserAddTime = new Date().getTime();
              }
              
              if (finalUserRemoveTime <= finalUserAddTime) {
                addUsersAllDataIsModified = true;
                user.addUserRemoveTime = user.addUserAddTime + 60000;
              }              
              
              if (user.addUserAddTime <= new Date(window.currentTime).getTime()){                
                passcodeFailedCount+=1;                
              }
              if (user.addUserAddTime > user.addUserRemoveTime && user.enabledCheckBox){                
                passcodeFailedCount+=1;
              }
            }   
          })
          if (addUsersAllDataIsModified) {
            setAllUsersData(window.addUsersAllData);
          }
          if (passcodeFailedCount === 0){
            setScheduleAddTimeIsPast(false);
          }else if (passcodeFailedCount > 0){
            setScheduleAddTimeIsPast(true);
          }
        }
        let errorCount = 0;
        let scheduleTimeIsCurrentTime = false;
        _.forEach(window.lockAssignedUsers,(row)=>{          
          if (row.selected){             
            if (row.scheduleAddTime && row.scheduleStartTime  ? new Date(row.scheduleAddTime).getTime() >= new Date(row.scheduleStartTime).getTime() : false ||
               row.scheduleAddTime && row.scheduleRemoveTime  ? new Date(row.scheduleAddTime).getTime() >= new Date(row.scheduleRemoveTime).getTime() : false || 
                row.scheduleStartTime ? new Date(row.scheduleStartTime).getTime() <= new Date(window.currentTime).getTime() : false ||
                    new Date(row.scheduleStartTime).getTime() <= new Date(window.currentTime).getTime() || 
                      new Date(row.scheduleRemoveTime).getTime() <= new Date(window.currentTime).getTime()){
              if (row.addRemovalType !== "cancelSchedule" && row.removeRemovalType !== "immediate"){
                if (row.addRemovalType !== "immediate"){  
                  errorCount+=1;
                }
              }                            
            }
            if (row.removeRemovalType === "schedule" && !row.hasOwnProperty("instructionId") && row.addRemovalType !== "cancelSchedule" && row.scheduleStartTime ? new Date(row.scheduleStartTime).getTime() <= new Date(window.currentTime).getTime() : false) {
              scheduleTimeIsCurrentTime = true;
            }
            if (new Date(row.scheduleAddTime).getTime() <= new Date(window.currentTime).getTime()){
              errorCount+=1
            }
          }
        })        
        setScheduleRemoveTimeIsCurretTime(scheduleTimeIsCurrentTime);
        if (errorCount > 0){
          setScheduleRemoveTimeIsIncorrect(true);
        }else {
          setScheduleRemoveTimeIsIncorrect(false);
        }
      }, 1000);
    }


    React.useEffect(()=>{
      fetchCurrentTime();
    },[showAddUserDialog])

    const onDevicesEventReceived = (message) => {
      enqueueSnackbar("Refreshing Lock Users ...");
      // setLocksData([]);
      if (!props.hasOwnProperty("showDirectlyAddUsersDialog")){
        fetchLockUsers(true);
      }else {
        handleShowAddUserDialog();
      }
    }
    
    React.useEffect(()=>{
      if (!props.hasOwnProperty("showDirectlyAddUsersDialog")){
        mqttDevicesMappingEventHandler = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.DEVICE_USER_MAPPINGS.replace("scopeId",sessionStorage.tenantId), "deviceUsers", onDevicesEventReceived);
      }
      return () => {
        IotServiceClient.abortSignal("usersDialog")
        if(mqttDevicesMappingEventHandler)
          mqttDevicesMappingEventHandler.unsubscribe();
      }
    },[])

    React.useEffect(()=>{
      if (!props.hasOwnProperty("showDirectlyAddUsersDialog")){
        fetchLockUsers(false);
      }else {
        handleShowAddUserDialog();
      }
      return () =>{
        delete window.lockAssignedUsers;
        if (currentTimeInterval)
          clearInterval(currentTimeInterval);
      }
    },[])

      function stableSort(array, comparator) {
        if (array.length > 0){
            const stabilizedThis = array.map((el, index) => [el, index]);
            stabilizedThis.sort((a, b) => {
              const order = comparator(a[0], b[0]);
              if (order !== 0) {
                return order;
              }
              return a[1] - b[1];
            });
            return stabilizedThis.map((el) => el[0]);
        }else {
            return [];
        }

      }

      function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
      }

      function getComparator(order, orderBy) {
        return order === 'desc'
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
      }

      const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setPageNumber(newPage);
      };

      const addUsersHandleChangePage = (event, newPage) => {
        setAddUserPage(newPage);
        setAddUserPageNumber(newPage)
      };

      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setPageNumber(0);
      };

      const adduserHandleChangeRowsPerPage = (event) => {
        setAddUserRowsPerPage(parseInt(event.target.value, 10));
        setAddUserPage(0);
        setAddUserPageNumber(0);
      };

      const isSelected = (name) => selected.indexOf(name) !== -1;

      const handleClick = (event, row) => {
        let newSelected = [];

        if (event.target.checked) {
          if (selected.length > 0) {
            selected.forEach((element) => {
              newSelected.push(element);
            });
            if (JSON.stringify(newSelected).indexOf(row.id) === -1) {
              newSelected.push(row);
            }
          } else {
            newSelected.push(row);
          }
        } else {
          selected.forEach((element) => {
            if (element.id !== row.id) {
              newSelected.push(element);
            }
          });
        }

        setSelected(newSelected);

      };

      const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - LocksData.length) : 0;
      

      const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
      };      

      const handleChange = (event) => {
        setPageNumber(event.target.value);
        setPage(event.target.value);
      };

      const addUserHandleChange = (event) => {
        setAddUserPageNumber(event.target.value);
        setAddUserPage(event.target.value);
      }


      const handleRowPerPage = (event) => {
        if (event.target.value > rowsPerPage && LocksData.length > 0){
          setPageNumber(Math.ceil(LocksData.length/event.target.value) - 1);
        }
        sessionStorage.usersdialogRPP = event.target.value;
        setRowsPerPage(event.target.value);
      };

      const handleAddUsersRowPerPage = (event) => {      
        if (event.target.value > addUserRowsPerPage && allUsersData.length > 0){
          setAddUserPageNumber(Math.ceil(allUsersData.length/event.target.value) - 1);
        }
        sessionStorage.addUsersRPP = event.target.value;
        setAddUserRowsPerPage(event.target.value);
      };

      const handleTextCange = (event) => {
        setSearchText(event.target.value);
      };

      const rowPerPageLabel = (
        <div className={paginationStyle.rowPerPageLabelDiv}>
          <Box className={paginationStyle.page}>
            <span style={{marginRight: '32px'}}>Page</span>
          </Box>
          <Box className={paginationStyle.pageSelectBox}>
            <Select value={pageNumber} onChange={handleChange}>
              {
                
                Math.ceil(LocksData.length / rowsPerPage) > 0 ?
                    // eslint-disable-next-line array-callback-return
                    Array(Math.ceil(LocksData.length / rowsPerPage))
                      .fill()
                      .map((_, index) => {
                        return (
                          <MenuItem value={index} sx={{minWidth: '64px'}}>
                            {index + 1}
                          </MenuItem>
                        );
                      })
                :
                    <MenuItem value={0} sx={{minWidth: '64px'}}>
                        1
                    </MenuItem>
              }
            </Select>
          </Box>
          <Box className={paginationStyle.rowPerPageLabelDiv}>
            <span style={{marginRight: '32px'}}>Rows Per Page</span>
          </Box>
          <Box className={paginationStyle.rowPerPageSelectBox}>
            <Select value={rowsPerPage} onChange={handleRowPerPage}>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={15}>15</MenuItem>
            </Select>
          </Box>
        </div>
      );

      const addUserRowPerPageLabel = (
        <div className={paginationStyle.rowPerPageLabelDiv}>
          <Box className={paginationStyle.page}>
            <span style={{marginRight: '32px'}}>Page</span>
          </Box>
          <Box className={paginationStyle.pageSelectBox}>
            <Select value={addUserPageNumber} onChange={addUserHandleChange}>
              {
                allUsersData.length > 0 ?
                  Math.ceil(allUsersData.length / addUserRowsPerPage) > 0 ?
                      // eslint-disable-next-line array-callback-return
                      Array(Math.ceil(allUsersData.length / addUserRowsPerPage))
                        .fill()
                        .map((_, index) => {
                          return (
                            <MenuItem value={index} sx={{minWidth: '64px'}}>
                              {index + 1}
                            </MenuItem>
                          );
                        })
                  :
                      <MenuItem value={0} sx={{minWidth: '64px'}}>
                          1
                      </MenuItem>
                :
                  null
              }
            </Select>
          </Box>
          <Box className={paginationStyle.rowPerPageLabelDiv}>
            <span style={{marginRight: '32px'}}>Rows Per Page</span>
          </Box>
          <Box className={paginationStyle.rowPerPageSelectBox}>
            <Select value={addUserRowsPerPage} onChange={handleAddUsersRowPerPage}>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={15}>15</MenuItem>
            </Select>
          </Box>
        </div>
      );

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    
    const handleSelectAllClick = (checked) => {
      let selectedData = [];
      let count = 0;
      _.forEach(LocksData, (device) => {
        if ((device.addRemovalType === "schedule" || device.removeRemovalType === "schedule") && checked){
          let falseConditionCount = 0;
          if (device.scheduleAddTime){
            if (new Date(device.scheduleAddTime).getTime() <= new Date(window.currentTime).getTime()){
              falseConditionCount+=1;
            }
          }
          if (device.scheduleRemoveTime){
            if (new Date(device.scheduleRemoveTime).getTime() <= new Date(window.currentTime).getTime()){
              falseConditionCount+=1;
            }
          }
          if (falseConditionCount > 0){
            device.selected = false;
          }else {
            device.selected = true;
          }
        }else{
          device.selected = checked;
        }
        if (device.selected){
          count +=1;
        }
        if (!checked){
          if (device.selected && device.scheduleRemoveTime){
            device.removeRemovalType = "schedule";
          }
          if (!device.selected){
            if (device.scheduleAddTime){
              device.scheduleAddTime = device.preserveOldscheduleAddTime ? device.preserveOldscheduleAddTime : device.scheduleAddTime;
            }
            if (device.scheduleRemoveTime){
              device.scheduleRemoveTime = device.preserveOldscheduleRemoveTime ? device.preserveOldscheduleRemoveTime : device.scheduleRemoveTime;
            }
          }
        }
        selectedData.push(device);
      })
      setSelectedCount(count);
      window.lockAssignedUsers = selectedData;
      setLocksData(selectedData);
    };

    const unCheck = (device,checked) => {
      let uncheckDevice = [];      
      let count = 0;
      _.forEach(LocksData,(value)=>{
        if (device.userId === value.userId && device.role === value.role && device.passcode === value.passcode){
          if (!checked) {
            value.addRemovalType = value.prservedOldAddRemovalType;
            value.removeRemovalType = value.preservedOldRemoveRemovalType;
            if (value.preserveOldscheduleRemoveTime) {
              value.scheduleRemoveTime = value.preserveOldscheduleRemoveTime;
            }
            if (value.preservedOldScheduleAddTime) {
              value.scheduleAddTime = value.preservedOldScheduleAddTime;
              value.scheduledTime = value.preservedOldScheduleAddTime;
            }
          }
          if ((device.addRemovalType === "schedule" || device.removeRemovalType === "schedule") && checked){
            
            let falseConditionCount = 0;
            if (device.scheduleAddTime){
              if (device.scheduleAddTime ? moment(device.scheduleAddTime).format("DD MMM YYYY,hh:mm A") === moment(window.currentTime).format("DD MMM YYYY,hh:mm A") : false){
                falseConditionCount+=1;
              }
            }
            if (device.scheduleRemoveTime){
              if (device.scheduleRemoveTime ? moment(device.scheduleRemoveTime).format("DD MMM YYYY,hh:mm A") === moment(window.currentTime).format("DD MMM YYYY,hh:mm A") : false){
                falseConditionCount+=1;
              }
            }
            if (falseConditionCount > 0){
              value.selected = false;
            }else {
              value.selected = true;
            }
          }else{
            value.selected = !device.selected;
          }                                               
          if (value.selected && value.scheduleRemoveTime){
            value.removeRemovalType = "schedule";
          }
          if (!device.selected){
            if (value.hasOwnProperty("scheduleAddTime")){
                value.scheduleAddTime = value.preserveOldscheduleAddTime ? value.preserveOldscheduleAddTime : value.scheduleAddTime;
            }
            if (value.hasOwnProperty("scheduleRemoveTime")){
                value.scheduleRemoveTime = value.preserveOldscheduleRemoveTime ? value.preserveOldscheduleRemoveTime : value.scheduleRemoveTime;
            }
          }
        }
        if (value.selected){
          count+=1;
        }
        uncheckDevice.push(value);
      });
      setSelectedCount(count);
      window.lockAssignedUsers = uncheckDevice;
      setLocksData(uncheckDevice);      
    };

    const unCheckUsers =(user,event) => {
      let uncheckUser = [];
      let count = 0;
      let invalid = false;
      let passcodeFailed = false
      _.forEach(allUsersData,(value)=>{
        if (user.id === value.id){
          value.selected = !user.selected;
          if (value.addUserPasscode === "" && event.target.checked){            
            passcodeFailed = true;
          }
          if (!event.target.checked) {
            value.addUserType = "role";
            value.addUserRemovalType = "normal";
            value.addUserPasscode = "";
            value.addUserAddType = "immediate";
            value.enabledCheckBox = false;
            let currentDate = new Date().getDate();
            let currentYear = new Date().getFullYear();
            let currentMonth = new Date().getMonth() + 1;                      
            value.addUserAddTime = new Date(currentMonth + "-" + currentDate + "-" + currentYear).getTime() + 84600000;
            value.addUserRemoveTime = new Date(currentMonth + "-" + currentDate + "-" + currentYear+" 00:00:00").getTime() + 86340000;
          }
        }
        if (value.selected){          
          count+=1;
          if (value.addUserPasscode === ""){
            passcodeFailed = true;
          }
          if (value.addUserAddType === "schedule"){
            if (moment(value.addUserAddTime).format("DD MMM YYYY,hh:mm A") <= moment(window.currentTime).format("DD MMM YYYY,hh:mm A") || (value.enabledCheckBox ? moment(value.addUserRemoveTime).format("DD MMM YYYY,hh:mm A") <= moment(value.addUserAddTime).format("DD MMM YYYY,hh:mm A") : false)){
              invalid = true;
            }
          }
        }
        uncheckUser.push(value);
      });
      setPasscodeConditionFailed(passcodeFailed);
      setInvalidDataOnCheckAUser(invalid);
      setAddUsersSelectedCount(count);
      setAllUsersData(uncheckUser);
      window.addUsersAllData = uncheckUser;
    }

    const handleRemovalTypeChange = (event, modifiedRow) => {
      let modifiedData = [];
      let removalType = event.target.value;
      let originalData = Object.assign([],LocksData);
      let scheduleTimeIsCurrentTime = false;
      _.forEach(originalData, (value) => {
          if(value.userId === modifiedRow.userId && value.role === modifiedRow.role && value.passcode === modifiedRow.passcode) {
            if (!value.hasOwnProperty("removeRemovalType")){
              value.addRemovalType = removalType;
            }else {
              value.removeRemovalType = removalType;
            }
            value.selected = true;
            if (value.hasOwnProperty("scheduleAddTime")){
              let addTimeDate = new Date(value.scheduleAddTime).getDate();
              let addTimeYear = new Date(value.scheduleAddTime).getFullYear();
              let addTimeMonth = new Date(value.scheduleAddTime).getMonth() + 1;
              value.scheduleStartTime = new Date(addTimeMonth + "-" + addTimeDate + "-" + addTimeYear + " 00:00:00").getTime() + 86340000;
            }else {
              let currentTimeDate = new Date().getDate();
              let currentTimeYear = new Date().getFullYear();
              let currentTimeMonth = new Date().getMonth() + 1;
              value.scheduleStartTime = new Date(currentTimeMonth + "-" + currentTimeDate + "-" + currentTimeYear + " 00:00:00").getTime() + 86340000;
            }
            
          }
          if (value.removeRemovalType === "schedule" &&  value.scheduleStartTime ? moment(value.scheduleStartTime).format("DD MMM YYYY,hh:mm A") <= moment(currentTime).format("DD MMM YYYY,hh:mm A") : false) {
            scheduleTimeIsCurrentTime = true;
          }
          modifiedData.push(value);
      })      
      setScheduleRemoveTimeIsCurretTime(scheduleTimeIsCurrentTime);
      window.lockAssignedUsers = modifiedData;
     setLocksData(modifiedData);
    };


    const handleAddRemovalTypeChange = (event,modifiedRow) => {
      let modifiedData = [];
      let removalType = event.target.value;      
      _.forEach(window.lockAssignedUsers, (value) => {
          if(value.userId === modifiedRow.userId && value.role === modifiedRow.role && value.passcode === modifiedRow.passcode) {
            value.addRemovalType = removalType;
            value.selected = true;
          }
          modifiedData.push(value);
      })
      window.lockAssignedUsers = modifiedData;
      setLocksData(modifiedData);
    }

    const handleRemoveRemovalTypeChange = (event, modifiedRow) => {
      let modifiedData = [];
      let removalType = event.target.value;
      _.forEach(window.lockAssignedUsers, (value) => {        
        if(value.userId === modifiedRow.userId && value.role === modifiedRow.role && value.passcode === modifiedRow.passcode) {
            value.removeRemovalType = removalType;
            value.selected = true;
            value.scheduleRemoveTime = value.scheduleRemoveTime ? new Date(value.scheduleRemoveTime).getTime() : new Date(currentTime).getTime();
          }
          modifiedData.push(value);
      })
      window.lockAssignedUsers = modifiedData;
      setLocksData(modifiedData);
    }

    const [scheduleStartTimeIsPast ,setScheduleStartTimeIsPast] = React.useState(false);

    const handleScheduleStartTimeChange = (newValue, modifiedRow) => {
      let modifiedData = [];
      let originalData = Object.assign([],LocksData);      
      let scheduleStartTimeIsPastCount = 0;
      let scheduleTimeIsCurrentTime = false;
      _.forEach(originalData, (value) => {          
          if(value.userId === modifiedRow.userId && value.role === modifiedRow.role && value.passcode === modifiedRow.passcode) {
            let updatedYear = new Date(newValue).getFullYear();
            let updatedMonth = new Date(newValue).getMonth() + 1;
            let updatedDate = new Date(newValue).getDate();
            let time = new Date(updatedMonth + "-" + updatedDate + "-" + updatedYear + " 00:00:00").getTime() + 86340000;
            value.scheduleStartTime = time;
            value.scheduleTime = time;
          }
          modifiedData.push(value);
      })
      window.lockAssignedUsers = modifiedData;
      setLocksData(modifiedData);
    };


    const handleScheduleRemove = (newValue, modifiedRow) => {
      let updatedYear = new Date(newValue).getFullYear();
      let updatedMonth = new Date(newValue).getMonth() + 1;
      let updatedDate = new Date(newValue).getDate();
      let time = new Date(updatedMonth + "-" + updatedDate + "-" + updatedYear).getTime() + 86340000;
      modifiedRow.scheduleRemoveTime = time;
      modifiedRow.removeTimeModified = true;
      modifiedRow.selected = true;
    }

    const handleScheduleAdd = (newValue, modifiedRow) => {
      let modifiedData = [];
      let originalData = Object.assign([],LocksData);
      let updatedYear = new Date(newValue).getFullYear();
      let updatedMonth = new Date(newValue).getMonth() + 1;
      let updatedDate = new Date(newValue).getDate();
      let currentYear = new Date().getFullYear();
      let currentMonth = new Date().getMonth() + 1;
      let currentDate = new Date().getDate();
      _.forEach(originalData, (value) => {          
        if(value.userId === modifiedRow.userId && value.role === modifiedRow.role && value.passcode === modifiedRow.passcode) {
          let time = "";
          if (currentDate + "-" + currentMonth + "-" + currentYear === updatedDate + "-" + updatedMonth + "-" + updatedYear) {              
            time = new Date().getTime() + 1000 * 60 * 60;
          }else {
            time = new Date(updatedMonth + "-" + updatedDate + "-" + updatedYear).getTime();
          }
          value.scheduleAddTime = time;          
          if (value.hasOwnProperty("scheduleStartTime") && value.scheduleStartTime <= time){
            value.scheduleStartTime = new Date(updatedMonth + "-" + updatedDate + "-" + updatedYear + " 00:00:00").getTime() + 86340000;            
            value.removeTimeModified = true;
          }
          if (value.hasOwnProperty("scheduleRemoveTime") && value.scheduleRemoveTime <= time){
            value.scheduleRemoveTime = new Date(updatedMonth + "-" + updatedDate + "-" + updatedYear + " 00:00:00").getTime() + 86340000;           
            value.removeTimeModified = true;
          }
          modifiedRow.addTimeModified = true;
          value.selected = true; 
        }
          modifiedData.push(value);
      });           
      window.lockAssignedUsers = modifiedData;
      setLocksData(modifiedData);
    }
    
    const getName = (data) => {
      let name = "";
      if(props.data.macId === data.deviceMacId){
        name = props.data.name;
      }
      return name;
    }

    const getRssi = (data) => {
      let rssi = "";
      if (props.data.macId === data.deviceMacId){
        rssi = props.data.rssi;
      }
      return rssi;
    }

    const getInstructionPayload = (data) => {
      let removeLockDeviceUserPayload = {
        "payload": {
          "email": data.email,
          "emailHash": data.emailHash,
          "macId": data.deviceMacId,
          "name": getName(data),
          "passcode": data.passcode,
          "role": data.role,
          "rssi": getRssi(data),
          "scopeId": sessionStorage.tenantId,
          "userId": data.userId
        },
        "gatewayMacId": props.data ? props.data.gateway_MacId : null,
        "type": Enums.instruction_enums.REMOVE_LOCK_DEVICE_USER
      }
      return removeLockDeviceUserPayload;
    }

    const getAddImmediateInstructionPayload = (data) => {
      let addLockDeviceUserPayload = {
        "payload": {
          "email": data.email,
          "emailHash": data.emailHash,
          "macId": data.deviceMacId,
          "name": getName(data),
          "passcode": data.passcode,
          "role": data.role,
          "rssi": getRssi(data),
          "scopeId": sessionStorage.tenantId,
          "userId": data.userId
        },
        "gatewayMacId": props.data ? props.data.gateway_MacId : null,
        "type": Enums.instruction_enums.ADD_LOCK_DEVICE_USER
      }
      return addLockDeviceUserPayload;
    }

    const createInstructionForUserAlreadyAddedInLock = (payload, user) => {
      GatewayServiceClient.createInstruction1(payload).then((response)=>{
        if (response.status === 201 || response.hasOwnProperty("instructionId")){
          if (response.hasOwnProperty("scheduledTime")){
            if (response.scheduledTime === null){              
              let mergedName = user.hasOwnProperty("lastName") || user.hasOwnProperty("firstName") ? user.lastName ? user.firstName ? user.firstName + " " + user.lastName  : user.lastName : "No-Name" : user.fullName;
              let gatewayLogPayload = {
                "macId" : props.data.gateway_MacId,
                "message" : payload.type === Enums.instruction_enums.REMOVE_LOCK_DEVICE_USER ? mergedName + " Remove User Initiated To " + props.data.name : mergedName + " Add User Initiated To " + props.data.name,
                "date": new Date().getTime(),
                "type": "event",      
              }
              GatewayServiceClient.createLockUnlockEntry(gatewayLogPayload).then(() => {})
            }
          }                      
          if (response.scheduledTime === null || (response.scheduledTime !== null ? response.scheduledTime <= new Date().getTime() : false)) {
            if (window.mqttEventHandlerFailedOrSuccessUserAddition) {              
              window.mqttEventHandlerFailedOrSuccessUserAddition.push(MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.GATEWAY_REMOVE_INSTRUCTION_SUCCESS_OR_FAILURE.replace("scopeId",sessionStorage.tenantId).replace("gatewayMacId",window.openedDialogData.gateway_MacId).replace("lockMacId",window.openedDialogData.macId).replace("instructionId",response.instructionId), "lock", handleGatewayAddInstructionSuccessOrFailures));
              window.mqttEventHandlerFailedOrSuccessUserAddition.push(MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.GATEWAY_ADD_INSTRUCTION_SUCCESS_OR_FAILURE.replace("scopeId",sessionStorage.tenantId).replace("gatewayMacId",window.openedDialogData.gateway_MacId).replace("lockMacId",window.openedDialogData.macId).replace("instructionId",response.instructionId), "lock", handleGatewayAddInstructionSuccessOrFailures));              
            }else {
              window.mqttEventHandlerFailedOrSuccessUserAddition = [];
              window.mqttEventHandlerFailedOrSuccessUserAddition.push(MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.GATEWAY_REMOVE_INSTRUCTION_SUCCESS_OR_FAILURE.replace("scopeId",sessionStorage.tenantId).replace("gatewayMacId",window.openedDialogData.gateway_MacId).replace("lockMacId",window.openedDialogData.macId).replace("instructionId",response.instructionId), "lock", handleGatewayAddInstructionSuccessOrFailures));
              window.mqttEventHandlerFailedOrSuccessUserAddition.push(MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.GATEWAY_ADD_INSTRUCTION_SUCCESS_OR_FAILURE.replace("scopeId",sessionStorage.tenantId).replace("gatewayMacId",window.openedDialogData.gateway_MacId).replace("lockMacId",window.openedDialogData.macId).replace("instructionId",response.instructionId), "lock", handleGatewayAddInstructionSuccessOrFailures));
            }
          }
          MqttEventHandler.publish(PubSubChannels.pubsub_channels.INSTRUCTIONS.replace("scopeId",sessionStorage.tenantId),"created",{qos:2, retain:false},function(){})
          enqueueSnackbar("Successfully created instruction")              
        }else {
          enqueueSnackbar("Failed to created instruction")
        }
      });
    }

    const createUpdateScheduleInstruction = (payload) => {
      GatewayServiceClient.updateInstruction(response => {
        if (response.status === 201 || response.status === 200 || response.status === 204){
          if (response.scheduledTime === null || (response.scheduledTime !== null ? response.scheduledTime <= new Date().getTime() : false)) {
            if (window.mqttEventHandlerFailedOrSuccessUserAddition) {
              window.mqttEventHandlerFailedOrSuccessUserAddition.push(MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.GATEWAY_ADD_INSTRUCTION_SUCCESS_OR_FAILURE.replace("scopeId",sessionStorage.tenantId).replace("gatewayMacId",window.openedDialogData.gateway_MacId).replace("lockMacId",window.openedDialogData.macId).replace("instructionId",response.instructionId), "lock", handleGatewayAddInstructionSuccessOrFailures));              
            }else {
              window.mqttEventHandlerFailedOrSuccessUserAddition = [];
              window.mqttEventHandlerFailedOrSuccessUserAddition.push(MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.GATEWAY_ADD_INSTRUCTION_SUCCESS_OR_FAILURE.replace("scopeId",sessionStorage.tenantId).replace("gatewayMacId",window.openedDialogData.gateway_MacId).replace("lockMacId",window.openedDialogData.macId).replace("instructionId",response.instructionId), "lock", handleGatewayAddInstructionSuccessOrFailures));
            }
          }
          enqueueSnackbar("Successfully created instruction")
        }else {
          enqueueSnackbar("Failed to created instruction")
        }
      }, payload);
    }

    const createRemoveScheduleInstruction = (id, toastMessage) => {
      GatewayServiceClient.deleteInstruction(id).then((res)=>{
        if (res.status === 204 || res.status === 200){
          MqttEventHandler.publish(PubSubChannels.pubsub_channels.INSTRUCTIONS.replace("scopeId",sessionStorage.tenantId),"created",{qos:2, retain:false},function(){})
          if (toastMessage === null || typeof toastMessage === 'undefined'){
            enqueueSnackbar("Successfully canceled scheduled instruction")
          }else if (toastMessage === "showInstruction") {
            enqueueSnackbar("Successfully created instruction")
          }
        }else {
          if (toastMessage === null || typeof toastMessage === 'undefined'){
            enqueueSnackbar("Failed to cancel scheduled instruction");
          }else if (toastMessage === "showInstruction") {
            enqueueSnackbar("Failed to create instruction")
          }
        }
      })
    }

    const handleRemoveUser = () => {
      let usersAlreadyAddedInLock = [];
      let scheduledUsers = [];
      let instructionCreatedForRemove = [];
      let userAddImmediate = [];
      _.forEach(LocksData,(value) => {
        if (value.selected){          
          if (value.removeRemovalType === "immediate" && !value.hasOwnProperty("scheduleAddTime")) {
            // Need to keep a check for add user in case of schedule.
            instructionCreatedForRemove.push(value);
          }
          if (!value.emailHash){
            let matchedObject = _.find(window.allUsers,{"email": value.email});
            value.emailHash = matchedObject ?  matchedObject.emailHash : null;
          }          
          if (!value.hasOwnProperty("isSchedule") || (!value.instructionId && (value.removeRemovalType === "schedule" || value.removeRemovalType === "immediate" || value.removeRemovalType === "cancelSchedule"))) {
            //this logic will be execueted when user addition is already done (a tick icon will be shown in addition status in locks page)
            usersAlreadyAddedInLock.push(value);
          }else {            
            scheduledUsers.push(value)
          }
        }
      })
                  
      _.forEach(usersAlreadyAddedInLock,(user) => {
        let payload = getInstructionPayload(user);
        if (user.removeRemovalType === "immediate") {
          createInstructionForUserAlreadyAddedInLock(payload, user);
        }else if (user.removeRemovalType === "schedule") {
          let matchedInstructionId = _.find(window.allScheduleData,{"email": user.email,"deviceMacId": user.deviceMacId , "role": user.role, "passcode": user.passcode});
          let removeTime = user.scheduleRemoveTime ? user.scheduleRemoveTime : (user.scheduleTime ? user.scheduleTime : user.scheduleStartTime);
          let removeTimeYear = new Date(removeTime).getFullYear();
          let removeTimeDate = new Date(removeTime).getDate();
          let removeTimeMonth = new Date(removeTime).getMonth() + 1;
          let time = new Date(removeTimeMonth + "-" + removeTimeDate + "-" + removeTimeYear).getTime() + 86340000;
          payload["scheduledTime"] = new Date(time).toUTCString();          
          let oldPreservedScheduleTime = null;
          if (matchedInstructionId){
            oldPreservedScheduleTime = matchedInstructionId.scheduledTime;
            payload["instructionId"] = matchedInstructionId.instructionId;
          }
          if (oldPreservedScheduleTime ? oldPreservedScheduleTime !== removeTime : false){
            createUpdateScheduleInstruction(payload);
          }else if (user.scheduleTime || user.scheduleStartTime){
            createInstructionForUserAlreadyAddedInLock(payload, user);
          }
        }else if (user.removeRemovalType === "cancelSchedule") {
          let matchedInstructionId = _.find(window.allScheduleData,{"email": user.email,"deviceMacId": user.deviceMacId , "role": user.role, "passcode": user.passcode});
          if (matchedInstructionId){
            let instructionId = matchedInstructionId.instructionId;
            if (instructionId){
              createRemoveScheduleInstruction(instructionId)
            }
          }
        }
      });

      _.forEach(scheduledUsers, (user) => {
        if (user.addRemovalType === "immediate") {
          let payload = getAddImmediateInstructionPayload(user);        
          // remove previous instructionId and add 
          let matchedObject = _.find(window.allScheduleData,{"email": user.email,"deviceMacId": user.deviceMacId , "role": user.role, "passcode": user.passcode, "type": "ADD_LOCKDEVICE_USER"});
          if (matchedObject){
            if (matchedObject.instructionId){
              createRemoveScheduleInstruction(matchedObject.instructionId , "noNeedToShowToastMessage");
              userAddImmediate.push(user);
            }
          }
          createInstructionForUserAlreadyAddedInLock(payload, user);          
          if (user.removeRemovalType === "schedule"){
            let schedulePayload = getInstructionPayload(user);            
            let matchedInstructionId = _.find(window.allScheduleData,{"email": user.email,"deviceMacId": user.deviceMacId , "role": user.role, "passcode": user.passcode, "type" : "REMOVE_LOCKDEVICE_USER"});
            let removeTime = user.scheduleRemoveTime ? user.scheduleRemoveTime : (user.scheduleTime ? user.scheduleTime : user.scheduleStartTime);
            let removeTimeYear = new Date(removeTime).getFullYear();
            let removeTimeDate = new Date(removeTime).getDate();
            let removeTimeMonth = new Date(removeTime).getMonth() + 1;
            let time = new Date(removeTimeMonth + "-" + removeTimeDate + "-" + removeTimeYear).getTime() + 86340000;
            schedulePayload["scheduledTime"] = new Date(time).toUTCString();          
            let oldPreservedScheduleTime = null;
            if (matchedInstructionId){
              oldPreservedScheduleTime = matchedInstructionId.scheduledTime;
              schedulePayload["instructionId"] = matchedInstructionId.instructionId;
            }
            if (oldPreservedScheduleTime ? oldPreservedScheduleTime !== removeTime : false){
              // update instruction
              createUpdateScheduleInstruction(schedulePayload);
            }else if (user.scheduleTime || user.scheduleStartTime || user.scheduleRemoveTime){
              //create a new instruction
              createInstructionForUserAlreadyAddedInLock(schedulePayload, user);
            }
          }else if (user.removeRemovalType === "cancelSchedule") {
            createRemoveScheduleInstruction(user.scheduleInstructionId);
          }
        }else if (user.addRemovalType === "schedule") {
          // update lock user addition time
          let schedulePayload = getInstructionPayload(user);            
          let matchedInstructionId = _.find(window.allScheduleData,{"email": user.email,"deviceMacId": user.deviceMacId , "role": user.role, "passcode": user.passcode, "type" : "ADD_LOCKDEVICE_USER"});
          let removeTime = user.scheduleAddTime;
          let removeTimeYear = new Date(removeTime).getFullYear();
          let removeTimeDate = new Date(removeTime).getDate();
          let removeTimeMonth = new Date(removeTime).getMonth() + 1;
          let time = new Date(removeTimeMonth + "-" + removeTimeDate + "-" + removeTimeYear).getTime();
          schedulePayload["scheduledTime"] = new Date(time).toUTCString();
          schedulePayload["type"] = "ADD_LOCKDEVICE_USER";
          let oldPreservedAddScheduleTime = null;
          if (matchedInstructionId){
            oldPreservedAddScheduleTime = matchedInstructionId.scheduledTime;
            schedulePayload["instructionId"] = matchedInstructionId.instructionId;
          }

          if (oldPreservedAddScheduleTime ? oldPreservedAddScheduleTime !== removeTime : false){            
            createUpdateScheduleInstruction(schedulePayload);
          }
          // Remove user logic when addition of a user is schedule
          if (user.removeRemovalType === "immediate") {
            // when remove is immediate and user not yet added we need to delete both instructions
            if (user.instructionId){
              createRemoveScheduleInstruction(user.instructionId,"showInsttruction");
            }
            if (user.scheduleInstructionId) {
              createRemoveScheduleInstruction(user.scheduleInstructionId);
            }
          }else if (user.removeRemovalType === "schedule") {
            //update or create a new instruction
            if (user.scheduleInstructionId){
              //update payload
              matchedInstructionId = _.find(window.allScheduleData,{"email": user.email,"deviceMacId": user.deviceMacId , "role": user.role, "passcode": user.passcode, "type" : "REMOVE_LOCKDEVICE_USER"});
              let removeTime = user.scheduleRemoveTime;
              let removeTimeYear = new Date(removeTime).getFullYear();
              let removeTimeDate = new Date(removeTime).getDate();
              let removeTimeMonth = new Date(removeTime).getMonth() + 1;
              let time = new Date(removeTimeMonth + "-" + removeTimeDate + "-" + removeTimeYear).getTime() + 86340000;
              schedulePayload["scheduledTime"] = new Date(time).toUTCString();
              schedulePayload["type"] = "REMOVE_LOCKDEVICE_USER";
              let oldPreservedRemovecheduleTime = null;
              if (matchedInstructionId){
                oldPreservedRemovecheduleTime = matchedInstructionId.scheduledTime;
                schedulePayload["instructionId"] = matchedInstructionId.instructionId;
              }
              if (oldPreservedRemovecheduleTime ? oldPreservedRemovecheduleTime !== removeTime : false){            
                createUpdateScheduleInstruction(schedulePayload);
              }
            }else {
              // create a new instruction
              let requestPayload = getInstructionPayload(user);
              let time = user.scheduleTime ? user.scheduleTime : user.scheduleStartTime;
              let removeTimeYear = new Date(time).getFullYear();
              let removeTimeDate = new Date(time).getDate();
              let removeTimeMonth = new Date(time).getMonth() + 1;
              time = new Date(removeTimeMonth + "-" + removeTimeDate + "-" + removeTimeYear).getTime() + 86340000;
              if(time ? time.toString() !== "NaN" : false){
                requestPayload["scheduledTime"] = new Date(time).toUTCString();
                requestPayload["type"] = "REMOVE_LOCKDEVICE_USER";
                createInstructionForUserAlreadyAddedInLock(requestPayload, user);
              }
            }
          }else if (user.removeRemovalType === "cancelSchedule") {
            if (user.scheduleInstructionId){
              createRemoveScheduleInstruction(user.scheduleInstructionId);
            }
          }
        }else if (user.addRemovalType === "cancelSchedule") {
          let payload = getAddImmediateInstructionPayload(user);        
          // remove previous instructionId and add 
          let matchedObject = _.find(window.allScheduleData,{"email": user.email,"deviceMacId": user.deviceMacId , "role": user.role, "passcode": user.passcode, "type": "ADD_LOCKDEVICE_USER"});
          if (matchedObject){
            if (matchedObject.instructionId){
              createRemoveScheduleInstruction(matchedObject.instructionId);
            }            
          }
          if (user.scheduleInstructionId) {
            createRemoveScheduleInstruction(user.scheduleInstructionId , "noNeedToShowToastMessage");
          }          
        }
      });
      if (instructionCreatedForRemove.length > 0) {
        props.close_users_dialog(null, instructionCreatedForRemove, "remove");
      }else if (userAddImmediate.length>0){
        props.close_users_dialog(null, userAddImmediate);
      }else {
        props.close_users_dialog();
      }
    };

    
    const fetchAddUserDialogData = () => {
      let res = [];
      IotServiceClient.getIotUsers(sessionStorage.tenantId, "usersDialog").then((data) => {
        res = data;
        _.forEach(res,(element)=>{
          element.mergedName = element.firstName ? (element.lastName ? element.firstName+ " " +element.lastName : element.firstName) : (element.lastName ? element.lastName : "No Name");
          element.email = element.email ? element.email : 'No-email';
          element.addUserType = "role";
          element.addUserRemovalType = "normal";
          element.addUserPasscode = "";
          element.addUserAddType = "immediate";
          element.passcodeError = false;
          element.selected = false;
          let currentDate = new Date().getDate();
          let currentYear = new Date().getFullYear();
          let currentMonth = new Date().getMonth() + 1;          
          element.addUserAddTime = new Date(currentMonth + "-" + currentDate + "-" + currentYear).getTime() + 84600000;
          // currentDate = currentDate + 1;
          element.addUserRemoveTime = new Date(currentMonth + "-" + currentDate + "-" + currentYear).getTime() + 85040000;
          element.enabledCheckBox = false;
          element.customValue = 1;
        })       
        setPasscodeConditionFailed(false);
        setAllUsersData(res);
        window.addUsersAllData = res;
        setAddUsersSelectedCount(0);
        setIsAddUserDialogDataLoading(false);
      });
    }

    const handleShowAddUserDialog = () => {
      setShowAddUserDialog(true);
      setIsAddUserDialogDataLoading(true);
      fetchAddUserDialogData();
    }


    const handleAddUserPasscode = (event, modifiedRow) => {
      let users = [];
      let count = 0;
      let error = null;
      let regex = null;      
      let invalid = false;
      let conditionFailed = false;      
      _.forEach(window.addUsersAllData,(data)=> {
          if(data.id === modifiedRow.id){
            data.addUserPasscode = event.target.value;
            if((props.data.type === 'LO_DB' || props.data.type === 'LO_SL') && (modifiedRow.addUserType === "keypadUser" || modifiedRow.addUserType === "admin")) {
              regex = modifiedRow.addUserType === "admin" ? new RegExp("^[0-9]{5}$") : new RegExp("^[0-9]{4}$");
              data.passcodeError = !event.target.value.match(regex);
            } else if ((modifiedRow.addUserType === "user" || modifiedRow.addUserType === "keypadUser") && (props.data.type === 'RFID' || props.data.type === 'RFID_FM' ||
                props.data.type === 'RFID_LO_DB' || props.data.type === 'RFID_LO_SL'||
                props.data.type === 'RFID_SM')) {
                regex = new RegExp("^[0-9]{4}$");
                data.passcodeError = !event.target.value.match(regex);
            }else if(modifiedRow.addUserType === "cardUser"){
              if (data.cardCSN){
                data.passcodeError = false;
              }else {
                regex = new RegExp("^[0-9a-fA-F]{10}$");
                data.passcodeError = !event.target.value.match(regex);
              }
            }else if(modifiedRow.addUserType === "self"){
              data.passcodeError = false;
            }else {
                let regex = modifiedRow.addUserType === "admin" ? new RegExp("^[1-4]{5}$") : new RegExp("^[1-4]{4}$");
                data.passcodeError = !event.target.value.match(regex);
            }
          }
          if (data.selected){
            count+=1;
            if (data.passcodeError || data.addUserType === "role" || data.passcode === "") {
              invalid = true;              
              conditionFailed = true;
            }
          }          
          users.push(data)
      })
      setInvalidDataOnCheckAUser(invalid);
      setPasscodeConditionFailed(conditionFailed);
      setAllUsersData(users);
      window.addUsersAllData = users;
      setSelectedCount(count);
    }

    const handleAddUserAddType = (event,modifiedRow) => {
      let users = [];
      let count = 0;
      _.forEach(allUsersData,(data)=> {
          if(data.id === modifiedRow.id){
            data.addUserAddType = event.target.value;
          }
          if (data.selected){
            count+=1;
          }
          users.push(data)
      })
      setAllUsersData(users);
      window.addUsersAllData = users;
      setSelectedCount(count);
    }


    const handleAddUserRemoveType = (event, modifiedRow) => {
      let users = [];
      let count = 0;
      _.forEach(allUsersData,(data)=> {
          if(data.id === modifiedRow.id){
            data.addUserRemovalType = event.target.value;            
          }
          if (data.selected){
            count+=1;
          }
          users.push(data)
      })
      setAllUsersData(users);
      window.addUsersAllData = users;
      setSelectedCount(count);
    }

    const handleGatewayAddInstructionSuccessOrFailures = (message) => {
      props.handleGatewayAddInstruction(message);
    }

    const sendImmediateInstrution = (data, macId , name, rssi) => {
      _.forEach(data,(value)=>{             
        setTimeout(() => {
          let ImmediateAddUserInstructionPayload ={ 
            "payload" :{
                "macId": macId,
                "userId": value.id,
                "passcode": value.addUserType === "cardUser" ? (value.cardCSN !== null ? value.cardCSN.toString().substring(0,10).toUpperCase()+"L" : value.addUserPasscode.toString().toUpperCase() + "L" ) : value.addUserPasscode ,
                "role": value.addUserType === "cardUser" ? "CARD_USER" : (value.addUserType === "admin" ? "ADMIN" : "USER"),
                "emailHash": value.emailHash,
                "email": value.email,
                "name": name,
                "time": new Date().toUTCString(),
                "rssi": rssi,
                "scopeId": sessionStorage.tenantId,
                'userRemoveType': value.addUserRemovalType
            },
            "gatewayMacId": props.data ? props.data.gateway_MacId : null,
            type: Enums.instruction_enums.ADD_LOCK_DEVICE_USER,
          }
          
          GatewayServiceClient.createInstruction1(ImmediateAddUserInstructionPayload).then((response)=>{
            if (response.status === 201 || response.hasOwnProperty("instructionId")){ 
              let payload = {
                "macId" : props.data.gateway_MacId,
                "message" : value.mergedName + " Add User Initiated To "+ props.data.name,
                "date": new Date().getTime(),
                "type": "event",      
                }
                
                GatewayServiceClient.createLockUnlockEntry(payload).then(() => {})

              if (response.scheduledTime === null || ( response.scheduledTime !== null ? response.scheduledTime <= new Date().getTime() : false)) {                                                                      
                if (window.mqttEventHandlerFailedOrSuccessUserAddition){
                  window.mqttEventHandlerFailedOrSuccessUserAddition.push(MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.GATEWAY_ADD_INSTRUCTION_SUCCESS_OR_FAILURE.replace("scopeId",sessionStorage.tenantId).replace("gatewayMacId",window.openedDialogData.gateway_MacId).replace("lockMacId",window.openedDialogData.macId).replace("instructionId",response.instructionId), "lock", handleGatewayAddInstructionSuccessOrFailures));
                }else {
                  window.mqttEventHandlerFailedOrSuccessUserAddition = [];
                  window.mqttEventHandlerFailedOrSuccessUserAddition.push(MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.GATEWAY_ADD_INSTRUCTION_SUCCESS_OR_FAILURE.replace("scopeId",sessionStorage.tenantId).replace("gatewayMacId",window.openedDialogData.gateway_MacId).replace("lockMacId",window.openedDialogData.macId).replace("instructionId",response.instructionId), "lock", handleGatewayAddInstructionSuccessOrFailures));
                }
              }
              MqttEventHandler.publish(PubSubChannels.pubsub_channels.INSTRUCTIONS.replace("scopeId",sessionStorage.tenantId),"created",{qos:2, retain:false},function(){})
              enqueueSnackbar("Successfully created instruction")              
            }else {
              enqueueSnackbar("Failed to created instruction")
            }
          });        
        }, 1000);        
      })
    }

    const sendScheduleInstruction = (value, addType, macId, name, rssi) => {
      let instructionDate = new Date(value[addType]).getDate();
      let instructionMonth = new Date(value[addType]).getMonth() + 1;
      let instructionYear = new Date(value[addType]).getFullYear();      
      let removeInstrutionTime = new Date(instructionMonth + "-" + instructionDate + "-" + instructionYear).getTime() + 86340000 ; // 23 hours 59 minutes      
      let currentDate = new Date().getDate();
      let currentMonth = new Date().getMonth() + 1 ;
      let addtime = "";
      if (addType === "addUserAddTime") {
        if (instructionDate + "-" + instructionMonth === currentDate + "-" + currentMonth){
          addtime = new Date().toUTCString();
        }else {
          addtime = new Date(instructionMonth + "-" + instructionDate + "-" + instructionYear).toUTCString();
        }
      }
      let time = addType === "addUserAddTime" ? addtime : new Date(removeInstrutionTime).toUTCString() ;
      let scheduleInstructionPayload ={ 
        "payload" :{
            "macId": macId,
            "userId": value.id,
            "passcode": value.addUserType === "cardUser" ? (value.cardCSN !== null ? value.cardCSN.toString().substring(0,10).toUpperCase()+"L" : value.addUserPasscode.toString().toUpperCase() + "L" ) : value.addUserPasscode.toString() ,
            "role": value.addUserType === "cardUser" ? "CARD_USER" : (value.addUserType === "admin" ? "ADMIN" : "USER"),
            "emailHash": value.emailHash,
            "email": value.email,
            "name": name,
            "time": time,
            "rssi": rssi,
            "scopeId": sessionStorage.tenantId,
            'userRemoveType': value.addUserRemovalType
        },
        "scheduledTime": time,
        "gatewayMacId": props.data ? props.data.gateway_MacId : null,
        "type": addType === "addUserAddTime" ? Enums.instruction_enums.ADD_LOCK_DEVICE_USER : Enums.instruction_enums.REMOVE_LOCK_DEVICE_USER,
      }  
      return scheduleInstructionPayload;
    }

    const constructPayload = (payload, removalPayload) => {
        if(removalPayload.enabledCheckBox){
            payload.userRemovalTime = new Date(removalPayload.addUserRemoveTime).toUTCString();
        }
        return payload;
    }

    const createInstructionForAddingUser = (data) => {
      setAddUsersSelectedCount(0);
      let allImmediateIntructions = [];
      let allScheduleInstructions = [];
      _.forEach(data,(value)=>{
        if (value.addUserAddType === "immediate"){
          allImmediateIntructions.push(value);
        }else if (value.addUserAddType === "schedule"){
          allScheduleInstructions.push(value);
        }
      })
      window.allAddUserInstructionsCreated = [];
      window.mqttEventHandlerFailedOrSuccessUserAddition = [];
      if (allImmediateIntructions.length > 0){
        if (!props.hasOwnProperty("showDirectlyAddUsersDialog")){
          sendImmediateInstrution(allImmediateIntructions, props.data.macId, props.data.name, props.data.rssi);
        }else {
          _.forEach(props.allData,(lock)=>{
            sendImmediateInstrution(allImmediateIntructions,lock.macId, lock.name, lock.rssi);
          })
        }
      }
      if (allScheduleInstructions.length > 0){
        let index = 0;
        let newIndex = 0;
        let scheduleSetInterval = setInterval(() => {  
          if (allScheduleInstructions[index] && index === newIndex){
            if (!props.hasOwnProperty("showDirectlyAddUsersDialog")){
              let payload = sendScheduleInstruction(allScheduleInstructions[index],"addUserAddTime",props.data.macId, props.data.name, props.data.rssi);
              var sendInvitationMailWhileConfSU = payload;
              GatewayServiceClient.createScheduleInstruction(payload).then((response) => {
                if (response.type === "ADD_LOCKDEVICE_USER"){
                  if (response.scheduledTime === null || ( response.scheduledTime !== null ? response.scheduledTime <= new Date().getTime() : false)) {
                    if (window.mqttEventHandlerFailedOrSuccessUserAddition) {
                      window.mqttEventHandlerFailedOrSuccessUserAddition.push(MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.GATEWAY_ADD_INSTRUCTION_SUCCESS_OR_FAILURE.replace("scopeId",sessionStorage.tenantId).replace("gatewayMacId",window.openedDialogData.gateway_MacId).replace("lockMacId",window.openedDialogData.macId).replace("instructionId",response.instructionId), "lock", handleGatewayAddInstructionSuccessOrFailures));
                    }else {
                      window.mqttEventHandlerFailedOrSuccessUserAddition = [];
                      window.mqttEventHandlerFailedOrSuccessUserAddition.push(MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.GATEWAY_ADD_INSTRUCTION_SUCCESS_OR_FAILURE.replace("scopeId",sessionStorage.tenantId).replace("gatewayMacId",window.openedDialogData.gateway_MacId).replace("lockMacId",window.openedDialogData.macId).replace("instructionId",response.instructionId), "lock", handleGatewayAddInstructionSuccessOrFailures));
                    }
                  }
                    IotServiceClient.sendInvitationEmailWhileConfiguringScheduledAddUser(constructPayload(sendInvitationMailWhileConfSU, allScheduleInstructions[index])).then((response) => {
                        if (response.status == 200) {
                            console.log("Invitation email sent successfully");
                        }
                    })
                  let dependentId = response.instructionId;
                  if (allScheduleInstructions[index].hasOwnProperty("enabledCheckBox") ? allScheduleInstructions[index].enabledCheckBox : false){
                    let removePayload = sendScheduleInstruction(allScheduleInstructions[index],"addUserRemoveTime",props.data.macId, props.data.name, props.data.rssi);
                    removePayload["dependentId"] = dependentId;
                    GatewayServiceClient.createScheduleInstruction(removePayload).then((res) => {
                      if (res.type === "REMOVE_LOCKDEVICE_USER"){
                        if (res.scheduledTime === null || (res.scheduledTime !== null ? res.scheduledTime <= new Date().getTime() : false)) {
                          if (window.mqttEventHandlerFailedOrSuccessUserAddition) {
                            window.mqttEventHandlerFailedOrSuccessUserAddition.push(MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.GATEWAY_ADD_INSTRUCTION_SUCCESS_OR_FAILURE.replace("scopeId",sessionStorage.tenantId).replace("gatewayMacId",window.openedDialogData.gateway_MacId).replace("lockMacId",window.openedDialogData.macId).replace("instructionId",res.instructionId), "lock", handleGatewayAddInstructionSuccessOrFailures));              
                          }else {
                            window.mqttEventHandlerFailedOrSuccessUserAddition = [];
                            window.mqttEventHandlerFailedOrSuccessUserAddition.push(MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.GATEWAY_ADD_INSTRUCTION_SUCCESS_OR_FAILURE.replace("scopeId",sessionStorage.tenantId).replace("gatewayMacId",window.openedDialogData.gateway_MacId).replace("lockMacId",window.openedDialogData.macId).replace("instructionId",res.instructionId), "lock", handleGatewayAddInstructionSuccessOrFailures));
                          }
                        }
                        enqueueSnackbar("Successfully created instruction")
                      }else {
                        enqueueSnackbar("Failed to created instruction")
                      }
                      index =  index+1;
                      newIndex = index-1;
                    });
                  }else {
                    // user had choosen only add user option
                    if (response.type === "ADD_LOCKDEVICE_USER"){
                      enqueueSnackbar("Successfully created instruction")
                    }else {
                      enqueueSnackbar("Failed to created instruction")
                    }
                    index =  index+1;
                    newIndex = index-1;
                  }
                }else {
                  enqueueSnackbar("Failed to created instruction")
                }
              });              
            }else {
              _.forEach(props.allData, (lock) => {
                let payload = sendScheduleInstruction(allScheduleInstructions[index],"addUserAddTime",lock.macId, lock.name, lock.rssi);
                GatewayServiceClient.createScheduleInstruction(payload).then((response) => {
                  if (response.type === "ADD_LOCKDEVICE_USER"){
                    let dependentId = response.instructionId;
                    if (response.scheduledTime === null || (response.scheduledTime !== null ? response.scheduledTime <= new Date().getTime() : false)) {
                      if (window.mqttEventHandlerFailedOrSuccessUserAddition) {
                        window.mqttEventHandlerFailedOrSuccessUserAddition.push(MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.GATEWAY_ADD_INSTRUCTION_SUCCESS_OR_FAILURE.replace("scopeId",sessionStorage.tenantId).replace("gatewayMacId",window.openedDialogData.gateway_MacId).replace("lockMacId",window.openedDialogData.macId).replace("instructionId",response.instructionId), "lock", handleGatewayAddInstructionSuccessOrFailures));              
                      }else {
                        window.mqttEventHandlerFailedOrSuccessUserAddition = [];
                        window.mqttEventHandlerFailedOrSuccessUserAddition.push(MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.GATEWAY_ADD_INSTRUCTION_SUCCESS_OR_FAILURE.replace("scopeId",sessionStorage.tenantId).replace("gatewayMacId",window.openedDialogData.gateway_MacId).replace("lockMacId",window.openedDialogData.macId).replace("instructionId",response.instructionId), "lock", handleGatewayAddInstructionSuccessOrFailures));
                      }
                    }
                    if (allScheduleInstructions[index].hasOwnProperty("enabledCheckBox") ? allScheduleInstructions[index].enabledCheckBox : false){
                      let removePayload = sendScheduleInstruction(allScheduleInstructions[index],"addUserRemoveTime",lock.macId, lock.name, lock.rssi);
                      removePayload["dependentId"] = dependentId;
                      GatewayServiceClient.createScheduleInstruction(removePayload).then((res) => {
                        if (res.type === "REMOVE_LOCKDEVICE_USER"){
                          if (res.scheduledTime === null || (res.scheduledTime !== null ? res.scheduledTime <= new Date().getTime() : false)) {
                            if (window.mqttEventHandlerFailedOrSuccessUserAddition) {
                              window.mqttEventHandlerFailedOrSuccessUserAddition.push(MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.GATEWAY_ADD_INSTRUCTION_SUCCESS_OR_FAILURE.replace("scopeId",sessionStorage.tenantId).replace("gatewayMacId",window.openedDialogData.gateway_MacId).replace("lockMacId",window.openedDialogData.macId).replace("instructionId",res.instructionId), "lock", handleGatewayAddInstructionSuccessOrFailures));              
                            }else {
                              window.mqttEventHandlerFailedOrSuccessUserAddition = [];
                              window.mqttEventHandlerFailedOrSuccessUserAddition.push(MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.GATEWAY_ADD_INSTRUCTION_SUCCESS_OR_FAILURE.replace("scopeId",sessionStorage.tenantId).replace("gatewayMacId",window.openedDialogData.gateway_MacId).replace("lockMacId",window.openedDialogData.macId).replace("instructionId",res.instructionId), "lock", handleGatewayAddInstructionSuccessOrFailures));
                            }
                          }
                          enqueueSnackbar("Successfully created instruction")
                        }else {
                          enqueueSnackbar("Failed to created instruction")
                        }
                        index =  index+1;
                        newIndex = index-1;
                      });
                    }else {
                      // user had choosen only add user option
                      if (response.type === "ADD_LOCKDEVICE_USER"){
                        enqueueSnackbar("Successfully created instruction")
                      }else {
                        enqueueSnackbar("Failed to created instruction")
                      }
                      index =  index+1;
                      newIndex = index-1;
                    }
                  }else {
                    enqueueSnackbar("Failed to created instruction")
                  }
                });
              })
            }
          }          
          if (index >= allScheduleInstructions.length){
            clearInterval(scheduleSetInterval);
          }
          newIndex++;          
        }, 1000);        
      }
    };

    const checkIsToday = (value) => {
      let givenDate = new Date(value).getDate();
      let givenMonth = new Date(value).getMonth() + 1;
      let givenYear = new Date(value).getFullYear();
      let currentMonth = new Date().getMonth() + 1;
      let currentDate = new Date().getDate();
      let currentYear = new Date().getFullYear();
      return givenDate + "-" + givenMonth + "-" + givenYear === currentDate + "-" + currentMonth + "-" + currentYear;  
    }

    const handleAddUserInstruction = () => {
      let selectedData = [];
      let modifiedData = [];
      let selectDataCopy = [];
      let allUsersDataCopy = Object.assign([],allUsersData);
      _.forEach(allUsersDataCopy, (value) => {
        if (value.selected){
          selectedData.push(value);          
          if (value.addUserAddType === "schedule" ? checkIsToday(value.addUserAddTime) : false || value.addUserAddType === "immediate"){
            if (value.addUserAddTime > 0){
              let currentYear = new Date().getFullYear();
              let currentDate = new Date().getDate();
              let currentMonth = new Date().getMonth() + 1;
              let addUserYear = new Date(value.addUserAddTime).getFullYear();
              let addUserDate = new Date(value.addUserAddTime).getDate();
              let addUserMonth = new Date(value.addUserAddTime).getMonth() + 1;
              if (currentDate + "-" + currentMonth + "-" + currentYear === addUserDate + "-" + addUserMonth + "-" + addUserYear) {
                selectDataCopy.push(value)  
              }
            }else {
              selectDataCopy.push(value)
            }
          }
        }
        value.selected = false;
        modifiedData.push(value);
      })
      setAllUsersData(modifiedData);
      window.addUsersAllData = modifiedData;
      createInstructionForAddingUser(selectedData);
      setShowAddUserDialog(false);    
      if (selectDataCopy.length > 0) {  
        props.close_users_dialog(null, selectDataCopy);      
      }else {
        props.close_users_dialog();      
      }
    }
    
    const handleCreateAddUserInstruction = () => {
      let selectedCardUserData = [];
      let selectedCardCsns = [];   
      let allCardCSNs = [];
      let allCardCSNwithEmailMapping = {};
      _.forEach(allUsersData, (value) => {
        if (value.selected && value.addUserType === "cardUser" && value.addUserPasscode !== "" && value.addUserPasscode !== null && value.cardCSN === null && value.addUserPasscode !== "SELF"){
          selectedCardUserData.push(value);
          selectedCardCsns.push(value.addUserPasscode);
        }
        if (value.cardCSN !== null && value.cardCSN !== "" ){
          allCardCSNs.push(value.cardCSN);
          allCardCSNwithEmailMapping[value.cardCSN] = value.email;
        }        
      });

      if (selectedCardUserData.length > 0){
        let alreadyCardCsnExist = false;
        let alreadyCSNExistData = [];
        _.forEach(selectedCardUserData,(user)=> {
          if (allCardCSNs.includes(user.addUserPasscode) || allCardCSNs.includes(user.addUserPasscode + "L")) {
            alreadyCardCsnExist = true;
            alreadyCSNExistData = [{"cardCSN" : user.addUserPasscode !== "" && user.addUserPasscode !== null && typeof user.addUserPasscode !== 'undefined' ? user.addUserPasscode.substring(0,10) : null , "email" : typeof allCardCSNwithEmailMapping[user.addUserPasscode] === 'undefined' ? allCardCSNwithEmailMapping[user.addUserPasscode + "L"] : allCardCSNwithEmailMapping[user.addUserPasscode]}];
          }
        })
        let counts = _.countBy(selectedCardCsns, _.identity);
        let repeatedStrings = _.filter(_.keys(counts), key => counts[key] > 1);
        if (!alreadyCardCsnExist) {
          if (repeatedStrings.length > 0){
            setCardCSNErrorData([{"repeatedString" : repeatedStrings[0]}]);
            setShowCardCsnExistAlertDialog(true);  
          }else {
            handleAddUserInstruction();
          }
        }else {
          setCardCSNErrorData(alreadyCSNExistData);
          setShowCardCsnExistAlertDialog(true);
        }            
      }else {
        handleAddUserInstruction();
      }
    };

    
    const addUsersSearchFilter = (text) => {
      let filteredData = [];
      setAddUserSearchFilterText(text);
      text = text !== null && text !== undefined && text !== null ? text.toLowerCase().replaceAll(" ","") : "";
      if (text !== ""){
        _.forEach(window.addUsersAllData, (value) => {
            if (value.email !== null && value.email !== "" && value.email !== undefined){
              if (value.email.toLowerCase().replaceAll(" ","").indexOf(text) !== -1){
                filteredData.push(value);
              }
            }
          })
      }else {
        filteredData = window.addUsersAllData;      
      }
      setAllUsersData(filteredData);
    }

    const getValidTime = (updatedAddTime) => {
      let updatedAddDate = new Date(updatedAddTime).getDate();
      let updatedAddMonth = new Date(updatedAddTime).getMonth() + 1;
      let updatedAddYear = new Date(updatedAddTime).getFullYear();
      let currentMonth = new Date().getMonth() + 1;
      let currentDate = new Date().getDate();
      let returnValue = "";
      if (updatedAddDate + "-" + updatedAddMonth === currentDate + "-" + currentMonth) {
        return new Date().getTime() + 1000 * 60 * 60;
      }else {
        return new Date(updatedAddMonth + "-" + updatedAddDate + "-" + updatedAddYear).getTime();
      }
    }

    const handleAddUserAddTime = (time, modifiedRow) => {      
      let users = [];
      let count = 0;
      let invalid = false;
      let currentYear = new Date().getFullYear();
      time = time ? getValidTime(time) : new Date().getTime() + 1000 * 60 * 60;
      _.forEach(allUsersData,(data)=> {
          if(data.id === modifiedRow.id){
            data.addUserAddTime = time;
            data.timeModifed = true;
          }
          if (data.selected){
            count+=1;
            if (data.passcodeError || data.addUserType === "role" || data.passcode === "") {
                invalid = true;
            }
          }         
          users.push(data)
      })
      setInvalidDataOnCheckAUser(invalid);
      setAllUsersData(users);
      window.addUsersAllData = users;
      setSelectedCount(count);
    }

    const handleCustomValueForScheduleDay = (day, modifiedRow) => {
      let users = [];
      if (day !== "" && day !== null && day) {
        _.forEach(allUsersData, (data) => {
          if (data.id === modifiedRow.id) {
            data.customValue = day;
          }
          users.push(data);
        })        
        setAllUsersData(users);
        window.addUsersAllData = users;
      }
    }

    const handleAddUserRemoveTime = (time, modifiedRow) => {
      let users = [];
      let count = 0;
      let invalid = false;
      let setInvalidRemoveTime = false;
      let currentYear = new Date(window.currentTime).getFullYear();
      let currentMonth = new Date(window.currentMonth).getMonth()+1;
      let currentDate = new Date(window.currentDate).getDate();
        if (time){
             let updatedYear = new Date(time).getFullYear();
             let updatedMonth = new Date(time).getMonth() + 1;
             let updatedDate = new Date(time).getDate();
             time = new Date(updatedYear + "-" + updatedMonth + "-" + updatedDate + " 00:00:00").getTime() + 86340000;
            }else {
             time = new Date(currentYear + "-" + currentMonth + "-" + currentDate + " 00:00:00").getTime() + 86340000;
           }
      _.forEach(allUsersData,(data)=> {
          if(data.id === modifiedRow.id){            
            data.addUserRemoveTime = time ;
            data.timeModified = true;
          }
          if (data.selected){
            count+=1;
            if (data.passcodeError || data.addUserType === "role" || data.passcode === "") {
                invalid = true;
            }
            if (data.enabledCheckBox ? new Date(data.addUserRemoveTime).getTime() < new Date(data.addUserAddTime).getTime() : false) {
              invalid = true;
              if (new Date(data.addUserRemoveTime).getTime() === new Date(data.addUserAddTime).getTime()) {
                setInvalidRemoveTime = true;
              }
            }            
          }          
          users.push(data)
      })
      setInvalidDataOnCheckAUser(invalid);
      setAllUsersData(users);
      window.addUsersAllData = users;
      setSelectedCount(count);
    }

    const changeEnableCheckBox = (checkedFlag, modifiedRow) => {
      let users = [];      
      _.forEach(allUsersData,(data)=> {
        if(data.id === modifiedRow.id){
          data.enabledCheckBox = checkedFlag;
        }
        users.push(data)
      })
      setAllUsersData(users);
      window.addUsersAllData = users;
    }

    const satisfyCondition = (row) => {
      return  row.scheduleAddTime && row.scheduleStartTime  ? new Date(row.scheduleAddTime).getTime() >= new Date(row.scheduleStartTime).getTime() : false || row.scheduleStartTime ? new Date(row.scheduleStartTime).getTime() <= new Date(currentTime).getTime() : true ; 
    }

    const changeRemoveScheduleCheckBox = (flag, modifiedRow) => {      
      _.forEach(LocksData,(val)=>{
        if (val.instructionId === modifiedRow.instructionId) {
          val.enabledCheckBox = flag;
          val.removeRemovalType = "immediate";
        }
      })
      window.lockAssignedUsers = LocksData;
    }

    return (
        <>
        {
          !props.hasOwnProperty("showDirectlyAddUsersDialog") ?
            <Dialog
              fullWidth={true}
              maxWidth="xl"
              fullScreen={fullScreen}
              open={props.show_users_dialog}
              onClose={props.close_users_dialog}
            >
              <DialogTitle
                style={{
                  color: 'white',
                  fontSize: '20px',
                  backgroundColor: 'rgb(20,19,139)',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Box id="draggable-dialog-title" style={{marginRight: 'auto'}}>
                  Users of {props.data.name}
                </Box>
                <span style={{marginTop: '6px'}}>
                  <Close onClick={props.close_users_dialog} />
                </span>
              </DialogTitle>
              <DialogContent sx={{height:'100%'}}>
                    <Box style={{width: '100%', height: 'inherit'}}>
                    <TableContainer sx={{height:'inherit'}}>
                      <Table>
                        <EnhancedTableHead
                          numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          onSelectAllClick={(checked) => handleSelectAllClick(checked)}
                          onRequestSort={handleRequestSort}
                          rowCount={LocksData.length}
                          rowSelected={selectedCount}
                          showCheckBox={props.data.authStatus === "AUTH_SUCCESS"}
                        />
                        <TableBody className="addUsers">
                          {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                            rows.slice().sort(getComparator(order, orderBy)) */}
                          {stableSort(LocksData, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                              const isItemSelected = isSelected(row.name);
                              const labelId = `enhanced-table-checkbox-${index}`;

                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  aria-checked={isItemSelected}
                                  tabIndex={-1}
                                  key={row.id}
                                  selected={new Date(row.scheduleAddTime).getTime() == new Date(currentTime).getTime() || new Date(row.scheduleRemoveTime).getTime() == new Date(currentTime).getTime()}
                                >
                                  {
                                    props.data.authStatus === "AUTH_SUCCESS" ?
                                      <TableCell
                                        sx={{
                                          padding: '16px',
                                          width:"10px !important",
                                          fontSize: '13px'
                                        }}
                                      >
                                        <Checkbox
                                          color="warning"
                                          disabled={row.scheduleAddTime ? moment(row.scheduleAddTime).format("DD MMM YYYY,hh:mm A") === moment(currentTime).format("DD MMM YYYY,hh:mm A") && !row.selected : false || row.scheduleRemoveTime ? moment(row.scheduleRemoveTime).format("DD MMM YYYY,hh:mm A") === moment(currentTime).format("DD MMM YYYY,hh:mm A") && !row.selected : false}
                                          checked={row.selected}
                                          onClick={(event)=>unCheck(row, event.target.checked)}
                                          sx={{margin: '0px', padding: '0px',left: "-3px",
                                                display: JSON.parse(sessionStorage.userDetails).permissions.includes("GATEWAY_INSTRUCTIONS_WRITE") ? 'flex' : 'none'
                                            }}
                                        />
                                      </TableCell>
                                    :
                                      null
                                  }
                                  <TableCell align="center">
                                    {row.fullName}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.email ? row.email : 'No Email'}
                                  </TableCell>
                                  <TableCell align="center" sx={{width:"56px !important"}}>{row.role === "CARD_USER"? row.passcode.toString().substring(0,10).toUpperCase() : row.passcode }</TableCell>
                                  <TableCell align="center">{row.role}</TableCell>
                                  <TableCell align="center">
                                    {
                                      row.hasOwnProperty("scheduleAddTime") ?
                                        !row.selected ?
                                          <Box sx={{display:"flex",flexDirection:"column"}}>
                                            <Box>
                                              <Tooltip
                                                  title={moment(row.scheduleAddTime).format("DD MMM YYYY,hh:mm A") === moment(currentTime).format("DD MMM YYYY,hh:mm A") ? "Please wait, instruction is pending" :"Click to edit"}
                                                  placement="top"
                                                  arrow
                                                >
                                                  {
                                                    moment(row.scheduleAddTime).format("DD MMM YYYY,hh:mm A") === moment(currentTime).format("DD MMM YYYY,hh:mm A") ?
                                                       <Refresh className="instruction_pending_icon" sx={{height:"20px",width:"20px",cursor:"pointer"}} />
                                                    :
                                                      <AlarmAdd sx={{color:"red",cursor:'pointer',height:"20px",width:"20px"}} onClick={()=> {
                                                        if (props.data.authStatus === "AUTH_SUCCESS")
                                                          unCheck(row,true)
                                                      }} />
                                                  }
                                              </Tooltip>
                                            </Box>
                                            <Box>
                                                {moment(row.scheduleAddTime).format("DD MMM YYYY")} at {moment(row.scheduleAddTime).format("hh:mm A")}
                                            </Box>
                                          </Box>
                                        :
                                          <Box className="datePicker" sx={{display:"flex",flexDirection:"column"}}>
                                            <Select variant="standard" value={row.addRemovalType} onChange={(event)=>handleAddRemovalTypeChange(event, row)}>
                                              <MenuItem value="immediate">
                                                Add Immediately 
                                              </MenuItem>
                                              <MenuItem value="schedule">
                                                Schedule Add Time 
                                              </MenuItem>
                                              <MenuItem value="cancelSchedule">
                                                Cancel Schedule Add Time
                                              </MenuItem>
                                            </Select>
                                            {
                                              row.addRemovalType !== "cancelSchedule" && row.addRemovalType !== "immediate" ?
                                                <>
                                                  <TextField
                                                    id="date"
                                                    type="date"
                                                    variant="standard"
                                                    value={moment(row.scheduleAddTime).format("yyyy-MM-DD")}
                                                    sx={{ width: "calc(100% - 5px)" }}
                                                    InputLabelProps={{
                                                      shrink: true,
                                                    }}
                                                    inputProps={{
                                                      min: moment(currentTime).format("yyyy-MM-DD")
                                                    }}
                                                    onChange={(event)=>handleScheduleAdd(event.target.value,row)}
                                                  />
                                                  {
                                                    new Date(row.scheduleAddTime).getTime() <= new Date(currentTime).getTime() && row.addRemovalType !== "cancelSchedule"  ?
                                                      <Box sx={{position:"relative"}}>
                                                        <Tooltip title="Addition time should be greater than current time" placement='left' arrow>
                                                          <ReportProblem sx={{fontSize: '16px',top: '-21px',right: '-11px',position: 'absolute',color:"red"}}/>
                                                        </Tooltip>
                                                      </Box>
                                                    :
                                                      null
                                                  }
                                                </>
                                              :
                                                null
                                            }
                                          </Box>
                                      :
                                        <Tooltip
                                          title="User is already added to the lock"
                                          placement="top"
                                          arrow
                                        >
                                          <Check
                                            style={{
                                              color: '#14138B',
                                              cursor: 'pointer',
                                            }}
                                            onClick={() => setSearchBar(true)}
                                          />
                                        </Tooltip>
                                    }
                                  </TableCell>
                                  <TableCell align="center" sx={{textTransform:'uppercase'}}>{row.userRemoveType}</TableCell>
                                  <TableCell align="center">
                                    {
                                      !row.isSchedule && row.selected && !row.hasOwnProperty("scheduleRemoveTime") && (row.addRemovalType === "cancelSchedule" || row.addRemovalType === "immediate") ?
                                        <Box className="datePicker" sx={{display:"flex",flexDirection:"column"}}>
                                          <Select variant="standard" value={row.removeRemovalType} onChange={(event)=>handleRemovalTypeChange(event, row)}>
                                            <MenuItem value="immediate">
                                              Remove Immediately
                                            </MenuItem>
                                            <MenuItem value="schedule">
                                              Schedule Removal Time
                                            </MenuItem>
                                          </Select>
                                          {
                                            row.removeRemovalType === "schedule" ?
                                              <>
                                                <TextField
                                                  type="date"
                                                  variant="standard"
                                                  value={moment(row.scheduleStartTime).format("yyyy-MM-DD")}
                                                  sx={{ width: '100%' }}
                                                  InputLabelProps={{
                                                    shrink: true,
                                                  }}
                                                  inputProps={{
                                                    min: row.hasOwnProperty("scheduleAddTime") ? moment(row.scheduleAddTime).format("yyyy-MM-DD") : moment(currentTime).format("yyyy-MM-DD")
                                                  }}
                                                  onChange={(event) => handleScheduleStartTimeChange(event.target.value, row)}
                                                />
                                                {
                                                  row.addRemovalType !== "cancelSchedule" && row.addRemovalType !== "immediate" ?
                                                    satisfyCondition(row)  ?
                                                      <Box sx={{position:"relative"}}>
                                                        <Tooltip title={row.removeRemovalType === "schedule" ? "Removal time should be greater than current time and scheduled add time" : null} placement='left' arrow>
                                                          <ReportProblem sx={{fontSize: '16px',top: '-23px',right: '-15px',position: 'absolute',color:"red"}}/>
                                                        </Tooltip>
                                                      </Box>
                                                    :
                                                      null
                                                  :
                                                    null
                                                }
                                              </>
                                            :
                                              null
                                          }
                                        </Box>
                                      :
                                          row.hasOwnProperty("scheduleRemoveTime") && !row.selected ?
                                            <Box sx={{display:"flex",flexDirection:"column"}}>
                                              <Box>
                                                <Tooltip
                                                  title={moment(row.scheduleRemoveTime).format("DD MMM YYYY,hh:mm A") === moment(currentTime).format("DD MMM YYYY,hh:mm A") ?  "Please wait, instruction is pending" :"User will be removed on "+ moment(row.scheduleRemoveTime).format("DD MMM YYYY") + " at "+ moment(row.scheduleRemoveTime).format("hh:mm A")}
                                                  placement="top"
                                                  arrow
                                                >
                                                    {
                                                      moment(row.scheduleRemoveTime).format("DD MMM YYYY,hh:mm A") === moment(currentTime).format("DD MMM YYYY,hh:mm A") ?
                                                       <Refresh className="instruction_pending_icon" sx={{height:"20px",width:"20px",cursor:"pointer"}} />
                                                    :
                                                      <AlarmAdd sx={{color:"red",cursor:'pointer',height:"20px",width:"20px"}} onClick={()=>
                                                        {
                                                          if (props.data.authStatus === "AUTH_SUCCESS")
                                                            unCheck(row,true)}
                                                        } />
                                                    }
                                                </Tooltip>
                                              </Box>
                                              <Box>
                                                  {moment(row.scheduleRemoveTime).format("DD MMM YYYY")} at {moment(row.scheduleRemoveTime).format("hh:mm A")}
                                              </Box>
                                            </Box>
                                        :
                                          row.hasOwnProperty("scheduleRemoveTime") && row.selected ?
                                            <Box className="datePicker" sx={{display:"flex",flexDirection:"column"}}>
                                              <Select variant="standard" value={row.removeRemovalType} onChange={(event)=>handleRemoveRemovalTypeChange(event, row)}>
                                                <MenuItem value="immediate">
                                                  Remove Immediately
                                                </MenuItem>
                                                <MenuItem value="schedule">
                                                  Schedule Removal Time
                                                </MenuItem>
                                                <MenuItem value="cancelSchedule">
                                                  Cancel Schedule Removal Time
                                                </MenuItem>
                                              </Select>
                                              {
                                                row.removeRemovalType !== "cancelSchedule" && row.removeRemovalType !== "immediate"  ?
                                                  <TextField
                                                    type="date"
                                                    variant="standard"
                                                    value={moment(row.scheduleRemoveTime).format("yyyy-MM-DD")}
                                                    sx={{ width: "calc(100% - 5px)" }}
                                                    InputLabelProps={{
                                                      shrink: true,
                                                    }}
                                                    inputProps={{
                                                      min: moment(row.scheduleAddTime).format("yyyy-MM-DD")
                                                    }}
                                                    onChange={(event)=>handleScheduleRemove(event.target.value,row)}
                                                  />
                                                :
                                                  null
                                              }
                                              {
                                                  (new Date(row.scheduleRemoveTime).getTime() <= new Date(row.scheduleAddTime).getTime() || new Date(row.scheduleRemoveTime).getTime() <= new Date(currentTime).getTime() ) && row.removeRemovalType !== "cancelSchedule" ?
                                                    <Box sx={{position:"relative"}}>
                                                      <Tooltip title={row.removeRemovalType === "schedule" ? "Remove time should be greater than current time and scheduled add time" : null} placement='left' arrow>
                                                        <ReportProblem sx={{fontSize: '16px',top: '-21px',right: '-11px',position: 'absolute',color:"red"}}/>
                                                      </Tooltip>
                                                    </Box>
                                                  :
                                                    null
                                                }
                                            </Box>
                                          :
                                            row.selected && !row.hasOwnProperty("scheduleRemoveTime") && row.hasOwnProperty("scheduleAddTime") ?
                                              <Box className="datePicker" sx={{display:"flex",flexDirection:"column",position:"relative"}}>
                                                <Checkbox
                                                  inputProps={{
                                                    'aria-label': 'select remove option',
                                                  }}
                                                  color="success"
                                                  checked={row.enabledCheckBox}                                             
                                                  className="userDialogRemoveScheduleCheckBox"
                                                  onClick={(event)=> changeRemoveScheduleCheckBox(event.target.checked,row)}
                                                  sx={{
                                                    position: 'absolute',
                                                    top: row.enabledCheckBox ? (row.removeRemovalType === "immediate" ? "0px" : "10px") : "0px",
                                                    left: "-37px"
                                                  }}
                                                />
                                                {
                                                  row.enabledCheckBox ?
                                                    <>
                                                      <Select variant="standard" value={row.removeRemovalType} onChange={(event)=>handleRemovalTypeChange(event, row)}>
                                                        <MenuItem value="immediate">
                                                          Remove Immediately
                                                        </MenuItem>
                                                        <MenuItem value="schedule">
                                                          Schedule Removal Time
                                                        </MenuItem>
                                                      </Select>
                                                      {
                                                        row.removeRemovalType === "schedule" ?
                                                          <>
                                                            <TextField
                                                              type="date"
                                                              variant="standard"
                                                              value={moment(row.scheduleStartTime).format("yyyy-MM-DD")}
                                                              sx={{ width: '100%' }}
                                                              InputLabelProps={{
                                                                shrink: true,
                                                              }}
                                                              inputProps={{
                                                                min: row.hasOwnProperty("scheduleAddTime") ? moment(row.scheduleAddTime).format("yyyy-MM-DD") : moment(currentTime).format("yyyy-MM-DD")
                                                              }}
                                                              onChange={(event) => handleScheduleStartTimeChange(event.target.value, row)}
                                                            />
                                                            {
                                                              satisfyCondition(row) ?
                                                                <Box sx={{position:"relative"}}>
                                                                  <Tooltip title={row.removeRemovalType === "schedule" ? "Removal time should be greater than current time and scheduled add time" : null} placement='left' arrow>
                                                                    <ReportProblem sx={{fontSize: '16px',top: '-23px',right: '-15px',position: 'absolute',color:"red"}}/>
                                                                  </Tooltip>
                                                                </Box>
                                                              :
                                                                null
                                                            }
                                                          </>
                                                        :
                                                          null
                                                      }
                                                    </>
                                                  :
                                                    <Select disabled value="1" variant="standard">
                                                      <MenuItem value="1">
                                                        Remove Immediately
                                                      </MenuItem>
                                                    </Select>                                                      
                                                }
                                              </Box>
                                            :
                                              <>-</>
                                    }
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>

                          {
                            LocksData.length === 0 && !LocksDataIsLoading ?
                              <TableBody className="fixedBody">
                                <TableCell colSpan={7} sx={{borderBottom: '1px solid transparent !important'}}>
                                  <Box sx={{display:"flex",flexDirection:"row",width:"100%",height:"20px",alignItems:"center",justifyContent:"center"}}>
                                    <Box sx={{position:"relative",top:"10px"}}>
                                      No users
                                    </Box>
                                  </Box>
                                </TableCell>
                              </TableBody>
                          :
                            LocksData.length === 0 && LocksDataIsLoading ?
                              <TableBody className="fixedBody">
                                <TableCell colSpan={7} sx={{borderBottom: '1px solid transparent !important'}}>
                                  <Box sx={{display:"flex",flexDirection:"row",width:"100%",height:"20px",alignItems:"center",justifyContent:"center"}}>
                                    <Box sx={{position:"relative",top:"10px"}}>
                                      <CircularProgress thickness="4" sx={{height:'26px !important',width: '26px !important',color: 'rgb(20,19,139) !important'}}/>
                                    </Box>
                                  </Box>
                                </TableCell>
                              </TableBody>
                            :
                              null
                        }
                      </Table>
                    </TableContainer>
                  </Box>
              </DialogContent>
              <Box sx={{borderBottom:"1px solid rgba(224, 224, 224, 1)",margin:"0px 24px"}}></Box>
              {
                props.data.authStatus === "AUTH_SUCCESS" ?
                  <Box className="addUsersDiv" sx={{width:"100%"}}>
                      <Box sx={{fontSize:'14px',display:"flex",alignItems:'center',marginLeft:'19px'}}>* Value displayed in hex</Box>
                      <Button className="addUsersButton" sx={{marginRight:"10px",color:"#c21858",display: JSON.parse(sessionStorage.userDetails).permissions.includes("GATEWAY_INSTRUCTIONS_WRITE") ? 'flex' : 'none'}} onClick={()=>handleShowAddUserDialog()}>
                        Add Users
                      </Button>
                      <Button disabled={selectedCount <= 0 || scheduleStartTimeIsPast || schduleAddTimeIsGraterThanRemoveTime || scheduleRemoveTimeIsIncorrect || scheduleRemoveTimeIsCurretTime} sx={{marginRight:"10px",color:"#c21858",display: JSON.parse(sessionStorage.userDetails).permissions.includes("GATEWAY_INSTRUCTIONS_WRITE") ? 'flex' : 'none'}} onClick={()=>handleRemoveUser()}>
                        Done  
                      </Button>
                      <br/>
                  </Box>
                :
                  null
              }
              <DialogActions>
                  <TablePagination
                    sx={{width: '100%', overflowX: 'hidden'}}
                    labelRowsPerPage={rowPerPageLabel}
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    className="pagination"
                    count={LocksData.length}
                    rowsPerPage={rowsPerPage}
                    page={pageNumber}
                    SelectProps={{
                      inputProps: {'aria-label': 'rows per page'},
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
              </DialogActions>
            </Dialog>
          :
            null
        }
        {
          showAddUserDialog ?
              <Dialog
                fullWidth={true}
                maxWidth="xl"                
                fullScreen={fullScreen}
                open={showAddUserDialog}
                onClose={() => {
                  setShowAddUserDialog(false)
                  if (props.hasOwnProperty("showDirectlyAddUsersDialog"))
                      props.close_users_dialog();
                }}
              >
                <DialogTitle
                  style={{
                    color: 'white',
                    fontSize: '20px',
                    backgroundColor: 'rgb(20,19,139)',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Box id="draggable-dialog-title-" style={{marginRight: 'auto'}}>
                    Add User
                  </Box>
                  <TextField
                    placeholder='Search ...'
                    className='searchFilterAddUser'
                    variant="standard"
                    value={addUserSearchFilterText}
                    onChange={(event)=>addUsersSearchFilter(event.target.value)}
                  />
                  <span style={{marginTop: '6px'}}>
                    <Close onClick={()=>{
                      setShowAddUserDialog(false);
                      setAddUserSearchFilterText("");
                      if (props.hasOwnProperty("showDirectlyAddUsersDialog"))
                        props.close_users_dialog();
                    }} />
                  </span>
                </DialogTitle>
                <DialogContent>
                    {!isAddUserDialogDataLoading ?
                      <TableContainer sx={{minHeight:"150px"}}>
                        <Table>
                          <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={(checked) => handleSelectAllClick(checked)}
                            onRequestSort={handleRequestSort}
                            rowCount={LocksData.length}
                            rowSelected={selectedCount}
                            addUserDialog={true}
                            showCheckBox={props.data.authStatus === "AUTH_SUCCESS" && !props.hasOwnProperty("showDirectlyAddUsersDialog")}
                          />
                          <TableBody className='addUsers'>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                              rows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(allUsersData, getComparator(order, orderBy))
                              .slice(addUserPage * addUserRowsPerPage, addUserPage * addUserRowsPerPage + addUserRowsPerPage)
                              .map((row, index) => {
                                const isItemSelected = isSelected(row.name);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"                                    
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.id}
                                    selected={isItemSelected}
                                  >
                                    {
                                      props.data.authStatus === "AUTH_SUCCESS" ?
                                        <TableCell
                                          sx={{
                                            padding: '16px',
                                            width:"10px !important",
                                            fontSize: '13px !important'
                                          }}
                                        >
                                          <Checkbox
                                            color="success"
                                            checked={row.selected}
                                            onClick={(event)=>unCheckUsers(row, event)}
                                            sx={{margin: '0px', padding: '0px',
                                                display: JSON.parse(sessionStorage.userDetails).permissions.includes("GATEWAY_INSTRUCTIONS_WRITE") ? 'flex' : 'none',
                                                left: "-3px"}}
                                          />
                                        </TableCell>
                                      :
                                        null
                                    }
                                    <TableCell align="left">
                                      {row.email}
                                    </TableCell>
                                    <TableCell align="left" >
                                      {
                                        (props.data.type ? props.data.type.indexOf("RFID") !== -1 : false) ?
                                          <Select disabled={!row.selected} sx={{width:"100%",height:"24px"}} variant="standard" value={row.addUserType} onChange={(event)=>handleAddUserType(event,row)}>
                                            <MenuItem disabled value="role">
                                              Role
                                            </MenuItem>
                                            <MenuItem value="admin">
                                              Admin
                                            </MenuItem>
                                            <MenuItem value="keypadUser">
                                              Keypad User
                                            </MenuItem>
                                            <MenuItem value="cardUser">
                                              Card User
                                            </MenuItem>
                                            <MenuItem value="self">
                                              Self User
                                            </MenuItem>                                            
                                          </Select>
                                        :
                                          <Select disabled={!row.selected} sx={{width:"100%",height:"24px"}} variant="standard" value={row.addUserType} onChange={(event)=>handleAddUserType(event,row)}>
                                            <MenuItem disabled value="role">
                                              Role
                                            </MenuItem>
                                            <MenuItem value="admin">
                                              Admin
                                            </MenuItem>
                                            <MenuItem value="keypadUser">
                                              Keypad User
                                            </MenuItem>                                            
                                          </Select>
                                      }
                                    </TableCell>
                                    <TableCell align="left">
                                      <Select variant="standard" disabled={!row.selected} sx={{width:"100%",height:"24px"}} value={row.addUserRemovalType} onChange={(event) => handleAddUserRemoveType(event, row)}>
                                        <MenuItem value="normal">
                                          Normal
                                        </MenuItem>
                                        <MenuItem value="automatic">
                                          Automatic
                                        </MenuItem>
                                      </Select>
                                    </TableCell>
                                    <TableCell align="left" sx={{position:'relative'}}>
                                      <TextField 
                                        type="text"
                                        variant="standard"
                                        class="addUserPasscode"
                                        disabled={!row.selected || row.addUserType === "role" || (row.addUserType === "cardUser" && row.cardCSN !== null) || row.addUserType === "self"}
                                        value={row.addUserPasscode}                                        
                                        sx={{textTransform:'uppercase'}}
                                        onChange={(event)=> handleAddUserPasscode(event, row)}
                                      />
                                      {
                                        row.passcodeError && row.selected ?
                                          <Tooltip title={props.data.type === "LO_DB" || props.data.type === "LO_SL" ? "Allowed chars 0 to 9. Admin should have 5 digits and User should have 4 digits code." : row.addUserType === "cardUser" ? "Allowed chars 0 to 9 or A to F.Card user should have 10 digit code" : "Allowed chars 1 to 4. Admin should have 5 digits and User should have 4 digit code"} placement='top' arrow>
                                            <ReportProblem sx={{fontSize: '16px',top: '24px',right: '18px',position: 'absolute',color:"red"}}/>
                                          </Tooltip>
                                        :
                                          null
                                      }
                                    </TableCell>
                                    <TableCell align="left">
                                      <Select variant="standard" disabled={!row.selected}  sx={{width:"100%",height:"24px"}} value={row.addUserAddType} onChange={(event) => handleAddUserAddType(event,row)}>
                                        <MenuItem value="immediate">
                                          Immediate
                                        </MenuItem>
                                        <MenuItem value="schedule">
                                          Schedule
                                        </MenuItem>
                                      </Select>
                                    </TableCell>
                                    <TableCell align="center">
                                      {
                                        row.selected ?
                                          <Box className="datePicker" sx={{display:"flex",flexDirection:"column"}}>
                                            {
                                              row.addUserAddType === "schedule" ?
                                              <Box sx={{display:"flex",flexDirection:"column"}}>
                                                <Box sx={{display:"flex",flexDirection:"row"}}>
                                                  <Box sx={{paddingRight:"31px",marginTop:"4px",color:"green"}}>
                                                    Add
                                                  </Box>                                                                                                   
                                                  <TextField
                                                    type="date"    
                                                    variant="standard"     
                                                    disabled={!row.selected}
                                                    value={moment(row.addUserAddTime).format("yyyy-MM-DD")} 
                                                    onChange = {(event) => handleAddUserAddTime(event.target.value,row)}
                                                    sx={{ width: 150 }}
                                                    InputLabelProps={{
                                                      shrink: true,                                                                                                            
                                                    }}
                                                    inputProps={{
                                                      min: moment(currentTime).format("yyyy-MM-DD")
                                                    }}
                                                  />                                                  
                                                  {
                                                    row.addUserAddTime ?
                                                      new Date(row.addUserAddTime).getTime() <= new Date(currentTime).getTime() ?
                                                        <Box sx={{position:"relative"}}>
                                                          <Tooltip title="Add time should be greater than current time" placement='left' arrow>
                                                            <ReportProblem sx={{fontSize: '16px',top: '5px',right: '-23px',position: 'absolute',color:"red"}}/>
                                                          </Tooltip>
                                                        </Box>
                                                      :
                                                        null
                                                    :
                                                      null
                                                  }
                                                  {/* <PopupState variant="popover" popupId="demo-popup-popover">
                                                    {(popupState) => (
                                                      <Box sx={{marginLeft:'28px',position: "relative",top: "2px",left: "21px"}}>                                                  
                                                        <ArrowDropDownIcon
                                                            sx={{float: 'left', marginLeft: '-26px',cursor:"pointer"}}
                                                            {...bindTrigger(popupState)}
                                                        />
                                                        <Popover
                                                          {...bindPopover(popupState)}
                                                          anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'right',
                                                          }}
                                                          transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'right',
                                                          }}
                                                          sx={{top:"4px !important"}}
                                                        >
                                                          <Box sx={{display:'flex',flexDirection:'column'}}>
                                                              <Box sx={{width: "214px", height: '148px'}}>
                                                                  {/* <FormControl variant="standard" sx={{width:"100%"}}>                                                                 */}
                                                                    {/* <MenuItem value="3600000" onClick={()=> {
                                                                      let addTime = moment(new Date().getTime() + 1 * 60 * 1000).format("yyyy-MM-DDTHH:mm");
                                                                      let removeTimeInMillis = new Date().getTime() + 3600000 + 1 * 60 * 1000;
                                                                      let removeTime = moment(removeTimeInMillis).format("yyyy-MM-DDTHH:mm");
                                                                      handleAddUserAddTime(addTime,row);
                                                                      handleAddUserRemoveTime(removeTime,row)
                                                                      changeEnableCheckBox(true,row);
                                                                      popupState.close();
                                                                    }}>an hour</MenuItem>
                                                                    <MenuItem value="21600000" onClick={()=> {
                                                                      let addTime = moment(new Date().getTime() + 1 * 60 * 1000).format("yyyy-MM-DDTHH:mm");
                                                                      let removeTimeInMillis = new Date().getTime() + 21600000 + 1 * 60 * 1000;
                                                                      let removeTime = moment(removeTimeInMillis).format("yyyy-MM-DDTHH:mm");
                                                                      handleAddUserAddTime(addTime,row);
                                                                      handleAddUserRemoveTime(removeTime,row)
                                                                      changeEnableCheckBox(true,row);
                                                                      popupState.close();
                                                                    }} >in 6 hours</MenuItem>
                                                                    <MenuItem value="43200000" onClick={()=> {
                                                                      let addTime = moment(new Date().getTime() + 1 * 60 * 1000).format("yyyy-MM-DDTHH:mm");
                                                                      let removeTimeInMillis = new Date().getTime() + 21600000 + 1 * 60 * 1000;
                                                                      let removeTime = moment(removeTimeInMillis).format("yyyy-MM-DDTHH:mm");
                                                                      handleAddUserAddTime(addTime,row);
                                                                      handleAddUserRemoveTime(removeTime,row)
                                                                      changeEnableCheckBox(true,row);
                                                                      popupState.close();                                                                
                                                                    }}>in 12 hours</MenuItem>
                                                                    <Box>
                                                                      <Radio size="small"/>
                                                                      <Box sx={{height:"40px"}}>
                                                                        <Box sx={{marginLeft:"10px",fontSize:'15px'}}>
                                                                          for <input type="number" value={row.customValue} max={60} min={1} defaultValue={1} onChange={(event)=> {                                                                                                                                                                                                      
                                                                            handleCustomValueForScheduleDay(event.target.value,row);
                                                                          }} style={{width:"43px",margin:"0px 5px"}}/> {row.customValue > 1 ? "days" : "day" }
                                                                          <CheckCircleIcon sx={{cursor:"pointer",position:"relative",top:"6px", color: row.customValue >= 1 && row.customValue <= 60 ? "#008000" : "#b5c8cf"}} onClick={()=>{                                                   
                                                                            if (row.customValue > 0 && row.customValue <=60){
                                                                              let addTime = moment(new Date().getTime() + 1 * 60 * 1000).format("yyyy-MM-DDTHH:mm");
                                                                              let removeTimeInMillis = new Date().getTime() + (21600000 * 4 * row.customValue) + (1 * 60 * 1000);
                                                                              let removeTime = moment(removeTimeInMillis).format("yyyy-MM-DDTHH:mm");
                                                                              handleAddUserAddTime(addTime,row);
                                                                              handleAddUserRemoveTime(removeTime,row)
                                                                              changeEnableCheckBox(true,row);
                                                                            }                                                                      
                                                                            popupState.close()
                                                                          }}/>
                                                                        </Box>
                                                                      </Box>
                                                                      </Box>
                                                                      <Box>
                                                                        <Radio size="small"/>
                                                                        <Box sx={{height:"40px"}}>
                                                                          <Box sx={{marginLeft:"10px"}}>                                                                        
                                                                            Start Date <input type="text" placeholder='DD-MM-YYYY'  style={{width:"114px"}}/> <br/>
                                                                            End Date <input type="text" placeholder='DD-MM-YYYY' style={{width:"114px"}}/>
                                                                          </Box>
                                                                        </Box>
                                                                      </Box>
                                                                  {/*</FormControl>
                                                              </Box>
                                                          </Box>
                                                        </Popover>
                                                      </Box>
                                                      )}
                                                  </PopupState> */}
                                                </Box>
                                                <Box sx={{display:"flex",flexDirection:"row",position:'relative'}}>
                                                  <Checkbox
                                                    inputProps={{
                                                      'aria-label': 'select remove option',
                                                    }}
                                                    color="success"
                                                    checked={row.enabledCheckBox}                                             
                                                    className="userDialogRemoveScheduleCheckBox"
                                                    onClick={(event)=> changeEnableCheckBox(event.target.checked,row)}
                                                    sx={{
                                                      position: 'absolute',
                                                      top: "-3px",
                                                      left: "-30px"
                                                    }}
                                                  />
                                                  {
                                                    row.enabledCheckBox ?
                                                      <>
                                                        <Box sx={{paddingRight:"4px",marginTop:"4px",color:"green"}}>
                                                          Remove
                                                        </Box>
                                                        <TextField
                                                          id="datetime-local"
                                                          type="date"    
                                                          variant="standard"     
                                                          disabled={!row.selected}                                                    
                                                          value={moment(new Date(row.addUserRemoveTime)).format("yyyy-MM-DD")} 
                                                          onChange = {(event) => {
                                                            handleAddUserRemoveTime(event.target.value,row)
                                                          }}
                                                          sx={{ width: 150 }}
                                                          InputLabelProps={{                                                                                                            
                                                            shrink: true,
                                                          }}
                                                          inputProps={{
                                                            min: moment(row.addUserAddTime).format("yyyy-MM-DD"),
                                                          }}
                                                        />
                                                        {
                                                          row.addUserRemoveTime ?
                                                            new Date(row.addUserRemoveTime).getTime() <= new Date(row.addUserAddTime).getTime() ?
                                                              <Box sx={{position:"relative"}}>
                                                                <Tooltip title="Remove time should be greater than add time and current time" placement='left' arrow>
                                                                  <ReportProblem sx={{fontSize: '16px',top: '5px',left:'8px',right: '-23px',position: 'absolute',color:"red"}}/>
                                                                </Tooltip>
                                                              </Box>
                                                            :
                                                              null
                                                          :
                                                            null
                                                        }
                                                      </>
                                                    :
                                                      <>
                                                        <Box sx={{paddingRight:"4px",marginTop:"4px",color:"green"}}>
                                                          Remove
                                                        </Box>
                                                        <TextField
                                                          id="date"
                                                          type="date"    
                                                          variant="standard"     
                                                          disabled={true}
                                                          value={moment(null).format("yyyy-MM-DD")}
                                                          sx={{ width: 150 }}
                                                          InputLabelProps={{                                                                                                            
                                                            shrink: true,
                                                          }}
                                                        />                                                        
                                                      </>
                                                  }
                                                </Box>                                                
                                              </Box>
                                              :
                                                null
                                            }
                                          </Box>
                                        :
                                          <>-</>
                                      }
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>    
                      :
                        <Box sx={{width:'100%',display:'flex',justifyContent: 'center',alignItems:'center',height:'100%',padding:"20px"}}>
                          <CircularProgress thickness="5" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                        </Box>
                      }
                </DialogContent>                
                <DialogActions sx={{display:"flex",flexDirection:"column"}}>                              
                    <TablePagination
                      sx={{width: '100%', overflowX: 'hidden'}}
                      labelRowsPerPage={addUserRowPerPageLabel}
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      className="pagination"
                      count={allUsersData.length}
                      rowsPerPage={addUserRowsPerPage}
                      page={addUserPageNumber}
                      SelectProps={{
                        inputProps: {'aria-label': 'rows per page'},
                        native: true,
                      }}
                      onPageChange={addUsersHandleChangePage}
                      onRowsPerPageChange={adduserHandleChangeRowsPerPage}
                      ActionsComponent={AddUserTablePaginationActions}
                    />
                    <Box sx={{display:"flex",flexDirection:"row",width:"100%"}}>
                      {
                        !props.hasOwnProperty("showDirectlyAddUsersDialog") ?
                          <Button sx={{marginRight:"auto",color:"#c21858"}} onClick={()=>{setShowAddUserDialog(false);
                          setAddUserSearchFilterText("");
                          }}>
                            Back
                          </Button>
                        :
                          null
                      }
                      <Button sx={{marginLeft: !props.hasOwnProperty("showDirectlyAddUsersDialog") ? "0px" : 'auto',marginRight:"10px",color:"#c21858",display: JSON.parse(sessionStorage.userDetails).permissions.includes("IOT_USER_WRITE") ? 'flex' : 'none'}} onClick={() => {
                        setShowAddUserDialog(false);
                        props.openCreateUserDialog();
                        if (props.hasOwnProperty("showDirectlyAddUsersDialog"))
                          props.close_users_dialog();
                      }}>
                        Create user
                      </Button>
                      <Button disabled={addUsersSelectedCount <= 0 || passcodeConditionFailed || scheduleAddTimeIsPast || invalidDataOnCheckAUser} sx={{marginRight:"10px",color:"#c21858"}} onClick={()=> handleCreateAddUserInstruction()} >
                        Add
                      </Button>
                  </Box>
                </DialogActions>
              </Dialog>
          :
            null
        }
        {
          showCardCsnExistAlertDialog ?
            <Dialog
                fullWidth={true}
                maxWidth="md"                
                fullScreen={fullScreen}
                open={showCardCsnExistAlertDialog}    
                onClose={()=> {
                  setShowCardCsnExistAlertDialog(false);
                  setCardCSNErrorData([]);
                }}                            
                aria-labelledby="draggable-dialog-title"
            >
              <DialogTitle>
                <Box id="draggable-dialog-title" style={{marginRight: 'auto'}}>
                  Error!
                </Box>
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                    {
                      cardCSNErrorData.length > 0  ?
                        cardCSNErrorData[0].hasOwnProperty("repeatedString") ?
                          <>
                            Same card <b>{cardCSNErrorData[0]["repeatedString"]}</b> cannot be assigned to multiple users. Please check.
                          </>
                        :
                          <>
                            This card <b>{cardCSNErrorData[0].cardCSN !== null && cardCSNErrorData[0].cardCSN !== "" ? cardCSNErrorData[0].cardCSN.substring(0,10) : null}</b> is already assigned to <b>{cardCSNErrorData[0].email}</b> and cannot be assigned to this user.                              
                          </>
                      :
                        <>                          
                          This card is already assigned to a user and cannot be assigned to this user.                              
                        </>
                    }
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                    onClick={()=> {
                      setShowCardCsnExistAlertDialog(false);
                      setCardCSNErrorData([]);
                    }}
                >
                  ok
                </Button>                                  
              </DialogActions>
            </Dialog>
          :
            null
        }          
      </>
  );
}
