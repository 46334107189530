import * as React from 'react';
import PropTypes from 'prop-types';
import { AppBar,CssBaseline,Box,Drawer,Button,IconButton,List,ListItem,ListItemText,Toolbar,Fab,Tooltip } from '@mui/material';
import { Menu,ArrowBack,ArrowLeft,ArrowRight,Pin,Download } from '@mui/icons-material';
import '../../stylesheet/SideNav.css';
import {createTheme} from '@material-ui/core/styles';
import Appheader from '../header/Appheader.js';
import { useNavigate } from "react-router-dom";
import CaribouLogo from '../../cariboutechnologieslogo.png';
import {ProgressBar} from "react-fetch-progressbar";
import UserSessionStorage from '../../userSession/UserSessionStorage';
import MqttEventHandler from '../Mqtt/MqttEventHandler';
import PubSubChannels from '../../pubsub/PubSubChannels';
import IotServiceClient from '../../clients/IotServiceClient';
import AuthClient from '../../clients/AuthClient';
import GatewayServiceClient from '../../clients/GatewayServiceClient';
import frontendServiceClient from '../../clients/FrontendClient';
import CsvDownload from 'react-json-to-csv';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { CircularProgress } from '@material-ui/core';
import DownloadSrialNumbersDialog from './DownloadSrialNumbersDialog';

function EngineeringView(props) {
  const checkIsFreeTier = () => {
      return 'Serial Numbers';
  };

  const {windows} = props;
  const [selectedListItem, setSelectedListIem] = React.useState(checkIsFreeTier());
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [drawerWidth, setDrawerWidth] = React.useState(240);
  const [mqttFrontend, setMqttFrontend] = React.useState(false);
  const [mqttIOT, setMqttIOT] = React.useState(false);
  const [mqttGateway, setMqttGateway] = React.useState(false);
  const [mqttAuth, setMqttAuth] = React.useState(false);
  const [menuItemIconColor, setMenuItemIconColor] = React.useState(
    checkIsFreeTier() === "Serial Numbers" ? 'Serial NumbersSideNavItem' : null
  );
  const [menuItemTextColor, setMenuItemTextColor] = React.useState(
    checkIsFreeTier() === "Serial Numbers" ? 'Serial NumbersSideNavItemText' : null
  );
  const [menuItemLeftBorder, setMenuItemLeftBorder] = React.useState(
    checkIsFreeTier() === "Serial Numbers" ? 'Serial NumbersSideNavItemDiv' : null
  );
  const [firstListBlock, setFirstListBlock] = React.useState([]);
  const [frontEndVersion, setFrontEndVersion] = React.useState("");
  const [iotVersion, setIotVersion] = React.useState("");
  const [gatewayVersion, setGatewayVersion] = React.useState("");
  const [authVersion, setAuthVersion] = React.useState("");
  const [downloadSerialNoDialog, setDownloadSerialNoDialog] = React.useState(false);
  const [selectedLockNamesCsvFile, setSelectedLockNamesCsvFile] = React.useState(null);
  const [showErrorMsg, setShowErrorMsg] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [locksCsvData, setLocksCsvData] = React.useState([]);
  const [csvLoading, setCsvLoading] = React.useState(false);
  const [showDownloadSerialNumbersDialog, setShowDownloadSerialNumbersDialog] = React.useState(false);
  const [showDownloadSerialNumbersDialogSpinner, setShowDownloadSerialNumbersDialogSpinner] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const isConnected = (status) => {
    return status.toString() === "connected" ? true : false;
  }

  const updateMqttSTatus = () => {
    let status = UserSessionStorage.getMqttStatus();
    setMqttFrontend(status)
    if (!status){
      setMqttAuth(status);
      setMqttGateway(status);
      setMqttIOT(status);
    }
  }

  let checkMqttInterval = setInterval(() => {
     updateMqttSTatus();
  }, 2000);

  let gatewayServiceStatusEventChannel, iotServiceStatusEventChannel, authServiceStatusEventChannel = null;


  const gatewayServiceStatusEventHandler = (message) => {
    if(isConnected(message)){
      setMqttGateway(true)
    }else {
      setMqttGateway(false)
    }
  }

  const iotServiceStatusEventHandler = (message) => {
    if(isConnected(message)){
      setMqttIOT(true)
    }else {
      setMqttIOT(false)
    }
  }

  const authServiceStatusEventHandler = (message) => {
    if(isConnected(message)){
      setMqttAuth(true)
    }else {
      setMqttAuth(false)
    }
  }

  const checkMqqttSTatusForFIGA = () => {
    gatewayServiceStatusEventChannel = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.GATEWAY_SERVICE_STATUS, "sideNav", gatewayServiceStatusEventHandler);

    iotServiceStatusEventChannel = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.IOT_SERVICE_STATUS, "sideNav" , iotServiceStatusEventHandler);

    authServiceStatusEventChannel = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.AUTH_SERVICE_STATUS, "sideNav", authServiceStatusEventHandler);
  }

  const getFigaVersions = () => {

    frontendServiceClient.getFrontendAppDetails().then((response)=>{
      setFrontEndVersion(response.version);
    }).catch((error)=>{})

    IotServiceClient.getIotAppDetails("sideNav").then((response)=>{
      setIotVersion(response.version);
    }).catch((error)=>{})

    AuthClient.getAuthAppDetails("sideNav").then((response)=>{
      setAuthVersion(response.version);
    }).catch((error)=>{})

    GatewayServiceClient.getGatewayAppDetails("sideNav").then((response)=>{
      setGatewayVersion(response.version);
    }).catch((error)=>{})
  }

  React.useEffect(() => {
    window.locksAndSerialNumbersDataInEngineeringView  = [];
    setFirstListBlock(['Serial Numbers']);
    getFigaVersions();
    checkMqqttSTatusForFIGA();

    return () => {
      if (gatewayServiceStatusEventChannel){
        gatewayServiceStatusEventChannel.unsubscribe();
      }
      if (iotServiceStatusEventChannel) {
        iotServiceStatusEventChannel.unsubscribe();
      }
      if (authServiceStatusEventChannel) {
        authServiceStatusEventChannel.unsubscribe();
      }
      if (sessionStorage.engineeringView){
        delete sessionStorage.engineeringView;
      }
      clearInterval(checkMqttInterval);
      IotServiceClient.abortSignal("engineeringView");
    }
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const changeDrawerWidth = (presentWidth) => {
    presentWidth === 240 ? setDrawerWidth(80) : setDrawerWidth(240);
  };

  const checkDrawerWidth = () => {
    let checkCondition;
    drawerWidth !== 240 ? (checkCondition = true) : (checkCondition = false);
    return checkCondition;
  };

  const changeSideNavTextColor = (id, listItem) => {
    setMenuItemIconColor(id);
    setMenuItemTextColor(id + 'Text');
    setMenuItemLeftBorder(id + 'Div');
    sessionStorage.sidenavAlreadySelectedItem = listItem;
    setSelectedListIem(listItem);
    handleDrawerToggle();
    if (id !== "Locks"){
      delete sessionStorage.navigatedFromDashboard
      delete sessionStorage.lockView;
    }
  };

  const readCsvFile = () => {
    setShowErrorMsg(false);
    setErrorMsg("");
    let fileReader = new FileReader();
    let uploadedFile = document.getElementById('uploadCSV');
    fileReader.readAsText(uploadedFile.files[0]);

    fileReader.onload = () => {
        let lockNames = fileReader.result.split('\n');
        if(lockNames[0] !== 'Lock Name' && lockNames[0] !== ''){
            setShowErrorMsg(true);
            setErrorMsg("Headers of selected csv file are not valid, please check and retry");
            return;
        } else if(lockNames[0] === ''){
            setShowErrorMsg(true);
            setErrorMsg("Uploaded file is empty, please check and retry.");
        } else if(lockNames[1] === ''){
            setShowErrorMsg(true);
            setErrorMsg("Uploaded file is empty, please check and retry.");
        }else {
          setShowDownloadSerialNumbersDialogSpinner(true);
          setShowDownloadSerialNumbersDialog(true);                    
          enqueueSnackbar("Fetching serial numbers");
          let filteredLockNames = _.cloneDeep(lockNames);
          if (filteredLockNames.length >= 1){
            filteredLockNames.splice(0,1);
            if (lockNames[lockNames.length-1] == ""){
              filteredLockNames.splice(-1,1);
            }
          }
            IotServiceClient.getLockSerialNumbersByLockNames(filteredLockNames,"engineeringView").then((res) => {                            
              let filteredCsvData = [];
              if (!res.hasOwnProperty("message") && Array.isArray(res)){
                let csvObject = {};
                _.forEach(res, (val)=>{
                    csvObject["Lock Name"] = val["Lock Name"] !== null ? val["Lock Name"] : "-";
                    csvObject["Tenant Name"] = val["Tenant Name"] != null ? val["Tenant Name"].replaceAll(",","\n") : "-";
                    csvObject["Serial Number"] = val["Serial Number"] != null ? val["Serial Number"].replaceAll(",","\n") : "-";
                    let clonedObject = _.cloneDeep(csvObject);
                    filteredCsvData.push(clonedObject);
                })
                window.filteredCsvDataEngineeringView = filteredCsvData;
                setShowDownloadSerialNumbersDialogSpinner(false);                
              }else {
                setShowDownloadSerialNumbersDialogSpinner(false);
                setShowDownloadSerialNumbersDialog(false);
                enqueueSnackbar("Failed to fetch Serial Numbers");
              }
              setCsvLoading(false);
            });
        }
    };
    setSelectedLockNamesCsvFile("");
  };

  const drawer = (
    <div
      style={{
        height:
          checkDrawerWidth() === true
            ? `calc(100% - ${240}px)`
            : `calc(100% - ${84}px)`,
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <Toolbar />
      <Toolbar />
      <List>
        {firstListBlock.map((text, index) => (
          <ListItem
            selected={text === selectedListItem}
            button
            key={text}
            className={text + 'SideNavItemDiv'}
            sx={{
              borderLeft:
                menuItemLeftBorder === text + 'SideNavItemDiv'
                  ? '6px solid #f39c12'
                  : '6px solid white',
              height: '48px'
            }}
            onClick={() => changeSideNavTextColor(text + 'SideNavItem', text)}
          >
            <ListItem
              className={text + 'SideNavItem'}
              sx={{
                color:
                  menuItemIconColor === text + 'SideNavItem'
                    ? '#f39c12'
                    : '#000000DE',
                    width:"56px",paddingLeft:"0px"
              }}
              fontSize= 'large'
            >
              {/*{text === 'Dashboard' || text === 'Dashboard1' ? (*/}
                {text === 'Serial Numbers' ? (
                  <Pin />
                ) : null}
            </ListItem>
            <ListItemText
              sx={{
                visibility: checkDrawerWidth() === true ? 'hidden' : 'visible',
                color:
                  menuItemTextColor === text + 'SideNavItemText'
                    ? '#f39c12'
                    : '#000000DE'
              }}
              primary={text}
              className={text + 'SideNavItemText'}
            />
          </ListItem>
        ))}
      </List>
      <Box
        sx={{
          display: 'flex',
          flexDirection: checkDrawerWidth() === false ? 'row' : 'column',
          marginTop: '4px',
          alignItems: 'center',
          marginLeft: '4px',
          padding: '2px 14px 2px 14px',
          position: 'fixed',
          bottom: checkDrawerWidth() === true ? '40px' : '40px',
          background: '#fff',
          width: checkDrawerWidth() !== true ? '234px' : '74px',
          borderTop: '1px solid #ccc',
          borderBottom: '1px solid #ccc',
        }}
      >
        <Tooltip
          title={mqttFrontend ? 'Online' : 'Offline'}
          placement={checkDrawerWidth() === true ? 'right' : 'top'}
          arrow
        >
          <Box
            sx={{
              flex: '25',
              textAlign: 'center',
              borderRight:
                checkDrawerWidth() === false ? '1px solid #ccc' : null,
            }}
          >
            F<span className="versionNumber">{frontEndVersion}</span>
            <br />
            {mqttFrontend ? (
              <span className="greenDot"></span>
            ) : (
              <span className="redDot"></span>
            )}
          </Box>
        </Tooltip>
        <Tooltip
          title={mqttIOT ? 'Online' : 'Offline'}
          placement={checkDrawerWidth() === true ? 'right' : 'top'}
          arrow
        >
          <Box
            sx={{
              flex: '25',
              textAlign: 'center',
              borderRight:
                checkDrawerWidth() === false ? '1px solid #ccc' : null,
            }}
          >
            I<span className="versionNumber">{iotVersion}</span>
            <br />
            {mqttIOT ? (
              <span className="greenDot"></span>
            ) : (
              <span className="redDot"></span>
            )}
          </Box>
        </Tooltip>
        <Tooltip
          title={mqttGateway ? 'Online' : 'Offline'}
          placement={checkDrawerWidth() === true ? 'right' : 'top'}
          arrow
        >
          <Box
            sx={{
              flex: '25',
              textAlign: 'center',
              borderRight:
                checkDrawerWidth() === false ? '1px solid #ccc' : null,
            }}
          >
            G<span className="versionNumber">{gatewayVersion}</span>
            <br />
            {mqttGateway ? (
              <span className="greenDot"></span>
            ) : (
              <span className="redDot"></span>
            )}
          </Box>
        </Tooltip>
        <Tooltip
          title={mqttAuth ? 'Online' : 'Offline'}
          placement={checkDrawerWidth() === true ? 'right' : 'top'}
          arrow
        >
          <Box sx={{flex: '25', textAlign: 'center'}}>
            A<span className="versionNumber">{authVersion}</span>
            <br />
            {mqttAuth ? (
              <span className="greenDot"></span>
            ) : (
              <span className="redDot"></span>
            )}
          </Box>
        </Tooltip>
      </Box>
      <Box
        className="cursorPointer"
        sx={{background: '#fff', position: 'fixed', bottom: '0px'}}
        onClick={() => changeDrawerWidth(drawerWidth)}
      >
        {checkDrawerWidth() === false ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: checkDrawerWidth() === true ? '80px' : '240px',
              fontSize: '12px',
              color: 'gray',
            }}
          >
            <img
              src={CaribouLogo}
              alt=""
              style={{height: '26px', width: '26px', margin: '5px'}}
            />
            <div
              style={{display: 'flex', flexDirection: 'column', width: `176px`}}
            >
              <div>Powered by</div>
              <div>
                <b> Caribou Technologies </b>
              </div>
            </div>
            <ArrowLeft
              className="sideNavMiniIcon"
              sx={{width: '40px', margin: 'auto'}}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: checkDrawerWidth() === true ? '80px' : '240px',
              fontSize: '12px',
              color: 'gray',
            }}
          >
            <img
              src={CaribouLogo}
              alt=""
              style={{height: '26px', width: '26px', margin: '5px 10px'}}
            />
            <ArrowRight
              className="sideNavMiniIcon"
              sx={{
                width: checkDrawerWidth() === true ? '28px' : '240px',
                paddingLeft: checkDrawerWidth() === false ? '200px' : '',
                marginTop: checkDrawerWidth() === true ? "3px" : "",

              }}
            />
            </Box>
        )}
      </Box>
    </div>
  );

  const container =
    windows !== undefined ? () => windows().document.body : undefined;

  const fabRedStyle = {
    color: 'common.white',
    bgcolor: '#14138b',
    '&:hover': {
      bgcolor: '#14138b',
    },
  };

  const customBreakPointtheme = createTheme({
    breakpoints: {
      values: {
        mobile: 0,
        lg: 1179,
        desktop: 1280,
      },
    },
  });
    const nav = useNavigate();
  return (
    <>
      <Box sx={{display: 'flex', overflowX: 'hidden'}}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <Toolbar className="header_nav">
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              theme={customBreakPointtheme}
              sx={{
                mr: 2,
                display: {
                  xs: 'block',
                  desktop: 'none',
                  width: '35px',
                  marginRight: '-1px',
                },
                color:"#fff"
              }}
            >
              <Tooltip title="Top Navbar" placement="right" arrow>
                <Menu />
              </Tooltip>
            </IconButton>
            <ProgressBar style={{position: 'absolute',top: 0,left:0,zIndex: 5000}}/>
            <Appheader parent="SideNav" />
          </Toolbar>
          <Box
            sx={{
              height: '56px',
              background: 'white',
              color: 'black',
              display: 'flex',
              flex: '100',
            }}
          >
              {
                     sessionStorage.isRootUser == 'true' ?
                  <span
                      style={{margin: '10px', marginLeft: '18px', marginRight: '12px'}}
                  >

              <a onClick={() => nav("/admin/tenants")}>
                <Tooltip title="Back to Admin" arrow>
                  <Fab
                      className="fabArrowIcon"
                      color="inherit"
                      size="small"
                      aria-label="add"
                      sx={{...fabRedStyle, cursor: 'pointer'}}
                  >
                    <ArrowBack/>
                  </Fab>
                </Tooltip>
              </a>
            </span>
             : <span
                          style={{margin: '10px', marginLeft: '18px', marginRight: '12px'}}
                      ></span> }
            <span
              style={{
                fontSIze: '18px',
                fontWeight: '500',
                margin: '14px',
                marginTop: '16px',
              }}
            >
              Engineering View
            </span>
          </Box>
        </AppBar>
        <Box
          component="nav"
          sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
          aria-label="mailbox folders"
          className="sideNavigation"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              [`& .MuiDrawer-paper`]: {
                boxSizing: 'border-box',
                width: drawerWidth,
              },
            }}
            className="smallDevicesDrawer"
          >
            {mobileOpen ? drawer : null}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              [`& .MuiDrawer-paper`]: {
                boxSizing: 'border-box',
                width: drawerWidth,
              },
            }}
            className="largeDevicesDrawer"
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          theme={customBreakPointtheme}
          sx={{
            flexGrow: 1,
            p: 3,
            width: {
              mobile: '100%',
              desktop: `calc(100% - ${drawerWidth}px)`,
              padding: '0px',
            },
          }}
        >
          <Toolbar />
          <Box>
            {selectedListItem === 'Serial Numbers' ? (                    
                    <Box sx={{margin:'10px',background:"#fff", display:'flex',justifyContent:'center',flexDirection:'column', height:'auto',padding:'10px'}}>
                      {!csvLoading ?
                          <>
                            <Box sx={{display:'flex',justifyContent:'center'}}>
                              <span>
                                Select a CSV file (filename.csv) with the list of Lock Names. <a href="/locknames.csv" download>Click here</a> here to download sample a file.
                              </span>
                            </Box>
                            <Box style={{display:'flex',justifyContent:'center',margin:'10px'}}>
                              <input
                                  type="file"
                                  accept=".csv"
                                  style={{display: 'none'}}
                                  id="uploadCSV"
                                  value={selectedLockNamesCsvFile}
                                  onChange={() => readCsvFile()}
                              />
                              <label htmlFor="uploadCSV">
                                <Button variant="standard"
                                  sx={{marginLeft: 'auto',
                                  background: 'rgb(20,19,139)',
                                  color: '#fff',
                                  marginRight: '10px',
                                  '&.MuiButtonBase-root:hover': {
                                      background: 'rgb(20,19,139)',
                                  },
                                  width: '167px',
                                  margin: '15px'}} component="span">
                                  UPLOAD CSV File
                                </Button>
                              </label>
                            </Box>
                            <Box sx={{display:showErrorMsg ? "flex" : "none", color : 'red', justifyContent:'center'}}>
                              {errorMsg}
                            </Box>
                          </>
                        :
                          <div style={{position: 'relative',display: "flex", alignItems : "center", justifyContent:"center", minHeight:"110px"}}>
                            <CircularProgress thickness="4" sx={{height:'26px !important',width: '26px !important',color: 'rgb(20,19,139) !important'}}/>
                          </div>
                      }
                    </Box>
            ) : null}
        </Box>
        </Box>
      </Box>    
        <CsvDownload
            data={locksCsvData}
            filename={"SerialNumbers.csv"}
            style={{
              border: 'transparent',
              background: 'transparent',
              visibility: 'hidden',
              position:'absolute'
            }}
            id="lockSerialNumbersNameCsvDataEngineeringView"
        >
          <Tooltip title="Download CSV" placement="top" arrow>
            <Download/>
          </Tooltip>
        </CsvDownload>                 
        {
          showDownloadSerialNumbersDialog ?
            <DownloadSrialNumbersDialog
              openDialog={showDownloadSerialNumbersDialog}
              closeLoader={showDownloadSerialNumbersDialogSpinner}
              closeDialog={()=>{
                setShowDownloadSerialNumbersDialog(false);
              }}
            />
          :
            null
        }
    </>    
  );
}

EngineeringView.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  windows: PropTypes.func,
};

export default EngineeringView;
