import React from 'react';
import { Box,TableBody,TableCell,TableContainer,TableHead,TablePagination,TableRow,Table,TableSortLabel,Checkbox,Select,MenuItem,Tooltip,LinearProgress } from '@mui/material';
import {visuallyHidden} from '@mui/utils';
import { Delete,FirstPage,LastPage,KeyboardArrowLeft,KeyboardArrowRight,Search,Refresh,Download,Close,People,Lock,LockOpen,LibraryBooks } from '@mui/icons-material';
import styles from '../../stylesheet/locks.module.css';
import paginationStyle from '../../stylesheet/pagination.module.css';
import HistoryDialog from './HistoryDialog';
import UsersDialog from './UsersDialog';
import IotServiceClient from "../../clients/IotServiceClient";
import "../../stylesheet/common.css";
import _ from 'lodash';
import { useSnackbar } from 'notistack';


function TablePaginationActions(props) {

  const {count, page, rowsPerPage, onPageChange} = props;

  function handleFirstPageButtonClick(event) {
    onPageChange(event, 0);
  }

  function handleBackButtonClick(event) {
    onPageChange(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onPageChange(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={paginationStyle.paginationActionIcons}>
     <FirstPage
        onClick={() => {
          if (page > 0) handleFirstPageButtonClick();
        }}
        sx={{
          color: page === 0 ? 'rgba(0,0,0,0.26)' : 'black',
          cursor: page === 0 ? 'auto' : 'pointer',
          marginRight: '8px'
        }}
    />
    <KeyboardArrowLeft
        onClick={() => {
          if (page > 0) handleBackButtonClick();
        }}
        sx={{
          color: page === 0 ? 'rgba(0,0,0,0.26)' : 'black',
          cursor: page === 0 ? 'auto' : 'pointer',
          marginRight: '8px'
        }}
    />
    <KeyboardArrowRight
        onClick={() => {
          if (page !== Math.ceil(count / rowsPerPage) - 1)
            handleNextButtonClick();
        }}
        sx={{
          color:
              page !== Math.ceil(count / rowsPerPage) - 1 && count > 0
                  ? 'black'
                  : 'rgba(0,0,0,0.26)',
          cursor:
              page !== Math.ceil(count / rowsPerPage) - 1 && count > 0 ? 'pointer' : 'auto',
          marginRight: '8px'
        }}
    />
    <LastPage
        onClick={() => {
          if (page !== Math.ceil(count / rowsPerPage) - 1)
            handleLastPageButtonClick();
        }}
        sx={{
          color:
              page !== Math.ceil(count / rowsPerPage) - 1 && count > 0
                  ? 'black'
                  : 'rgba(0,0,0,0.26)',
          cursor:
              page !== Math.ceil(count / rowsPerPage) - 1 && count > 0 ? 'pointer' : 'auto',
        }}
    />
  </div>
  );
}


function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const [changeTextColor,setChangeTextColor] = React.useState('');
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
    setChangeTextColor('');
  };

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
      width: '90px'
    },
    {
      id: 'serialNumber',
      numeric: false,
      disablePadding: false,
      label: 'Serial #',
      width: '90px'
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: 'Type',
      width: '100px'
    },
    {
      id: 'mode',
      numeric: false,
      disablePadding: false,
      label: 'Mode',
      width: '80px'
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Status',
      width: '60px'
    },
    {
      id: 'users',
      numeric: false,
      disablePadding: false,
      label: 'Users',
      width: '60px'
    },
    {
      id: 'batteryLevel',
      numeric: false,
      disablePadding: false,
      label: 'Battery',
      width: '90px'
    },
    {
      id: 'rssi',
      numeric: false,
      disablePadding: false,
      label: 'Rssi',
      width: '60px'
    },
    {
      id: 'gatewayMacId',
      numeric: false,
      disablePadding: false,
      label: 'GatewayId ',
      width: '100px'
    },
    {
      id: 'firmwareVersion',
      numeric: false,
      disablePadding: false,
      label: 'Firmware',
      width: '130px'
    },
  ];

  const selectAll = (event) => {
    onSelectAllClick(event.target.checked);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{
            display: 'none',
            padding: '16px',
            fontSize: '13px'
          }}
        >
          <Checkbox
            color="error"
            onClick={(event) => selectAll(event)}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
            id="tableHeadCheckBox"
            sx={{margin: '0px', padding: '0px'}}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="left">
            {headCell.label !== 'History' && headCell.id !== 'delete' && headCell.id !== 'users' ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                sx={{
                  width: headCell.width,
                  maxWidth: headCell.width,
                  color: orderBy !== headCell.id ? '#0000008A !important' : '#000000DE !important',
                  overflowWrap: 'break-word',
                }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box className="styles.lockSortIcon" component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <Box
                sx={{
                  width: headCell.width,
                  maxWidth: headCell.width,
                  overflowWrap: 'break-word',
                  color: changeTextColor !== 'history' || changeTextColor !== 'delete' ? '#0000008A !important' : '#000000DE !important',
                }}
                onClick={()=>setChangeTextColor('history')}
              >
                {headCell.label}
              </Box>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function FreeTierLocks() {
  const [SearchBar, setSearchBar] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(sessionStorage.freeTierRPP ? Number(sessionStorage.freeTierRPP) : 25);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('Name');
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(false);
  const [editMode, setEdiitMode] = React.useState(false);
  const [showEditIcon, setShowEditIcon] = React.useState(true);
  const [searchText, setSearchText] = React.useState('');
  const [iotDevices, setIotDevices] = React.useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(0);

  let rows = [];

  const fetchIotDevices = () => {
    let res = [];
    let isRefreshingWithFilter = searchText.length > 0;
    IotServiceClient.getIotDevices(sessionStorage.tenantId, "freeTier").then((data) => {
      if(sessionStorage.navigatedValueFromGlobalSearch === "gs") {
         _.forEach(data,(element)=>{
            if (element.macId === sessionStorage.gsMacId){
              res.push(element);
            }
          })
          if(!isRefreshingWithFilter){
            setIotDevices(res)
          }
          window.allLocks = res;
      } else {
            if(!isRefreshingWithFilter){
              setIotDevices(data)
            } else {
                setTimeout(function(){
                    filterDataBasedOnSearchTerm(searchText);
                }, 2000);
            }
          window.allLocks = data
      }
    });
  }

  React.useEffect(() => {
    fetchIotDevices();
    window.oldPreservedLocksPageNumber = 0;
    return () => {
      delete sessionStorage.navigatedValueFromGlobalSearch;
      IotServiceClient.abortSignal("freeTier")
    }
  }, []);

  const removeFilter = () => {
     delete sessionStorage.navigatedValueFromGlobalSearch;
     fetchIotDevices();
  }

  const refreshData = () => {
    enqueueSnackbar("Refreshing Locks ...");
    fetchIotDevices();
  }
 function numberCharacterOrganizer(array, orderOfsort, orderByOfSort){
    let numbers = [], characters = [];
    _.forEach(array, (element) => {
        if(typeof(element[orderByOfSort]) === 'string' ? Number(element[orderByOfSort] ? element[orderByOfSort].replaceAll(" ","") : element[orderByOfSort]) : Number(element[orderByOfSort])){
          numbers.push(element);
        }
        else{
          characters.push(element);
        }
    })
    array = orderOfsort === 'asc' ? characters.concat(numbers): numbers.concat(characters);
    return array;
  }

  function stableSort(array, orderOfsort, orderByOfSort) {
    array = numberCharacterOrganizer(array, orderOfsort, orderByOfSort);
    array = _.orderBy(array, [obj => obj[orderByOfSort] !== null ? typeof(obj[orderByOfSort]) === 'string' ? !Number(_.get(obj, orderByOfSort, '').replaceAll(" ","")) ? _.get(obj, orderByOfSort, '').toString().toLowerCase() : Number(_.get(obj, orderByOfSort).replaceAll(" ","")) : Number(_.get(obj, orderByOfSort,'')) : ""], [orderOfsort]);
    return array;
  }

  function stableOldSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPageNumber(newPage);
    window.oldPreservedLocksPageNumber = newPage;
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleClick = (event, row) => {
    let newSelected = [];

    if (event.target.checked) {
      if (selected.length > 0) {
        selected.forEach((element) => {
          newSelected.push(element);
        });
        if (JSON.stringify(newSelected).indexOf(row.id) === -1) {
          newSelected.push(row);
        }
      } else {
        newSelected.push(row);
      }
    } else {
      selected.forEach((element) => {
        if (element.id !== row.id) {
          newSelected.push(element);
        }
      });
    }

    setSelected(newSelected);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleSelectAllClick = (checked) => {
    if (checked) {
      // eslint-disable-next-line array-callback-return
      const newSelecteds = rows.filter((n) => {
        if (
          n.status !== 'INACTIVE' &&
          typeof n.id !== 'undefined' &&
          n.id !== null
        ) {
          return n;
        }
      });
      setSelected([]);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChange = (event) => {
    setPageNumber(event.target.value);
    setPage(event.target.value);
    window.oldPreservedLocksPageNumber = event.target.value;
  };

  const handleRowPerPage = (event) => {
    let rowPerPageValue = event.target.value;
    if (iotDevices.length > 0){      
      let pageNumberCount = Math.ceil((iotDevices.length/rowPerPageValue)) - 1;                
      if (window.oldPreservedLocksPageNumber > pageNumberCount) {
        setPage(pageNumberCount);
        setPageNumber(pageNumberCount);        
        window.oldPreservedLocksPageNumber = pageNumberCount;
      }else {
        if (window.oldPreservedLocksPageNumber) {
          setPage(window.oldPreservedLocksPageNumber);
          setPageNumber(window.oldPreservedLocksPageNumber);        
        }else {
          setPage(0);
          setPageNumber(0);
          window.oldPreservedLocksPageNumber = 0;
        }
      }
    }  
      sessionStorage.freeTierRPP = rowPerPageValue;
      setRowsPerPage(rowPerPageValue);
    
  };

  const checkSearchTextExist = (data, key,  text) => {
    let value = data[key];
    return value !== null && typeof value !== 'undefined' && value !== "" ? value.toString().toLowerCase().replaceAll(" ","").indexOf(text) !== -1 : false;
  };

  const handleTextCange = (event) => {
    setSearchText(event.target.value);
    filterDataBasedOnSearchTerm(event.target.value);
  };

  const filterDataBasedOnSearchTerm = (searchText) => {
        let sortedDevices = [];
        if (searchText !== "" && searchText !== undefined && searchText !==  null){
          searchText = searchText.toLowerCase().replaceAll(" ","");
          _.forEach(window.allLocks,(value)=>{
            if (checkSearchTextExist(value, "name", searchText) || checkSearchTextExist(value, "serialNumber", searchText) ||
                checkSearchTextExist(value, "type", searchText) || checkSearchTextExist(value, "mode", searchText) || checkSearchTextExist(value, "accessed", searchText)||
                checkSearchTextExist(value, "batteryLevel", searchText) || checkSearchTextExist(value, "rssi", searchText) || checkSearchTextExist(value, "gatewayMacId", searchText) ||
                checkSearchTextExist(value, "firmwareVersion", searchText)){
              sortedDevices.push(value);
            }
          })
          sortedDevices = _.uniq(sortedDevices);
          setIotDevices(sortedDevices);
        }else {
          setIotDevices(window.allLocks);
        }

        if (window.oldPreservedLocksPageNumber) {
          if (sortedDevices.length > 0) {
            let pageNumberCount = Math.ceil((sortedDevices.length/rowsPerPage)) - 1;                
            if (window.oldPreservedLocksPageNumber > pageNumberCount) {
              setPage(pageNumberCount);
              setPageNumber(pageNumberCount);
              window.oldPreservedLocksPageNumber = pageNumberCount;
            }
          }else {
            setPageNumber(0);
            setPage(0);     
            window.oldPreservedLocksPageNumber = 0;
          }
        }else {
          setPageNumber(0);
          setPage(0);   
          window.oldPreservedLocksPageNumber = 0;
        }
  }

  const rowPerPageLabel = (
    <div className={paginationStyle.rowPerPageLabelDiv}>
      <Box className={paginationStyle.page}>
        <span style={{marginRight: '32px'}}>Page</span>
      </Box>
      <Box className={paginationStyle.pageSelectBox}>
        <Select value={pageNumber} onChange={handleChange}>
            {
              Math.ceil(iotDevices.length / rowsPerPage) > 0 ?
                // eslint-disable-next-line array-callback-return
                Array(Math.ceil(iotDevices.length / rowsPerPage))
                  .fill()
                  .map((_, index) => {
                    return (
                      <MenuItem value={index} sx={{minWidth: '64px'}}>
                        {index + 1}
                      </MenuItem>
                    );
                  })
              :
                <MenuItem value={0} sx={{minWidth: '64px'}}>
                    1
                </MenuItem>
          }
        </Select>
      </Box>
      <Box className={paginationStyle.rowPerPageLabelDiv}>
        <span style={{marginRight: '32px'}}>Rows Per Page</span>
      </Box>
      <Box className={paginationStyle.rowPerPageSelectBox}>
        <Select value={rowsPerPage} onChange={handleRowPerPage}>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
      </Box>
    </div>
  );

  const [openUsersDialog, setOpenUsersDialog] = React.useState(false);
  const [usersData, setUsersData] = React.useState([]);
  const usersDialog = (data) => {
    setUsersData(data);
    setOpenUsersDialog(true);
  };

  const handleUsersClose = () => {
    setOpenUsersDialog(false);
  };

  return (
    <>

      {
        sessionStorage.navigatedFromDashboard === "online" || sessionStorage.navigatedFromDashboard === "offline"
        || sessionStorage.navigatedValueFromGlobalSearch === "gs" ?
            <Box sx={{display:"flex",flexDirection:"row",padding: '10px',background:"#ffcc80"}}>
              <Box>
                Filtered by :
              </Box>
              <Box sx={{marginRight: "auto", marginLeft: "6px",height:"22px",background:"#ede8e8",width:"auto"}}>
                <Box sx={{display:"flex",flexDirection:"row",marginLeft:"9px"}}>
                  <Tooltip placement='top' title={sessionStorage.navigatedFromDashboard ? sessionStorage.navigatedFromDashboard : sessionStorage.navigatedLockName} arrow>
                    <Box className='filterNameForSmallScreens'>
                      {sessionStorage.navigatedFromDashboard ? sessionStorage.navigatedFromDashboard : sessionStorage.navigatedLockName}
                    </Box>
                  </Tooltip>
                  <Box sx={{padding:"0px 3px 0px 3px"}}>
                    <Close sx={{cursor:"pointer",fontSize:"21px",paddingLeft:"4px",marginTop:"1px"}} onClick={()=> removeFilter()} />
                  </Box>
                </Box>
              </Box>
            </Box>
            :
            null
      }
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: '20px 0px 20px 24px',
        }}
      >
        <span style={{fontSize: '20px', marginRight: 'auto'}}>Locks</span>
        <div style={{display: 'flex'}} id="iconsBlock">
          <input
            id="searchText"
            type="text"
            onChange={handleTextCange}
            placeholder="Search Locks..."
            style={{
              marginLeft: 'auto',
              marginRight: '4px',
              minWidth: '37px',
              width: '140px',
              maxWidth: '100%',
              visibility: SearchBar ? 'visible' : 'hidden',
            }}
          />
          <Close
            style={{
              marginRight: '4px',
              cursor: 'pointer',
              display: SearchBar ? 'flex' : 'none',
              color: '#0000008A'
            }}
            onClick={() => {
              setSearchBar(false);
              setSearchText('');
              document.getElementById('searchText').value = '';
              setIotDevices(window.allLocks);
            }}
          />
          <Tooltip title="Search" placement="top" arrow>
            <Search
              style={{
                marginRight: '10px',
                cursor: 'pointer',
                display: !SearchBar ? 'flex' : 'none',
                color: '#0000008A'
              }}
              onClick={() => setSearchBar(true)}
            />
          </Tooltip>
          <Tooltip title="Refresh" placement="top" arrow>
            <Refresh onClick={()=> refreshData()} style={{marginRight: '10px', cursor: 'pointer',color: '#0000008A'}} />
          </Tooltip>
        </div>
      </Box>
      <Box style={{width: '100%', height: 'auto', marginBottom: '86px'}}>
        <TableContainer>
          <Table>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={(checked) => handleSelectAllClick(checked)}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(iotDevices, order, orderBy)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      sx={{lineHeight: '48px',fontSize:'13px',background: "#fff"}}
                      selected={isItemSelected}
                    >
                      <TableCell align="left" className={paginationStyle.tBodyTableCell}>{row.name}</TableCell>
                      <TableCell align="left" className={paginationStyle.tBodyTableCell}>{row.serialNumber}</TableCell>
                      <TableCell align="left" className={paginationStyle.tBodyTableCell}>{row.type}</TableCell>
                      <TableCell align="left" className={paginationStyle.tBodyTableCell}>{row.mode}</TableCell>
                      <TableCell align="left" className={paginationStyle.tBodyTableCell}>
                        {row.status === 'LOCKED' ? (
                          <Lock sx={{color: '#0000008A',cursor: 'pointer'}} />
                        ) : (
                            <LockOpen sx={{color: '#0000008A',cursor:'pointer'}}/>
                        )}
                      </TableCell>
                      <TableCell align="left" className={paginationStyle.tBodyTableCell}>
                        {
                            <People
                              sx={{cursor: 'pointer',color: '#0000008A'}}
                              onClick={() => usersDialog(row)}
                            />
                        }
                      </TableCell>
                      <TableCell align="left" className={paginationStyle.tBodyTableCell}>
                        <span>{row.batteryLevel}</span>
                        <LinearProgress
                          color="inherit"
                          variant="determinate"
                          sx={{background:"rgba(255,0,0,0.61)",color: "#6CC644 !important"}}
                          value={row.batteryLevel}
                        />
                      </TableCell>
                      <TableCell align="left" className={paginationStyle.tBodyTableCell}>{row.rssi}</TableCell>
                      <TableCell align="left" className={paginationStyle.tBodyTableCell}>{row.gatewayMacId}</TableCell>
                      <TableCell align="left" className={paginationStyle.tBodyTableCell}>{row.firmwareVersion}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{width: '100%', overflowX: 'hidden'}}
          labelRowsPerPage={rowPerPageLabel}
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          className="pagination"
          count={iotDevices.length}
          rowsPerPage={rowsPerPage}
          page={pageNumber}
          SelectProps={{
            inputProps: {'aria-label': 'rows per page'},
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Box>
      {
        openUsersDialog ?
          <UsersDialog
            data={usersData}
            show_users_dialog={openUsersDialog}
            close_users_dialog={() => handleUsersClose()}
          />
        :
          null
    }
    </>
  );
}
