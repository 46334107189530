import React from 'react';
import '../stylesheet/MigrateLocks.css';
import { ArrowBack,Add,Check,RemoveCircleOutlined,AddCircleOutline,Warning } from '@mui/icons-material';
import { Avatar, Button, Box, Fab, Tooltip,Dialog,DialogActions,DialogContent,DialogTitle,DialogContentText,CircularProgress,Radio,RadioGroup,FormControlLabel } from '@mui/material';
import { useNavigate } from "react-router-dom";
import Papa from 'papaparse';
import DraggableComponent from './DraggableComponent';
import AuthClient from "../clients/AuthClient";
import IotServiceClient from "../clients/IotServiceClient";
import _ from 'lodash';

export default function MigrateLocks() {
  const [selectedTenant, setSelectedTenant] = React.useState([]);
  const [tenants, setTenants] = React.useState([]);
  const [sortedTenants, setSortedlTenants] = React.useState([]);
  const [step, setStep] = React.useState(1);
  const [stepText, setStepText] = React.useState('Select Customer');
  const [createDialog, setCreateDialog] = React.useState(false);
  const [selectedFile, setSelectedFile] =  React.useState(null);
  const [listOfCSVAssociatedLocks, setListOfCSVAssocatedLocks] = React.useState([]);
  const [listOfCSVNotAssociatedLocks, setListOfCSVNotAssociatedLocks] = React.useState([]);
  const [listOfCSVAssociatedUsers, setListOfCSVAssocatedUsers] = React.useState([]);
  const [selectedMigratedLocks, setSelectedMigratedLocks] = React.useState([]);
  const [selectedMigratedUsers, setSelectedMigratedUsers] = React.useState([]);
  const [migrationDialog, setMigrationDialog] = React.useState(false);
  const [hirarchyLoading, setHirarchyLoading] = React.useState(false);
  const [fromTenant, setFromTenant] = React.useState("");
  const [toTenant, setToTenant] = React.useState("");
  const [iotUserId, setIotUsersId] = React.useState([]);
  const [migrateNetworkLoading, setMigrateNetworkLoading] = React.useState(false);
  const [migrateOption, setMigrateOption] = React.useState("migratelocks");
  const [networkFetchUsersForMigration, setNetworkFetchUsersForMigration] = React.useState(false);
  const [migrateUsersDialog, setMigrateUsersDialog] = React.useState(false);
  const [showCreateDialog, setShowCreateDialog] = React.useState(false);
  const [showErrorMsg, setShowErrorMsg] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  
  React.useEffect(() => {    
    let allTenants = [];
    // eslint-disable-next-line array-callback-return
    AuthClient.getHirarchy().then((data) => {
        _.forEach(data.children,(value) => {
          value.scope.sortedName = value.scope.name ? value.scope.name.toLowerCase() : value.scope.name;
          allTenants.push(value.scope);
        });        
        data.scope.sortedName = data.scope.name ? data.scope.name.toLowerCase() : data.scope.name;
        allTenants.push(data.scope);
        setTenants(allTenants);
        allTenants = _.sortBy(allTenants,['sortedName'],['asc']);
        setSortedlTenants(allTenants);
        setSelectedTenant(allTenants[0]);
    });
    return () => {
      IotServiceClient.abortSignal("locksMigration");
    }    
  }, []);

  const getBackgroundColor = (element) => {
    let name = element + 's';
    var hash = 0;
    for (var i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    var color = (hash & 0x00ffffff).toString(16).toUpperCase();
    return '00000'.substring(0, 6 - color.length) + color;
  };

  const handleBack = () => {
    if (step === 1) {
      setStepText("What do you want to Migrate?");  
    } else if (step === 2) {
      if (migrateOption === "migratelocks"){
        setStepText("Migrate Locks");
      }else {
        setStepText("Migrate Users");
      }      
    }
  }

  const fetchUsers = () => {
    let selectedMigratedUsers = [];
    IotServiceClient.getAssignedUsersForMigration("5947df4362a1280006cdd54c", selectedTenant.id, "locksMigration").then((response)=> {
      setNetworkFetchUsersForMigration(false);
      setListOfCSVAssocatedUsers(response.iotUsers);
      _.forEach(response.iotUsers, (value)=>{
        selectedMigratedUsers.push(value.email);
      })                  
      setSelectedMigratedUsers(selectedMigratedUsers);
    });
  };

  const nextStep = () => {
    if (step === 1) {
      setStepText("What do you want to Migrate?");
      setStep(step + 1);      
    } else if (step === 2) {
      if (migrateOption === "migratelocks"){
        setStepText("Migrate Locks");
      }else {
        setStepText("Migrate Users");
      }
      setStep(step + 1);
    }
  };

  React.useEffect(()=>{
    if (step === 3 && migrateOption !== "migratelocks"){
      setNetworkFetchUsersForMigration(false);
      fetchUsers();
    }
  },[step]);

  const validateMetaData = (results) => {    
    let fields = results ? results.meta ? results.meta.fields ? (results.meta.fields.length > 0 ? results.meta.fields : []): [] : [] : [];
    if (fields.length === 1){
      if (fields[0] !== "SerialNumber"){
        if (fields[0] ? /^[a-zA-Z]*$/.test(fields[0]) : false){
          if (fields[0] !== "SerialNumber") {            
            setShowErrorMsg(true);
            setErrorMsg("Invalid CSV - Incorrect Headers. Please refer to the sample CSV and retry.");
            return false;
          }
        }else {
          setShowErrorMsg(true);
          setErrorMsg("Invalid CSV - missing Headers. Please refer to the sample CSV and retry.");
          return false;
        }
      }else {
        if (results.data.length > 0) {
          return true;
        }else {
          setShowErrorMsg(true);
          setErrorMsg("Selected csv file has no serial numbers, please check and retry.");
          return false;
        }
      }
    }else {
      if (fields.length === 0){
        setShowErrorMsg(true);
        setErrorMsg("Empty CSV, Please update and retry.")
        return false;
      }else {
        setShowErrorMsg(true);
        setErrorMsg("Invalid CSV - Incorrect Headers. Please refer to the sample CSV and retry.");
        return false;
      }      
    }
  } 

  const uploadCSVHandler = (event) => {
      Papa.parse(event.target.files[0], {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {            
              let csvValid = validateMetaData(results);
              if (!csvValid) {
                return;
              }                            
              let from = "5947df4362a1280006cdd54c", to = selectedTenant.id;
              setFromTenant("5947df4362a1280006cdd54c");
              setToTenant(to);
              //TODO : NEED TO KEEP CHECK IF USER UPLOAD > 100 RECORDS
              IotServiceClient.getAssignedUsersFormSerialNumber(from, to, results.data,"locksMigration").then((data) => {
                  let selectedMigratedLocks = [];
                  let selectedMigratedUsers = [];
                  setStep(4);
                  setStepText("Select Locks and Users");
                  setListOfCSVAssocatedLocks(data.iotDevices);
                  setListOfCSVNotAssociatedLocks(data.nonExistingDeviceSerialNumbers);
                  _.forEach(data.iotDevices, (value) => {
                      selectedMigratedLocks.push(value.macId);
                  })
                  setSelectedMigratedLocks(selectedMigratedLocks);
                  setListOfCSVAssocatedUsers(data.iotUsers);

                  setListOfCSVAssocatedUsers(data.iotUsers);
                  _.forEach(data.iotUsers, (value)=>{
                      selectedMigratedUsers.push(value.email);
                  })                  
                  setSelectedMigratedUsers(selectedMigratedUsers);
                  setListOfCSVAssocatedUsers(data.iotUsers);

              });
          },
      });
      setSelectedFile("");
  }
  const nav = useNavigate();

  const modifiySelectedLocksList = (macId, option) => {
    let selectedLocks = Object.assign([],selectedMigratedLocks);
    if (option === "select"){      
      selectedLocks.push(macId);
      setSelectedMigratedLocks(selectedLocks);  
    }else {
      selectedLocks.splice(selectedLocks.indexOf(macId),1);      
      setSelectedMigratedLocks(selectedLocks);
    }
  };

  const modifiySelectedUsersList = (email, option) => {
    let selectedUsers = Object.assign([],selectedMigratedUsers);
    if (option === "select"){      
      selectedUsers.push(email);
      setSelectedMigratedUsers(selectedUsers);  
    }else {
      selectedUsers.splice(selectedUsers.indexOf(email),1);
      setSelectedMigratedUsers(selectedUsers);
    }
  };

  const handleMigration = () => {
      setMigrationDialog(true);
  };

  const getIotUsersIds = () => {
    let iotUsersId = [];        
    _.forEach(selectedMigratedUsers,(user)=> {    
      _.forEach(listOfCSVAssociatedUsers, (value) => {                
          if(user === value.email){
              iotUsersId.push(value.id);
          }
      })
    })
    window.iotUsersId = iotUsersId;
    setIotUsersId(iotUsersId);
    return true;
  };

  const processMigration = (isUsers) => {
      if (isUsers === null){
        setMigrationDialog(false);
      }
      setMigrateNetworkLoading(true);      
      if (getIotUsersIds()){
        let json = {
          iotDeviceMacIds: selectedMigratedLocks,
          iotUserIds: isUsers === null ? iotUserId : window.iotUsersId
        }
        let from = "5947df4362a1280006cdd54c", to = selectedTenant.id;
        IotServiceClient.migrateLocks(json, from, to).then((data)=>{            
            setStep(5);
            setStepText("Migration Summary")
            let succededLocks = [];
            let succededUsers = [];
            _.forEach(data.succeededDevices,(value) => {
              _.forEach(listOfCSVAssociatedLocks,(lock)=> {
                  if(lock.macId === value){
                    succededLocks.push(lock);
                  }
              });
            });
            _.forEach(data.succeededUsers, (value) => {
              _.forEach(listOfCSVAssociatedUsers,(user)=> {
                if(user.id === value){
                  succededUsers.push(user);
                }
              });
            })
            setListOfCSVAssocatedLocks(succededLocks);
            setListOfCSVAssocatedUsers(succededUsers);
            setMigrateNetworkLoading(false);
                //TODO: Need to make changes for failed 
              // listOfCSVAssociatedLocks(value.succeededDevices);
              // listOfCSVAssociatedUsers(value.)
              // setSuccededLocks(value.succeededDevices);
              // setFailedLocks(value.failedDevices);
              // setSuccededUsers(value.succeededUsers)
              // setFailedUsers(value.failedUsers);                   
        });
          setMigrationDialog(false);
      }
      setMigrateUsersDialog(false);
  };

  const handleUserMigration = () => {
    setMigrateUsersDialog(true);    
  };

  const processUsersMigration = () => {
    setMigrateUsersDialog(false);
    processMigration(true);
  };

  return (
    <>
      <div>
        <div style={{height: '56px'}}>
          <ArrowBack
            onClick={() => {
              // window.location.href = '/admin/tenants';
              nav("/admin/tenants");
              window.location.reload();
            }}
            className="backArrow"
          />
        </div>
        <div className="migrateContainer" style={{height: 'auto'}}>
          <div
            className="migrateSubContainer"
            style={{
              maxHeight: '100%',
              overflow: 'hidden',
              margin: ' 0px auto 16px auto',
            }}
          >
            <div
              style={{
                height: '64px',
                minHeight: '64px',
                padding: '0 12px',
                borderBottom: '1px solid rgba(0,0,0,0.12)',
              }}
            >
              <h3>
                {step === 5 ? null : step + ". "} {
                  step === 1 ?
                    "Select Customer"
                  :
                    step === 2 ?
                      "What do you want to Migrate?"
                    :
                      step === 3 && migrateOption === "migratelocks" ?
                        "Migrate Locks"
                      :
                      step === 3 && migrateOption !== "migratelocks" ?
                          "Migrate Users"
                      :
                        step === 4 && migrateOption === "migratelocks" ?
                          "Select Locks and Users"
                        :
                          step === 4 && migrateOption !== "migratelocks" ?
                            "Select Lock Users"
                          :
                            step === 5 ?
                              "Migration Summary"
                            :
                              null
                }
              </h3>
            </div>
            <div
              className="migrateSubBlock"
              style={{flex: '100', maxHeight: `calc(100%-64px)px`}}
            >
              <div className="migratePortions" style={{width: '100%'}}>
                {step === 1 ? (
                  <>
                    <div className="migrateSelectedBlock">
                      <div style={{fontSize: '16px', fontWeight: '600'}}>
                        Selected Customer:
                      </div>
                      <div style={{width: '10px'}}>&nbsp;</div>
                      <div>{selectedTenant !== null ? selectedTenant.name : null}</div>
                    </div>
                    {
                      sortedTenants.length > 0 ?
                    <div
                      className="migrateTenansBlock"
                      style={{
                        margin: '17px',
                        border: '1px solid rgba(0,0,0,0.12)',
                        maxHeight: '352px',
                        overflow: 'auto',
                      }}                      
                    >
                      {
                        // eslint-disable-next-line array-callback-return
                        sortedTenants.map((element) => {
                          return (
                            <>                              
                              <Box
                                className="migrateTenantItem"
                                sx={{
                                  display: 'inline-flex',
                                  width: '98%',
                                  marginTop: '10px',
                                  height: '64px',
                                  overflow: 'auto',
                                }}
                              >
                                <Button
                                  sx={{width: '100%'}}
                                  onClick={() => setSelectedTenant(element)}
                                >
                                  <div
                                    key={element}
                                    style={{
                                      width: '96px',
                                      height: '100%',
                                      alignItems: 'center',
                                      display: 'flex',
                                    }}
                                  >
                                    <Avatar
                                      id="avatar"
                                      style={{
                                        marginLeft: '16px',
                                        padding: '12px',
                                        height: '25px',
                                        width: '25px',
                                        fontSize: '28px',
                                        background:
                                          '#' + getBackgroundColor(element.name),
                                      }}
                                    >
                                      {
                                        element.name
                                          .toUpperCase()
                                          .replaceAll(' ', '')[0]
                                      }
                                    </Avatar>
                                  </div>
                                  <div
                                    key={element.id}
                                    className="migrateCardText"
                                  >
                                    <div className="tenantName">
                                      <div
                                        title={element.name}
                                        style={{
                                          display: 'flex',
                                          flex: '100',
                                          height: '100%',
                                          flexDirection: 'column',
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: '82%',
                                            alignItems: 'left',
                                            paddingLeft: '10px',
                                            textAlign: 'left',
                                            textTransform: 'none',
                                            textOverflow: 'ellipsis',
                                            fontSize: '16px',
                                            color: 'black',
                                            fontWeight: '400',
                                            display: 'block',
                                            overflow: 'hidden',
                                          }}
                                        >
                                          {element.name}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <Check
                                    id={element.name}
                                    sx={{
                                      float: 'right',
                                      position: 'relative',
                                      visibility:
                                        selectedTenant !== null ? (selectedTenant.name === element.name
                                          ? 'visible' : 'hidden')
                                          : 'hidden',
                                    }}
                                  />
                                </Button>
                              </Box>
                            </>
                          );
                        })
                      }
                      <Tooltip
                        title="CREATE NEW CUSTOMER"
                        placement="top"
                        arrow
                      >
                        <Fab
                          className="fabAdd"
                          disableFocusRipple
                          disableRipple
                          key="migrateLocks"
                          size="small"
                          aria-label="create customer"
                          sx={{
                            position: 'sticky',
                            display:"none",
                            bottom: '11px',
                            right: '10px',
                            float: 'right',
                            background: 'rgb(194,24,91)',
                            color: 'white',
                            '&.MuiButtonBase-root:hover': {
                              bgcolor: 'rgb(194,24,91)',
                            },
                          }}
                          onClick={()=> setShowCreateDialog(true)}
                        >
                          <Add />
                        </Fab>
                      </Tooltip>
                    </div>
                      :
                          null
                    }
                  </>
                ) : step === 2 ? (
                  <>
                    <RadioGroup
                      aria-label="gender"
                      defaultValue={migrateOption}
                      name="radio-buttons-group"
                      sx={{marginLeft: '40px', paddingBlock: '12px'}}
                      onChange={(event) => setMigrateOption(event.target.value)}
                    >
                      <FormControlLabel
                        value="migratelocks"
                        control={<Radio />}
                        label="Migrate Locks"
                      />
                      <Box sx={{margin: '0px 12px', fontSize: '14px'}}>
                        Select this option to manually migrate the locks and
                        their users from <b>FreeTier</b> to{' '}
                        <b>{selectedTenant.name}</b>
                      </Box>
                      <br/>
                      <FormControlLabel
                        value="migrateusers"
                        control={<Radio />}
                        label="Migrate Users"
                      />
                      <Box sx={{margin: '0px 12px', fontSize: '14px'}}>
                        Select this option{' '}
                        <b>after Automatic Migration is complete.</b>This will
                        migrate the users (with domain <b>test</b>) from{' '}
                        <b>FreeTier</b> to <b>{selectedTenant.name}.</b>
                      </Box>
                    </RadioGroup>
                  </>
                ) :
                    // TODO FOR STEP 3

                    step === 3 && migrateOption === "migratelocks"? (
                            <>
                              <div style={{ textAlign: "center", margin: "38px" }}>
                                  <div className="text">Select a CSV file (<i>filename.csv</i>) with the list of lock
                                      serial
                                      numbers.&nbsp;
                                      <a href="/migrateLocks.csv" download>Click here</a>&nbsp;to
                                      download sample file.
                                  </div>
                                  <form>
                                          <Button
                                              component="label"
                                              variant="outlined"
                                              sx={{
                                                  marginLeft: step === 1 ? '6px' : 'auto',
                                                  background: 'rgb(20,19,139)',
                                                  color: '#fff',
                                                  marginRight: '10px',
                                                  '&.MuiButtonBase-root:hover': {
                                                      background: 'rgb(20,19,139)',
                                                  },
                                                  width: '167px',
                                                  margin: '15px'
                                              }}                                              
                                          >
                                              Upload CSV File
                                              <input type="file" accept=".csv" value={selectedFile} hidden onChange={(event)=>uploadCSVHandler(event)} />
                                          </Button>
                                  </form>
                                  <Box sx={{display:showErrorMsg ? "flex" : "none", marginTop : '10px', color : 'red',justifyContent:"center"}}>
                                    {errorMsg}
                                  </Box>
                              </div>
                            </>
                        ) :
                          step === 4 || step === 5 || (step === 3 && migrateOption !== "migratelocks")? 
                          <>
                            {
                              !migrateNetworkLoading ?
                              <Box className="step4migrateLocksDiv">
                                {
                                  migrateOption === "migratelocks" ?
                                  <Box className="step4migrateLocksSubDiv">
                                      <Box sx={{display:"flex",border:'1px solid rgba(0,0,0,0.1)',height: 'auto',width: '100%'}}>
                                          <Box sx={{padding: '0px 12px',display:'flex',width: '100%',border:'1px solid rgba(0,0,0,0.1)',height: '60px'}}>
                                            <Box>
                                              <h3>
                                                {step === 4 ? "List of Locks" : "Devices Status"}
                                              </h3>
                                            </Box>
                                            <Box sx={{color: '#800000B3',marginLeft:'auto',display:'flex',flexDirection:'colummn',alignItems:'center'}}>    
                                                {step === 4 ? selectedMigratedLocks.length +" Selected" : listOfCSVAssociatedLocks.length + " MIGRATED"}
                                            </Box>
                                          </Box>
                                      </Box>

                                        <Box sx={{border:'1px solid rgba(0,0,0,0.1)', borderTop: '1px solid transparent',height: "250px",overflowX:'hidden',overflowY:'scroll'}}>
                                          <Box
                                            sx={{
                                               display:
                                               listOfCSVAssociatedLocks.length > 0
                                                 ? 'block'
                                                 : 'none',
                                                  }}>
                                              {
                                              listOfCSVAssociatedLocks.map((lock)=> {
                                                return(
                                                    <Box sx={{display:'flex',flexDirection:"row",padding: "20px"}}>
                                                      {
                                                        step === 4 ?
                                                          <Box sx={{width:'40px',marginTop: '6px'}}>
                                                            <Tooltip placement='top' title="Select Device" arrow>
                                                              <AddCircleOutline
                                                                id={lock.macId}
                                                                sx={{
                                                                  display:
                                                                  selectedMigratedLocks.includes(
                                                                    lock.macId
                                                                  )
                                                                    ? 'none'
                                                                    : 'block',
                                                                  color: 'rgb(194,24,91)'
                                                                }}
                                                                onClick={() =>
                                                                  modifiySelectedLocksList(lock.macId,"select")
                                                                }
                                                              />
                                                            </Tooltip>
                                                            <Tooltip placement='top' title="Remove Device" arrow>
                                                              <RemoveCircleOutlined
                                                                className="removeCircle"
                                                                  sx={{
                                                                    display:
                                                                    selectedMigratedLocks.includes(
                                                                      lock.macId
                                                                    )
                                                                      ? 'block'
                                                                      : 'none',
                                                                    color: 'rgb(194,24,91)'
                                                                  }}
                                                                  id={
                                                                    lock.macId +
                                                                    'RemoveCircleOutlineIcon'
                                                                  }
                                                                  onClick={() =>
                                                                    modifiySelectedLocksList(lock.macId,"unselect")
                                                                  }

                                                              />
                                                            </Tooltip>
                                                          </Box>
                                                        :
                                                          null
                                                      }
                                                      <Box sx={{display:'flex',flexDirection:"column",width: step === 5 ? '95%' : '90%'}}>
                                                        <Box>
                                                          {lock.name}
                                                        </Box>
                                                        <Box
                                                        sx={{
                                                          color: '#808080',
                                                          marginTop:'2px'
                                                        }}
                                                        >
                                                            {lock.macId}
                                                        </Box>
                                                      </Box>
                                                      {
                                                        step === 5 ?
                                                          <Box sx={{display:'flex',flexDirection:"column"}}>
                                                            <Tooltip placement='top' title="Migration Successful" arrow>
                                                              <Check sx={{color:'rgba(0,0,0,0.54)'}} />
                                                            </Tooltip>
                                                          </Box>
                                                          :null
                                                      }
                                                    </Box>
                                                )
                                              })
                                              }
                                          </Box>
                                          <Box
                                            sx={{
                                             display:
                                             listOfCSVNotAssociatedLocks.length > 0 && step === 4
                                               ? 'block'
                                               : 'none',
                                                }}>
                                            {
                                              listOfCSVNotAssociatedLocks.map((lock)=> {
                                                return(
                                                    <Box sx={{display:'flex',flexDirection:"row",padding: "20px"}}>
                                                      <Box sx={{width:'40px',marginTop: '6px'}}>
                                                          <Warning
                                                              sx={{
                                                                color: 'rgb(231,87,83)',
                                                              }}
                                                            />
                                                      </Box>
                                                      <Box sx={{display:'flex',flexDirection:"column",width: step === 5 ? '95%' : '90%'}}>
                                                        <Box>
                                                          {lock}
                                                        </Box>
                                                        <Box
                                                         sx={{
                                                              color: '#808080',
                                                              marginTop:'2px'
                                                            }}
                                                        >
                                                            Lock not found
                                                        </Box>
                                                      </Box>
                                                    </Box>
                                                )
                                              })
                                            }
                                          </Box>
                                          <Box className="noMigrateLocks"
                                            sx={{
                                             display:
                                             listOfCSVAssociatedLocks.length > 0 || listOfCSVNotAssociatedLocks.length > 0
                                               ? 'none'
                                               : 'block',
                                                }}>
                                            No Locks
                                           </Box>
                                      </Box>
                                  </Box>
                                  :
                                          null
                                  }
                                  <Box className="step4migrateLocksSubDiv step4migrateUsersSubDiv">
                                      <Box sx={{display:"flex",border:'1px solid rgba(0,0,0,0.1)',height: 'auto',width: '100%'}}>
                                          <Box sx={{padding: '0px 12px',display:'flex',width: '100%',border:'1px solid rgba(0,0,0,0.1)',height: '60px'}}>
                                            <Box>
                                              {
                                                step === 3 && migrateOption !== "migratelocks" ?
                                                  <h4 style={{color:'#800000B3'}}>
                                                    {step === 4 ? "List of Users" : (step === 3 && migrateOption !== "migratelocks" ? "Selected : "+ selectedMigratedUsers.length : "Users Status")}  
                                                  </h4>
                                                :
                                                  <h3>
                                                    {step === 4 ? "List of Users" : (step === 3 && migrateOption !== "migratelocks" ? "Selected : "+ selectedMigratedUsers.length : "Users Status")}
                                                  </h3>
                                              }
                                            </Box>
                                            <Box sx={{color: '#800000B3',marginLeft:'auto',display:'flex',flexDirection:'colummn',alignItems:'center'}}>
                                                { step === 4 ? selectedMigratedUsers.length +" Selected" : (step === 3 && migrateOption !== "migratelocks" ? null : listOfCSVAssociatedUsers.length + " MIGRATED") }
                                            </Box>
                                          </Box>
                                      </Box>
                                      <Box sx={{border:'1px solid rgba(0,0,0,0.1)', borderTop: '1px solid transparent',overflowX:'hidden',height: "250px",overflowY:'scroll'}}>
                                          {
                                            listOfCSVAssociatedUsers.length > 0 ?
                                              listOfCSVAssociatedUsers.map((user)=> {
                                                return(
                                                    <Box sx={{display:'flex',flexDirection:"row",padding: "20px"}}>
                                                      {step === 4 || (step === 3 && migrateOption !== "migratelocks") ?<Box sx={{width:'40px',marginTop: '6px'}}>
                                                        <Tooltip placement='top' title="Select User" arrow>
                                                          <AddCircleOutline
                                                            id={user.email}
                                                            sx={{
                                                              display:
                                                              selectedMigratedUsers.includes(
                                                                user.email
                                                              )
                                                                ? 'none'
                                                                : 'block',
                                                              color: 'rgb(194,24,91)'
                                                            }}
                                                            onClick={() =>
                                                              modifiySelectedUsersList(user.email,"select")
                                                            }
                                                          />
                                                        </Tooltip>
                                                        <Tooltip placement='top' title="Remove User" arrow>
                                                          <RemoveCircleOutlined
                                                            className="removeCircle"
                                                              sx={{
                                                                display:
                                                                selectedMigratedUsers.includes(
                                                                  user.email
                                                                )
                                                                  ? 'block'
                                                                  : 'none',
                                                                color: 'rgb(194,24,91)'
                                                              }}
                                                              id={
                                                                user.email +
                                                                'RemoveCircleOutlineIcon'
                                                              }
                                                              onClick={() =>
                                                                modifiySelectedUsersList(user.email,"unselect")
                                                              }

                                                            />
                                                          </Tooltip>
                                                        </Box>: null}
                                                      <Box sx={{display:'flex',flexDirection:"column",width:step === 5 ? '95%' : '90%'}}>
                                                        <Box>
                                                          { user.firstName !== null && user.lastName !== null ? user.firstName + user.lastName : "No Name" }
                                                        </Box>
                                                        <Box>
                                                          {user.email}
                                                        </Box>
                                                      </Box>
                                                      {
                                                        step === 5 ?
                                                          <Box sx={{display:'flex',flexDirection:"column"}}>
                                                            <Tooltip placement='top' title="Migration Successful" arrow>
                                                              <Check sx={{color:'rgba(0,0,0,0.54)'}} />
                                                            </Tooltip>
                                                          </Box>
                                                        :
                                                          null
                                                      }
                                                    </Box>
                                                )
                                              })
                                            :
                                              <Box className="noMigrateUsers">
                                                No Users
                                              </Box>
                                          }
                                      </Box>
                                  </Box>
                              </Box>
                            :                                        
                              <Box className="step4migrateLocksDiv" sx={{width: '100%',justifyContent: 'center',alignItems: 'center',height: '200px'}}>
                                  <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                              </Box>
                            }
                          </>
                        :
                          null
                }
                <Box sx={{display: 'flex', paddingBottom: '8px'}}>
                  {
                    step === 1 ?
                      <Button
                        variant="text"
                        sx={{
                          marginLeft: 'auto',
                          width: '88px',
                          color: 'red',
                        }}
                        onClick={()=>{
                          nav("/admin/tenants");
                          window.location.reload();
                        }}
                      >
                        cancel
                      </Button>
                    :
                      null
                  }
                  {
                    step > 1 && step < 5  ?
                        <Button
                        variant="text"
                        sx={{
                          marginRight: 'auto',
                          width: '88px',
                        }}
                        onClick={()=>{
                          let steps = step;
                          setStep(steps-1);
                          handleBack();
                        }}
                      >
                        back
                      </Button>
                    :
                      null
                  }
                  {
                    step < 3 ?
                      <Button
                        variant="contained"
                        sx={{
                          marginLeft: step === 1 ? '6px' : 'auto',
                          background: 'rgb(20,19,139)',
                          marginRight: '10px',
                          '&.MuiButtonBase-root:hover': {
                            background: 'rgb(20,19,139)',
                          },
                          width: '88px',
                        }}
                        onClick={() => nextStep()}
                      >
                        Next
                      </Button>
                    :
                      step === 4 && migrateOption === "migratelocks"?
                        <Button
                          variant="contained"
                          sx={{
                            marginLeft: step === 1 ? '6px' : 'auto',
                            background: 'rgb(20,19,139)',
                            marginRight: '10px',
                            '&.MuiButtonBase-root:hover': {
                              background: 'rgb(20,19,139)',
                            },
                            width: '88px',
                          }}
                          disabled={selectedMigratedLocks.length === 0 && selectedMigratedUsers.length === 0}
                          onClick={() => handleMigration()}
                        >
                          Migrate
                        </Button>
                      :
                        step === 5 ?
                          <Button
                            variant="contained"
                            sx={{
                              marginLeft: step === 1 ? '6px' : 'auto',
                              background: 'rgb(20,19,139)',
                              marginRight: '10px',
                              '&.MuiButtonBase-root:hover': {
                                background: 'rgb(20,19,139)',
                              },
                              width: '88px',
                            }}   
                            onClick={()=> {
                              nav("/admin/tenants");
                              window.location.reload();
                            }}                                                   
                          >
                            Done
                          </Button>
                        :
                          step === 3 && migrateOption !== "migratelocks"?
                            <Button
                              variant="contained"
                              sx={{
                                marginLeft: step === 1 ? '6px' : 'auto',
                                background: 'rgb(20,19,139)',
                                marginRight: '10px',
                                '&.MuiButtonBase-root:hover': {
                                  background: 'rgb(20,19,139)',
                                },
                                width: '160px',
                              }}
                              disabled={selectedMigratedUsers.length === 0}
                              onClick={() => handleUserMigration()}
                            >
                              Migrate users
                            </Button>
                        :
                          null
                  }
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        showCreateDialog ?
          <DraggableComponent
            dialog="createNewTenant"
            title="Create New Tenant"
            showCreateNewTenantDialog={showCreateDialog}
            closeCreateNewTenantDialog={(value)=>setShowCreateDialog(false)}            
          />
        :
          null
      }
      {
        migrationDialog ?
          <Dialog
            open={migrationDialog}
            onClose={()=> setMigrationDialog(false)}
          >
            <DialogTitle>
                Confirm Migration - {selectedMigratedLocks.length} Locks and {selectedMigratedUsers.length} Users
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure you want to migrate the devices and users to <b>{selectedTenant.name}</b>? This operation cannot be undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=> setMigrationDialog(false)}>No</Button>
              <Button onClick={() => processMigration()}>YES</Button>
            </DialogActions>
          </Dialog>
        :
          null
      }
      {
        migrateUsersDialog ?
          <Dialog
            open={migrateUsersDialog}
            onClose={()=> migrateUsersDialog(false)}
          >
            <DialogTitle>
                Confirm Migration - {selectedMigratedUsers.length} Users
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure you want to migrate the device users to <b>{selectedTenant.name}</b>? This operation cannot be undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=> setMigrateUsersDialog(false)}>No</Button>
              <Button onClick={() => processUsersMigration()}>YES</Button>
            </DialogActions>
          </Dialog>
        :
          null
      }

      
    </>
  );
}
