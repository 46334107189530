import * as React from 'react';
import {styled} from '@mui/material/styles';
import { Box,Paper,Grid,Typography,List,ListItem,ListItemText,ListItemAvatar,Button,TextField,CircularProgress } from '@mui/material';
import { LockOutlined,PersonAdd,BrightnessHigh,Email,Done,Lock,VisibilityOff,Visibility } from '@mui/icons-material';
import lockImage from '../.././wesko_lock.png';
import { useNavigate } from "react-router-dom";
import '../../stylesheet/Login.css';
import {fontSize, padding, style} from '@mui/system';
import AuthClient from "../../clients/AuthClient";
import UserSessionStorage from '../../userSession/UserSessionStorage';
import _ from 'lodash';
import md5 from 'md5';



const Item = styled(Paper)(({theme}) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function Login() {
  const [password, setPassword] = React.useState('');
  const [name, setName] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [showForgotPwdVw, setShowForgotPwdVw] = React.useState(false);
  const [showLogin, setShowLogin] = React.useState(true);
  const [resetLinkSent, setResetLinkSent] = React.useState(false);
  const [orangeColor, setOrangeColor] = React.useState(false);
  const [orangeColorPassword ,setOrangeColorPassword] = React.useState(false);
  const [showErrorMessage, setShowErrorMessage] = React.useState(false);
  const [showPasswordResetErrorMessage, setShowPasswordResetErrorMessage] = React.useState(false);
  const [passwordType, setPasswordType] = React.useState("password");

  React.useEffect(()=>{
    sessionStorage.clear();
  },[])
  let user_details = {};
    const nav = useNavigate();
    const login = () => {
      user_details.email = name.toLowerCase();
      user_details.password = password;
      setIsLoading(true);
      AuthClient.login(response => {
          if (response.status === 200) {
            sessionStorage.showMqttClass = true;
            sessionStorage.userLoggedIn = true;
            sessionStorage.userPasscode = md5(user_details.password);
            AuthClient.fetchUserDetails().then(response =>{
              sessionStorage.setItem("userDetails",JSON.stringify(response));              
              if(response.isRootUser){
                  sessionStorage.isRootUser = true;
                  setIsLoading(false);
                  nav("/admin/tenants");
                  window.location.reload();
              }else{
                  setIsLoading(false);
                  sessionStorage.isRootUser = false;
                  sessionStorage.tenantId = response.user.scopeId;                    
                  AuthClient.getHirarchy().then((data) => {                    
                    if (data.scope.id === response.user.scopeId){
                      UserSessionStorage.setTenantName(data.scope.name);
                    }
                    nav("/cli/dashboard")
                    window.location.reload();
                  });
              }                
              });
          } else {
              setShowErrorMessage(true);
              setTimeout(() => {
                setShowErrorMessage(false);  
              }, 3000);
              setIsLoading(false);
              sessionStorage.userLoggedIn = false;
          }

      }, user_details);
  };

    const forgotPassword = () => {
        user_details.email = name;
        setIsLoading(true);
        sessionStorage.clear();
        AuthClient.forgotPassword(response => {
            if (response.status === 200) {
              setIsLoading(false);
              sessionStorage.userLoggedIn = true;
              setResetLinkSent(true);
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            } else {
              setIsLoading(false);
              setShowPasswordResetErrorMessage(true);
              setTimeout(() => {
                setShowPasswordResetErrorMessage(false);
              }, 1500);

            }

        }, user_details);
    };

    const showForgotPwd = () => {
    setShowForgotPwdVw(true);
  };
  const hideForgotPwd = () => {
    setShowForgotPwdVw(false);
  };

    const togglePassword =()=>{
        if(passwordType==="password")
        {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

  return (
  <>

    <Box
      sx={{
        background: '#fff !important',
        overflowY: 'auto',
        height: '100%',
        display: 'flex',
        color:'#000000DE !important',
        flexDirection: 'column',
      }}
      className="loginComponent"
    >
        <Box sx={{height: '2px'}}>

        </Box>
          <Box
            sx={{
              background: '#fff',
              paddingBottom: '1px',
              marginTop: '-6px',
              height: 'calc(98% - 2px)'
            }}
          >
            <Box sx={{flexGrow: 1, margin: '8px', padding: '8px 0px 0px 8px', height: '100%'}}>
              <Grid container direction="row" spacing={2} sx={{height: '100%'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          textAlign: 'center',
                          margin: '8px',
                          padding: '8px',
                        }}
                      >
                        <Typography
                          mt={2}
                          style={{
                            fontWeight: '600',
                            fontSize: '1.5em',
                            width: '100%',
                          }}
                        >
                          Welcome to Wesko eLocks Cloud Management Portal
                        </Typography>
                      </Box>

                      <List sx={{width: '100%', background: 'transparent'}}>
                        <ListItem>
                          <ListItemAvatar>
                            <LockOutlined sx={{fontSize: '36px', margin: '0 12px'}} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Typography
                                variant="h6"
                                style={{fontSize: '20px', fontWeight: '600'}}
                              >
                                Manage Lock Users
                              </Typography>
                            }
                            secondary={
                              <Typography
                                variant="h6"
                                style={{
                                  fontSize: '16px',
                                  fontWeight: '400',
                                  letterSpacing: '.010em',
                                  lineHeight: '24px',
                                }}
                              >
                                and remotely setup on locks
                              </Typography>
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <PersonAdd sx={{fontSize: '36px', margin: '0 12px'}} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Typography
                                variant="h6"
                                style={{fontSize: '20px', fontWeight: '600'}}
                              >
                                Remotely Control Locks
                              </Typography>
                            }
                            secondary={
                              <Typography
                                variant="h6"
                                style={{
                                  fontSize: '16px',
                                  fontWeight: '400',
                                  letterSpacing: '.010em',
                                  lineHeight: '24px',
                                }}
                              >
                                if users forget their password
                              </Typography>
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <BrightnessHigh sx={{fontSize: '36px', margin: '0 12px'}} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Typography
                                variant="h6"
                                style={{fontSize: '20px', fontWeight: '600'}}
                              >
                                Display Real Time Lock Status
                              </Typography>
                            }
                            secondary={
                              <Typography
                                variant="h6"
                                style={{
                                  fontSize: '16px',
                                  fontWeight: '400',
                                  letterSpacing: '.010em',
                                  lineHeight: '24px',
                                }}
                              >
                                and change lock settings
                              </Typography>
                            }
                          />
                        </ListItem>
                      </List>
                      <img className="wesko_lock_image" src={lockImage} alt="logo" />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Box
                        sx={{
                          backgroundColor: !isLoading ? '#f5f5f5' : '#fff',
                          position: 'relative',
                          margin: '24px 12px',
                          padding: '32px',
                          opacity: isLoading && !resetLinkSent ? 0.5 : null
                        }}
                      >
                        <Typography
                          mt={2}
                          style={{
                            display: !resetLinkSent ? 'block' : 'none',
                            fontSize: '1.17em',
                            marginBlockStart: '1em',
                            marginBlockEnd: '1em',
                            marginInlineStart: '0px',
                            marginInlineEnd: '0px',
                            fontWeight: 'bold',
                          }}
                        >
                          {showForgotPwdVw ? "Enter your email address" : "Sign in with existing account"}
                        </Typography>

                        <Box
                          sx={{
                            display: !resetLinkSent ? 'flex' : 'none',
                            alignItems: 'flex-end',
                            margin: '18px 0',
                          }}
                        >
                          <Email sx={{color: orangeColor ? 'rgb(237,87,83)' : null, mr: 1, my: 0.5}} />
                          <TextField
                            fullWidth
                            id="input-with-sx"
                            type="email"
                            label={showForgotPwdVw ? "Email Address" : "Username"}
                            variant="standard"
                            value={name}
                            error={name ? (name.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) ? false : true) : false}
                            onFocus={()=>setOrangeColor(true)}
                            onBlur={()=>setOrangeColor(false)}
                            onKeyPress={(event)=>{
                              if (event.key === 'Enter'){
                                login();
                              }
                            }}
                            InputLabelProps={{
                              style: { color: orangeColor? 'rgb(237,87,83)' : null },
                            }}      
                            onChange={(event) => {
                              setName(event.target.value);
                            }}
                          />
                        </Box>
                        {
                          showPasswordResetErrorMessage ?
                            <Box sx={{textAlign:"center",fontSize:"14px",color:"red"}}>
                              Error: Please try again
                            </Box>
                          :
                            null
                        }
                        <Box
                          sx={{
                            display: showForgotPwdVw ? 'none' : 'flex',
                            alignItems: 'flex-end',
                            margin: '18px 0',
                          }}
                        >
                          <Lock sx={{color: orangeColorPassword ? 'rgb(237,87,83)' : null, mr: 1, my: 0.5}} />
                          <TextField
                            fullWidth
                            id="input-with-sx"
                            label="Password"
                            type={passwordType}
                            variant="standard"
                            value={password}
                            onChange={(event) => {
                              setPassword(event.target.value);                              
                            }}
                            onFocus={()=>setOrangeColorPassword(true)}
                            onBlur={()=>setOrangeColorPassword(false)}
                            InputLabelProps={{
                              style: { color: orangeColorPassword ? 'rgb(237,87,83)' : null },
                            }}
                            onKeyPress={(event)=>{
                              if (event.key === 'Enter'){
                                login();
                              }
                            }}
                          />
                            <button onClick={togglePassword} style={{width: '36px', height: '32px', border: "none",}}>
                                { passwordType==="password"? <VisibilityOff></VisibilityOff> :<Visibility></Visibility>}
                            </button>
                        </Box>                      

                        <Box component="span"
                            sx={{
                                display: showForgotPwdVw ? 'none' : 'block',
                            }}>
                          {
                            showErrorMessage ?
                              <Box sx={{color: 'red',fontSize: '12px',display: 'flex',alignItems: 'center',justifyContent: 'center'}}>
                                Invalid Username or Password
                              </Box>
                            :
                              null
                          }                          
                          <Button
                            variant="contained"
                            style={{
                              width: '100%',
                              margin: '20px 0',
                              color: 'rgba(255,255,255,0.87)',
                              backgroundColor: 'rgb(20,19,139)',
                            }}
                            onClick={() => login()}
                          >
                            LOGIN
                          </Button>
                          {
                              isLoading ?
                                  <Box sx={{width: 'calc(100% - 17px)',display: 'flex',justifyContent: 'center',alignItems: 'center',height: '100%',position: 'absolute',top: '-19px',right:'8px'}}>
                                      <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                                  </Box>
                              :
                                  null
                          }
                        </Box>

                        <Box
                            sx={{
                              display: showForgotPwdVw && !resetLinkSent ? 'flex' : 'none',
                              alignItems: 'center',
                              margin: '8px',
                              textAlign: 'center',
                              flexDirection: 'row-reverse',
                            }}
                          >
                            <Button
                              variant="contained"
                              style={{minWidth: '88px', margin: '8px 6px',
                              color: 'rgba(255,255,255,0.87)',
                              backgroundColor: 'rgb(20,19,139)',}}
                              onClick={() => forgotPassword()}
                            >
                              RESET PASSWORD
                            </Button>
                            <Button
                              variant="text"
                              style={{
                                minWidth: '88px',
                                margin: '8px 6px',
                                color: 'rgba(0,0,0,0.87)',
                              }}
                              onClick={() => hideForgotPwd()}
                            >
                              CANCEL
                            </Button>
                            {
                              isLoading ?
                                  <Box sx={{width: 'calc(100% - 17px)',display: 'flex',justifyContent: 'center',alignItems: 'center',height: '100%',position: 'absolute',top: '-19px',right:'8px'}}>
                                      <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                                  </Box>
                              :
                                  null
                            }
                          </Box>


                          <Box
                              sx={{
                                  display: resetLinkSent ? 'block' : 'none',
                                  textAlign: 'center',
                                  cursor: 'pointer',
                              }}
                          >
                          <Typography mt={2}
                              style={{
                                  color: "#008000",
                              }}
                          >
                              <Done sx={{position:"relative",top:"6px",right:"4px"}}/>
                              Success
                          </Typography>
                              <Typography mt={2}
                                  style={{
                                  }}
                              >
                                  An email with reset password link has been sent to your Email Id.
                              </Typography>
                          </Box>
                        <Typography
                          mt={2}
                          style={{
                            display: showForgotPwdVw ? 'none' : 'block',
                            fontWeight: '350',
                            fontSize: '1em',
                            textAlign: 'center',                            
                          }}
                        >
                          <span style={{cursor: 'pointer'}} onClick={() => showForgotPwd()}>
                            Forgot Password?
                          </span>
                        </Typography>
                      </Box>
                    </Grid>
                </Grid>
                <Grid item sx={{margin: '8px 8px 0px 8px',width: '100%'}}>
                    <Typography
                      style={{
                        fontWeight: '350',
                        fontSize: '1em',
                        textAlign: 'center',
                        background: '#fff',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'end',
                        height: '100%',
                        padding: '8px',
                        paddingBottom: '0px'
                      }}
                    >
                      Powered by Caribou Technologies
                   </Typography>
                </Grid>
              </Grid>
            </Box>
            </Box>
        </Box>
    </>
  );
}
