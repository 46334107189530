import * as React from 'react';
import { Dialog,DialogTitle,DialogActions,LinearProgress,DialogContent,Button,Table,TableBody,TableCell,TableContainer,TableHead,TablePagination,TableRow,TableSortLabel,Box,Checkbox,Select,MenuItem,Tooltip,RadioGroup,Radio,useTheme,useMediaQuery } from '@mui/material';
import { Edit,Close,Search,Lock,ReportProblem,LockOpen,People,CheckCircle,AlarmAdd,ArrowDropDown,Check,Refresh,Settings,FirstPage,LastPage,KeyboardArrowLeft,KeyboardArrowRight,Download,Info,Wifi,SignalWifiOff,Delete,ViewHeadline } from '@mui/icons-material';
import {visuallyHidden} from '@mui/utils';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { AdapterDateFns,LocalizationProvider,DateTimePicker } from '@mui/x-date-pickers';
import "../stylesheet/common.css";
import _ from "lodash";
import { CircularProgress, DialogContentText, FormControl, Paper, Popover, requirePropFactory, TextField,Stack,Chip,Autocomplete } from '@mui/material';
import { useSnackbar } from 'notistack';
import Draggable from 'react-draggable';
import MqttEventHandler from './Mqtt/MqttEventHandler';



function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export default function ForceProbeDialog(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));    
    const getWindowDimensions = () => {
        const {innerWidth: width, innerHeight: height} = window;
        return {
        width,
        height,
        };
    };

    const [Dimensions, setDimensions] = React.useState(getWindowDimensions());

    const [forceProbedLockName, setForceProbedLockName] = React.useState([]);

    const { enqueueSnackbar } = useSnackbar();

    const [showForceProbeLocklistUploadCsvDialog, setShowForceProbeLocklistUploadCsvDialog] = React.useState(false);

    const [errorMsg, setErrorMsg] = React.useState("");

    const [showErrorMsg, setShowErrorMsg] = React.useState(false);

    const [selectedForceProbeCsvFile, setSelectedForbeProbeCsvFile] = React.useState(null);

    const readCsvFile = () => {
        setShowErrorMsg(false);
        setErrorMsg("");
        let fileReader = new FileReader();
        let uploadedFile = document.getElementById('uploadCSV');
        let filteredLockNames = [];
        fileReader.readAsText(uploadedFile.files[0]);
        forceProbedLockName.forEach((element) => {
          filteredLockNames.push(element);
        });
    
        fileReader.onload = () => {
          let lockNames = fileReader.result.split('\n');
          let csvHasStart = false;
          if(lockNames[0] !== 'Lock MacId' && lockNames[0] !== ''){
            setShowErrorMsg(true);
            setErrorMsg("Headers of selected csv file are not valid, please check and retry");
            return;
          } else if(lockNames[0] === ''){
            setShowErrorMsg(true);
            setErrorMsg("Uploaded file is empty, please check and retry.");
          } else if(lockNames[1] === ''){
            setShowErrorMsg(true);
            setErrorMsg("Uploaded file is empty, please check and retry.");
          }
          else {
            lockNames.forEach((element, index) => {
              if (index === 0) {
                if (element === 'Lock MacId') {
                  return;
                }
              }
              if (forceProbedLockName.toString().indexOf(element) === -1) {
                if (element  !== "*"){                
                    filteredLockNames.push(element);
                }
              }
            });
            filteredLockNames = _.uniq(filteredLockNames.sort());
            setForceProbedLockName(filteredLockNames);
            setShowForceProbeLocklistUploadCsvDialog(false);
          }
        };
        setSelectedForbeProbeCsvFile("");
      };

    const handleForceProbeLocksNames = (event, lockNames) => {
        let allLocks = [];    
        lockNames.forEach((element) => {
            if (element !== "*"){
                allLocks.push(element);
            }
        });
        setForceProbedLockName(allLocks);
    };

    const publishForceProbeLockNames = () => {
        let forceProbeLocksPayload = JSON.stringify(forceProbedLockName);
        MqttEventHandler.publish("gateways/"+props.data.macId+"/receive/devices/forceProbe", forceProbeLocksPayload , {qos: 2, retain: false}, function() {});
        enqueueSnackbar("Successfully published force probe")
        props.closeDialog(false);
    };

    return (
        <>
            <Dialog
                fullWidth={true}
                fullScreen={fullScreen}
                open={true}    
                onClose={()=> props.closeDialog()}                            
                PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                aria-labelledby="draggable-dialog-title"
                >
                <DialogTitle style={{
                    background: 'rgb(20,19,139)',
                    display: 'flex',
                    flexDirection: 'row',
                    color: 'white',
                }}>
                    <Box sx={{flex: '95'}}>Manage Force Probe</Box>
                    <Box sx={{flex: '5', marginTop: '6px'}}>
                        <Close onClick={() => props.closeDialog()} />
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Stack sx={{marginTop: '14px', marginBottom: '14px'}}>
                    <Autocomplete
                        id="whiteListLockNames"
                        multiple
                        options={[]}
                        freeSolo
                        value={forceProbedLockName}
                        onChange={(event, value) => handleForceProbeLocksNames(event,value)}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    variant="outlined"
                                    label={option}
                                    {...getTagProps({index})}
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField {...params} placeholder="Enter Lock MacId" />
                        )}
                    />
                    </Stack>
                    <DialogContentText sx={{fontSize: '12px'}}>
                    Enter Lock macId for force probe. After typing each Lock MacId, press 'return / enter' key.
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    sx={{
                        display: 'flex',
                        flexDirection: Dimensions.width <= 400 ? 'column' : 'row',
                    }}
                >
                    <Button onClick={()=> {setShowForceProbeLocklistUploadCsvDialog(true)}}>
                        Upload csv
                    </Button>
                    <Button
                        onClick={() =>setForceProbedLockName([])}
                        style={{color: 'ponk'}}
                    >
                    clear list
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => publishForceProbeLockNames()}
                        sx={{background: 'rgb(20, 19, 139)'}}
                    >
                        publish list
                    </Button>
                </DialogActions>
            </Dialog>  
            {
                showForceProbeLocklistUploadCsvDialog ?
                  <Dialog
                    fullWidth={true}
                    fullScreen={fullScreen}
                    open={showForceProbeLocklistUploadCsvDialog}
                    onClose={() => setShowForceProbeLocklistUploadCsvDialog(false)}
                    PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                    aria-labelledby="draggable-dialog-title"
                  >
                    <DialogTitle
                      id="responsive-dialog-whitelist-title"
                      style={{
                        background: 'rgb(20,19,139)',
                        display: 'flex',
                        flexDirection: 'row',
                        color: 'white',
                      }}
                    >
                    <Box sx={{flex: '95'}}>
                      Upload Force Probe CSV
                    </Box>
                    <Box sx={{flex: '5', marginTop: '6px'}}>
                      <Close onClick={() => {
                        setShowForceProbeLocklistUploadCsvDialog(false)
                        setShowErrorMsg(false);
                        setErrorMsg("");
                      }} />
                    </Box>
                  </DialogTitle>
                    <DialogContent>
                      <Box sx={{marginTop:"14px"}}>
                        Select a CSV file (<i>filename.csv</i>) with Lock names.&nbsp;
                        <a href="/probeList.csv" download>Click here</a>&nbsp;to download sample file.
                      </Box>
                      <center>
                        <input
                            type="file"
                            accept=".csv"
                            style={{display: 'none'}}
                            id="uploadCSV"
                            value={selectedForceProbeCsvFile}
                            onChange={() => readCsvFile()}
                        />
                        <label htmlFor="uploadCSV">
                          <Button variant="standard" 
                            sx={{marginLeft: 'auto',
                            background: 'rgb(20,19,139)',
                            color: '#fff',
                            marginRight: '10px',
                            '&.MuiButtonBase-root:hover': {
                                background: 'rgb(20,19,139)',
                            },
                            width: '167px',
                            margin: '15px'}} component="span">
                            UPLOAD CSV File
                          </Button>
                        </label>
                        <Box sx={{display:showErrorMsg ? "flex" : "none", marginTop : '10px', color : 'red'}}>
                           {errorMsg}
                         </Box>
                      </center>
                    </DialogContent>
                  </Dialog>
                :
                  null
              }
        </>      
    )
    
}
