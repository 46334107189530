import * as React from 'react';
import { Button,Dialog,DialogActions,DialogContent,DialogTitle,Box,Select,MenuItem,InputLabel,TextField,useMediaQuery,useTheme,Fab,FormControl, DialogContentText, CircularProgress,Paper, Snackbar } from '@mui/material';
import { PersonAddRounded,Edit,Close,Delete } from '@mui/icons-material';
import '../stylesheet/HomePage.css';
import Draggable from 'react-draggable';
import AuthClient from "../clients/AuthClient";
import IotServiceClient from "../clients/IotServiceClient";
import GatewayServiceClient from "../clients/GatewayServiceClient";
import Permissions from '../components/portalusers/Permissions'
import _ from 'lodash';


function PaperComponent(props) {
  return (
      <Draggable
          handle=".draggable-dialog-title"
          cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
  );
}

export default function DraggableHomPageComponent(props) {
  let [close, setClose] = React.useState(true);
    
    const [errorForOldPassword, setErrorForOldPassword] = React.useState(false);
    const [tenantLabel, setTenantLabel] = React.useState(null);
    const [errorForTenantLabel, setErrorForTenantLabel] = React.useState(false);
    const [firstName, setFirstName] = React.useState(null);
    const [errorForFirstName, setErrorForFirstName] = React.useState(false);
    const [lastName, setLastName] = React.useState(null);
    const [errorForLastName, setErrorForLastName] = React.useState(false);
    const [superAdminEmail, setSuperAdminEmail] = React.useState(null);
    const [errorForSuperAdminEmail, setErrorForSuperAdminEmail] = React.useState(false);
    const [superAdminPassword, setSuperAdminPassword] = React.useState(null);
    const [errorForSuperAdminPassword, setErrorForSuperAdminPassword] = React.useState(false);
    const [defaultAdminPassword, setDefaultAdminPassword] = React.useState(null);
    const [errorForDefaultAdminPassword, setErrorForDefaultAdminPassword] = React.useState(false);
    const [domainName, setDomainName] = React.useState(null);
    const [errorForDomainName, setErrorForDomainName] = React.useState(false);
    const [isValidToSubmit, setIsValidToSubmit] = React.useState(false);
    const [isLoading , setIsLoading] = React.useState(false);
    const [isChanged, setIsChanged] = React.useState(false);
    const [tenantDetails, setTenantDetails] = React.useState({});
    const [tenantOwner, setTenantOwner] = React.useState({});
    const [allValidEmails, setAllValidEmails] = React.useState([]);
    const [invalidEmailId, setInvalidEmailId] = React.useState(false);   
    const [showSnackBar, setShowSnackBar] = React.useState("");
    const [showSnackBarMessage ,setShowSnackBarMessage] = React.useState("");
    const [sameValuesAsExistingError , setSameValuesAsExistingError] = React.useState(false);
    const [preservedSuperAdminEmail, setPreservedSuperAdminEmail] = React.useState("");
    const [preservedSuperAdminPassword, setPreservedSuperAdminPassword] = React.useState("");;
    const [preservedDefaultAdminPassword, setPreservedDefaultAdminPassword] = React.useState("");;

  const checkSpace = (name, setField) => {
    if(name === " " || name.indexOf(" ") === 0){
        if(name.length <= 1){
            setField("");
        }else{
            setField( name ? name.trim() : "");
        }
    }
  }

  const isValid = (name) => {
    return name !== "" && name !== null && typeof name !== 'undefined' && name !== " " && name.trim()!== "";
  }

  const checkOnTenantLabel = (name) => {
    setTenantLabel(name);
    if (isValid(name)) {
      setErrorForTenantLabel(false);
      checkForValidity("label", false);
    } else {
      setErrorForTenantLabel(true);
      checkForValidity("label", true);
    }
    checkSpace(name, setTenantLabel);
  };

  const checkOnFirstName = (name) => {
    setFirstName(name);
    if (isValid(name)) {
      setErrorForFirstName(false);
      checkForValidity("firstName", false);
    } else {
      setErrorForFirstName(true);
      checkForValidity("firstName", true);
    }    
    checkSpace(name, setFirstName);
  };

  const checkOnLastName = (name) => {
    setLastName(name);
    if (isValid(name)) {
      setErrorForLastName(false);
      checkForValidity("lastName", false);
    } else {
      setErrorForLastName(true);
      checkForValidity("lastName", true);
    }    
    checkSpace(name, setLastName);
  };

  const onSuperAdminEmailChange = (email) => {    
    setSuperAdminEmail(email);
    if (isValid(email)) {
      let regex = new RegExp("^[a-zA-Z0-9+_.\-]+@[a-zA-Z0-9_.\-]+[.]{1}[a-z]{2,3}$");
      let emailConditionMatched = email.match(regex);
      setErrorForSuperAdminEmail(!emailConditionMatched);
      if (props.buttonName === "update"){
        setInvalidEmailId(!allValidEmails.includes(email))
      }
      checkForValidity("email", !emailConditionMatched);
    } else {
      setErrorForSuperAdminEmail(true);
      checkForValidity("email", true);
    }
  };

  const onSuperAdminPasswordChange = (password) => {      
    setSuperAdminPassword(password);
    if (isValid(password)) {
      let passwordCondition = password.match(/\d{6,6}/)
      setErrorForSuperAdminPassword(!passwordCondition);
      checkForValidity("adminPassword", !passwordCondition)
    } else {
      setErrorForSuperAdminPassword(true);
      checkForValidity("adminPassword", true)
    }
  };

  const onDefaultAdminPasswordChange = (password) => {    
    setDefaultAdminPassword(password);      
    if (isValid(password)) {
      let passwordCondition = password.match(/\d{5,5}/)
        setErrorForDefaultAdminPassword(!passwordCondition);
        checkForValidity("defaultPassword", !passwordCondition);
    } else {
      setErrorForDefaultAdminPassword(true);
      checkForValidity("defaultPassword", true)
    }    
  };

  const onDomainNameChange = (name) => {
    setDomainName(name);
    const domainFormat = /^[\w-]{2}[\w-]+$/;
    if (isValid(name)) {
      let domainCondition = name.match(domainFormat)
      setErrorForDomainName(!domainCondition);
      checkForValidity("domainName", !domainCondition);
    } else {
      setErrorForDomainName(true);
      checkForValidity("domainName", true);
    }
  }

  const checkForValidity = (modifiedField, errorForModifiedField) => {
    if (props.buttonName.toLowerCase() === 'create') {
      let submitCondition = (modifiedField === "label" ? !errorForModifiedField : (isValid(tenantLabel) ? !errorForTenantLabel : false)) && 
      (modifiedField === "firstName" ? !errorForModifiedField : (isValid(firstName) ?  !errorForFirstName : false)) && (modifiedField === "lastName" ? !errorForModifiedField : (isValid(lastName) ? !errorForLastName : false)) &&
       (modifiedField === "email" ? !errorForModifiedField : (isValid(superAdminEmail) ? !errorForSuperAdminEmail: false)) &&
       (modifiedField === "adminPassword" ? !errorForModifiedField : (isValid(superAdminPassword) ? !errorForSuperAdminPassword : false)) && (modifiedField === "defaultPassword" ? !errorForModifiedField : (isValid(defaultAdminPassword) ? !errorForDefaultAdminPassword: false)) &&
        (modifiedField === "domainName" ? !errorForModifiedField : (isValid(domainName) ? !errorForDomainName : false));
      setIsValidToSubmit(submitCondition)
    } else {
      setIsChanged(true);
      setIsValidToSubmit(!errorForSuperAdminPassword && !errorForDefaultAdminPassword && !errorForSuperAdminEmail);
    }    
  }

  const creatNewTenant = () => {
    let newTenantDetails = {
      emailDomain: domainName,
      lockDefaultAdminPasscode: defaultAdminPassword,
      lockSuperUserPasscode: superAdminPassword,
      parent: "5947df4362a1280006cdd54b",
      name: tenantLabel
    }
    AuthClient.createScope(response => {
          if (response){            
            let portalUser = {};
            portalUser.firstName = firstName;
            portalUser.lastName = lastName;
            portalUser.email = superAdminEmail;
            portalUser.permissions = Object.values(Permissions.userPermissions.superAdminPermissions);
            portalUser.scopeId = response.id;
            portalUser.owner = "true";  
            const rootTenantScopeId = '5947df4362a1280006cdd54b';
            IotServiceClient.getEmailTemplate(rootTenantScopeId, "homePage").then((template)=>{
                let payload = {};
                payload.scopeId = response.id;
                payload.welcomeEmailTemplate = template.welcomeEmailTemplate;
                payload.registrationEmailTemplate = template.registrationEmailTemplate;
                payload.alertEmailTemplate = template.alertEmailTemplate;
                payload.welcomeLockEmailTemplate = template.welcomeLockEmailTemplate;
                payload.passwordEmailTemplate = template.passwordEmailTemplate;
                payload.welcomeRfidLockEmailTemplate = template.welcomeRfidLockEmailTemplate;
                payload.welcomeSelfUserEmailTemplate = template.welcomeSelfUserEmailTemplate;
                AuthClient.updateEmailTemplate(res => {                          
                  AuthClient.createPortalUser(createRes => {
                    setShowSnackBar(true)
                    setIsLoading(false);
                    setShowSnackBarMessage("updated successfully");
                    setIsLoading(false);
                    props.handleCloseDialogBox(false);
                    props.refreshData();
                    setClose(false);
                    setTimeout(() => {
                      setShowSnackBar(false);  
                      setIsLoading(false);
                    }, 2001);                                            
                  },portalUser)           
                  setShowSnackBar(true)
                  setShowSnackBarMessage("New customer created successfully");
                  setTimeout(() => {
                    setShowSnackBar(false);
                  }, 2001);         
                },payload);
            })                     
          }else {            
            setShowSnackBar(true)
            setShowSnackBarMessage("Error while creating customer: Conflict");
            setTimeout(() => {
              setShowSnackBar(false);  
              setIsLoading(false);
            }, 2001); 
          }
        }, newTenantDetails);        
  }

  const resetAllData = () => {
    setTenantLabel(null);
    setErrorForTenantLabel(false);
    setFirstName(null);    
    setErrorForFirstName(false);
    setLastName(null);
    setErrorForLastName(false);
    setSuperAdminEmail(null);
    setErrorForSuperAdminEmail(false);
    setSuperAdminPassword(null);
    setErrorForSuperAdminPassword(false);
    setDefaultAdminPassword(null);
    setErrorForDefaultAdminPassword(false);
    setDomainName(null);
    setErrorForDomainName(false);
    setIsValidToSubmit(false);
    setIsLoading(false);
    setIsChanged(false);
    setTenantDetails({});
    setTenantOwner({});
    setAllValidEmails([]);
    setInvalidEmailId(false);   
    setShowSnackBar("");
    setShowSnackBarMessage("");
    setPreservedSuperAdminEmail("");
    setPreservedDefaultAdminPassword("");
    setPreservedSuperAdminPassword("");
  }

  const createTenant = () => {
    if (!props.allExistingDomains.includes(domainName)){
        
        setIsLoading(true)
        AuthClient.checkDuplicateUserEmail(superAdminEmail).then((data) => {          
            if(!data[0]){
              creatNewTenant();
            }else {
              setIsLoading(false);
              data[0].email = superAdminEmail;
              props.showEmailDomainAlreadyExistDialog(data);
            }  
        });        
        
    }else {
      setShowSnackBar(true)
      setShowSnackBarMessage("Error while creating customer: Conflict");
      setTimeout(() => {
        setShowSnackBar(false);                  
      }, 2001);      
    }    
  };  

  const fetchData = () => {
    setIsLoading(true);
    AuthClient.getScopeData(props.data.id).then((response) =>{        
        setIsLoading(false);
        let responseData = response;
        setTenantDetails(response);
        AuthClient.getPortalUsersData(props.data.id).then((subResponse) => {
          setIsLoading(false);
          let emails = [];
          _.forEach(subResponse,(val)=>{
            emails.push(val.email);
          })
          setAllValidEmails(emails);
          let index = subResponse.findIndex(obj => obj.owner)
          setFirstName(subResponse[index].firstName)
          setLastName(subResponse[index].lastName)
          setSuperAdminEmail(subResponse[index].email)
          setDefaultAdminPassword(responseData.lockDefaultAdminPasscode);
          setSuperAdminPassword(responseData.lockSuperUserPasscode);
          setDomainName(responseData.emailDomain);
          setTenantLabel(responseData.name);
          setTenantOwner(subResponse[index]);
          setErrorForTenantLabel(false);
          setErrorForFirstName(false);
          setErrorForLastName(false);
          setErrorForSuperAdminEmail(false);
          setErrorForSuperAdminPassword(false);
          setErrorForDefaultAdminPassword(false);
          setErrorForDomainName(false);
          setPreservedSuperAdminEmail(subResponse[index].email);
          setPreservedDefaultAdminPassword(responseData.lockDefaultAdminPasscode);
          setPreservedSuperAdminPassword(responseData.lockSuperUserPasscode);
        }).catch((error)=>{
            //TODO need to implement snackbar
        });
    }).catch((error) => {
        //need to imeplement snackbar
    });
  }

  React.useEffect(() =>{
    if (props.openDialogBox && props.buttonName === "update"){
      fetchData();
    }else if(props.buttonName === "create"){
      resetAllData();
    }    
  },[props.openDialogBox])

  React.useEffect(()=> {
    return ()=> {
      IotServiceClient.abortSignal("homePage")
    }
  },[])
  

  const updateTenant = () => {
    let networkCallCount  = 0;
    let networkCallSuccessCount = 0;
    setIsLoading(true);
    let insideELseCount = 0;
    if (superAdminEmail !== tenantOwner.email) {
        tenantOwner.email = superAdminEmail;
        networkCallCount+=1;
        AuthClient.updatePortalUser(response => {
            networkCallSuccessCount +=1;
            if (response.status === 200){
              setShowSnackBar(true)
              setShowSnackBarMessage("Details updated successfully");
              setTimeout(() => {
                setShowSnackBar(false);                
                setClose(false);  
                window.location.reload()
              }, 2001); 
            }else {
              setShowSnackBar(true)
              setShowSnackBarMessage("Failed to update details");
              setTimeout(() => {
                setShowSnackBar(false);                
                setClose(false);  
                window.location.reload()
              }, 2001); 
            }
        }, tenantOwner);
    }else {
      insideELseCount+=1; 
    }

    if (superAdminPassword != tenantDetails.lockSuperUserPasscode ||
        defaultAdminPassword != tenantDetails.lockDefaultAdminPasscode) {
        tenantDetails.lockSuperUserPasscode = superAdminPassword;
        tenantDetails.lockDefaultAdminPasscode = defaultAdminPassword;
        networkCallCount +=1
        AuthClient.updateScope(response => {
          networkCallSuccessCount+=1;
          if (response.status === 200){
            setShowSnackBar(true)
            setShowSnackBarMessage("Details updated successfully");
            setTimeout(() => {
              setShowSnackBar(false);                
              setClose(false);  
              window.location.reload()
            }, 2001); 
          }else {
            setShowSnackBar(true)
            setShowSnackBarMessage("Failed to update details");
            setTimeout(() => {
              setShowSnackBar(false);                
              setClose(false);  
              window.location.reload()
            }, 2001); 
          }
        }, tenantDetails);
    }else {
      insideELseCount+=1;
    }     
    if (insideELseCount === 2) {
      setShowSnackBar(true)
      setShowSnackBarMessage("Failed to update details");
      setTimeout(() => {
        setShowSnackBar(false);                
        setClose(false);  
        window.location.reload()
      }, 2001);
    }
  }

  const deleteTenant = () => {
     let payload = {payload: {removalScopeId: props.scopeId }, type: "REMOVE_TENANT"};
      setIsLoading(true);  
      GatewayServiceClient.createInstruction(response => {
          if (response.status === 201 || response.status === 200){
            setShowSnackBar(true)
            setShowSnackBarMessage("Successfully created delete customer instruction");
            setTimeout(() => {
              setShowSnackBar(false);
              props.handleCloseDialogBox(false);
              setIsLoading(false)
              setClose(false);  
              window.location.reload();
            }, 2001);                         
        }else {
          setShowSnackBar(true)
          setShowSnackBarMessage("Error while deleting tenant");
          setTimeout(() => {
            setShowSnackBar(false);  
            props.handleCloseDialogBox(false);
            setIsLoading(false)
            setClose(false);            
          }, 2001); 
        }
      }, payload);      
  };

  const handle_close_dialog = () => {
    props.handleCloseDialogBox(false);
    setClose(false);
    if (props.title === "Create New Tenant"){     
      resetAllData();
    }else if (props.title === "Update Tenant") {
      fetchData();
    }
  };

  let showDialog = function () {
    if (close === false) {
      setClose(true);
    }
    props.iconClicked(true);
  };

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
      <div onClick={() => showDialog()} style={{width: '100%'}}>
        {props.buttonName.toLowerCase() === 'create' ? (
            <Fab
                style={{
                  position: 'fixed',
                  bottom: '37px',
                  right: '40px',
                  height: '56px',
                  width: '56px',
                  miWwidth: '55px',
                  maxWidth: '55px',
                  borderRadius: '50%',
                  backgroundColor: 'rgb(20,19,139)',
                }}
            >
              <PersonAddRounded style={{color: 'white'}} />
            </Fab>
        ) : props.buttonName.toLowerCase() === 'delete' ? (
            <>
              <Box
                  sx={{
                    display: props.hasOwnProperty('iconColor') ? 'inline-flex' : null,
                  }}
              >
                <Box>
                  <Delete
                      style={{
                        margin: 'auto 16px auto 0',
                        fontSize: '18px',
                        paddingLeft: '6px',
                        paddingTop: '6px',
                        color: 'grey',
                      }}
                  />
                </Box>
                <Box className="deleteText" style={{paddingTop: '6px'}}>
                  Delete
                </Box>
              </Box>
            </>
        ) : (
            <>
              <Box
                  sx={{
                    display: props.hasOwnProperty('iconColor') ? 'inline-flex' : null,
                  }}
              >
                <Box>
                  <Edit
                      style={{
                        margin: 'auto 16px auto 0',
                        fontSize: '18px',
                        paddingLeft: '6px',
                        paddingTop: '6px',
                        color:  'grey',
                      }}
                  />
                </Box>
                <Box className="editText" style={{paddingTop: '5px'}}>
                  Edit
                </Box>
              </Box>
            </>
        )}

        {
          props.openDialogBox ?
                  <Dialog
                      fullWidth={true}
                      fullScreen={fullScreen}
                      open={close === false ? false : props.openDialogBox}
                      onClose={() => props.handleCloseDialogBox(false)}
                      PaperComponent={props.dimentionWidth >= 600 ? PaperComponent : null}
                      aria-labelledby="responsive-dialog-title"
                  >
                    <DialogTitle
                        id="responsive-dialog-title"
                        className="draggable-dialog-title"
                        style={{
                          background:
                              props.buttonName.toLowerCase() !== 'delete'
                                  ? 'rgb(20,19,139)'
                                  : null,
                          display: 'flex',
                          flexDirection: 'row',
                          color: props.buttonName.toLowerCase() !== 'delete' ? 'white' : null,
                        }}
                    >
                      <Box sx={{flex: '95'}}>{props.title}</Box>
                      {props.buttonName.toLowerCase() !== 'yes' ? (
                          <Box sx={{flex: '5', marginTop: '6px',cursor:"pointer"}}>
                            <Close onClick={() => handle_close_dialog()} />
                          </Box>
                      ) : null}
                    </DialogTitle>
                    <DialogContent>
                      {
                        !isLoading?
                          props.buttonName.toLowerCase() === 'delete' ? (
                              <DialogContentText>
                                You cannot undo this operation. Are you sure, you want to delete
                                this customer?
                              </DialogContentText>
                          ) : (
                              <>
                                <Box className="paddingBottom" sx={{position:"relative"}}>
                                  <TextField
                                      disabled={props.buttonName === 'update'}
                                      inputProps={{ maxLength: 20 }}
                                      InputLabelProps={{ shrink: tenantLabel !== '' || tenantLabel !== null}}
                                      label="Tenant Label *"
                                      variant="standard"
                                      sx={{width: '100%'}}
                                      value={tenantLabel}
                                      error={errorForTenantLabel}
                                      helperText={errorForTenantLabel? 'Tenant Label is required': ''}
                                      onChange={(event) => {
                                        checkOnTenantLabel(event.target.value);                                        
                                      }}
                                  />
                                  <Box sx={{position:"absolute",top:'54px',right:"0px",fontSize:"12px",color: tenantLabel !== "" && tenantLabel && typeof tenantLabel !== 'undefined' ? "#000000DE" : "red"}}>
                                    {tenantLabel !== "" && tenantLabel && typeof tenantLabel !== 'undefined' ? tenantLabel.length + "/20" : "0/20"}
                                  </Box>
                                </Box>
                                <Box className="nameTextField ">
                                  <Box className="firstName paddingBottom" sx={{position:'relative'}}>
                                    <TextField
                                        disabled={props.buttonName === 'update'}
                                        inputProps={{ maxLength: 20 }}
                                        InputLabelProps={{ shrink: firstName !== '' || firstName !== null}}
                                        label="First Name *"
                                        variant="standard"
                                        sx={{width: '100%'}}
                                        value={firstName}
                                        error={errorForFirstName}
                                        helperText={
                                          errorForFirstName
                                          ? 'First name is required'
                                          : ''
                                        }
                                        onChange={(event) => {
                                          checkOnFirstName(event.target.value);                                          
                                        }}
                                    />
                                    <Box sx={{position:"absolute",top:'54px',right:"0px",fontSize:"12px",color: firstName !== "" && firstName && typeof firstName !== 'undefined' ? "#000000DE" : "red"}}>
                                      {firstName !== "" && firstName && typeof firstName !== 'undefined' ? firstName.length + "/20" : "0/20"}
                                    </Box>
                                  </Box>
                                  <Box className="lastName paddingBottom focuspaddingBottom" sx={{position: 'relative'}}>
                                    <TextField
                                        disabled={props.buttonName === 'update'}
                                        inputProps={{ maxLength: 20 }}
                                        InputLabelProps={{ shrink: lastName !== '' || lastName !== null}}
                                        label="Last Name *"
                                        variant="standard"
                                        sx={{width: '100%'}}
                                        value={lastName}
                                        error={errorForLastName}
                                        helperText={
                                          errorForLastName
                                          ? 'Last name is required'
                                          : ''
                                        }
                                        onChange={(event) => {                                          
                                          checkOnLastName(event.target.value);
                                        }}
                                    />
                                    <Box sx={{position:"absolute",top:'54px',right:"0px",fontSize:"12px",color: lastName !== "" && lastName && typeof lastName !== 'undefined' ? "#000000DE" : "red"}}>
                                      {lastName !== "" && lastName && typeof lastName !== 'undefined' ? lastName.length + "/20" : "0/20"}
                                    </Box>
                                  </Box>
                                </Box>
                                <Box className="paddingBottom">
                                  <TextField
                                      InputLabelProps={{ shrink: superAdminEmail !== '' || superAdminEmail !== null}}
                                      label="Super Admin Email *"
                                      variant="standard"
                                      sx={{width: '100%'}}
                                      value={superAdminEmail}
                                      error={errorForSuperAdminEmail}
                                      helperText={
                                        errorForSuperAdminEmail ? 'Invalid Email Id' : ''
                                      }
                                      onChange={(event) => onSuperAdminEmailChange(event.target.value)}
                                  />
                                  </Box>


                                <Box className="nameTextField ">
                                  <Box className="firstName paddingBottom">
                                    <TextField
                                        label="Super Admin Password *"
                                        variant="standard"
                                        sx={{width: '100%'}}
                                        InputLabelProps={{ shrink: superAdminPassword !== '' || superAdminPassword !== null}}
                                        inputProps={{ maxLength: 6 }}
                                        value={superAdminPassword}
                                        error={errorForSuperAdminPassword}
                                        helperText={
                                          errorForSuperAdminPassword
                                          ? 'Password must be 6 digit code.'
                                          : ''
                                        }
                                        onChange={(event) => {
                                          onSuperAdminPasswordChange(event.target.value);
                                        }}
                                    />
                                  </Box>
                                  <Box className="lastName paddingBottom">
                                    <TextField
                                        label="Default Admin Password *"
                                        variant="standard"
                                        sx={{width: '100%'}}
                                        inputProps={{ maxLength: 5 }}
                                        InputLabelProps={{ shrink: defaultAdminPassword !== '' || defaultAdminPassword !== null}}
                                        value={defaultAdminPassword}
                                        error={errorForDefaultAdminPassword}
                                        helperText={
                                          errorForDefaultAdminPassword
                                          ? 'Password must be 5 digit code.'
                                          : ''
                                        }
                                        onChange={(event) => {
                                          onDefaultAdminPasswordChange(event.target.value);
                                        }}
                                    />
                                  </Box>
                                </Box>
                                <Box className="paddingBottom">
                                  <TextField
                                      disabled={props.buttonName === 'update'}
                                      label="Domain Name *"
                                      variant="standard"
                                      sx={{width: '100%'}}
                                      InputLabelProps={{ shrink: domainName !== '' || domainName !== null}}
                                      value={domainName}
                                      error={errorForDomainName}
                                      helperText={
                                        errorForDomainName
                                        ? 'Domain name can contain only letters,digits and hyphen'
                                        : ''
                                      }
                                      onChange={(event) => {
                                        onDomainNameChange(event.target.value);
                                      }}
                                  />
                                </Box>
                              </>
                          )
                        :
                          <Box sx={{width:'100%',display:'flex',justifyContent: 'center',alignItems:'center',height:'100px'}}>
                            <CircularProgress thickness="4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                        </Box>
                      }
                    </DialogContent>
                    {
                      !isLoading ?
                        <>
                          <DialogActions sx={{ display: props.buttonName === 'delete' ? 'flex' : 'none'}} >
                            <Button onClick={() => handle_close_dialog()} style={{color:'red'}}>Cancel</Button>
                            <Button onClick={() => deleteTenant()}>
                              {props.buttonName}
                            </Button>
                          </DialogActions>
                          <DialogActions sx={{ display: props.buttonName !== 'delete' ? 'flex' : 'none'}} >
                            <Button onClick={() => handle_close_dialog()} style={{color: 'red'}}>Cancel</Button>
                            <Button disabled={ props.buttonName === 'update' ?  invalidEmailId || !isChanged || errorForSuperAdminPassword || errorForDefaultAdminPassword || errorForSuperAdminEmail || sameValuesAsExistingError || (superAdminEmail === preservedSuperAdminEmail && superAdminPassword === preservedSuperAdminPassword && defaultAdminPassword === preservedDefaultAdminPassword) : !isValidToSubmit }
                              onClick={() => props.buttonName === "create" ? createTenant() : updateTenant()}>
                              {props.buttonName}
                            </Button>
                          </DialogActions>
                        </>
                      :
                        null
                    }
              </Dialog>
            :
              null
          } 
          {
            showSnackBar ?
              <Snackbar
                anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
                open={showSnackBar}
                autoHideDuration={3000}
                message={showSnackBarMessage}
              />
          :
            null
          }
      </div>      
  );
}
