/* eslint-disable no-undef */
import axios from "axios";
// const withQuery = require('with-query');

const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({})
};

let allAbortControlllers = {};

function addAbortController(page) {
    let abortController = new AbortController();
    if (!allAbortControlllers.hasOwnProperty(page)){
        allAbortControlllers[page] = [abortController];
    }else {
        allAbortControlllers[page].push(abortController);
    }
    return abortController;
}


function checkIsAuthorised (response) {
    if (response.hasOwnProperty("message")){
        if (response.message.indexOf("401 Unauthorized:") !== -1){
            sessionStorage.clear();
            window.location.hash = "/login";
            window.location.reload()
        }
    }
    return response;
}

function login(callback, user_details) {
    return fetch('/api/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user_details)
    }).then(callback)
    // .then(data => this.setState({ postId: data.id }));
}

function getHirarchy() {
    return fetch('/api/hirarchy').then(function(response) {
            return response.json();
        }).then(function(data) {
        return checkIsAuthorised(data);
    });
}

function getScopesLatestVersion(page) {
    let abortController = addAbortController(page);
    return fetch('/api/scope/latest-versions', {signal: abortController.signal}).then(function(response) {
            return response.json();
        }).then(function(data) {
        return checkIsAuthorised(data);
    });
}

function getPortalUsers(scopeId) {
    return fetch('/api/getPortalUsers/portalUsers?scopeId=' + scopeId).then(res => res.json()).then((json) => {
        console.info(json);
        return checkIsAuthorised(json);
    }).catch((err) => {
        console.error(err);
        return err;
    });
}

function deletePortalUser(userId, scopeId){
    return fetch("/api/delete-portal-user/userId/"+userId+"/scopeId/"+scopeId,{
        method:'DELETE',
    })
    .then((response)=>{
        return response;
    })
}

function checkDuplicateUserEmail(email) {
    return fetch('/api/check-duplicate-user-email/' +email).then(res => {
        if (res === ""){
            return ""
        }else {
            return res.json();
        }
    }).catch((err) => {
        return err;
    });
}

function getScope(scopeId) {
    return fetch('/api/scope/' +scopeId).then(function(response) {
            return response.json();
    }).then(function(data) {
            return data;
    });
}


function updateScope(callback, scopeDetails) {
    return fetch('/api/updateScope', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(scopeDetails)
    }).then(callback)
}


function createScope(callback, scopeDetails) {
    return fetch('/api/createScope', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(scopeDetails)
    })
//    .then(callback)
    .then((response) => {
         if(response.status == 201) {
            return response.json();
         }
    }).then(function(data) {
        callback(data);
    });
}

function createPortalUser(callback, user_details) {
    return fetch('/api/createPortalUser', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user_details)
    }).then(callback)
    // .then(data => this.setState({ postId: data.id }));
}

function updatePortalUser(callback, user_details) {
    return fetch('/api/updatePortalUser', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user_details)
    }).then(callback)
}

function updateEmailTemplate(callback, user_details) {
    return fetch('/api/updateEmailTemplate', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user_details)
    }).then(callback)
    // .then(data => this.setState({ postId: data.id }));
}
function fetchUserDetails() {
    return fetch('/api/user-details').then(function(response) {
        return response.json();
    }).then(function(data) {
        return data;
    });
}

function changePassword(user_details) {
    return fetch('/api/changePassword', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user_details)
    }).then((response)=>{
        if (response.status !== 200){
            return response.json();
        }else {
            return response;
        }
    })
    // .then(data => this.setState({ postId: data.id }));
}

function forgotPassword(callback, user_details) {
    return fetch('/api/forgotPassword', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user_details),
        // body: user_details
    }).then(callback)
    // .then(data => this.setState({ postId: data.id }));
}

function resetPassword(user_details) {
    return fetch('/api/resetPassword', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user_details)
    }).then((response) => {
        if (response.ok){
            return response;
        }else {
            return response.json();
        }        
    }).then((data) => {
      return data;
    });
    // .then(data => this.setState({ postId: data.id }));
}


function fetchFreeTierDetails() {
    return fetch('/freeTierDetails').then(function(response) {
        return response.json();
    }).then(function(data) {
        return data;
    });
}

function getHierarchyScope(scope) {

    return fetch('/api/hierarchy/scope?scopeId=' + scope).then(res => res.json()).then((json) => {
            console.info(json);
            checkIsAuthorised(json)
            return json;
        }).catch((err) => {
            console.error(err);
            return err;
        });
}

function getIotDevices(scopeId) {
    return fetch('/iotdevices?scopeId=' + scopeId)
        .then(res => res.json())
        .then((json) => {
            console.info(json);
            return json;
        })
        .catch((err) => {
            console.error(err);
            return err;
        });
}

function uploadFirmware(url, formData, authToken) {
    return fetch(url, {
        method: 'POST',
        header: {
            cookie: {
                'auth-token' : authToken
            }
        },
        body: formData
    }).then((response)=> {
        return response;
    })
    .catch((err) => {
        console.error(err);
        return err;
    });
}

function applyFirmware(json,fileStoreId, scopeId) {
    return fetch("/api/apply-firmware/fileStoreId/"+fileStoreId+"/scopeId/"+scopeId,{
        method:"PUT",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(json)
    }).then((response)=>{
        return response;
    })
    .catch((err) => {
        console.error(err);
        return err;
    });
}

function getHirarchy1(callback) {
    return axios('/hirarchy').then( response => {}).then(callback);
}

function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }
    const error = new Error(`HTTP Error ${response.statusText}`);
    error.status = response.statusText;
    error.response = response;
    throw error;
}


function getAuthAppDetails() {
    return fetch("/api/auth-app-details").then((response)=>{
        return response.json();
    }).catch((err)=>{
        return err;
    })
}

function getPortalUsersData(scopeId) {
    return fetch('/api/getPortalUsers/portalUsers?scopeId=' + scopeId)
    .then((response) => {
        return response.json();
    }).catch((err) => {
        return err;
    });
}

function getScopeData(scopeId) {
    return fetch('/api/scope/' +scopeId).then((response) => {
        return response.json();
    }).catch((err) => {
        return err;
    });
}

function parseJSON(response) {
    return response.json();
}

function abortSignal(page) {
    let abortSignals = allAbortControlllers.hasOwnProperty(page) ? allAbortControlllers[page] : [];
    _.forEach(abortSignals, (abortController)=> {
        abortController.abort()
    })
}


const AuthClient = { login, getAuthAppDetails , getPortalUsersData, getScopeData, getHirarchy, getHirarchy1, uploadFirmware, fetchFreeTierDetails, getHierarchyScope, getIotDevices,
    getScopesLatestVersion, checkDuplicateUserEmail, getScope, createScope, updateScope,
     getPortalUsers, createPortalUser, updatePortalUser,
    changePassword, forgotPassword, resetPassword,fetchUserDetails,updateEmailTemplate, deletePortalUser , applyFirmware, abortSignal};
export default AuthClient;
