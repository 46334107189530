import React, {Component} from 'react';
import '../stylesheet/HomePage.css';
import Card from './Card.js';
import DraggableHomPageComponent from './DraggableHomPageComponent';
import AuthClient from "../clients/AuthClient";
import GatewayServiceClient from "../clients/GatewayServiceClient";
import { CircularProgress, Box,Snackbar } from '@mui/material';
import _ from 'lodash';
import Mqtt_Service from "./Mqtt/Mqtt_Service";
import MqttEventHandler from "./Mqtt/MqttEventHandler";
import DraggableComponent from './DraggableComponent';
import PubSubChannels from '../pubsub/PubSubChannels';
import Cookies from 'js-cookie';


var sortedTenants = [];

export default class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_dialog: false,
      tenants: null,
      isLoading: true,
      allDomains: [],
      showDomainAlreadyExistDialog: false,
      domainAlreadyExistDialogData: {},
      existingemail: "",
      existingTenant: "",
      scopeEvent: null,
      showsnackBar: false,
      showSnackBarMessage : "",
      showCreatTenantIcon: false
    };    
  }

  fetchData() {
    if (!sessionStorage.userLoggedIn){
      window.location.hash = "/";
      window.location.reload();
  }else {
      AuthClient.getHirarchy().then((data) => {
        let domains = [];
        GatewayServiceClient.getAllTenantsWithGateways("homePage").then((response)=>{          
          _.forEach(data.children,function(value){
              if (value['scope'].emailDomain){
                domains.push(value['scope'].emailDomain);
              }
              if(response.hasOwnProperty("message")){
                value['scope'].hasGateways = false;
                value['scope'].onlineCount = 0;
                value['scope'].offlineCount = 0;
              }else if(response.length === 0){
                value['scope'].hasGateways = false;
                value['scope'].onlineCount = 0;
                value['scope'].offlineCount = 0;
              }else{
                _.forEach(response,function(val){
                    if(value['scope'].id === val.scopeId){
                        value['scope'].hasGateways = true;
                        value['scope'].onlineCount = val.onlineGateways;
                        value['scope'].offlineCount = val.offlineGateways;
                    }
                })
              }
          });
          if (data.scope.emailDomain){
            domains.push(domains.push(data.scope.emailDomain));
          }
          this.setState({allDomains: domains});
          AuthClient.getScopesLatestVersion("homePage").then((versions) => {
            let tooltipText = "";
            _.forEach(data.children,(tenants)=>{                
              _.forEach(Object.keys(versions.fileStores), (scopeId) => {
                if (tenants["scope"].id === scopeId){     
                  _.forEach(versions.fileStores[scopeId],(rootTenantData)=>{
                    let name = rootTenantData.name !== "img" ? rootTenantData.name.replace("WeskoGateway","gateway").replace("OSPatch","partial os").toUpperCase() : rootTenantData.type.toUpperCase() ;
                    tooltipText += rootTenantData.version +" ("+name+") \n";
                  })
                  tenants.versions = versions.fileStores[scopeId];
                  tenants.desktopViewToolTip = tooltipText;
                  tooltipText = "";
                }                  
              })
            });
            tooltipText = "";
            _.forEach(versions.fileStores[data.scope.id],(rootTenantData)=>{
              let name = rootTenantData.name !== "img" ? rootTenantData.name.replace("WeskoGateway","gateway").replace("OSPatch","partial os").toUpperCase() : rootTenantData.type.toUpperCase() ;
              tooltipText += rootTenantData.version +" ("+name+") \n";
            })              
            data.scope.versions = versions.fileStores[data.scope.id];
            data.scope.desktopViewToolTip = tooltipText;
            this.setState({
              tenants: data,
              isLoading: false,
              showCreatTenantIcon: true
            });
          })            
        });
      });
  }
  delete sessionStorage.sidenavAlreadySelectedItem;
  delete window.clickEvent;
  }

  handleScopeEvent () {
    this.setState({
      setShowSnackBar:true,
      setShowSnackBarMessage: "Details updated successfully"
    })    
    setTimeout(() => {
      this.setState({
        setShowSnackBar:false,
        setShowSnackBarMessage: ""
      })    
    }, 2001);
    this.fetchData()
  }

  componentDidMount() {
    this.state.isLoading = true;
    this.setState( {scopeEvent :MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.SCOPES.replace("scopeId",sessionStorage.tenantId), "homePage",this.handleScopeEvent)});
    this.fetchData();    
  }

  componentWillUnmount() {
    if (this.state.scopeEvent){
      this.state.scopeEvent.unsubscribe()
    }
    GatewayServiceClient.abortSignal("homePage");
    AuthClient.abortSignal("configureEnail");
  }

  render() {

      const handleshowEmailDOmainAlreadyExistDialog = (value) => {
        this.setState({show_dialog: false,showDomainAlreadyExistDialog: true,domainAlreadyExistDialogData: value[0]});      
        
      }

      return (
          <>
              <div className="homePageContainer">
                  <Mqtt_Service />
              <div className="homePagesubContainer">
                <div className="subContainerBlock" style={{maxWidth: '95%', margin: 'auto'}}>
                  <div className="adminBlock">
                    <div className="adminSubBlock blockWidth" style={{height: !this.state.isLoading ? '174px' : '100px'}}>
                      {
                        !this.state.isLoading ?
                              <>
                                  <div className="subContainerBlockTitle" style={{color: '#000000DE'}}>
                                    <span style={{color: '#414c59', fontSize: '20px', fontWeight: '300'}}>
                                      Admin:
                                    </span>
                                  </div>
                                  <hr />
                                  <div className="cardBlock">
                                    <Card
                                        name={this.state.tenants['scope'].name}
                                        scopeId={this.state.tenants['scope'].id}
                                        data={this.state.tenants['scope']}
                                        versions={this.state.tenants['scope'].versions}
                                        desktopViewToolTip = {this.state.tenants['scope'].desktopViewToolTip}
                                        setClassName="cardList"
                                    />
                                  </div>
                              </>
                          :
                              <Box sx={{width:'100%',display:'flex',justifyContent: 'center',alignItems:'center',height:'100px'}}>
                                  <CircularProgress thickness="4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                              </Box>

                      }
                    </div>
                  </div>
                  <div style={{width: '14px'}}>&nbsp;</div>
                  <div className="adminBlock">
                    <div className="adminSubBlock blockWidth" style={{height: !this.state.isLoading ? '174px' : '100px'}}>
                      {
                        !this.state.isLoading ?
                          <>
                              <div className="subContainerBlockTitle">
                                <span style={{color: '#414c59', fontSize: '20px', fontWeight: '300',}}>
                                  Non Enterprise:
                                </span>
                              </div>
                              <hr />
                              <div className="cardBlock">
                                <Card
                                    scope={this.state.tenants['children'][0]['scope']}
                                    data={this.state.tenants['children'][0]['scope']}
                                    name={this.state.tenants['children'][0]['scope'].name}
                                    scopeId={this.state.tenants['children'][0]['scope'].id}
                                    versions={this.state.tenants['children'][0].versions}
                                    desktopViewToolTip = {this.state.tenants['children'][0].desktopViewToolTip}
                                    setClassName="cardList"
                                />
                              </div>
                          </>
                         :
                          <Box sx={{width:'100%',display:'flex',justifyContent: 'center',alignItems:'center',height:'100px'}}>
                              <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                          </Box>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div style={{width: '14px'}}>&nbsp;</div>
              <div className="enterpriseContainer">
                <div className="homePagesubContainer">
                  <div className="subContainerBlock" style={{maxWidth: '95%', margin: 'auto'}}>
                    <div className="adminBlock">
                      <div className="enterpriseSubBlock">
                      {
                        !this.state.isLoading  ?
                           <>
                                <div className="subContainerBlockTitle">
                                  <span style={{color: '#000000DE', fontSize: '20px', fontWeight: '300',}}>
                                    Enterprise:
                                  </span>
                                </div>
                                <hr />
                                <div style={{width: '100%', display: 'flex', flexWrap: 'wrap'}}>
                                  {
                                    // eslint-disable-next-line array-callback-return
                                    _.sortBy(this.state.tenants['children'],[element => element['scope'].name.toLowerCase()],['asc']).map((element, index) => {
                                      if (element['scope'].name !== 'FreeTier') {
                                        return (
                                            <div key={element['scope'].name} className="customesBlock">
                                                  <Card
                                                      key={element['scope'].name}
                                                      data={element['scope']}
                                                      status= {element['scope'].status}
                                                      gateways = {element['scope'].hasGateways}
                                                      gatewayOnlineCount = {element['scope'].onlineCount}
                                                      gatewayOfflineCount = {element['scope'].offlineCount}
                                                      name={element['scope'].name}
                                                      scopeId={element['scope'].id}
                                                      versions={element.versions}
                                                      desktopViewToolTip = {element.desktopViewToolTip}
                                                      setClassName="enterprisecardList"
                                                  />
                                            </div>
                                        );
                                      }
                                    })
                                  }
                                </div>
                            </>
                           :
                            <Box sx={{width:'100%',display:'flex',justifyContent: 'center',alignItems:'center',height:'100px'}}>
                                <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                            </Box>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {
              this.state.showCreatTenantIcon ?
              <DraggableHomPageComponent
                  title="Create New Tenant"
                  buttonName="create"
                  allExistingDomains={this.state.allDomains}
                  iconClicked={(val) => this.setState({show_dialog: true})}
                  openDialogBox={this.state.show_dialog}
                  refreshData={()=>window.location.reload()}
                  showEmailDomainAlreadyExistDialog={(value)=> handleshowEmailDOmainAlreadyExistDialog(value)}
                  handleCloseDialogBox={(val) => {
                    this.setState({show_dialog: val});
                  }}
              />
              :
                  null
            }
            {
                this.state.showDomainAlreadyExistDialog ?
                    <DraggableComponent
                      dialog="domainExistAlert"
                      openDialog={this.state.showDomainAlreadyExistDialog}
                      data={this.state.domainAlreadyExistDialogData}
                      closeDialog={() => this.setState({showDomainAlreadyExistDialog: false})}
                    />
                :
                  null
            }
            {
              this.state.showSnackBar ?
                <Snackbar
                  anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
                  open={this.state.showSnackBar}
                  autoHideDuration={3000}
                  message={this.state.allDomains.showSnackBarMessage}
                />
            :
              null
            }
          </>
      );
  }
}
