import React from 'react';
import { Box,Button,Dialog,DialogActions,DialogContent, DialogTitle, useTheme,Table,TableBody,TableCell,TableRow,Paper, FormControl, MenuItem, Select } from '@mui/material';
import { Close, Download,NotificationsActive, } from '@mui/icons-material';
import { CircularProgress, TableContainer, TextField, Tooltip, useMediaQuery } from '@mui/material';
import Draggable from 'react-draggable';
import IotServiceClient from '../../clients/IotServiceClient';
import _ from 'lodash';
import "../../stylesheet/common.css";
import moment from 'moment';
import CsvDownload from 'react-json-to-csv';


export default function HistoryDialog(props) {
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const getWindowDimensions = () => {
    const {innerWidth: width, innerHeight: height} = window;
    return {
      width,
      height,
    };
  };

  const [Dimensions, setDimensions] = React.useState(getWindowDimensions());

  const [historyInterval, setHistoryInterval] = React.useState(3600000);

  const [isLoading, setIsloading] = React.useState(false);

  const [historyData, setHistortyData] = React.useState([]);

  const [csvDownloadData, setCsvDownloadData] = React.useState([]);

  const [historyDates, setHistoryDates] = React.useState([]);

  const [searchText, setSearchText] = React.useState("");

  const checkSearchTextExistInHistoryData = (data, key,  text) => {
    let value = data[key];
    return value !== null && typeof value !== 'undefined' && value !== "" ? value.toString().toLowerCase().indexOf(text) !== -1 : false;
  };

  const handleHistorySearchFilter = (event) => {
    let searchFilterText = event.target.value;
    setSearchText(searchFilterText);
    let searchFIlteredHistoryData = [];
    let searchFilteredDataForCSV = [];
    if (searchFilterText !== "" && typeof searchFilterText !== 'undefined' && searchFilterText !== null){
      _.forEach(window.historyData,(value, i)=>{
        if (checkSearchTextExistInHistoryData(value, "timeStamp", searchFilterText) || checkSearchTextExistInHistoryData(value, "eventTrigger", searchFilterText) ||
        checkSearchTextExistInHistoryData(value, "eventCode", searchFilterText) || checkSearchTextExistInHistoryData(value, "eventResult", searchFilterText)){
              searchFIlteredHistoryData.push(value);
              searchFilteredDataForCSV.push(window.csvDownloadData[i]);
        }
      });
      searchFIlteredHistoryData = _.uniq(searchFIlteredHistoryData);
      searchFilteredDataForCSV = _.uniq(searchFilteredDataForCSV);
      setHistortyData(searchFIlteredHistoryData);
      setCsvDownloadData(searchFilteredDataForCSV);
    }else {
      setHistortyData(window.historyData);
      setCsvDownloadData(window.csvDownloadData);
    }
  }
  
  const changeHistoryInterval = (value) => {
      setHistoryInterval(value);
      fetchData(value);
  };

  const fetchData = (toTime) => {
    let endTime = new Date().getTime();
    setIsloading(true);
    if (props.hasOwnProperty("data")){
      IotServiceClient.getLockHistory(toTime ? toTime === "all" ? "all" : endTime - toTime : endTime - historyInterval, endTime, props.data.macId, sessionStorage.tenantId, "historyDialog").then((response) => {
        let dates = [];
        let csvData = [];
        _.forEach(response,(value)=>{
            var csvValue = Object.assign({}, value);
            let date = moment(value.timeStamp).format("ddd MMM Do, YYYY");
            value.historyDate = date;                 
            dates.push(date);
            value.eventResult = value.eventResult ? value.eventResult : "-";
            let dateForCsv = moment(value.timeStamp).format("YYYY MMM DD, h:mm:ss A");
            value.timeInMills = value.timeStamp;
            value.timeStamp = moment(new Date(value.timeStamp)).format("h:mm:ss A");


            csvValue.time = dateForCsv;
            csvValue.timeStampRef = value.timeStamp;
            csvValue.eventResult = value.eventResult;
            csvData.push(csvValue);

        });
        dates = _.reverse(_.uniq(dates));
        setHistoryDates(dates);
        response = _.orderBy(response,['timeInMills'],['desc']);
        csvData = _.orderBy(csvData,['timeStamp'],['desc']);
        _.forEach(csvData,(value)=>{
            delete value.id;
            delete value.timeStampRef;
        });
        setHistortyData(response);
        setCsvDownloadData(csvData);
        window.historyData = response;
        window.csvDownloadData = csvData;
        setIsloading(false);
      });
    }
  };

  React.useEffect(()=>{
    fetchData();
    return () => {
      IotServiceClient.abortSignal("historyDialog");
    }
  },[])

  return (
    <Dialog
      key="historyDialog"
      fullWidth={true}
      fullScreen={fullScreen}
      open={props.show_history_dialog}
      onClose={props.close_history_dialog}
      maxWidth="md"
    >
      <DialogTitle
        sx={{
          fontSize: '20px',          
          display: 'flex',
          flexDirection: 'column',
          padding:'0px'
        }}
      >
        <Box sx={{display:'flex',flexDirection:'row',backgroundColor: 'rgb(20,19,139)',color: 'white',padding:'14px'}}>
          <Box id="draggable-dialog-title" style={{marginRight: 'auto'}}>
            History ({historyData.length})
          </Box>
          <CsvDownload
            data={csvDownloadData}
            filename={props.data.name + '-'+ props.data.macId + '-history.csv'}
            style={{
              border: 'transparent',
              background: 'transparent',
              paddingLeft: '0px',
              color: '#0000008A',
              marginTop: '6px', marginRight: '-7px'
            }}
          >
            <Tooltip title="Download CSV" placement="top" arrow>
              <Download style={{ color: '#fff',marginRight: '14px', cursor: 'pointer'}} />
            </Tooltip>
          </CsvDownload>            
          <Close sx={{marginTop: '6px',cursor:'pointer'}} onClick={()=>props.close_history_dialog()} />
        </Box>
      </DialogTitle>
        <Box sx={{display: 'flex',flexDirection:"row",padding:'10px', width:"100%"}}>
          <Box></Box>
          <Box sx={{display:'flex',marginLeft:"auto"}}>
            <TextField
                type="search"  
                onChange={handleHistorySearchFilter}
                placeholder="Search"
                variant='standard'
                sx={{
                  textIndent: '4px',
                  width: '100%',
                  height: '30px',
                  fontSize: '16px',
                }}
              />
          </Box>
          <Box sx={{display:'flex',marginLeft:"12px"}}>
            <FormControl variant="standard" sx={{width:"100%"}}>
                <Select value={historyInterval} onChange={(event)=> {changeHistoryInterval(event.target.value)}} labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" sx={{width: '100%'}}>
                    <MenuItem value={3600000}>1 hour</MenuItem>
                    <MenuItem value={21600000}>6 hours</MenuItem>
                    <MenuItem value={43200000}>12 hours</MenuItem>
                    <MenuItem value={86400000}>24 hours</MenuItem>
                    <MenuItem value={604800000}>1 week</MenuItem>
                    <MenuItem value={1209600000}>2 weeks</MenuItem>
                    <MenuItem value={2629800000}>1 month</MenuItem>
                    <MenuItem value={7889400000}>3 months</MenuItem>
                    <MenuItem value={15778800000}>6 months</MenuItem>
                    <MenuItem value="all">All</MenuItem>
                </Select>
            </FormControl>
          </Box>
      </Box>
      <DialogContent className='historyDialogContent' sx={{padding:"0px"}}>
          <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width:'100%',
            height: '100%',            
            padding:'0px'
          }}
        > 
          {
            !isLoading ?
              <TableContainer sx={{height:'100%'}}>
              {
                historyDates.length > 0 ?
                  <Table stickyHeader>
                    {
                      historyDates.map((date,key)=>(           

<>                        
                            <TableRow sx={{width:'100%',position:'sticky',top:'0px',zIndex:{key},background:'#fff',boxShadow:'2px 0px 2px grey'}}>
                                <TableCell sx={{color: 'red',borderTop:'1px solid rgba(224, 224, 224, 1)'}} key={"historyDay"}>
                                  {date}
                                </TableCell>
                                <TableCell sx={{color: 'red',borderTop:'1px solid rgba(224, 224, 224, 1)'}} key={"historytrigger"}>
                                  Trigger
                                </TableCell>
                                <TableCell sx={{color: 'red',borderTop:'1px solid rgba(224, 224, 224, 1)'}} key={"historycode"}>
                                  Code
                                </TableCell>
                                <TableCell  sx={{color: 'red',borderTop:'1px solid rgba(224, 224, 224, 1)'}} key={"historyResult"}>
                                  Result
                                </TableCell>
                              </TableRow>                        
                            <TableBody>
                              {                            
                                historyData.map((data) => (
                                  date === data.historyDate  ?
                                      <TableRow hover>
                                          <TableCell>{data.timeStamp}</TableCell>
                                          <TableCell>{data.eventTrigger}</TableCell>
                                          <TableCell>{data.eventCode}</TableCell>
                                          <TableCell>{data.eventResult}</TableCell>
                                      </TableRow>
                                    :
                                      null
                                ))
                              }
                            </TableBody>
                          </>
                      ))}                     
                    </Table>
                  : 
                    <Box sx={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",height:"100%"}}>
                      <NotificationsActive sx={{color:"#808080",height:"48px",width:"48px"}} />
                      <Box sx={{fontSize:"16px"}}>
                        No history for this lock
                      </Box>
                    </Box>
                  }
                </TableContainer>
              :
                <Box sx={{width:'100%',height:"100%",display:'flex',justifyContent: 'center',alignItems:'center'}}>
                  <CircularProgress thickness="4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                </Box>
            }
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={()=>props.close_history_dialog()} style={{color: 'red'}}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
