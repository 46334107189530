import * as React from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, useMediaQuery, useTheme, TableCell, Checkbox, LinearProgress, Select, MenuItem, TableContainer, Tooltip, TableHead, TableRow, TablePagination, TableSortLabel, Radio, RadioGroup } from '@mui/material';
import { People,Lock,LockOpen,Check,Close,CheckCircle,HelpOutline, AlarmAdd,ArrowDropDown,ReportProblem,FirstPage,LastPage,KeyboardArrowLeft,KeyboardArrowRight,Search,Refresh,Download } from '@mui/icons-material';
import { Button, CircularProgress, DialogContentText, FormControl, Paper, Popover, requirePropFactory, TextField } from '@mui/material';
import {visuallyHidden} from '@mui/utils';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { AdapterDateFns,LocalizationProvider,DateTimePicker } from '@mui/x-date-pickers';
import styles from '../../stylesheet/users.module.css';
import paginationStyle from '../../stylesheet/pagination.module.css';
import HistoryDialog from './HistoryDialog';
import "../../stylesheet/common.css";
import IotServiceClient from "../../clients/IotServiceClient";
import GatewayServiceClient from "../../clients/GatewayServiceClient";
import _ from "lodash";
import moment from 'moment';
import Enums from '../../constants/Enums';
import { useSnackbar } from 'notistack';
import MqttEventHandler from '../Mqtt/MqttEventHandler';
import PubSubChannels from '../../pubsub/PubSubChannels';
import Draggable from 'react-draggable';

function PaperComponent(props) {
return (
    <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
    >
        <Paper {...props} />
    </Draggable>
);
}

export default function SwitchLockModeDialog(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));    
    const getWindowDimensions = () => {
        const {innerWidth: width, innerHeight: height} = window;
        return {
        width,
        height,
        };
    };

    const [Dimensions, setDimensions] = React.useState(getWindowDimensions());

    const [loader, setLoader] = React.useState(false);

    const [showInitialMesage, setShowInitialMesage] = React.useState(true)

    const { enqueueSnackbar } = useSnackbar();

    const [showSuccessOfFailureMessage, setShowSuccessOfFailureMessage] = React.useState("");

    const handleSuccessOrFailureSwitchMode = (message) => {
        message = message.toString();
        setLoader(false);
        setShowInitialMesage(false);
        if (message === "success") {
           setShowSuccessOfFailureMessage("<span class='greenText'>Success!</span>") 
        }else if (message === "failed") {
            setShowSuccessOfFailureMessage("<span class='redText'>Failed</span>. The lock is currently unreachable. Please retry after some time.") 
        }
        if (window.sharedModeSwitchingChannel) {
            window.sharedModeSwitchingChannel.unsubscribe();
        }
    }

    const handleSwitchModeLogic = () => {
        setShowInitialMesage(false);
        setLoader(true);
        let device = props.data;
        let instructionPayload = {
            "payload" : {
                "macId": device.macId,
                "mode": device.mode === "shared" ? "residential" : "shared",
                "name": device.name,
                "scopeId": sessionStorage.tenantId,
                "time": new Date().toUTCString()
            },
            "type": "CHANGE_LOCK_MODE",
            "gatewayMacId": props.data.gateway_MacId,
            "targetScopeId": device.scopeId
        }        
        let latestLockMode = device.mode === "shared" ? "Resident" : "Shared";
        let oldLockMode = device.mode === "shared" ? "Shared": "Resident";
        let payload = {
            "macId" : device.gateway_MacId,
            "message" : device.name + "\n " + oldLockMode + " -> " + latestLockMode + "\n Initiated",
            "date": new Date().getTime(),
            "type": "event",      
        }                  

        GatewayServiceClient.createLockModeInstruction1(instructionPayload, device.type).then((response)=>{
            if (response.hasOwnProperty("instructionId")) {                                
                GatewayServiceClient.createLockUnlockEntry(payload).then(() => {})
                window.sharedModeSwitchingChannel = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.GATEWAY_CHANGE_LOCK_MODE_INSTRUCTION.replace("scopeId",sessionStorage.tenantId).replace("gatewayMacId",device.gateway_MacId).replace("lockMacId",device.macId).replace("instructionId",response.instructionId), "lock",handleSuccessOrFailureSwitchMode);
                enqueueSnackbar("Successfully created instruction");
            }else {
                setLoader(false);
                setShowInitialMesage(false);
                setShowSuccessOfFailureMessage("<span class='redText'>Failed</span>. The lock is currently unreachable. Please retry after some time.") 
                enqueueSnackbar("Failed to create instruction");
            }            
        })        
    }

    React.useEffect(() => {
        window.sharedModeSwitchingChannel = null;
        return () =>{
            if (window.sharedModeSwitchingChannel) {
                window.sharedModeSwitchingChannel.unsubscribe()
            }
        }
    },[])


    return (
        <Dialog
            fullWidth={true}
            fullScreen={fullScreen}
            open={true}    
            onClose={()=> props.closeDialog()}                            
            PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
            aria-labelledby="draggable-dialog-title"
            >
            <DialogTitle style={{
                background: 'rgb(20,19,139)',
                display: 'flex',
                flexDirection: 'row',
                color: 'white',
            }}>
                <Box sx={{flex: '95'}}>Switching Mode Of {props.data.name}</Box>
                <Box sx={{flex: '5', marginTop: '6px'}}>
                    <Close onClick={() => props.closeDialog()} />
                </Box>
            </DialogTitle>
            {
                showSuccessOfFailureMessage !== "" ?
                    <DialogContent sx={{fontSize:"18px !important",paddingTop:"20px !important"}}>
                        <Box sx={{fontSize:"18px !important",display:"flex",justifyContent:"center",margin:"20px"}}>
                            <Box dangerouslySetInnerHTML={{ __html: showSuccessOfFailureMessage }}>
                            </Box>
                        </Box>
                    </DialogContent>
                :
                    <DialogContent sx={{fontSize:"18px !important",paddingTop:"20px !important"}}>
                        {
                            loader ?
                                <>
                                    <Box sx={{display:"flex",flexDirection:"row",width:"100%",height:"80px",alignItems:"center",justifyContent:"center"}}>
                                        <Box sx={{position:"relative",top:"10px"}}>
                                            <CircularProgress thickness="4" sx={{height:'40px !important',width: '40px !important',color: 'rgb(20,19,139) !important'}}/>
                                        </Box>                            
                                    </Box>
                                    <Box sx={{fontSize:"16px !important",display:"flex",justifyContent:"center",margin:"20px"}}>
                                        <Box>
                                            Switching Mode From <b>{props.data.mode === "shared" ? "Shared" : "Resident"} </b> To <b>{props.data.mode === "shared" ? "Resident" : "Shared"}</b>
                                        </Box>
                                    </Box>
                                </>
                            :
                                null
                        }
                        {   
                            showInitialMesage ?
                                <Box sx={{fontSize:"16px !important"}}>
                                    {
                                        props.data.mode === "shared" ? 
                                            <>
                                                You are switching the locker operating mode from <b>Shared</b> to <b>Resident</b>. The current locker user will be removed and the locker will be unlocked. Click OK to confirm.
                                            </>
                                        :
                                            <>
                                                You are switching the locker operating mode from <b>Resident </b> to  <b>Shared</b>. All the existing locker users will be removed and the locker will be unlocked. Click OK to confirm.        
                                            </>
                                    }
                                </Box>
                            :
                                null
                        }                
                    </DialogContent>
            }            
            <DialogActions> 
            {
                showInitialMesage ?    
                    <>
                        <Button sx={{color:"red"}} onClick={() => props.closeDialog()}>
                            cancel
                        </Button>                                  
                        <Button onClick={() => handleSwitchModeLogic()}>
                            ok
                        </Button>
                    </>
                :
                    null
            }
            </DialogActions>
        </Dialog>

    )
}
