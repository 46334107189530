import React from 'react';
import '../../stylesheet/UpdateFirmware.css';
import { ArrowBack,HelpOutline,AddCircleOutline,RemoveCircleOutlined } from '@mui/icons-material';
import { Radio,RadioGroup,Select,MenuItem,FormControlLabel,FormControl,Button,Box,Avatar,Chip,Tooltip,CircularProgress, Snackbar } from '@mui/material';
import AuthClient from "../../clients/AuthClient";
import GatewayServiceClient from "../../clients/GatewayServiceClient.js";
import _ from 'lodash';
import moment from 'moment';
import PubSubChannels from '../../pubsub/PubSubChannels';
import UserDetailService from '../../services/UserDetailService';
import MqttEventHandler from '../Mqtt/MqttEventHandler';


export default class UpdateFirmware extends React.Component {
  constructor(props) {
    super(props);
    if (window.uploadFirmwareResponseSuccess) {
      delete window.uploadFirmwareResponseSuccess;
    }
    let OSPattern = window.location.href.indexOf("staging") !== -1 ? "PartialUpdate\\_(\\d{4}-\\d{2}-\\d{2})_STAGING_V(\\d{3})\\.zip" : "PartialUpdate\\_(\\d{4}-\\d{2}-\\d{2})_PROD_V(\\d{3})\\.zip";
    this.state = {
      selectedTenant: [],
      searchText: '',
      selectedRadio: 'Gateway',
      selectedRadioAction: 'Apply',
      allBgmVersions: [],
      allBleVersions: [],
      allRfidVersions: [],
      allBootLoaderVersions: [],
      allGatewayVersions:[],
      allOsPatchVersions: [],
      publishTimeout: null,
      selectedBgm: '',
      selectedBle: '',
      selectedRfid: '',
      selectedBoot: '',
      selectedGateway: '',
      selectedOspatch: '',
      tenants: null,
      isLoading: true,
      showSnackBar : false,
      snackBarMessage : "",
      isStaging: window.location.href.indexOf('staging') !== -1,
      bgmFileNamePattern : new RegExp('wesko-elock.\\production-(\\d{4}-\\d{2}-\\d{2})-BGMP'+'\\.hex', 'g'),
      bleFileNamePattern : new RegExp('wesko-elock\\.production-(\\d{4}-\\d{2}-\\d{2})-BLEP'+'\\.hex', 'g'),
      rfidFileNamePattern : new RegExp('wesko-elock.\\production-(\\d{4}-\\d{2}-\\d{2})-RFID'+'\\.hex', 'g'),
      bootLoaderFileNamePattern : new RegExp('wesko-elock.\\production-(\\d{4}-\\d{2}-\\d{2})-Bootloader'+'\\.hex', 'g'),
      gatewayFileNamePattern : new RegExp('Wesko\\.(\\d{4}-\\d{2}-\\d{2})_GW'+'\\.zip', 'g'),
      py3WeskoNamePattern: new RegExp('py3WESKO\\.(\\d{4})' + '\\.zip', 'g'),
      osPatchFileNamePattern : new  RegExp(OSPattern, 'g'),
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleActionRadioChange = this.handleActionRadioChange.bind(this);

  }

  handleChange(e) {
    this.setState({searchText: e.target.value});
  }

  handleRadioChange(e) {
    this.setState({selectedRadio: e.target.value});
  }

  handleActionRadioChange(e) {
    this.setState({selectedRadioAction: e.target.value});
  }

  handleSelectApplyOptionChange(value,name) {
    if (name === "bgm"){
      this.setState({selectedBgm : value});
    }else if(name === "ble"){
      this.setState({selectedBle : value});
    }else if(name === "rfid"){
      this.setState({selectedRfid : value});
    }else if(name === "boot"){
      this.setState({selectedBoot : value});
    }else if(name === "gateway"){
      this.setState({selectedGateway : value});
    }else if(name === "os"){
      this.setState({selectedOspatch : value});
    }

  }

  fetchData(){
    this.setState({isLoading : true});
      if (!sessionStorage.userLoggedIn){
          window.location.hash = "/";
          window.location.reload();
      }else {
        let filteredTenants = [];
        let filteredTenantsWithGateways = [];
        let allBgmversions = [];
        let allBleversions = [];
        let allRfidversions = [];
        let allBootloaderversions = [];
        let allGatewayversions = [];
        let allOspatchversions = [];
        
        AuthClient.getHirarchy().then((data) => {
            GatewayServiceClient.getAllTenantsWithGateways("updateFirmware").then((response) => {
                  _.forEach(data.children,(value)=>{
                    filteredTenants.push(value.scope);
                  })
                  filteredTenants.push(data.scope);
                _.forEach(filteredTenants,(tenant) => {
                    if (tenant.id === ""){
                      filteredTenantsWithGateways.push(tenant);
                    }
                      _.forEach(response,(value) => {
                        if(tenant.id === value.scopeId){
                            filteredTenantsWithGateways.push(tenant);
                        }
                      })
                  });
                  AuthClient.getScopesLatestVersion("updateFirmware").then((versions) => {
                      if (versions.fileStores){
                        _.forEach(filteredTenants,(tenant) => {
                          tenant.versions = versions.fileStores[tenant.id] ? versions.fileStores[tenant.id] : [] ;
                        })
                        _.forEach(filteredTenantsWithGateways, (tenant) => {
                          tenant.versions = versions.fileStores[tenant.id] ? versions.fileStores[tenant.id] : [] ;
                        })
                      }

                      _.forEach(filteredTenants,(data)=>{
                        _.forEach(data.versions,(version)=>{
                            if (version.name === "img" && version.type === "BGM"){
                              data.bgmVersion = version.version;
                              data.bgmVersionId = version.id;
                              if (JSON.stringify(allBgmversions).indexOf(version.date) === -1){
                                allBgmversions.push(version);
                              }
                            }else if (version.name === "img" && version.type === "BLE"){
                              data.bleVersion = version.version;
                              data.bleVersionId = version.id;
                              if (JSON.stringify(allBleversions).indexOf(version.date) === -1){
                                allBleversions.push(version);
                              }
                            }else if (version.name === "img" && version.type === "RFID"){
                              if (JSON.stringify(allRfidversions).indexOf(version.date) === -1){
                                allRfidversions.push(version);
                              }
                              data.rfidVersionId = version.id;
                              data.rfidVersion = version.version;
                            }else if (version.name === "boot"){
                              if (JSON.stringify(allBootloaderversions).indexOf(version.date) === -1){
                                allBootloaderversions.push(version);
                              }
                              data.bootVersionId = version.id;
                              data.bootVersion = version.version;
                            }else if (version.type === "RASPI"){
                              if(JSON.stringify(allGatewayversions).indexOf(version.date) === -1){                                
                                allGatewayversions.push(version);
                              }
                              data.gatewayVersion = version.version;
                            }else if (version.name === "OSPatch"){
                              if(JSON.stringify(allOspatchversions).indexOf(version.date) === -1){
                                allOspatchversions.push(version);
                              }
                              data.osVersionId = version.id;
                              data.osVersion = version.version;
                            } else if (version.name === "PY3WESKO"){
                              if(JSON.stringify(allOspatchversions).indexOf(version.date) === -1){
                                allOspatchversions.push(version);
                              }
                              data.osVersionId = version.id;
                              data.osVersion = version.version;
                            }
                        })
                      })
                      allBgmversions = _.orderBy(_.uniqBy(allBgmversions, (versn) => versn.version),["date"],["desc"]);
                      allBleversions = _.orderBy(_.uniqBy(allBleversions, (versn) => versn.version),["date"],["desc"]);
                      allBootloaderversions = _.orderBy(_.uniqBy(allBootloaderversions, (versn) => versn.version),["date"],["desc"]);
                      allGatewayversions = _.orderBy(_.uniqBy(allGatewayversions, (versn) => versn.version),["date"],["desc"]);
                      allOspatchversions = _.orderBy(_.uniqBy(allOspatchversions, (versn) => versn.version),["date"],["desc"]);
                      this.setState({
                        tenants: filteredTenantsWithGateways,
                        allBgmVersions: allBgmversions,
                        allBleVersions: allBleversions,
                        allRfidVersions: allRfidversions,
                        allBootLoaderVersions: allBootloaderversions,
                        allGatewayVersions: allGatewayversions,
                        allOsPatchVersions: allOspatchversions,
                        selectedBgm: allBgmversions[0] ? allBgmversions[0].version : null,
                        selectedBle:allBleversions[0] ? allBleversions[0].version : null,
                        selectedRfid: allRfidversions[0] ? allRfidversions[0].version : null,
                        selectedBoot: allBootloaderversions[0] ? allBootloaderversions[0].version : null,
                        selectedGateway: allGatewayversions[0] ? allGatewayversions[0].version : null,
                        selectedOspatch: allOspatchversions[0] ? allOspatchversions[0].version : null,
                        isLoading: false
                      });
                  })
            });
          });
      }
  }

  firmwareChannelEvent () {
    let showToastInterval = setInterval(() => {
      //else if is kept because if window.uploadFirmwareResponseSuccess is undefined then also condtion else will be called
      if (window.uploadFirmwareResponseSuccess === true) {
        setTimeout(() => {
          window.location.reload()
        }, 1000);
      }else if(window.uploadFirmwareResponseSuccess === false){
        clearInterval(showToastInterval);
      }      
    }, 1000);    
  }

  componentDidMount() {
    window.firmwareMqttChannel = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.GATEWAY_OS_PATCH_DETAILS, "upgradeFirmware",this.firmwareChannelEvent)
    this.fetchData();
  }

  componentWillUnmount() {
    if (window.firmwareMqttChannel){
      window.firmwareMqttChannel.unsubscribe();
      delete window.firmwareMqttChannel;
    }
    if (window.uploadFirmwareResponseSuccess) {
      delete window.uploadFirmwareResponseSuccess;
    }
    GatewayServiceClient.abortSignal("updateFirmware")
    AuthClient.abortSignal("configureEnail");
  }

  render() {
    const getBackgroundColor = (tenant) => {
      let name = tenant + 's';
      var hash = 0;
      for (var i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
      }
      var color = (hash & 0x00ffffff).toString(16).toUpperCase();
      return '00000'.substring(0, 6 - color.length) + color;
    };

    let changeIcon = (name) => {
      name = name.replaceAll('RemoveCircleOutlineIcon', '');
      var selectedTenents = this.state.selectedTenant;
      if (!selectedTenents.includes(name)) {
        selectedTenents.push(name);
        this.setState({selectedTenant: selectedTenents});
      } else {
        var lastIndx = selectedTenents.lastIndexOf(name);
        if (lastIndx > -1) {
          selectedTenents.splice(lastIndx, 1);
        }
        this.setState({selectedTenant: selectedTenents});
      }
    };


    const backToTenantPage = () => {
      if(sessionStorage.isRootUser === "true"){
        window.location.hash= "/admin/tenants";
      }else {
        window.location.hash= "/cli/dashboard";
      }
      window.location.reload();
    }
    const handleDelete = (name) => {
      changeIcon(name);
    };

    const handleSelectAll = () => {
      var selectedTenents = [];
      this.state.tenants.map((element) => {
        selectedTenents.push(element.name);
        this.setState({selectedTenant: selectedTenents});
      });
    };

    const removeSelectedData = () => {
      this.fetchData();
    }

    const handleUnSelectAll = () => {
      var selectedTenents = [];
      this.setState({selectedTenant: selectedTenents});
    };

    const getScopeIds = (names)=> {
      let scopeIds = [];
      _.forEach(names,(name) => {
          for (let index =0; index < this.state.tenants.length ; index++){
            if (this.state.tenants[index].name === name){
              scopeIds.push(this.state.tenants[index].id);
              break;
            }
          }
      })
      return _.uniq(scopeIds)
    }

    const showToastMessage = (message) => {
      this.setState({
        showSnackBar : true,
        snackBarMessage : message,
      });
      setTimeout(() => {
        this.setState({
          showSnackBar : false,
          snackBarMessage : "",
        });
      }, 2500);
    }

    const validateAppliedVersionsOnTenants = (selectedVersion, appliedName, appliedVersionId, allVersionsData) => {
      let selectedTenantHasNewVersion = false;
      let selectedTenantsId = [];
      let selectedFileStoreId = "";
      _.forEach(allVersionsData,(version)=>{
        if (version.version === selectedVersion){
          selectedFileStoreId = version.id;
        }
      })
      _.forEach(this.state.tenants,(tenant) => {
        _.forEach(this.state.selectedTenant,(selectedTenant) => {
          if (tenant.name === selectedTenant){
            selectedTenantsId.push(tenant.id);
            if (selectedVersion <= tenant[appliedName]){
            selectedTenantHasNewVersion = true;
            }
          }
        })
      })
      if (selectedTenantHasNewVersion){
        this.setState({
          showSnackBar : true,
          snackBarMessage : appliedName === "osVersion" ? "You cannot downgrade or apply the same OS version as the latest." : "You cannot downgrade or apply the same firmware version as the latest",
        });
        setTimeout(() => {
          this.setState({
            showSnackBar : false,
            snackBarMessage : "",
          });
        }, 2500);
      }else {
          let firmwareAppliedCount = 0;
          let networkCallsResponseReceived = 0;
          let firmwareFailedCount = 0;
          let selectedTenants = Object.assign([],this.state.selectedTenant);
          let selectedTenantIndex = 0;
          _.forEach(selectedTenantsId,(id)=>{
          setTimeout(() => {
            let json = {
              "scopeId": id,
              "fileStoreId": selectedFileStoreId
            }
            AuthClient.applyFirmware(json,selectedFileStoreId,id).then((response) => {
              networkCallsResponseReceived+=1;
              if (response.status === 200){
                firmwareAppliedCount+=1;
              }else {
                firmwareFailedCount +=1;
              }
            }).catch((error)=>{
              networkCallsResponseReceived+=1
              firmwareFailedCount+=1;
            })
          }, 1000 + selectedTenantIndex);
          selectedTenantIndex += 500;
          let toastMessageInterval = setInterval(() => {
            if (networkCallsResponseReceived === selectedTenants.length && selectedTenants.length > 1){
              if (firmwareFailedCount === 0 && firmwareAppliedCount > 0){
                let tenantorTenants = firmwareAppliedCount > 0 ? " tenants" : " tenant" ;
                if (appliedName === "osVersion"){
                  showToastMessage("OS patch applied for "+firmwareAppliedCount + tenantorTenants);
                }else {
                  showToastMessage("Firmware applied for "+firmwareAppliedCount + tenantorTenants);
                }
                clearInterval(toastMessageInterval);
              }else {
                let tenantorTenants = firmwareAppliedCount > 0 ? " tenants" : " tenant" ;
                let tenantsOrTenants = firmwareFailedCount > 0 ? " tenants" : " tenant" ;
                if (appliedName === "osVersion"){
                  showToastMessage("OS patch applied for "+firmwareAppliedCount + tenantorTenants + " and failed to apply for "+ firmwareFailedCount + tenantsOrTenants);
                }else {
                  showToastMessage("Firmware applied for "+firmwareAppliedCount + tenantorTenants + " and failed to apply for "+ firmwareFailedCount + tenantsOrTenants);
                }
                clearInterval(toastMessageInterval);
              }
              window.uploadFirmwareResponseSuccess = true;
            }else if (networkCallsResponseReceived === selectedTenants.length && selectedTenants.length  === 1){
              if (firmwareFailedCount === 0 && firmwareAppliedCount > 0){
                let tenantorTenants = firmwareAppliedCount > 0 ? " tenants" : " tenant" ;
                if (appliedName === "osVersion"){
                  showToastMessage("Successfully applied OS patch for "+ selectedTenants[0]);
                }else {
                  showToastMessage("Successfully applied firmware for "+ selectedTenants[0]);
                }
                clearInterval(toastMessageInterval);
              }else {
                let tenantorTenants = firmwareAppliedCount > 0 ? " tenants" : " tenant" ;
                let tenantsOrTenants = firmwareFailedCount > 0 ? " tenants" : " tenant" ;
                if (appliedName === "osVersion"){
                  showToastMessage("Failed to apply OS patch for "+ selectedTenants[0]);
                }else {
                  showToastMessage("Failed to apply firmware for "+ selectedTenants[0]);
                }
                clearInterval(toastMessageInterval);
              }
              window.uploadFirmwareResponseSuccess = true;
            }
          }, 1000);
        })
      }
    }

    const applyFirmware = (firmwareName) => {
      if (firmwareName === "gateway"){
        validateAppliedVersionsOnTenants(this.state.selectedGateway,"gatewayVersion","gatewayVersionId",this.state.allGatewayVersions);
      }else if (firmwareName === "os"){
        validateAppliedVersionsOnTenants(this.state.selectedOspatch,"osVersion","osVersionId",this.state.allOsPatchVersions);
      }
    }

    const uploadFile = (event, fileName) => {



      if (fileName === "os" || fileName === "gateway" || fileName === "py3gateway") {
        const file = event.target.files[0];
        let uploadedFileName = file.name;
        let fileFormateAccepted = false;            

        if (fileName === "BGM"){
          fileFormateAccepted = uploadedFileName.match(this.state.bgmFileNamePattern);
        }else if (fileName === "BLE"){
          fileFormateAccepted = uploadedFileName.match(this.state.bleFileNamePattern);
        }else if (fileName === "RFID"){
          fileFormateAccepted = uploadedFileName.match(this.state.rfidFileNamePattern);
        }else if (fileName === "boot"){
          fileFormateAccepted = uploadedFileName.match(this.state.bootLoaderFileNamePattern);
        }else if (fileName === 'gateway'){
          fileFormateAccepted = uploadedFileName.match(this.state.gatewayFileNamePattern);
        }else if (fileName === "os") {
          fileFormateAccepted = uploadedFileName.match(this.state.osPatchFileNamePattern);
        } else if (fileName == "py3gateway") {
          fileFormateAccepted = uploadedFileName.match(this.state.py3WeskoNamePattern);
        }


        let alreadyExistedVersion = false;
        
        if (fileName === "os") {          
          _.forEach(this.state.allOsPatchVersions, (versions) => {
            if (versions.version === Number(uploadedFileName.substr(uploadedFileName.indexOf(".zip")-3,3))){
              alreadyExistedVersion = true;
            }
          })
        }else if (fileName === "gateway") {
          _.forEach(this.state.allGatewayVersions, (versions) => {
            if (versions.version === Number(uploadedFileName.substr(uploadedFileName.indexOf(uploadedFileName.match(/\d/)),10).replace(/-/g,""))){
              alreadyExistedVersion = true;
            }
          })
        }

        if (!fileFormateAccepted){
          this.setState({
            showSnackBar : true,
            snackBarMessage : "Invalid version number or file name",
          });
          setTimeout(() => {
            this.setState({
              showSnackBar : false,
              snackBarMessage : "",
            });
          }, 2000);
        }else if(alreadyExistedVersion) {          
          this.setState({
            showSnackBar : true,
            snackBarMessage : fileName === "os" ? "Uploaded OS version already exists in the tenant.Please choose APPLY instead of UPLOAD" : "Uploaded Gateway version already exists in the tenant.Please choose APPLY instead of UPLOAD",
          });
          setTimeout(() => {
            this.setState({
              showSnackBar : false,
              snackBarMessage : "",
            });
          }, 4000);
        }else {
          let name = fileName;
          let type = null;
          let version = null;

          if (fileName === "gateway"){
            name = "WeskoGateway";
            type = "RASPI";
          }else if (fileName === "os"){
            name = "OSPatch";
            type = "PARTIAL_OS";
          }else if (fileName === "boot"){
            name = "boot";
            type = null;
          }else if (fileName === "BGM" || fileName === "RFID" || fileName === "BLE"){
            name = "img";
            type = fileName;
          } else if (fileName === "py3gateway") {
            name = "PY3WESKO";
            type = "PY3MTK";
          }

          if (fileName === "os"){
            version = uploadedFileName.substr(uploadedFileName.indexOf(".zip")-3,3)
          } else if (fileName === "py3gateway") {
            version = uploadedFileName.substr(uploadedFileName.indexOf(".zip")-4,4)
          } else {
            version = uploadedFileName.substr(uploadedFileName.indexOf(uploadedFileName.match(/\d/)),10).replace(/-/g,"")
          }


          if (file){

            let scopeIds = [];
            let alreadyHaveExistingVersion = false;
            _.forEach(this.state.selectedTenant,(tenantName) => {
                for (let index =0; index < this.state.tenants.length ; index++){
                  if (this.state.tenants[index].name === tenantName){
                    scopeIds.push(this.state.tenants[index].id);
                    if (this.state.tenants[index].versions){
                      for(let subIndex = 0; subIndex < this.state.tenants[index].versions.length ; subIndex++){
                        if (this.state.tenants[index].versions[subIndex].name === name && name !== "img"){
                          if (this.state.tenants[index].versions[subIndex].version >= version){
                            alreadyHaveExistingVersion = true;
                          }
                        }else if (this.state.tenants[index].versions[subIndex].name === name && this.state.tenants[index].versions[subIndex].type === type){
                          if (this.state.tenants[index].versions[subIndex].version >= version){
                            alreadyHaveExistingVersion = true;
                          }
                        }
                      }
                    }
                    break;
                  }
                }
            })



            scopeIds = _.uniq(scopeIds);
            if (!alreadyHaveExistingVersion){
              let info = {
                "name": name,
                "type": type,
                "version": version,
                "scopes": scopeIds,
              };
              const formData = new FormData();
              formData.append('upload', file);
              formData.append('info', JSON.stringify(info));
              let url = window.location.origin +"/v1/auth-service/file-storage";
              AuthClient.uploadFirmware(url, formData, UserDetailService.getAuthToken).then((response) => {
                if (response.status === 200){
                  window.uploadFirmwareResponseSuccess = true;
                  let responseScopes = response.scopes ? response.scopes : [];
                  let successfullyUpdatedScopes = 0;
                  _.forEach(scopeIds,(id)=>{
                    let scopeFound = responseScopes.indexOf(id);
                    if (scopeFound > 0){
                      successfullyUpdatedScopes +=1;
                    }                      
                  })

                  if (scopeIds.length === 1){
                    this.setState({
                      showSnackBar : true,
                      snackBarMessage : fileName === "os" ? "OS Patch updated successfully for "+ this.state.selectedTenant[0] : "Firmware updated successfully for " + this.state.selectedTenant[0],
                    });
                    setTimeout(() => {
                      this.setState({
                        showSnackBar : false,
                        snackBarMessage : "",
                      });
                    }, 3500);
                  }else if (scopeIds > 1) {
                    this.setState({
                      showSnackBar : true,
                      snackBarMessage : fileName === "os" ? "OS Patch updated successfully for "+ successfullyUpdatedScopes + " tenants" : "Firmware updated successfully for " + successfullyUpdatedScopes + " tenants",
                    });
                    setTimeout(() => {
                      this.setState({
                        showSnackBar : false,
                        snackBarMessage : "",
                      });
                    }, 3500);
                  }
                }else {
                  window.uploadFirmwareResponseSuccess = false;
                  scopeIds = scopeIds ? scopeIds : [];
                  if (scopeIds.length === 1) {
                    this.setState({
                      showSnackBar : true,
                      snackBarMessage : fileName === "os" ? "Failed to apply OS patch for " + this.state.selectedTenant[0]  : "Failed to apply firmware for " + this.state.selectedTenant[0],
                    });
                    setTimeout(() => {
                      this.setState({
                        showSnackBar : false,
                        snackBarMessage : "",
                      });
                    }, 3500);
                  }else if (scopeIds.length > 1) {
                    this.setState({
                      showSnackBar : true,
                      snackBarMessage : fileName === "os" ? "Failed to apply OS patch for " + scopeIds.length + " tenants" : "Failed to apply firmware for " + scopeIds.length + " tenants",
                    });
                    setTimeout(() => {
                      this.setState({
                        showSnackBar : false,
                        snackBarMessage : "",
                      });
                    }, 3500);           
                  }
                }
              }).catch((response)=>{
                window.uploadFirmwareResponseSuccess = false;
                scopeIds = scopeIds ? scopeIds : [];
                if (scopeIds.length === 1) {
                  this.setState({
                    showSnackBar : true,
                    snackBarMessage : fileName === "os" ? "Failed to apply OS patch for " + this.state.selectedTenant[0]  : "Failed to apply firmware for " + this.state.selectedTenant[0],
                  });
                  setTimeout(() => {
                    this.setState({
                      showSnackBar : false,
                      snackBarMessage : "",
                    });
                  }, 3500);
                }else if (scopeIds.length > 1) {
                  this.setState({
                    showSnackBar : true,
                    snackBarMessage : fileName === "os" ? "Failed to apply OS patch for " + scopeIds.length + " tenants" : "Failed to apply firmware for " + scopeIds.length + " tenants",
                  });
                  setTimeout(() => {
                    this.setState({
                      showSnackBar : false,
                      snackBarMessage : "",
                    });
                  }, 3500);           
                }
              });
            }else {
              let msge = fileName === "os" ? "You cannot downgrade or apply the same OS version as the latest" : "You cannot downgrade or apply the same firmware version as the latest.";
              showToastMessage(msge);
            }
          }
        }
        if (event.target.value){
          event.target.value = "";
        }
      }
    }

    return (
      <>
        {
          this.state.showSnackBar ?
          <Snackbar
            anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
            open={this.state.showSnackBar}
            message={this.state.snackBarMessage}
          />
        :
          null
        }
        <div style={{height: '90%'}}>
          <div style={{height: '56px'}}>
            <ArrowBack
              onClick={() => {
                if(sessionStorage.isRootUser === "true"){
                  window.location.hash= "/admin/tenants";
                }else {
                  window.location.hash= "/cli/dashboard";
                }
                window.location.reload();
              }}
              className="backArrow"
            />
          </div>
          <div className="container" style={{maxHeight: '95%'}}>
            <div
              className="subContainer"
              style={{
                minHeight: '100%',
                marginRight: 'auto',
                marginLeft: 'auto',
              }}
            >
              <div
                style={{
                  flex: '20',
                  padding: '0 12px',
                  borderBottom: '1px solid rgba(0,0,0,0.12)',
                }}
              >
                <h5 style={{fontSize: '16px'}}>Update Gateway Firmware</h5>
              </div>
              <div className="subBlock" style={{flex: '60'}}>
                <div className="portions" style={{width: '100%'}}>
                  {this.state.selectedTenant.length > 0 ? (
                    <div className="selectedBlockExist">
                      {this.state.selectedTenant.map((index) => {
                        return (
                          <Chip
                            sx={{marginTop: '8px', marginLeft: '8px'}}
                            label={index}
                            onDelete={() => handleDelete(index)}
                          />
                        );
                      })}
                    </div>
                  ) : (
                    <div className="selectedBlock">
                      <span>Select a tenant to update the firmware</span>
                    </div>
                  )}
                  <div className="radioGroup">
                    <FormControl component="fieldset" variant="standard">
                      <RadioGroup
                        defaultValue="Gateway"
                        row
                        aria-label="emailType"
                        name="row-radio-buttons-group"
                        onChange={this.handleRadioChange}
                      >
                        <FormControlLabel
                          value="Firmware"
                          control={
                            <Radio
                              sx={{
                                color: 'rgba(0,0,0,0.54)',
                                '&.Mui-checked': {
                                  color: 'rgba(194,24,91,0.87)',
                                },
                              }}
                            />
                          }
                          label="Firmware"
                          sx={{display:"none"}}
                        />
                        <FormControlLabel
                          value="BootLoader"
                          control={
                            <Radio
                              sx={{
                                color: 'rgba(0,0,0,0.54)',
                                '&.Mui-checked': {
                                  color: 'rgba(194,24,91,0.87)',
                                },                                
                              }}
                            />
                          }
                          label="BootLoader"
                          sx={{display:'none'}}
                        />
                        <FormControlLabel
                          value="Gateway"
                          control={
                            <Radio
                              sx={{
                                color: 'rgba(0,0,0,0.54)',
                                '&.Mui-checked': {
                                  color: 'rgba(194,24,91,0.87)',
                                },
                              }}
                            />
                          }
                          label="Gateway"
                        />
                        <FormControlLabel
                              value="OSPatch"
                              control={
                                <Radio
                                  sx={{
                                    color: 'rgba(0,0,0,0.54)',
                                    '&.Mui-checked': {
                                      color: 'rgba(194,24,91,0.87)',
                                    },
                                  }}
                                />
                              }
                              label="OS Patch"
                            />

                        <FormControlLabel
                            value="py3Wesko"
                            control={
                              <Radio
                                  sx={{
                                    color: 'rgba(0,0,0,0.54)',
                                    '&.Mui-checked': {
                                      color: 'rgba(194,24,91,0.87)',
                                    },
                                  }}
                              />
                            }
                            label="PY3 WESKO"
                        />
                      </RadioGroup>
                      <RadioGroup
                          defaultValue="Apply"
                          row
                          aria-label="emailType"
                          name="row-radio-buttons-group"
                          onChange={this.handleActionRadioChange}
                        >
                          <FormControlLabel
                            value="Apply"
                            control={
                              <Radio
                                sx={{
                                  color: 'rgba(0,0,0,0.54)',
                                  '&.Mui-checked': {
                                    color: 'rgba(194,24,91,0.87)',
                                  },
                                }}
                              />
                            }
                            label="Apply"
                          />
                          <FormControlLabel
                            value="Upload"
                            control={
                              <Radio
                                sx={{
                                  color: 'rgba(0,0,0,0.54)',
                                  '&.Mui-checked': {
                                    color: 'rgba(194,24,91,0.87)',
                                  },
                                }}
                              />
                            }
                            label="Upload"
                          />
                        </RadioGroup>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          margin: '8px',
                        }}
                      >
                          <div style={{ display:
                                this.state.selectedRadio === 'Firmware' && this.state.selectedRadioAction === 'Apply'
                                 ? 'block' : 'none', }}
                          >
                            <Box className="firmwareUploadColumn" sx={{ display: 'flex', alignItems: 'center', margin: '8px', textAlign: 'center',}} >
                              <Box sx={{display:"flex",flexDirection:'row'}}>
                                  <b>BGM:</b>
                              </Box>
                              <Select
                                id="bgmSelect"
                                value={this.state.selectedBgm}
                                disabled={this.state.allBgmVersions.length === 0}
                                onChange={(event)=>this.handleSelectApplyOptionChange(event.target.value,"bgm")}
                                sx={{width: '100%'}}
                              >
                                {
                                  this.state.allBgmVersions.length > 0 ?
                                    this.state.allBgmVersions.map((version)=>{
                                      return (
                                        <MenuItem value={version.version}>
                                          {version.version} ({moment(version.date).format("MMM Do, YYYY h:mm:ss a")})
                                        </MenuItem>
                                      )
                                    })
                                  :
                                    null
                                }
                              </Select>
                              <Box>
                                <Button sx={{
                                    '&.MuiButtonBase-root:hover': {
                                      background: 'rgb(194,24,91)',
                                  },
                                  margin: '6px',
                                  color: this.state.selectedTenant.length !== 0 ? "#fff" : null,background: this.state.selectedTenant.length !== 0 ? "rgb(194,24,91)" : 'rgba(0,0,0,0.12)'
                                }} disabled={this.state.selectedTenant.length === 0} variant="contained" onClick={()=>applyFirmware("bgm")} > APPLY </Button>
                              </Box>
                            </Box>
                            <Box className="firmwareUploadColumn" sx={{ display: 'flex', alignItems: 'center', margin: '8px', textAlign: 'center',}} >
                              <Box sx={{display: 'flex',flexDirection:'row'}} >
                                <b>BLE:</b>
                              </Box>
                              <Select
                                id="bleSelect"
                                value={this.state.selectedBle}
                                disabled={this.state.allBleVersions.length === 0}
                                onChange={(event)=>this.handleSelectApplyOptionChange(event.target.value,"ble")}
                                sx={{width: '100%'}}
                              >
                                {
                                  this.state.allBleVersions.length > 0 ?
                                    this.state.allBleVersions.map((version)=>{
                                      return (
                                        <MenuItem value={version.version}>
                                          {version.version} ({moment(version.date).format("MMM Do, YYYY h:mm:ss a")})
                                        </MenuItem>
                                      )
                                    })
                                  :
                                    null
                                }
                              </Select>
                              <Box>
                                <Button disabled={this.state.selectedTenant.length === 0} variant="contained" onClick={()=>applyFirmware("ble")} sx={{
                                    '&.MuiButtonBase-root:hover': {
                                      background: 'rgb(194,24,91)',
                                  },
                                  margin: '6px',
                                  color: this.state.selectedTenant.length !== 0 ? "#fff" : null,background: this.state.selectedTenant.length !== 0 ? "rgb(194,24,91)" : 'rgba(0,0,0,0.12)'
                                }} > APPLY </Button>
                              </Box>
                            </Box>
                            <Box  className="firmwareUploadColumn" sx={{ display: 'flex', alignItems: 'center', margin: '8px', textAlign: 'center',}} >
                              <Box sx={{display: 'flex'}} >
                                <b>RFID:</b>
                              </Box>
                              <Select
                                id="rfidSelect"
                                value={this.state.selectedRfid}
                                disabled={this.state.allRfidVersions.length === 0}
                                onChange={(event)=>this.handleSelectApplyOptionChange(event.target.value,"rfid")}
                                sx={{width: '100%'}}
                              >
                                {
                                  this.state.allRfidVersions.length > 0 ?
                                    this.state.allRfidVersions.map((version)=>{
                                      return (
                                        <MenuItem value={version.version}>
                                          {version.version} ({moment(version.date).format("MMM Do, YYYY h:mm:ss a")})
                                        </MenuItem>
                                      )
                                    })
                                  :
                                    null
                                }
                              </Select>
                              <Box>
                              <Button disabled={this.state.selectedTenant.length === 0} variant="contained" onClick={()=>applyFirmware("rfid")} sx={{
                                    '&.MuiButtonBase-root:hover': {
                                      background: 'rgb(194,24,91)',
                                  },
                                  margin: '6px',
                                  color: this.state.selectedTenant.length !== 0 ? "#fff" : null,background: this.state.selectedTenant.length !== 0 ? "rgb(194,24,91)" : 'rgba(0,0,0,0.12)'
                                }} > APPLY </Button>
                              </Box>
                            </Box>
                          </div>
                        <div
                          style={{
                            display:
                              this.state.selectedRadio === 'Firmware' && this.state.selectedRadioAction === 'Upload'
                               ? 'block' : 'none',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              margin: '8px',
                              textAlign: 'center',
                            }}
                            className="firmwareUploadColumn"
                          >
                            <Box sx={{display: 'flex',flexDirection:"row"}}>
                              <b>BGM:</b>
                                <Tooltip placement="top" title={
                                  <div style={{ whiteSpace: 'pre-line',fontSize:"10px"}}>
                                    {"Expected File Name Format: \n wesko-elock.production-yyyy-mm-dd-BGMP.hex"}
                                  </div>
                                } arrow>
                                  <HelpOutline sx={{cursor:'help',width: 18, height: 18, color: 'rgba(0,0,0,0.54)', marginRight: '8px',marginLeft: '8px', }} />
                                </Tooltip>
                            </Box>
                            <Button
                                component="label"
                                variant="outlined"
                                disabled={this.state.selectedTenant.length === 0}
                                sx={{
                                    '&.MuiButtonBase-root:hover': {
                                      background: 'rgb(20,19,139)',
                                  },
                                  width: '100%',color: this.state.selectedTenant.length !== 0 ? "#fff" : null, margin: '6px',background: this.state.selectedTenant.length !== 0 ? "rgb(20,19,139)" : 'rgba(0,0,0,0.12)'
                                }}
                            >
                                SELECT FILE
                                <input type="file" accept=".hex" value={null} hidden onChange={(event)=>uploadFile(event,"BGM")} />
                            </Button>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              margin: '8px',
                              textAlign: 'center',
                            }}
                            className="firmwareUploadColumn"
                          >
                            <Box sx={{display: 'flex',flexDirection:"row"}}>
                              <b>BLE:</b>
                              <Tooltip placement="top" title={
                                <div style={{ whiteSpace: 'pre-line',fontSize:"10px"}}>
                                  {"Expected File Name Format: \n wesko-elock.production-yyyy-mm-dd-BLEP.hex"}
                                </div>
                              } arrow>
                                <HelpOutline sx={{cursor:'help',width: 18, height: 18, color: 'rgba(0,0,0,0.54)', marginRight: '8px',marginLeft: '8px', }} />
                              </Tooltip>
                            </Box>
                            <Button
                                component="label"
                                variant="outlined"
                                disabled={this.state.selectedTenant.length === 0}
                                sx={{
                                  '&.MuiButtonBase-root:hover': {
                                    background: 'rgb(20,19,139)',
                                },
                                  width: '100%',color: this.state.selectedTenant.length !== 0 ? "#fff" : null, margin: '6px',background: this.state.selectedTenant.length !== 0 ? "rgb(20,19,139)" : 'rgba(0,0,0,0.12)'
                                }}
                            >
                                SELECT FILE
                                <input type="file" accept=".hex" value={null} hidden onChange={(event)=>uploadFile(event,"BLE")} />
                            </Button>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              margin: '8px',
                              textAlign: 'center',
                            }}
                            className="firmwareUploadColumn"
                          >
                            <Box sx={{display: 'flex',flexDirection:"row"}}>
                              <b>RFID:</b>
                              <Tooltip placement="top" title={
                                <div style={{ whiteSpace: 'pre-line',fontSize:"10px"}}>
                                  {"Expected File Name Format: \n wesko-elock.production-yyyy-mm-dd-RFID.hex"}
                                </div>
                              } arrow>
                                <HelpOutline sx={{cursor:'help',width: 18, height: 18, color: 'rgba(0,0,0,0.54)', marginRight: '8px',marginLeft: '8px', }} />
                              </Tooltip>
                            </Box>
                            <Button
                                component="label"
                                variant="outlined"
                                disabled={this.state.selectedTenant.length === 0}
                                sx={{
                                  '&.MuiButtonBase-root:hover': {
                                    background: 'rgb(20,19,139)',
                                },
                                  width: '100%',color: this.state.selectedTenant.length !== 0 ? "#fff" : null, margin: '6px',background: this.state.selectedTenant.length !== 0 ? "rgb(20,19,139)" : 'rgba(0,0,0,0.12)'
                                }}
                            >
                                SELECT FILE
                                <input type="file" accept=".hex" value={null} hidden onChange={(event)=>uploadFile(event,"RFID")} />
                            </Button>
                          </Box>
                        </div>
                        <div style={{ display:
                                this.state.selectedRadio === 'BootLoader' && this.state.selectedRadioAction === 'Apply'
                                 ? 'block' : 'none', }}
                          >
                            <Box className='firmwareUploadColumn' sx={{ display: 'flex', alignItems: 'center', margin: '8px', textAlign: 'center',}} >
                              <Box sx={{display: 'flex',flexDirection:'row'}} >
                                <b>BL:</b>
                              </Box>
                              <Select
                                id="bootSelect"
                                value={this.state.selectedBoot}
                                disabled={this.state.allBootLoaderVersions.length === 0}
                                onChange={(event)=>this.handleSelectApplyOptionChange(event.target.value,"boot")}
                                sx={{width: '100%'}}
                              >
                                {
                                  this.state.allBootLoaderVersions.length > 0 ?
                                    this.state.allBootLoaderVersions.map((version)=>{
                                      return (
                                        <MenuItem value={version.version}>
                                          {version.version} ({moment(version.date).format("MMM Do, YYYY h:mm:ss a")})
                                        </MenuItem>
                                      )
                                    })
                                  :
                                    null
                                }
                              </Select>
                              <Box>
                                <Button disabled={this.state.selectedTenant.length === 0} variant="contained" onClick={()=>applyFirmware("boot")} sx={{
                                    '&.MuiButtonBase-root:hover': {
                                      background: 'rgb(194,24,91)',
                                  },
                                  margin: '6px',
                                  color: this.state.selectedTenant.length !== 0 ? "#fff" : null,background: this.state.selectedTenant.length !== 0 ? "rgb(194,24,91)" : 'rgba(0,0,0,0.12)'
                                }} > APPLY </Button>
                              </Box>
                            </Box>
                        </div>
                        <div
                          style={{
                            display:
                              this.state.selectedRadio === 'BootLoader' && this.state.selectedRadioAction === 'Upload'
                                ? 'block'
                                : 'none',
                          }}
                        >
                          <Box
                            className="firmwareUploadColumn"
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              margin: '8px',
                              textAlign: 'center',
                            }}
                          >
                            <Box sx={{display: 'flex',flexDirection:"row"}}>
                              <b>BL:</b>
                              <Tooltip placement="top" title={
                                    <div style={{ whiteSpace: 'pre-line',fontSize:"10px"}}>
                                      {"Expected File Name Format: \n wesko-elock.production-yyyy-mm-dd-Bootloader.hex"}
                                    </div>
                                  } arrow>
                                    <HelpOutline sx={{cursor:'help',width: 18, height: 18, color: 'rgba(0,0,0,0.54)', marginRight: '8px',marginLeft: '8px', }} />
                                </Tooltip>
                            </Box>
                            <Button
                                component="label"
                                variant="outlined"
                                disabled={this.state.selectedTenant.length === 0}
                                sx={{
                                  '&.MuiButtonBase-root:hover': {
                                    background: 'rgb(20,19,139)',
                                },
                                  width: '100%',color: this.state.selectedTenant.length !== 0 ? "#fff" : null, margin: '6px',background: this.state.selectedTenant.length !== 0 ? "rgb(20,19,139)" : 'rgba(0,0,0,0.12)'
                                }}
                            >
                                SELECT FILE
                                <input type="file" accept=".hex" value={null} hidden onChange={(event)=>uploadFile(event,"boot")} />
                            </Button>
                          </Box>
                        </div>
                        <div style={{ display:
                                this.state.selectedRadio === 'Gateway' && this.state.selectedRadioAction === 'Apply'
                                 ? 'block' : 'none', }}
                          >
                            <Box className="firmwareUploadColumn" sx={{ display: 'flex', alignItems: 'center', margin: '8px', textAlign: 'center',}} >
                              <Box sx={{display: 'flex',flexDirection:"row"}} >
                                <b>GW:</b>&nbsp;
                              </Box>
                              <Select
                                id="gatewaySelect"
                                value={this.state.selectedGateway}
                                disabled={this.state.allGatewayVersions.length === 0}
                                onChange={(event)=>this.handleSelectApplyOptionChange(event.target.value,"gateway")}
                                sx={{width: '100%'}}
                              >
                                {
                                  this.state.allGatewayVersions.length > 0 ?
                                    this.state.allGatewayVersions.map((version)=>{
                                      return (
                                        <MenuItem value={version.version}>
                                          {version.version} ({moment(version.date).format("MMM Do, YYYY h:mm:ss a")})
                                        </MenuItem>
                                      )
                                    })
                                  :
                                    null
                                }
                              </Select>
                              <Box>
                                <Button disabled={this.state.selectedTenant.length === 0} variant="contained" onClick={()=>applyFirmware("gateway")} sx={{
                                    '&.MuiButtonBase-root:hover': {
                                      background: 'rgb(194,24,91)',
                                  },
                                  margin: '6px',
                                  color: this.state.selectedTenant.length !== 0 ? "#fff" : null,background: this.state.selectedTenant.length !== 0 ? "rgb(194,24,91)" : 'rgba(0,0,0,0.12)'
                                }} > APPLY </Button>
                              </Box>
                            </Box>
                        </div>


                        <div style={{ display:
                              this.state.selectedRadio === 'py3Wesko' && this.state.selectedRadioAction === 'Apply'
                                  ? 'block' : 'none', }}
                        >
                          <Box className="firmwareUploadColumn" sx={{ display: 'flex', alignItems: 'center', margin: '8px', textAlign: 'center',}} >
                            <Box sx={{display: 'flex',flexDirection:"row"}} >
                              <b>PY3 GW:</b>&nbsp;
                            </Box>
                            <Select
                                id="gatewaySelect"
                                value={this.state.selectedGateway}
                                disabled={this.state.allGatewayVersions.length === 0}
                                onChange={(event)=>this.handleSelectApplyOptionChange(event.target.value,"gateway")}
                                sx={{width: '100%'}}
                            >
                              {
                                this.state.allGatewayVersions.length > 0 ?
                                    this.state.allGatewayVersions.map((version)=>{
                                      return (
                                          <MenuItem value={version.version}>
                                            {/*{version.version} ({moment(version.date).format("MMM Do, YYYY h:mm:ss a")})*/}
                                          </MenuItem>
                                      )
                                    })
                                    :
                                    null
                              }
                            </Select>
                            <Box>
                              <Button disabled={this.state.selectedTenant.length === 0} variant="contained" onClick={()=>applyFirmware("gateway")} sx={{
                                '&.MuiButtonBase-root:hover': {
                                  background: 'rgb(194,24,91)',
                                },
                                margin: '6px',
                                color: this.state.selectedTenant.length !== 0 ? "#fff" : null,background: this.state.selectedTenant.length !== 0 ? "rgb(194,24,91)" : 'rgba(0,0,0,0.12)'
                              }} > APPLY </Button>
                            </Box>
                          </Box>
                        </div>
                        <div
                          style={{
                            display:
                              this.state.selectedRadio === 'Gateway' && this.state.selectedRadioAction === 'Upload'
                                ? 'block'
                                : 'none',
                          }}
                        >
                          <Box
                            className="firmwareUploadColumn"
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              margin: '8px',
                              textAlign: 'center',
                            }}
                          >
                            <Box sx={{display: 'flex',flexDirection:"row"}}>
                              <b>GW:</b>
                              <Tooltip placement="top" title={
                                    <div style={{ whiteSpace: 'pre-line',fontSize:"10px"}}>
                                      {"Expected File Name Format: \n Wesko.yyyy-mm-dd_GW.xxx"}
                                    </div>
                                  } arrow>
                                    <HelpOutline sx={{cursor:'help',width: 18, height: 18, color: 'rgba(0,0,0,0.54)', marginRight: '8px',marginLeft: '8px', }} />
                                </Tooltip>
                            </Box>
                            <Button
                                component="label"
                                variant="outlined"
                                disabled={this.state.selectedTenant.length === 0}
                                sx={{
                                  '&.MuiButtonBase-root:hover': {
                                    background: 'rgb(20,19,139)',
                                },
                                  width: '100%',color: this.state.selectedTenant.length !== 0 ? "#fff" : null, margin: '6px',background: this.state.selectedTenant.length !== 0 ? "rgb(20,19,139)" : 'rgba(0,0,0,0.12)'
                                }}
                            >
                                SELECT FILE
                                <input type="file" accept=".zip" value={null} hidden onChange={(event)=>uploadFile(event,"gateway")} />
                            </Button>
                          </Box>
                        </div>



                        <div
                            style={{
                              display:
                                  this.state.selectedRadio === 'py3Wesko' && this.state.selectedRadioAction === 'Upload'
                                      ? 'block'
                                      : 'none',
                            }}
                        >
                          <Box
                              className="firmwareUploadColumn"
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                margin: '8px',
                                textAlign: 'center',
                              }}
                          >
                            <Box sx={{display: 'flex',flexDirection:"row"}}>
                              <b>PY3 GW:</b>
                              <Tooltip placement="top" title={
                                <div style={{ whiteSpace: 'pre-line',fontSize:"10px"}}>
                                  {"Expected File Name Format: \n py3WESKO.yyyy.xxx"}
                                </div>
                              } arrow>
                                <HelpOutline sx={{cursor:'help',width: 18, height: 18, color: 'rgba(0,0,0,0.54)', marginRight: '8px',marginLeft: '8px', }} />
                              </Tooltip>
                            </Box>
                            <Button
                                component="label"
                                variant="outlined"
                                disabled={this.state.selectedTenant.length === 0}
                                sx={{
                                  '&.MuiButtonBase-root:hover': {
                                    background: 'rgb(20,19,139)',
                                  },
                                  width: '100%',color: this.state.selectedTenant.length !== 0 ? "#fff" : null, margin: '6px',background: this.state.selectedTenant.length !== 0 ? "rgb(20,19,139)" : 'rgba(0,0,0,0.12)'
                                }}
                            >
                              SELECT FILE
                              <input type="file" accept=".zip" value={null} hidden onChange={(event)=>uploadFile(event,"py3gateway")} />
                            </Button>
                          </Box>
                        </div>




                        <div style={{ display:
                                this.state.selectedRadio === 'OSPatch' && this.state.selectedRadioAction === 'Apply'
                                 ? 'block' : 'none', }}
                          >
                            <Box className="firmwareUploadColumn" sx={{ display: 'flex', alignItems: 'center', margin: '8px', textAlign: 'center',}} >
                              <Box sx={{display: 'flex',flexDirection:"row"}} >
                                <b>Version:</b>
                              </Box>
                              <Select
                                id="osSelect"
                                value={this.state.selectedOspatch}
                                disabled={this.state.allOsPatchVersions.length === 0}
                                onChange={(event)=>this.handleSelectApplyOptionChange(event.target.value,"os")}
                                sx={{width: '100%'}}
                              >
                                {
                                  this.state.allOsPatchVersions.length > 0 ?
                                    this.state.allOsPatchVersions.map((version)=>{
                                      return (
                                        <MenuItem value={version.version}>
                                          {version.version} ({moment(version.date).format("MMM Do, YYYY h:mm:ss a")})
                                        </MenuItem>
                                      )
                                    })
                                  :
                                    null
                                }
                              </Select>
                              <Box>
                                <Button disabled={this.state.selectedTenant.length === 0} variant="contained" onClick={()=>applyFirmware("os")} sx={{
                                    '&.MuiButtonBase-root:hover': {
                                      background: 'rgb(194,24,91)',
                                  },
                                  margin: '6px',
                                  color: this.state.selectedTenant.length !== 0 ? "#fff" : null,background: this.state.selectedTenant.length !== 0 ? "rgb(194,24,91)" : 'rgba(0,0,0,0.12)'
                                }} > APPLY </Button>
                              </Box>
                            </Box>
                        </div>
                        <div
                          style={{
                            display:
                              this.state.selectedRadio === 'OSPatch' && this.state.selectedRadioAction === 'Upload'
                                ? 'block'
                                : 'none',
                          }}
                        >
                          <Box
                            className="firmwareUploadColumn"
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              margin: '8px',
                              textAlign: 'center',
                            }}
                          >
                            <Box sx={{display: 'flex',flexDirection:"row"}}>
                              <b>Upload:</b>
                              <Tooltip placement="top" title={
                                    <div style={{ whiteSpace: 'pre-line',fontSize:"10px"}}>
                                      {"Expected File Name Format: \n PartialUpdate_yyyy-mm-dd_"}
                                      <span>
                                       { this.state.isStaging ? "STAGING" : "PROD" }
                                      </span>
                                      {"_VXXX.zip"}
                                    </div>
                                  } arrow>
                                    <HelpOutline sx={{cursor:'help',width: 18, height: 18, color: 'rgba(0,0,0,0.54)', marginRight: '8px',marginLeft: '8px', }} />
                                </Tooltip>
                            </Box>
                            <Button
                                component="label"
                                variant="outlined"
                                disabled={this.state.selectedTenant.length === 0}
                                sx={{
                                  '&.MuiButtonBase-root:hover': {
                                    background: 'rgb(20,19,139)',
                                },
                                  width: '100%',color: this.state.selectedTenant.length !== 0 ? "#fff" : null, margin: '6px',background: this.state.selectedTenant.length !== 0 ? "rgb(20,19,139)" : 'rgba(0,0,0,0.12)'
                                }}
                            >
                                SELECT FILE
                                <input type="file" accept=".zip" value={null} hidden onChange={(event)=>uploadFile(event,"os")} />
                            </Button>
                          </Box>
                        </div>
                      </Box>
                    </FormControl>
                  </div>
                </div>
                <div
                  className="portions tenantsBlock"
                  style={{
                    margin: 'auto',
                    height: '100%',
                    width: '95%',
                  }}
                >
                  <div
                    className="searchBlock"
                    style={{padding: '7px', display: 'initial'}}
                  >
                    <input
                      type="text"
                      value={this.state.value}
                      onChange={this.handleChange}
                      placeholder="Search"
                      style={{
                        textIndent: '4px',
                        height: '34px',
                        fontSize: '100%',
                      }}
                    />
                    <Button
                      style={{
                        color: 'rgb(194,24,91)',
                        margin: '2px',
                        fontWeight: '500',
                      }}
                      onClick={() => handleSelectAll()}
                    >
                      SELECT ALL
                    </Button>
                    <Button
                      style={{
                        color: 'rgb(194,24,91)',
                        margin: '2px',
                        fontWeight: '500',
                      }}
                      onClick={() => handleUnSelectAll()}
                    >
                      UNSELECT ALL
                    </Button>
                  </div>
                  <div className="allTenants" style={{height:"100%"}}>
                    {
                      !this.state.isLoading ?
                      // eslint-disable-next-line array-callback-return
                      this.state.tenants ? this.state.tenants
                        .sort((first, next) =>
                          first.name.localeCompare(next.name)
                        )
                        .map((element, index) => {
                          if (index >= 0) {
                            if (
                              element.name
                                .toLowerCase()
                                .lastIndexOf(
                                  this.state.searchText.toLowerCase()
                                ) !== -1
                            ) {
                              return (
                                <div
                                  key={element.name}
                                  className="tenantItems"
                                  style={{minHeight: '72px', height: 'auto',
                                  marginBottom: '10px', marginTop: '5px'}}
                                >
                                  <div
                                    style={{
                                      width: '96px',
                                      height: '100%',
                                      alignItems: 'center',
                                      display: 'flex',
                                    }}
                                  >
                                    <Avatar
                                      id="avatar"
                                      style={{
                                        margin: ' 10px 4px 10px 24px',
                                        padding: '4px',
                                        background:
                                          '#' +
                                          getBackgroundColor(
                                            element.name
                                          ),
                                      }}
                                    >
                                      {element.name[0].toUpperCase()}
                                    </Avatar>
                                  </div>
                                  <div
                                    style={{
                                      marginTop: 'auto',
                                      marginBottom: 'auto',
                                      width: '80%',
                                    }}
                                  >
                                    {element.name}
                                    {
                                      this.state.selectedRadio === "Firmware" ?
                                        <>
                                          <div style={{
                                            marginTop: '5px',
                                            color: 'gray',
                                            fontSize: '12px',
                                        }}>
                                            {
                                              element.versions ?
                                                element.versions.map((value)=>(
                                                  value.name === "img" && value.type === "BGM" ?
                                                  "BGM: " + value.version + " ("+ moment(value.date).format("MMM Do, YYYY h:mm:ss a") + ")"
                                                  :
                                                    null
                                                ))
                                              :
                                                null
                                            }
                                        </div>
                                        <div style={{
                                            color: 'gray',
                                            fontSize: '12px',
                                        }}>
                                            {
                                              element.versions ?
                                                element.versions.map((value)=>(
                                                  value.name === "img" && value.type === "BLE" ?
                                                  "BLE: " + value.version + " ("+ moment(value.date).format("MMM Do, YYYY h:mm:ss a") + ")"
                                                  :
                                                    null
                                                ))
                                              :
                                                null
                                            }
                                        </div>
                                        <div style={{
                                            color: 'gray',
                                            fontSize: '12px',
                                        }}>
                                            {
                                              element.versions ?
                                                element.versions.map((value)=>(
                                                  value.name === "img" && value.type === "RFID" ?
                                                  "RFID: " + value.version + " ("+ moment(value.date).format("MMM Do, YYYY h:mm:ss a") + ")"
                                                  :
                                                    null
                                                ))
                                              :
                                                null
                                            }
                                        </div>
                                        </>
                                      :
                                        this.state.selectedRadio === "BootLoader" ?
                                            <>
                                              <div style={{
                                                  marginTop: '5px',
                                                  color: 'gray',
                                                  fontSize: '12px',
                                              }}>
                                                  {
                                                    element.versions ?
                                                      element.versions.map((value)=>(
                                                        value.name === "boot" ?
                                                         value.version + " ("+ moment(value.date).format("MMM Do, YYYY h:mm:ss a") + ")"
                                                        :
                                                          null
                                                      ))
                                                    :
                                                      null
                                                  }
                                              </div>
                                            </>
                                        :
                                          this.state.selectedRadio === "Gateway" ?
                                            <>
                                              <div style={{
                                                  marginTop: '5px',
                                                  color: 'gray',
                                                  fontSize: '12px',
                                              }}>
                                                  {
                                                    element.versions ?
                                                      element.versions.map((value)=>(
                                                        value.type === "RASPI" ?
                                                        value.version + " ("+ moment(value.date).format("MMM Do, YYYY h:mm:ss a") + ")"
                                                        :
                                                          null
                                                      ))
                                                    :
                                                      null
                                                  }
                                              </div>
                                            </>
                                          :
                                            this.state.selectedRadio === "OSPatch" ?
                                              <>
                                                <div style={{
                                                    marginTop: '5px',
                                                    color: 'gray',
                                                    fontSize: '12px',
                                                }}>
                                                    {
                                                      element.versions ?
                                                        element.versions.map((value)=>(
                                                          value.name === "OSPatch" ?
                                                          value.version + " ("+ moment(value.date).format("MMM Do, YYYY h:mm:ss a") + ")"
                                                          :
                                                            null
                                                        ))
                                                      :
                                                        null
                                                    }
                                                </div>
                                              </>
                                            :
                                                null
                                          }
                                  </div>
                                  <div style={{margin: 'auto 30px auto 0px'}}>
                                    {
                                      <>
                                        <AddCircleOutline
                                          id={element.name}
                                          style={{
                                            display:
                                              this.state.selectedTenant.includes(
                                                element.name
                                              )
                                                ? 'none'
                                                : 'block',
                                          }}
                                          onClick={() =>
                                            changeIcon(element.name)
                                          }
                                          sx={{color: 'rgba(0,0,0,0.54)'}}
                                        />
                                        <RemoveCircleOutlined
                                          className="removeCircle"
                                          style={{
                                            display:
                                              this.state.selectedTenant.includes(
                                                element.name
                                              )
                                                ? 'block'
                                                : 'none',
                                            color: 'rgba(0,0,0,0.54)',
                                          }}
                                          id={
                                            element.name +
                                            'RemoveCircleOutlineIcon'
                                          }
                                          onClick={() =>
                                            changeIcon(
                                              element.name +
                                                'RemoveCircleOutlineIcon'
                                            )
                                          }
                                        />
                                      </>
                                    }
                                  </div>
                                </div>
                              );
                            }
                          }
                        }) : null
                      :
                        <Box sx={{width:'100%',display:'flex',justifyContent: 'center',alignItems:'center',minHeight: '72px', height: '100%'}}>
                          <CircularProgress thickness="4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                        </Box>
                    }
                  </div>
                </div>
              </div>

              <div className="bottomDiv" style={{}}>
                <Button style={{color: 'rgb(194,24,91)', margin: '2px'}} onClick={()=>backToTenantPage()}>
                  BACK TO TENANTS
                </Button>
              </div>
              <div style={{height: '10%'}}>&nbsp;&nbsp;</div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
