import React from 'react';
import appLogo from '../.././wesko-eLock_logo.jpeg';
import { Search,AccountCircle,ViewModule,ViewList } from '@mui/icons-material';
import { Box,Menu,MenuItem,Divider,IconButton } from '@mui/material';
import { Link,useNavigate } from 'react-router-dom';
import '../../stylesheet/header.css';
import { progressBarFetch, setOriginalFetch, ProgressBar } from "react-fetch-progressbar";
import { useIdleTimer } from 'react-idle-timer';
import { Tooltip } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

const Appheader = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [lockView, setLockView] = React.useState(sessionStorage.lockView === "listView" || sessionStorage.tenantName === "FreeTier" || !sessionStorage.lockView  ? "listView" : "gridView");
  const open = Boolean(anchorEl);
  let location = useLocation();  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [landingPage, setLandingPage] = React.useState(false);
  
  const nav = useNavigate();
  const reloadPage = (url) => {
    if (url === "/engineering-View"){
      sessionStorage.engineeringView = true;
    }
    if (url === "/logout"){
        sessionStorage.clear();
      }
      nav(url);
      window.location.reload();
  };

  const checkLocatiionHashChange = () => {
    window.addEventListener("hashchange", function(e){ 
      setLockView("listView");
    });
  }

  const isCariboutechDomain = () => {
    return JSON.parse(sessionStorage.userDetails).user.email.indexOf("cariboutech.com") !==-1
  }

  const isWeskoDomain = () => {
    return JSON.parse(sessionStorage.userDetails).user.email.indexOf("wesko-elocks.com") !== -1;
  }

  setInterval(() => {
    if (window.location.href.indexOf("/admin/tenants") !== -1 && sessionStorage.hasOwnProperty("userDetails") ? JSON.parse(sessionStorage.userDetails).permissions.includes("FIRMWARE_WRITE") : false){
      setLandingPage(true);
    }else {
      setLandingPage(false);
    }
    if(window.location.hash !== "" || window.location.hash !== "#/"){
        if(!sessionStorage.userLoggedIn){
            window.location.hash = "#/"
        }
    }
    if(window.location.hash === "#/admin/tenants"){
      delete sessionStorage.tenantId
    }
    if (window.location.hash.indexOf("list_view") !== -1 || window.location.hash.indexOf("grid_view") !== -1){
      if (!sessionStorage.lockView || sessionStorage.lockView === "listView"){
        window.location.hash = "/cli/devices/list_view" + "?scopeId="+ sessionStorage.tenantId;
      }
      if (sessionStorage.lockView === "gridView"){
        window.location.hash = "/cli/devices/grid_view" + "?scopeId="+ sessionStorage.tenantId;
      }
    }
    if ((document.cookie.indexOf("mqtt-auth-token") === -1 || !sessionStorage.hasOwnProperty("userDetails")) && window.location.hash !== "#/login" && window.location.hash !== "#/" && window.location.hash !== "/"){
      window.location.hash = "#/login";
    }
  },1000);

  React.useEffect(()=>{
    checkLocatiionHashChange();
  },[])

  // IDLE LOGOUT LOGIC
    const timeout = 900000;
    const handleOnIdle = () => {
        let urlHash = window.location.hash;
        if (urlHash !== "" && urlHash !== "#/" && urlHash !== "#/login" && urlHash !== "#/logout"){
            nav("/");
            window.location.reload();
        }
    }

    const {
      reset,
      pause,
      resume,
    } = useIdleTimer({
      timeout,
      onIdle: handleOnIdle
    })
  //IDLE LOGIC END

  return (
    <nav style={{width: props.hasOwnProperty('parent') ? '100%' : null, color: '#0000008A'}}>
      <div className="div-header">
        <img
          className="logo-img"S
          style={{
            padding: props.hasOwnProperty('parent') ? '0px' : '4px',
            marginLeft: props.hasOwnProperty('parent') ? null : '20px',
          }}
          src={appLogo}
          alt="logo"
          onClick={() => {
            if (window.location.hash !== "" && window.location.hash !== "#/" && window.location.hash !== "#/admin/tenants"){
                if(sessionStorage.isRootUser === "true"){
                  nav("/admin/tenants");
                }else {
                  nav("/cli/dashboard");
                }
                window.location.reload();
            }
          }
            // window.location.pathname !== '/' &&
            // window.location.pathname !== '/login'
            //   ? reloadPage('/admin/tenants')
            //   : null
          }
        />
        <div className="top-right-items">
          {(window.location.hash !== "" && window.location.hash !== "#/") && sessionStorage.userLoggedIn  ? (
            <React.Fragment>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                {
                landingPage ? (
                  <Link
                    to="/global-search"
                    style={{textDecoration: 'unset', color: 'black'}}
                    onClick={() => reloadPage('/global-search')}
                  >
                    <IconButton
                      size="small"
                      sx={{ml: 2, color: 'white', marginLeft: '0px'}}
                    >
                      <Search sx={{width: 24, height: 24}} />
                    </IconButton>
                  </Link>
                ) : null}
                {
                  !sessionStorage.engineeringView ?
                    sessionStorage.sidenavAlreadySelectedItem && sessionStorage.tenantName !== "FreeTier" ?
                      sessionStorage.sidenavAlreadySelectedItem === "Locks" ?
                        sessionStorage.lockView === "listView" || !sessionStorage.lockView ?
                          <Tooltip placement='bottom' title="Toggle view" arrow>
                            <ViewModule sx={{color:"#fff",cursor:"pointer"}} onClick={()=>{
                              setLockView("gridView");
                              sessionStorage.lockView = "gridView";
                            }}/>
                          </Tooltip>
                        :
                          <Tooltip placement='bottom' title="Toggle view" arrow>
                            <ViewList  sx={{color:"#fff",cursor:"pointer"}} onClick={()=>{
                              setLockView("listView")
                              sessionStorage.lockView = "listView";                            
                            }}/>
                          </Tooltip>
                      :
                        null  
                  :
                    null
                  :
                    null
                }
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ml: 2, color: 'white'}}
                >
                  <AccountCircle sx={{width: 24, height: 24}} />
                </IconButton>
              </Box>
              <Menu
                className="account_popover"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
              >
                <div className="row_1">
                  <div className="name">{sessionStorage.userDetails ? JSON.parse(sessionStorage.userDetails).user.firstName + " " + JSON.parse(sessionStorage.userDetails).user.lastName : ""}</div>
                  <div className="email">{sessionStorage.userDetails ? JSON.parse(sessionStorage.userDetails).user.email : ""}</div>
                </div>
                <Divider />
                <Link
                  to="/change-password"
                  style={{textDecoration: 'unset', color: 'black'}}
                  onClick={() => reloadPage('/change-password')}
                >
                  <MenuItem>Change Password</MenuItem>
                </Link>
                <Link
                  to="/configure"
                  style={{textDecoration: 'unset', color: 'black'}}
                  onClick={() => reloadPage('/configure')}
                >
                  <MenuItem>Configure Email</MenuItem>
                </Link>                
                <Link
                  to="/engineering-View"
                  style={{textDecoration : 'unset', color : 'black', display : isCariboutechDomain() || isWeskoDomain() ? null : 'none'}}
                  onClick = {() => reloadPage('/engineering-View')}
                >
                  <MenuItem>Engineering View</MenuItem>
                </Link>
                {
                  sessionStorage.isRootUser === "true" && sessionStorage.userDetails && JSON.parse(sessionStorage.userDetails).permissions.includes("IOT_DEVICE_SCOPE_MIGRATE") ?
                    <Link
                      to="/locks"
                      style={{textDecoration: 'unset', color: 'black'}}
                      onClick={() => reloadPage('/locks')}
                    >
                      <MenuItem sx={{mt: 1}}>Update Gateway Firmware</MenuItem>
                    </Link>
                  :
                    null
                }
                <Link
                  to="/"
                  style={{textDecoration: 'unset', color: 'black'}}
                  onClick={() => reloadPage('/logout')}
                >
                  <MenuItem>Logout</MenuItem>
                </Link>
              </Menu>
            </React.Fragment>
          ) : null}
        </div>
      </div>
    </nav>
  );
};

export default Appheader;
