import React from 'react';
import { Button,Table,TableBody,TableCell,TableContainer,TableHead,TablePagination,TableRow,TableSortLabel,Box,Checkbox,Select,MenuItem,Tooltip } from '@mui/material';
import { Edit,Close,Search,Refresh,Settings,FirstPage,LastPage,KeyboardArrowLeft,KeyboardArrowRight,Info,Wifi,SignalWifiOff,Delete,ViewHeadline } from '@mui/icons-material';
import {visuallyHidden} from '@mui/utils';
import '../stylesheet/DashboardGatewayPage.css';
import DraggableComponent from './DraggableComponent';
import GatewayServiceClient from "../clients/GatewayServiceClient";
import IotServiceClient from "../clients/IotServiceClient";
import paginationStyle from "../stylesheet/pagination.module.css";
import "../stylesheet/common.css";
import styles from "../stylesheet/gatewayStatusDialog.module.css";
import _ from 'lodash';
import AppConfigService from '../services/AppConfigService';
import UserDetailService from '../services/UserDetailService';
import PahoMqtt from './Mqtt/PahoMqtt';
import PubSubChannels from '../pubsub/PubSubChannels';
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';
import UserSessionStorage from '../userSession/UserSessionStorage';
import MqttEventHandler from './Mqtt/MqttEventHandler';
import ForceProbeDialog from './ForceProbeDialog';
import { CircularProgress } from '@mui/material';

function TablePaginationActions(props) {
  const {count, page, rowsPerPage, onPageChange} = props;

  function handleFirstPageButtonClick(event) {
    onPageChange(event, 0);
  }

  function handleBackButtonClick(event) {
    onPageChange(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onPageChange(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={paginationStyle.paginationActionIcons}>
      <FirstPage
        onClick={() => {
          if (page > 0) handleFirstPageButtonClick();
        }}
        sx={{
          color: page === 0 ? 'rgba(0,0,0,0.26)' : 'black',
          cursor: page === 0 ? 'auto' : 'pointer',
          marginRight: '8px'
        }}
      />
      <KeyboardArrowLeft
        onClick={() => {
          if (page > 0) handleBackButtonClick();
        }}
        sx={{
          color: page === 0 ? 'rgba(0,0,0,0.26)' : 'black',
          cursor: page === 0 ? 'auto' : 'pointer',
          marginRight: '8px'
        }}
      />
      <KeyboardArrowRight
        onClick={() => {
          if (page !== Math.ceil(count / rowsPerPage) - 1)
            handleNextButtonClick();
        }}
        sx={{
          color:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0
              ? 'black'
              : 'rgba(0,0,0,0.26)',
          cursor:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0 ? 'pointer' : 'auto',
            marginRight: '8px'
        }}
      />
      <LastPage
        onClick={() => {
          if (page !== Math.ceil(count / rowsPerPage) - 1)
            handleLastPageButtonClick();
        }}
        sx={{
          color:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0
              ? 'black'
              : 'rgba(0,0,0,0.26)',
          cursor:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0 ? 'pointer' : 'auto',
        }}
      />
    </div>
  );
}

function EnhancedTableHead(props) {
  const {onSelectAllClick, order, orderBy, onRequestSort} = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const [changeTextColor,setChangeTextColor] = React.useState('');
  const isCaribouTechDomain = () => {
    return JSON.stringify(sessionStorage.userDetails).indexOf("cariboutech.com") !== -1;
  }
  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
      width: '90px'
    },
    {
      id: 'macId',
      numeric: false,
      disablePadding: false,
      label: 'Mac Id',
      width: '90px'
    },
    {
      id: 'noOfReachableDevices',
      numeric: false,
      disablePadding: false,
      label: 'Scanned/#Whitelisted',
      width: '180px'
    },
    {
      id: "gatewayCurrentStatus",
      numeric: false,
      disablePadding: false,
      label: "Current State",
      width: "170px"
    },
    {
      id: 'appVersion',
      numeric: true,
      disablePadding: false,
      label: 'App Version',
      width: '125px'
    },
    {
      id: 'info',
      numeric: true,
      disablePadding: false,
      label: 'Info',
      width: '20px'
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Status',
      width: '80px'
    },
    {
      id: 'pendingInstructions',
      numeric: false,
      disablePadding: false,
      label: 'Pending Instructions',
      width: '160px'
    },
  ];


  const selectAll = (event) => {    
    onSelectAllClick(event.target.checked);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{
            display: props.editMode ? 'table-cell' : 'none',
            padding: '16px',
          }}
        >
          <Checkbox
            color="error"
            checked={props.check_all}
            onClick={(event) => selectAll(event)}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
            id="tableHeadCheckBox"
            sx={{margin: '0px', padding: '0px'}}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="left">
            {headCell.label !== 'Pending Instructions' ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                sx={{
                  width: headCell.width,
                  overflowWrap: 'break-word',
                  color: orderBy !== headCell.id ? '#0000008A !important' : '#000000DE !important',
                }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>              
            ) : (
              <Box
                sx={{
                  width: headCell.width,
                  overflowWrap: 'break-word',
                  color: changeTextColor !== 'Pending Instructions' ? '#0000008A !important' : '#000000DE !important',
                }}
              >
                {headCell.label}
              </Box>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function DashBoardGatewayPage() {
  const [SearchBar, setSearchBar] = React.useState(false);

  const [rowsPerPage, setRowsPerPage] = React.useState(sessionStorage.dashboardGatewayPageRPP ? Number(sessionStorage.dashboardGatewayPageRPP) : 10);

  const [order, setOrder] = React.useState('asc');

  const [orderBy, setOrderBy] = React.useState('calories');

  const [selected, setSelected] = React.useState([]);

  const [dense, setDense] = React.useState(false);

  const [editMode, setEdiitMode] = React.useState(false);

  const [showEditIcon, setShowEditIcon] = React.useState(true);

  const [searchText, setSearchText] = React.useState('');

  const [checkAll, setCheckAll] = React.useState(false);

  const [page, setPage] = React.useState(0);

  const [rows, setRows] = React.useState([]);

  const [subscribeChannels, setSubscribeChannels] = React.useState([]);

  const [publishChannels, setPublishChannels] = React.useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const gatewayListData = [];

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const [pageNumber, setPageNumber] = React.useState(0);

  const [onBoardDialog, setOnBoardDialog] = React.useState(false);

  const [gatewayLogDialog, setGatewayLogDialog] = React.useState(false);

  const [gatewayLogData, setGatewayLogData] = React.useState([]);
  
  const [infoDialog, setInfoDialog] = React.useState(false);

  const [infoData, setInfoData] = React.useState([]);

  const [showDeleteGatewayDialog, setShowDeleteGatewayDialog] = React.useState(false);

  const [deleteGatewayData , setDeleteGatewayData] = React.useState([]);

  let allGatewayStateMqttCHannels = [];
  
  let gatewayCurrentStatusFetchedData = {};

  const modifyCurrentStateText = (text) => {
    text = text.replace("State","");
    switch (text) {
      case "Idle":
        text = "Idle";
        break;
      case "Scanning":
        text = "Scanning";
        break;
      case "AddingUser":
        text = "User addition";
        break;
      case "RemovingUser":
        text = "User deletion";
        break;
      case "RemovingUserAcrossLocks":
        text = "User deletion across locks";
        break;
      case "LockProbing":
        text = "Probing";
        break;
      case "FetchLockHistory":
        text = "Fetching history";
        break;
      case "UserModeToggle":
        text = "User mode toggle";
        break;
      case "LockToggling":
        text = "Lock toggle";
        break;
      case "UpdateFirmware":
        text = "Firmware update"
        break;      
    }
    return text;
  }

  const gatewayCurrentStateEventHandler = (message,topic) => {
    message = JSON.parse(message);
    let latestData = [];
    _.forEach(window.allGateways,(value)=> {
      if (topic.indexOf(value.macId) !== -1 && value.status === "CONNECTED"){
        let currentStateMessage = message.currentState !== "" && typeof message.currentState !== 'undefined' ? message.currentState.replace("State", "") : "-";
        value.gatewayCurrentStatus = currentStateMessage !== "-" ? modifyCurrentStateText(currentStateMessage) : currentStateMessage;
        gatewayCurrentStatusFetchedData[value.macId] = value.gatewayCurrentStatus;
        if (typeof window.gatewayCurrentStatusFetchedData === 'undefined') {
          window.gatewayCurrentStatusFetchedData = {};
        } 
        window.gatewayCurrentStatusFetchedData[value.macId] = value.gatewayCurrentStatus;
      }
      latestData.push(value);
    })
    window.allGateways = latestData;
    setRows(latestData);
    searchFilter("");
  }

  const subscribeToGetCurrentStatusFromGateway = (macId) => {
    let gatewayStateChannel = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.GATEWAY_STATUS.replace("scopeId",sessionStorage.tenantId).replace("gatewayId",macId), "currentState", gatewayCurrentStateEventHandler);
    allGatewayStateMqttCHannels.push(gatewayStateChannel);
    allGatewayStateMqttCHannels = _.uniq(allGatewayStateMqttCHannels);
    window.allGatewayStateMqttCHannels = allGatewayStateMqttCHannels;
  }

  const getCurrentStateFromPreviousData = (macId) => {
    let data = _.find(window.allGateways,{"macId": macId});
    return data ? data.gatewayCurrentStatus ? data.gatewayCurrentStatus : "Idle" : "Idle"; 
  }

  const fetchGateways = (manualRefresh) => {
    // delete sessionStorage.navigatedValueOfGtFromGlobalSearch;
    let gatewayData = [];
    setSelected([])
    setCheckAll(false);
    let isRefreshingWithFilter = document.getElementById("searchText") ? document.getElementById("searchText").value.length > 0 : false ;
    GatewayServiceClient.getGateways(sessionStorage.tenantId, "gatewaysPage").then((data) => {
      if(sessionStorage.navigatedValueOfGtFromGlobalSearch === "gsG") {
          _.forEach(data,function(value){
            value.name = value.name ? value.name : "-";
            value.noOfReachableDevices =value.noOfReachableDevices ? value.noOfReachableDevices : 0;
            value.noOfWhiteListedDevices = value.noOfWhiteListedDevices ? (value.noOfWhiteListedDevices === -1 ? "*" : value.noOfWhiteListedDevices) : 0;
            value.scannedAndWhiteListData = value.noOfReachableDevices + "/" + value.noOfWhiteListedDevices;
            if (gatewayCurrentStatusFetchedData.hasOwnProperty(value.macId) || window.gatewayCurrentStatusFetchedData ? window.gatewayCurrentStatusFetchedData.hasOwnProperty(value.macId) : false || value.status !== "CONNECTED"){
              if (value.status !== "CONNECTED"){
                value.gatewayCurrentStatus = "-";
              }else {
                value.gatewayCurrentStatus = window.gatewayCurrentStatusFetchedData[value.macId];
              }
            }else {
              value.gatewayCurrentStatus = "-";
            }
            if(!manualRefresh){
              subscribeToGetCurrentStatusFromGateway(value.macId);
            }
            if(sessionStorage.gatewayMacId === value.macId) gatewayData.push(value);
          });
      } else {
        _.forEach(data,function(value){
          value.name = value.name ? value.name : "-";
          value.noOfReachableDevices =value.noOfReachableDevices ? value.noOfReachableDevices : 0;
          value.noOfWhiteListedDevices = value.noOfWhiteListedDevices ? (value.noOfWhiteListedDevices === -1 ? "*" : value.noOfWhiteListedDevices) : 0;
          value.scannedAndWhiteListData = value.noOfReachableDevices + "/" + value.noOfWhiteListedDevices;          
          if (gatewayCurrentStatusFetchedData.hasOwnProperty(value.macId) || window.gatewayCurrentStatusFetchedData ? window.gatewayCurrentStatusFetchedData.hasOwnProperty(value.macId) : false || value.status !== "CONNECTED"){
            if (value.status !== "CONNECTED"){
              value.gatewayCurrentStatus = "-";
            }else {
              value.gatewayCurrentStatus = window.gatewayCurrentStatusFetchedData[value.macId];
            }
          }else {
            value.gatewayCurrentStatus = "-";
          }
          if(!manualRefresh){
            subscribeToGetCurrentStatusFromGateway(value.macId);
          }
          gatewayData.push(value);
        });
      }
      window.allGateways = gatewayData;
      let dialogExist = document.getElementById("draggable-dialog-title");
      if (dialogExist) {
        _.forEach(data,(val)=>{
          if (typeof val === 'undefined'){
            if(val.macId === window.setGatewayLogData.macId){
              setGatewayLogData(val);
            }
          }
        })
      }
      // window.gatewayListData = Object.assign([],data);
      if(!isRefreshingWithFilter){
          setRows(gatewayData);
          resetPagination([]);
        } else {
            setTimeout(function(){
                      handleTextCange(searchText);
                  }, 2000);
        }
    });
  };

  const handlePendingINstructionIconChange = (data) => {
    data = data ? data.toString().indexOf("message") !== -1 ? JSON.parse(data.toString()) : "" : "" ;
    if (data.hasOwnProperty("message")){
      handleEvent(data);
    }
  } 

  const subscribeMqttChannels = () => {
    window.gatewaysStatusMqttChannels = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.GATEWAYS.replace("scopeId",sessionStorage.tenantId),"gatewayController",handleGatewayEventHandler);
    window.gatewayPendingInstructionIconChannel = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.PENDING_INSTRUCTION_ICONS_CHANNEL.replace("scopeId", sessionStorage.tenantId), "gatewayContrller", handlePendingINstructionIconChange )
    window.deviceGatewayMappingRemovedChannel = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.DEVICE_GATEWAY_MAPPINGS_REMOVED.replace("scopeId",sessionStorage.tenantId),"gatewayController", handleDeviceGatewayMappingsRemovedEventHandler)
  }

  React.useEffect(() => {
      enqueueSnackbar("Fetching Gateways ...")
      window.oldPreservedGatewayPageNumber = 0;
      fetchGateways(false);      
      subscribeMqttChannels();
      return () => {
        if (window.gatewayCurrentStatusFetchedData) {
          window.gatewayCurrentStatusFetchedData = {};
        }
        if (window.scanCountMqttEvent){
          window.scanCountMqttEvent.unsubscribe();
        }
        if (window.gatewaysStatusMqttChannels) {
          window.gatewaysStatusMqttChannels.unsubscribe();
          delete window.gatewaysStatusMqttChannels;
        }
        if (window.deviceGatewayMappingRemovedChannel) {
          window.deviceGatewayMappingRemovedChannel.unsubscribe();
        }
        if (window.gatewayPendingInstructionIconChannel){
          window.gatewayPendingInstructionIconChannel.unsubscribe();
        }
        _.forEach(allGatewayStateMqttCHannels,(val)=>{
          val.unsubscribe();
        });
        delete sessionStorage.navigatedValueOfGtFromGlobalSearch;
        GatewayServiceClient.abortSignal("gatewaysPage")
      }
  }, []);

   function numberCharacterOrganizer(array, orderOfsort, orderByOfSort){
      let numbers = [], characters = [];
      _.forEach(array, (element) => {
          if(typeof(element[orderByOfSort]) === 'string' ? Number(element[orderByOfSort] ? element[orderByOfSort].replaceAll(" ","") : element[orderByOfSort]) : Number(element[orderByOfSort])){
            numbers.push(element);
          }
          else{
            characters.push(element);
          }
      })
      array = orderOfsort === 'asc' ? characters.concat(numbers): numbers.concat(characters);
      return array;
    }

  function stableSort(array, orderOfsort, orderByOfSort) {
    array = numberCharacterOrganizer(array, orderOfsort, orderByOfSort);
    array = _.orderBy(array, [obj => obj[orderByOfSort] !== null ? typeof(obj[orderByOfSort]) === 'string' ? !Number(_.get(obj, orderByOfSort, '').replaceAll(" ","")) ? _.get(obj, orderByOfSort, '').toString().toLowerCase() : Number(_.get(obj, orderByOfSort).replaceAll(" ","")) : Number(_.get(obj, orderByOfSort,'')) : ""], [orderOfsort]);
    return array;
  }

  function descendingComparator(a, b, orderBy) {
    if (typeof b[orderBy] !== 'undefined' && typeof a[orderBy] !== 'undefined' && b[orderBy] !== null && a[orderBy] !== null){
      if (b[orderBy].toString().toLowerCase() < a[orderBy].toString().toLowerCase()) {
        return -1;
      }
      if (b[orderBy].toString().toLowerCase() > a[orderBy].toString().toLowerCase()) {
        return 1;
      }
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPageNumber(newPage);
    window.oldPreservedGatewayPageNumber = newPage;
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (event, row) => {
    let newSelected = [];

    if (event.target.checked) {
      if (selected.length > 0) {
        selected.forEach((element) => {
          newSelected.push(element);
        });
        if (JSON.stringify(newSelected).indexOf(row.id) === -1) {
          newSelected.push(row);
        }
      } else {
        newSelected.push(row);
      }
    } else {
      selected.forEach((element) => {
        if (element.id !== row.id) {
          newSelected.push(element);
        }
      });
    }

    setSelected(newSelected);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleSelectAllClick = (checked) => {
    // eslint-disable-next-line array-callback-return
    if (checked){
      const newSelecteds = rows.filter((n) => {
        if (
          n.status !== 'INACTIVE' && n.registerMe !== 1 &&
          typeof n.id !== 'undefined' &&
          n.id !== null
        ) {
          return n;
        }
      });
      setSelected(newSelecteds);
    }else {
      setSelected([]);
    }
    setCheckAll(checked);    
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const handleChange = (event) => {
    setPageNumber(event.target.value);
    setPage(event.target.value);
    window.oldPreservedGatewayPageNumber = event.target.value;
  };

  const handleRowPerPage = (event) => {
    let rowPerPageValue = event.target.value;    
    if (rows.length > 0) {
      let pageNumberCount = Math.ceil((rows.length/rowPerPageValue)) - 1;                
      if (window.oldPreservedGatewayPageNumber > pageNumberCount) {
        setPage(pageNumberCount);
        setPageNumber(pageNumberCount);        
        window.oldPreservedGatewayPageNumber = pageNumberCount;
      }else {
        setPage(window.oldPreservedGatewayPageNumber);
        setPageNumber(window.oldPreservedGatewayPageNumber);
      }
    }
    sessionStorage.dashboardGatewayPageRPP = rowPerPageValue;
    setRowsPerPage(rowPerPageValue);
  };

  const searchFilter = (text) => {
    let filteredRows = [];
    text = document.getElementById("searchText") ? document.getElementById("searchText").value.toLowerCase().replaceAll(" ","").replace(/:/g,"") : "";
    if (text === ""){
      setRows(window.allGateways);
    }else {
      _.forEach(window.allGateways,(value) => {
        if ((value.name ? value.name.toLowerCase().replaceAll(" ","").indexOf(text) !== -1 : false) || (value.macId ? value.macId.toLowerCase().replace(/:/g,"").replaceAll(" ","").indexOf(text) !== -1 : false) || (value.status ? value.status.toLowerCase().replaceAll(" ","").indexOf(text) !== -1 : false) || (value.appVersion ? String(value.appVersion).replaceAll(" ","").indexOf(text) !== -1 : false) ){
          filteredRows.push(value);
        }
      });
      filteredRows = _.uniq(filteredRows);
      setRows(filteredRows);
    }
    resetPagination(filteredRows)
  };

  const resetPagination = (data) =>{
    if (window.oldPreservedGatewayPageNumber) {
      if (data.length > 0) {
        let pageNumberCount = Math.ceil((data.length/rowsPerPage)) - 1;                
        if (window.oldPreservedGatewayPageNumber > pageNumberCount) {
          setPage(pageNumberCount);
          setPageNumber(pageNumberCount);
          window.oldPreservedGatewayPageNumber = pageNumberCount;
        }
      }else {
        let pageNumberCount = Math.ceil((window.allGateways.length/rowsPerPage)) - 1;
        if (window.oldPreservedGatewayPageNumber > pageNumberCount) {
          setPage(pageNumberCount);
          setPageNumber(pageNumberCount);
          window.oldPreservedGatewayPageNumber = pageNumberCount;
        }else if (window.oldPreservedGatewayPageNumber <= pageNumberCount && pageNumberCount > 0){
          setPage(window.oldPreservedGatewayPageNumber);
          setPageNumber(window.oldPreservedGatewayPageNumber);        
        }else {
          setPage(0);
          setPageNumber(0);
          window.oldPreservedGatewayPageNumber = 0;
        }        
      }
    }else {
      setPageNumber(0);
      setPage(0);   
      window.oldPreservedGatewayPageNumber = 0;
    }
  }

  const handleTextCange = (textValue) => {
    textValue = document.getElementById("searchText") ? document.getElementById("searchText").value : "";
    setSearchText(textValue);
    if (textValue !== "" || textValue !== null || textValue !== undefined){
      searchFilter(textValue);
    }else {
      setRows(window.allGateways);
    }
  };

  const rowPerPageLabel = (
    <div className={paginationStyle.rowPerPageLabelDiv}>
      <Box className={paginationStyle.page}>
        <span style={{marginRight: '32px'}}>Page</span>
      </Box>
      <Box className={paginationStyle.pageSelectBox}>
        <Select value={pageNumber} onChange={handleChange}>
          {
            Math.ceil(rows.length / rowsPerPage) > 0 ?
              // eslint-disable-next-line array-callback-return
              Array(Math.ceil(rows.length / rowsPerPage))
                .fill()
                .map((_, index) => {
                  return (
                    <MenuItem value={index} sx={{minWidth: '64px'}}>
                      {index + 1}
                    </MenuItem>
                  );
                })
            :
              <MenuItem value={0} sx={{minWidth:"64px"}}>
                1
              </MenuItem>
          }
        </Select>
      </Box>
      <Box className={paginationStyle.rowPerPageLabelDiv}>
        <span style={{marginRight: '32px'}}>Rows Per Page</span>
      </Box>
      <Box className={paginationStyle.rowPerPageSelectBox}>
        <Select value={rowsPerPage} onChange={handleRowPerPage}>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={15}>15</MenuItem>
        </Select>
      </Box>
    </div>
  );

  // on board dialog

  const handleCloseOnBoardingDialog = (value) => {
    setSelected([]);
    setCheckAll(false);
    setOnBoardDialog(value);
  };

  // Gateway Log dialog
  const reSubscribeAllGatewayChannels = () => {
    allGatewayStateMqttCHannels =   [];
    setTimeout(() => {
      _.forEach(window.allGateways,(value) => {
        subscribeToGetCurrentStatusFromGateway(value.macId);
      })
    }, 1000);    
  }
  const gatewayLog = (data) => {
    _.forEach(window.allGatewayStateMqttCHannels,(value) => {
      value.unsubscribe();
    })
    setTimeout(() => {
      window.setGatewayLogData = data;
      setGatewayLogData(data);
      setGatewayLogDialog(true);
    }, 800);    
  };

  // info Dialog

  const information = (data) => {
    setInfoData(data);
    setInfoDialog(true);
  };

  // pending instruction dialog

  const [pendingDialog, setPendingDialog] = React.useState(false);

  const [pendingInstructionData, setPendingInstructionData] = React.useState([]);

  const [pendingInstructionExist,setPendingInstructionExist] = React.useState(false);

  const pendingInstruction = (data) => {
    setPendingInstructionData(data);    
    setPendingDialog(true);    
  };

  //White list dialog

  const [whiteListDialog, setWhiteListDialog] = React.useState(false);

  const [forceProbeDialog, setForceProbeDialog] = React.useState(false);

  const handleWhiteListDialog = (value) => {
    setGatewayLogDialog(false);
    reSubscribeAllGatewayChannels();
    window.setTimeout(function () {
      setWhiteListDialog(true);
    }, 1000);
  };

  const handleForceProbeDialog = (value) => {
    setGatewayLogDialog(false);
    reSubscribeAllGatewayChannels();
    window.setTimeout(function () {
      setForceProbeDialog(true);
    }, 1000);
  };

  //Restart Gateway Dialog

  const [restartGatewayDialog, setRestartGatewayDialog] = React.useState(false);

  //Clear BlackList Dialog

  const [clearBlackListDialog, setClearBlackListDialog] = React.useState(false);

  const handleRestartDialog = (value) => {
    setGatewayLogDialog(false);
    reSubscribeAllGatewayChannels();
    window.setTimeout(function () {
      setRestartGatewayDialog(true);
    }, 700);
  };

  const handleClearBlackListDialog = (value) => {
    setGatewayLogDialog(false);
    reSubscribeAllGatewayChannels();
    window.setTimeout(function () {
      setClearBlackListDialog(true);
    }, 700);
  };

  const handleRefresh = ()=> {
    enqueueSnackbar("Refreshing Gateways ...");
    fetchGateways(true);
  }

  const handleEvent = (data) => {
    let latestGatewayData = [];
    let message = data.message ? data.message : "";
    let payload = data.payload ? data.payload : "";
    let updateUi = false;
    if (message === "update" || message === "updateStatus" || message === "updateRegister" || message === "scannedDataUpdated") {
      _.forEach(window.allGateways, (val)=> {
        if (val.macId === payload.macId && val.scopeId === payload.scopeId) {
          val.name = payload.name ? payload.name : "-";
          val.noOfReachableDevices = payload.noOfReachableDevices ? payload.noOfReachableDevices : 0;
          val.noOfWhiteListedDevices = payload.noOfWhiteListedDevices ? (payload.noOfWhiteListedDevices === -1 ? "*" : payload.noOfWhiteListedDevices) : 0;
          val.scannedAndWhiteListData = val.noOfReachableDevices + "/" + val.noOfWhiteListedDevices;
          val.appVersion = payload.appVersion;
          val.registerMe = payload.registerMe;
          val.status = payload.status;
          val.listOfWhiteListedLocks = payload.listOfWhiteListedLocks;
          val.threshold = payload.threshold;
          val.connectionInfo = payload.connectionInfo;
          if (gatewayCurrentStatusFetchedData.hasOwnProperty(val.macId) || window.gatewayCurrentStatusFetchedData ? window.gatewayCurrentStatusFetchedData.hasOwnProperty(val.macId) : false || val.status !== "CONNECTED"){
            if (payload.status !== "CONNECTED"){
              val.gatewayCurrentStatus = "-";
            }else {
              val.gatewayCurrentStatus = window.gatewayCurrentStatusFetchedData[val.macId];
            }
          }else {
            val.gatewayCurrentStatus = "-";
          }
        }
        latestGatewayData.push(val)
      });
      updateUi = true;
    }else if (message === "create") {
      _.forEach(window.allGateways, (val)=> {
        latestGatewayData.push(val)
      });
      payload.name = payload.name ? payload.name : "-";
      payload.noOfReachableDevices = payload.noOfReachableDevices ? payload.noOfReachableDevices : 0;
      payload.noOfWhiteListedDevices = payload.noOfWhiteListedDevices ? (payload.noOfWhiteListedDevices === -1 ? "*" : payload.noOfWhiteListedDevices) : 0;
      payload.scannedAndWhiteListData = payload.noOfReachableDevices + "/" + payload.noOfWhiteListedDevices;
      payload.appVersion = payload.appVersion;
      payload.registerMe = payload.registerMe;
      payload.status = payload.status;      
      if (gatewayCurrentStatusFetchedData.hasOwnProperty(payload.macId) || window.gatewayCurrentStatusFetchedData ? window.gatewayCurrentStatusFetchedData.hasOwnProperty(payload.macId) : false || payload.status !== "CONNECTED"){
        if (payload.status !== "CONNECTED"){
          payload.gatewayCurrentStatus = "-";
        }else {
          payload.gatewayCurrentStatus = window.gatewayCurrentStatusFetchedData[payload.macId];
        }
      }else {
        payload.gatewayCurrentStatus = "-";
      }
      latestGatewayData.push(payload);
      subscribeToGetCurrentStatusFromGateway(payload.macId);
      updateUi = true;
    }else if (message === "deviceMoved") {
      _.forEach(window.allGateways, (val)=> {
        if (val.id !== payload.id) {
          latestGatewayData.push(val);
        }
      });
      updateUi = true;
    }else if (message === "scopeChange") {
      if (payload.scopeId === sessionStorage.tenantId){
        payload.name = payload.name ? payload.name : "-";
        payload.noOfReachableDevices = payload.noOfReachableDevices ? payload.noOfReachableDevices : 0;
        payload.noOfWhiteListedDevices = payload.noOfWhiteListedDevices ? (payload.noOfWhiteListedDevices === -1 ? "*" : payload.noOfWhiteListedDevices) : 0;
        payload.scannedAndWhiteListData = payload.noOfReachableDevices + "/" + payload.noOfWhiteListedDevices;
        payload.appVersion = payload.appVersion;
        payload.registerMe = payload.registerMe;
        payload.status = payload.status;      
        if (gatewayCurrentStatusFetchedData.hasOwnProperty(payload.macId) || window.gatewayCurrentStatusFetchedData ? window.gatewayCurrentStatusFetchedData.hasOwnProperty(payload.macId) : false || payload.status !== "CONNECTED"){
          if (payload.status !== "CONNECTED"){
            payload.gatewayCurrentStatus = "-";
          }else {
            payload.gatewayCurrentStatus = window.gatewayCurrentStatusFetchedData[payload.macId];
          }
        }else {
          payload.gatewayCurrentStatus = "-";
        }
        latestGatewayData.push(payload);
        subscribeToGetCurrentStatusFromGateway(payload.macId);
        updateUi = true;
      }
    }else if (message === "showIcon") {
      if (data.macId !== null) {
        let showToastMessage = false;
        _.forEach(window.allGateways, (val)=> {
          if (val.macId === data.macId) {
            if (val.pendingInstructionExist !== true){
              showToastMessage = true
            }
            val.pendingInstructionExist = true;
          }
          latestGatewayData.push(val)
        });
        updateUi = showToastMessage;
      }else {
        enqueueSnackbar("Refreshing Gateways ...");
        fetchGateways(true);
      }
    }else if (message === "hideIcon") {
      if (data.macId !== null){
        let showToastMessage = false;
        _.forEach(window.allGateways, (val)=> {
          if (val.macId === data.macId) {
            if (val.pendingInstructionExist !== false){
              showToastMessage = true
            }
            val.pendingInstructionExist = false;
          }
          latestGatewayData.push(val)
        });
        updateUi = showToastMessage;
      }else {
        enqueueSnackbar("Refreshing Gateways ...");
        fetchGateways(true);
      }
    }

    if (updateUi) {
      enqueueSnackbar("Refreshing Gateways ...");
      window.allGateways = latestGatewayData;
      let text = document.getElementById("searchText") ? document.getElementById("searchText").value : "";
      searchFilter(text);
    }
  }

  const handleGatewayEventHandler = (data) => {
    data = data ? data.toString().indexOf("message") !== -1 ? JSON.parse(data.toString()) : "" : "" ;
    if (data.hasOwnProperty("message")){
      handleEvent(data);
    }
  }

  const handleDeviceGatewayMappingsRemovedEventHandler = (message) => {
    message = message ? message.toString() : message;
    if (message === "success") {
      enqueueSnackbar("Device mapping removed successfully");
    }else if (message === "fail") {
      enqueueSnackbar("Failed to remove device mapping");
    }
  }

  const handleAbortInstruction = (allInstructions) => {
    let data = [];
    _.forEach(allInstructions,(value)=> {
      if (value.isChecked){
        delete value.isChecked;
        data.push(value);
      }
    })
    GatewayServiceClient.abortInstruction(data).then((response)=>{
      if (response.status === 200){
        enqueueSnackbar("Successfully aborted");
      }else {
        enqueueSnackbar("Failed to abort instructions")
      }
    })
  }

  const handleUpdateGateway = (data) => {
    GatewayServiceClient.updateThresholdData(data).then((response) => {
      if (response.status === 200){
        enqueueSnackbar("Successfully updated gateway");
      }else {
        enqueueSnackbar("Failed to update gateway");
      }
    }).catch((err)=>{
      enqueueSnackbar("Failed to update gateway");
    })

  };

  const handleDeleteGateway = (data) => {
    setDeleteGatewayData(data)
    setShowDeleteGatewayDialog(true);
  }

  const removeFilter = () => {
    delete sessionStorage.navigatedValueOfGtFromGlobalSearch;
    delete sessionStorage.navigatedGatewayName;
    fetchGateways(true);
  }

  const handleFetchGateways = () => {
    setShowDeleteGatewayDialog(false);
    fetchGateways(true);
  }

  const isCaribouTechDomain = () => {
    return JSON.stringify(sessionStorage.userDetails).indexOf("cariboutech.com") !== -1;
  }

  const isWeskelocksDomain = () => {
    return JSON.stringify(sessionStorage.userDetails).indexOf("wesko-elocks.com") !== -1;
  }

  return (
    <>
      {sessionStorage.navigatedValueOfGtFromGlobalSearch === "gsG" ?
          <Box sx={{display:"flex",flexDirection:"row",padding: '10px',background:"#ffcc80"}}>
            <Box>
              Filtered by :
            </Box>
            <Box sx={{marginRight: "auto", marginLeft: "6px",height:"22px",background:"#ede8e8",width:"auto"}}>
              <Box sx={{display:"flex",flexDirection:"row",marginLeft:"9px"}}>
                <Tooltip placement='top' title={sessionStorage.navigatedFromDashboard ? sessionStorage.navigatedFromDashboard : sessionStorage.navigatedGatewayName} arrow>
                  <Box className='filterNameForSmallScreens'>
                    {sessionStorage.navigatedFromDashboard ? sessionStorage.navigatedFromDashboard : sessionStorage.navigatedGatewayName}
                  </Box>
                </Tooltip>
                <Box sx={{padding:"0px 3px 0px 3px"}}>
                  <Close sx={{cursor:"pointer",fontSize:"21px",paddingLeft:"4px",marginTop:"1px"}} onClick={()=> removeFilter()} />
                </Box>
              </Box>
            </Box>
          </Box>
          :
          null
      }
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: '20px 0px 20px 24px',
        }}
      >
        <span style={{fontSize: '20px'}}>Gateways</span>
        <span
          style={{
            marginRight: '12px',
            marginLeft: 'auto',
            visibility: selected.length > 0 ? 'visible' : 'hidden',
            cursor:"pointer"
          }}
        >
          <Tooltip title="On Board" placement="top" arrow>
            <Settings onClick={() => setOnBoardDialog(true)}/>
          </Tooltip>
          {onBoardDialog ? (
            <DraggableComponent
              show_on_board_dialog={onBoardDialog}
              close_on_board_dialog={(value) =>
                handleCloseOnBoardingDialog(value)
              }
              dialog="onBoardDialog"
              rows={rows}
              rowsSelected={selected}
            />
          ) : null}
        </span>
        <input
          id="searchText"
          type="text"
          onChange={(event)=>handleTextCange(event.target.value)}
          placeholder="Search Gateways..."
          style={{
            marginRight: '10px',
            minWidth: '37px',
            width: '140px',
            maxWidth: '100%',
            display: SearchBar ? 'flex' : 'none',
          }}
        />
        <Close
          style={{
            marginRight: '10px',
            cursor: 'pointer',
            display: SearchBar ? 'flex' : 'none',
          }}
          onClick={() => {
            setSearchText('');
            if (document.getElementById("searchText")){
              document.getElementById("searchText").value = ""
            }
            setSearchBar(false);
            searchFilter("");
          }}
        />
        <Tooltip title="Search" placement="top" arrow>
          <Search
            style={{
              marginRight: '10px',
              cursor: 'pointer',
              display: !SearchBar ? 'flex' : 'none',              
            }}
           onClick={() => {setSearchBar(true);
            setTimeout(() => {document.getElementById("searchText").focus();},100);
           }}
          />
        </Tooltip>
        <Tooltip title="Refresh" placement="top" arrow>
          <Refresh style={{marginRight: '14px',cursor:'pointer'}}  onClick={()=> handleRefresh()}/>
        </Tooltip>
      </Box>
      <Box style={{width: '100%', height: 'auto', marginBottom: '86px'}}>
        <TableContainer>
          <Table>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              check_all={checkAll}
              onSelectAllClick={(checked) => handleSelectAllClick(checked)}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              editMode={editMode}
            />
            <TableBody sx={{background: '#fff'}} className={paginationStyle.tableBodyRow}>
              {stableSort(rows,order, orderBy)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                // eslint-disable-next-line array-callback-return
                .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        className={
                          row.registerMe !== 0 ? 'showAnimation ' : null
                        }
                        sx={{borderBottom:'1px solid #c1a6a6'}}
                      >
                        <TableCell
                          padding="checkbox"
                          sx={{
                            display: editMode ? 'table-cell' : 'none',
                            padding: '16px',
                          }}
                        >
                          <Checkbox
                            color="error"
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                            checked={
                              JSON.stringify(selected).indexOf(row.id) !== -1
                            }
                            disabled={row.status === 'CONNECTED' && row.registerMe === 0 ? false : true}
                            onClick={(event) =>
                              row.status === 'CONNECTED' && row.registerMe === 0
                                ? handleClick(event, row)
                                : null
                            }
                            sx={{padding: '0px', margin: '0px'}}
                          />
                        </TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell align="left">{row.macId}</TableCell>
                        <TableCell align="left">{row.scannedAndWhiteListData}</TableCell>
                        <TableCell align="left">
                          {row.gatewayCurrentStatus}
                          {
                            row.gatewayCurrentStatus && row.gatewayCurrentStatus !== "-" && row.gatewayCurrentStatus !== "" && typeof row.gatewayCurrentStatus !== 'undefined' ?
                              row.gatewayCurrentStatus !== "Idle" ?
                                <CircularProgress thickness={3} sx={{height:'20px !important',width: '20px !important',color: 'rgb(20,19,139) !important', position:'relative',top: "5px",left: "17px"}}/>
                              :
                                null
                            :
                              null
                          }
                        </TableCell>
                        <TableCell align="left">{row.appVersion}</TableCell>
                        <TableCell align="left">
                          <Tooltip title="Information" placement="top" arrow>
                            <Info
                              sx={{
                                color: row.registerMe !== 0 ? 'red' : '#0000008A',
                                cursor: 'pointer',
                              }}
                              onClick={() => information(row)}
                            />
                          </Tooltip>
                        </TableCell>
                        <TableCell align="left">
                          {row.status === 'CONNECTED' ? (
                            <Box>
                                <Tooltip title="CONNECTED" placement="top" arrow>
                                  <Wifi
                                    style={{color: '#2196f3', cursor: isCaribouTechDomain() || isWeskelocksDomain() ? 'pointer' : 'auto'}}
                                    onClick={() => {
                                      if (isCaribouTechDomain() || isWeskelocksDomain() )
                                        gatewayLog(row)
                                    }}
                                  />
                                </Tooltip>
                                {
                                    row.hasOwnProperty("connectionInfo") ?
                                        row.connectionInfo.isMqttAlive  ?
                                          <Tooltip title="MQTT CONNECTED" placement="top" arrow>
                                            <div className={`${styles.gatewayMqttStatusIcon} ${styles.connectedGatewayMqttStatusIcon}`}>
                                                &nbsp;
                                            </div>
                                          </Tooltip>
                                        :
                                            <Tooltip title="MQTT DISCONNECTED" placement="top" arrow>
                                                <div className={`${styles.gatewayMqttStatusIcon} ${styles.disconnectedGatewayMqttStatusIcon}`}>
                                                    &nbsp;
                                                </div>
                                            </Tooltip>
                                    :
                                        null
                                }
                            </Box>
                          ) : (
                            <Box>
                              <Tooltip title="INACTIVE" placement="top" arrow>
                                <SignalWifiOff
                                  sx={{cursor: isCaribouTechDomain() || isWeskelocksDomain() ? 'pointer' : 'auto',color: '#0000008A',marginRight:'10px'}}
                                  onClick={() => {
                                    if (isCaribouTechDomain() || isWeskelocksDomain())
                                      gatewayLog(row)
                                  }}
                                />
                              </Tooltip>
                              {
                                  row.hasOwnProperty("connectionInfo") ?
                                        row.connectionInfo.isMqttAlive ?
                                          <Tooltip title="MQTT CONNECTED" placement="top" arrow>
                                            <div className={`${styles.gatewayMqttStatusIcon} ${styles.connectedGatewayMqttStatusIconInactive}`}>
                                                &nbsp;
                                            </div>
                                          </Tooltip>
                                        :
                                              <Tooltip title="MQTT DISCONNECTED" placement="top" arrow>
                                                  <div className={`${styles.gatewayMqttStatusIcon} ${styles.disconnectedGatewayMqttStatusIconInactive}`}>
                                                      &nbsp;
                                                  </div>
                                              </Tooltip>
                                  :
                                      null
                              }
                              <Delete style={{cursor: 'pointer',color: '#0000008A'}} onClick={()=> handleDeleteGateway(row)} />
                            </Box>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          <Tooltip
                            title="Pending Instructions"
                            placement="top"
                            arrow
                          >
                            <ViewHeadline
                              style={{cursor: 'pointer',color: '#000008A'}}
                              onClick={() => pendingInstruction(row)}
                            />
                          </Tooltip>
                          {
                            row.pendingInstructionExist === true ?
                                <div className={`${styles.gatewayMqttStatusIcon} ${styles.disconnectedGatewayMqttStatusIcon}`}>
                                    &nbsp;
                                </div>
                            :
                                null
                          }
                        </TableCell>
                        {/*TODO://Need to include management script*/}
                      </TableRow>
                    );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{width: '100%', overflowX: 'hidden'}}
          labelRowsPerPage={rowPerPageLabel}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          className="pagination"
          rowsPerPage={rowsPerPage}
          editMode={editMode}
          page={pageNumber}
          SelectProps={{
            inputProps: {'aria-label': 'rows per page'},
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
        {whiteListDialog ? (
          <DraggableComponent
            data = {gatewayLogData}
            updateGateway = {(gateway) => handleUpdateGateway(gateway)}
            showDIalog={whiteListDialog}
            close_whiteList_dialog={(value) => setWhiteListDialog(false)}
            dialog="whiteListDialog"
          />
        ) : null}
        {restartGatewayDialog ? (
          <DraggableComponent
            data = {gatewayLogData}
            showDIalog={restartGatewayDialog}
            close_restart_gateway_dialog={(value) =>{
              setRestartGatewayDialog(false);
            }
            }
            dialog="restartGatewayDialog"
          />
        ) : null}
      </Box>
      {showEditIcon ? (
        <Button
          onClick={() => {
            setEdiitMode(!editMode);
            setShowEditIcon(!showEditIcon);
          }}
          style={{
            position: 'fixed',
            bottom: '37px',
            right: '40px',
            height: '62px',
            miWwidth: '55px',
            maxWidth: '55px',
            borderRadius: '50%',
            backgroundColor: 'rgb(20,19,139)',
            display: JSON.parse(sessionStorage.userDetails).permissions.includes("TOP_LEVEL_SCOPE_READ") ? 'flex' : 'none',
          }}
        >
          <Edit style={{color: 'white'}} />
        </Button>
      ) : (
        <Button
          onClick={() => {
            setEdiitMode(!editMode);
            setShowEditIcon(!showEditIcon);
            setSelected([]);
            setCheckAll(false);
          }}
          style={{
            position: 'fixed',
            bottom: '37px',
            right: '40px',
            height: '62px',
            miWwidth: '55px',
            maxWidth: '55px',
            borderRadius: '50%',
            backgroundColor: 'rgb(20,19,139)',
          }}
        >
          <Close style={{color: 'white'}} />
        </Button>
      )}

      {/* DIALOG BOXES */}
      {pendingDialog ? (
        <>
          <DraggableComponent
            data={pendingInstructionData}
            show_pending_instruction_dialog={pendingDialog}
            close_pending_instruction_dialog={(value) =>{
              setPendingDialog(value);
            }}
            abortInstructions={(data)=> handleAbortInstruction(data)}
            dialog="pendingInstructionDialog"
          />
        </>
      ) : null}

      {/* INFORMATION DAILOG */}

      {infoDialog ? (
        <>
          <DraggableComponent
            data={infoData}
            allData={rows}
            show_info_dialog={infoDialog}
            close_info_dialog={(value) => {
              setInfoDialog(value);
            }}
            dialog="infoDialog"
          />
        </>
      ) : null}

      {/* GATEWAY LOG DIALOG */}

      {gatewayLogDialog ? (
        <>
          <DraggableComponent
            data={gatewayLogData}
            allData={rows}
            open_whiteList_dialog={(value) => handleWhiteListDialog(value)}
            open_force_probe_dialog= {(value) => handleForceProbeDialog(value)}
            open_restart_dialog={(value) => handleRestartDialog(value)}
            open_clear_whiteList_dialog={(value)=> handleClearBlackListDialog(value)}
            show_gateway_log_dialog={gatewayLogDialog}
            close_gateway_log_dialog={(value) => {
              setGatewayLogDialog(value);
              if (window.generateScannedLocksCsv){
                window.generateScannedLocksCsv.unsubscribe();
              }
              reSubscribeAllGatewayChannels();
            }}
            dialog="gatewayLogDialog"
          />
        </>
      ) : null}

      {
        clearBlackListDialog ?
            <DraggableComponent
                data={gatewayLogData}
                showDIalog={clearBlackListDialog}
                close_clear_black_list_dialog={(value) =>{
                  setClearBlackListDialog(false);
                }}
                dialog="clearBlackListDialog"
            />

        :
            null

      }
      {
        showDeleteGatewayDialog ?
          <DraggableComponent
                data={deleteGatewayData}
                showDIalog={showDeleteGatewayDialog}
                closeDialog={(value) =>{
                  setShowDeleteGatewayDialog(false);
                }}
                fetchAllGateways={()=> handleFetchGateways()}
                dialog="deleteeGatwayDialog"
            />
        :

          null
      }
      {
        forceProbeDialog ?
          <ForceProbeDialog
            data={gatewayLogData}
            closeDialog={()=> setForceProbeDialog(false)}/>
        :
          null
      }
    </>
  );
}