import * as React from 'react';
import { Avatar,Popper,ClickAwayListener,Grow,Paper,MenuItem,MenuList,Box,Tooltip } from '@mui/material';
import {useEffect, useState} from 'react';
import { History,InfoOutlined,OpenInNewOutlined,Delete,MoreVert,DeleteForever } from '@mui/icons-material';
import DraggableHomePageComponent from './DraggableHomPageComponent';
import '../stylesheet/HomePage.css';
import { useNavigate } from "react-router-dom";
import styles from "../stylesheet/gatewayStatusDialog.module.css";
import UserSessionStorage from '../userSession/UserSessionStorage';
import _ from 'lodash';
import IotServiceClient from '../clients/IotServiceClient';
import DraggableComponent from './DraggableComponent';
import DraggableDialogComponent from './draggableDialog/DraggableDialogComponent';


export default function FolderList(props) {
  //Get window dimensions
  const getWindowDimensions = () => {
    const {innerWidth: width, innerHeight: height} = window;
    return {
      width,
      height,
    };
  };
  const [dimensions, setDimensions] = useState(getWindowDimensions());
  const [showMoreOptions, updateShowMoreOption] = useState(false);

  useEffect(() => {
    function handleResize() {
      setDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      IotServiceClient.abortSignal("pcToolHistory");
    }
  }, []);

  // window dimension logic ends

  const showOptions = () => {
    if (dimensions.width > 1280) {
      updateShowMoreOption(true);
    }
  };

  const hideOptions = () => {
    if (dimensions.width > 1280) {
      updateShowMoreOption(false);
    }
  };

  const getBackgroundColor = () => {
    let name = props.name + 's';
    var hash = 0;
    for (var i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    var color = (hash & 0x00ffffff).toString(16).toUpperCase();
    return '00000'.substring(0, 6 - color.length) + color;
  };

  //menu logic

  const [open, setOpen] = React.useState(false);

  const [openDialog, setOpenDialog] = React.useState(false);

  const anchorRef = React.useRef(null);

  const [deleteAlert, setDeleteAlert] = React.useState(false);

  const handleToggle = () => {
    if (openDialog === true || deleteAlert === true) {
      setOpenDialog(false);
      setDeleteAlert(false);
    }
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      if (anchorRef.current) {
        anchorRef.current.focus();
      }
    }

    prevOpen.current = open;
  }, [open]);

  // menu logic end
    const nav = useNavigate();
    const openTenantPage = (tenantId, tenantName) => {
    UserSessionStorage.setTenantName(tenantName);
    sessionStorage.tenantName = tenantName;
    sessionStorage.tenantId = tenantId;
    nav("/cli/dashboard")
  };

  const [showPcToolHistoryDialog, setShowPcToolHistoryDialog] = React.useState(false)
  const [pcToolHistoryData, setPcToolHistoryData] = React.useState([]);
  const [showDeletePermanentDialog, setShowDeletePermanentDialog] = React.useState(false);
  const [permanentDeleteTenant, setPermanentDeleteTenant] = React.useState([]);
  const handlePcToolHistoryDialog = () => {
    IotServiceClient.getPcToolHistory("pcToolHistory").then((response)=>{
      setPcToolHistoryData(response);      
      
    })
    setShowPcToolHistoryDialog(true);    
  }

  const showpermanentDeleteDialog = (data) => {
    setPermanentDeleteTenant(data);
    setShowDeletePermanentDialog(true);
  }

  return (
          <div
              className={props.status === "DELETE_PENDING" ?  `${props.setClassName} ${"tenantDeletePending"}` : props.setClassName}
              onMouseEnter={() => showOptions()}
              onMouseLeave={() => hideOptions()}
          >
            <div
                className="innerCardBlock"
                style={{
                  width:
                      dimensions.width < 600 &&
                      props.name.toLowerCase() !== 'wesko' &&
                      props.name.toLowerCase() !== 'freetier'
                          ? '90%'
                          : null,
                }}
            >
              <div
                  style={{
                    width: '96px',
                    height: '100%',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                  onClick={(event) => openTenantPage(props.scopeId, props.name)}
              >
                {
                    props.gateways ?
                       props.gatewayOnlineCount > 0 && props.gatewayOfflineCount > 0 ?
                          <>
                             <Tooltip title={props.gatewayOnlineCount} placement="top" arrow>
                                 <div className={styles.greenDot}>
                                     &nbsp;
                                 </div>
                             </Tooltip>
                             <Tooltip title={props.gatewayOfflineCount} placement="top" arrow>
                                 <div className={styles.redDotOffline}>
                                     &nbsp;
                                 </div>
                             </Tooltip>
                          </>
                       :
                          props.gatewayOnlineCount > 0 ?
                              <Tooltip title={props.gatewayOnlineCount} placement="top" arrow>
                                  <div className={styles.greenDot}>
                                      &nbsp;
                                  </div>
                              </Tooltip>
                          :
                              <Tooltip title={props.gatewayOfflineCount} placement="top" arrow>
                                  <div className={styles.redDot}>
                                      &nbsp;
                                  </div>
                              </Tooltip>
                    :
                        null
                }
                <Avatar
                    id="avatar"
                    style={{
                      marginLeft: '16px',
                      padding: '12px',
                      fontSize: '33px',
                      background: '#' + getBackgroundColor(),
                    }}
                >
                  {props.name.toUpperCase().replaceAll(' ', '')[0]}
                </Avatar>
              </div>
              <div
                  className="cardText"
                  style={{minWidth: '0px', textOverflow: 'ellipsis'}}
              >
                <div
                    className="tenantName"
                    onClick={(event) => openTenantPage(props.scopeId, props.name)}
                >
                  <div
                      style={{
                        display: 'flex',
                        flex: '100',
                        height: '100%',
                        flexDirection: 'column',
                      }}
                  >
                    <div style={{display: 'flex', flex: '33'}}>&nbsp;</div>
                    <Tooltip title={props.status === "DELETE_PENDING" ? "This customer is being deleted" : props.name} placement="top" arrow>
                        <div
                            style={{
                              width: '82%',
                              alignItems: 'center',
                              paddingLeft: '10px',
                              textOverflow: 'ellipsis',
                              fontSize: '18px',
                              fontWeight: '400',
                              display: 'block',
                              overflow: 'hidden',
                              color: '#000000DE !important'
                            }}
                        >
                          {props.name}
                        </div>
                    </Tooltip>
                    <div style={{display: 'flex', flex: '33'}}>&nbsp;</div>
                  </div>
                </div>
                {dimensions.width > 1280 ? (
                    <Box
                        className="cardIconBlock"
                        sx={{
                          visibility: showMoreOptions === true ? 'visible' : 'hidden',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                    >
                      {props.name.toLowerCase().replaceAll(' ', '') !== 'freetier' ? (
                          <Box sx={{flex: '33%'}}>
                            {
                                props.status !== "DELETE_PENDING" ?
                                    <DraggableHomePageComponent
                                        title="Update Tenant"
                                        dimentionWidth={dimensions.width}
                                        data={props.data}
                                        iconClicked={() => setOpenDialog(true)}
                                        scopeId={props.scopeId}
                                        openDialogBox={openDialog}
                                        handleCloseDialogBox={(val) => {
                                          setOpenDialog(val);
                                          setOpen(false);
                                          hideOptions();
                                        }}
                                        buttonName="update"
                                    />
                                :
                                    <Tooltip placement="top" title="Permanent delete" arrow>
                                        <DeleteForever
                                            onClick={() => showpermanentDeleteDialog(props.data)}
                                            className="cardIcons"
                                            style={{
                                              fontSize: '18px',
                                              paddingLeft: '6px',
                                              paddingTop: '6px',
                                              color: 'grey',
                                              marginRight:"15px"
                                            }}
                                        />
                                    </Tooltip>
                            }
                          </Box>
                      ) : (
                          <Box sx={{flex: '33%',
                            display: JSON.parse(sessionStorage.userDetails).permissions.includes("IOT_DEVICE_SCOPE_MIGRATE") ? 'flex' : 'none'}} >
                            <Tooltip title="Migrate locks" placement='top' arrow>
                            <OpenInNewOutlined
                                onClick={() => {
                                  // window.location.href = '/admin/migrate-locks';
                                  nav("/admin/migrate-locks");
                                }}
                                className="cardIcons"
                                style={{
                                  fontSize: '18px',
                                  paddingLeft: '6px',
                                  paddingTop: '6px',
                                  color: 'grey',
                                }}
                            />
                            </Tooltip>
                          </Box>
                      )}

                      {props.name.toLowerCase().replaceAll(' ', '') !== 'freetier' &&
                      props.name.toLowerCase().replaceAll(' ', '') !== 'wesko' ? (
                              <Box sx={{flex: '33%'}}>
                                  {
                                    props.status !== "DELETE_PENDING" ?
                                        <Tooltip title={
                                          props.versions.length > 0?
                                            <div style={{ whiteSpace: 'pre-line',fontSize:"14px",fontWeight:'bold' }}>{props.desktopViewToolTip}</div>                              
                                          :
                                          <div style={{fontSize:"14px",fontWeight:'bold' }}>No information available</div>
                                        } placement="top" arrow>
                                          <InfoOutlined
                                              onClick={() => {}}
                                              className="cardIcons"
                                              style={{
                                                fontSize: '18px',
                                                paddingLeft: '6px',
                                                paddingTop: '6px',
                                                color: 'grey',
                                              }}
                                          />
                                        </Tooltip>
                                     :
                                        null
                                  }
                              </Box>

                      ) : props.name.toLowerCase().replaceAll(' ', '') === 'wesko' ? (
                          <Box sx={{flex: '33%'}}>
                            <History
                                onClick={() => handlePcToolHistoryDialog()}
                                className="cardIcons"
                                style={{
                                  fontSize: '20px',
                                  paddingLeft: '5px',
                                  paddingTop: '4px',
                                  color: 'grey',
                                }}
                            />
                          </Box>
                      ) : (
                          <Box sx={{flex: '33%'}}>
                            <div
                                className="cardIcons"
                                style={{
                                  fontSize: '18px',
                                  paddingLeft: '6px',
                                  paddingTop: '6px',
                                  color: 'grey',
                                }}
                            >
                              {' '}
                              &nbsp;
                            </div>
                          </Box>
                      )}

                      {props.name.toLowerCase().replaceAll(' ', '') !== 'freetier' &&
                      props.name.toLowerCase().replaceAll(' ', '') !== 'wesko' ? (
                          <Box sx={{flex: '33%'}}>
                            {
                                props.status !== "DELETE_PENDING" ?
                                    <DraggableHomePageComponent
                                        title="Delete Customer"                                        
                                        dimentionWidth={dimensions.width}
                                        iconClicked={() => setDeleteAlert(true)}
                                        openDialogBox={deleteAlert}
                                        scopeId={props.scopeId}
                                        handleCloseDialogBox={(val) => {
                                          setDeleteAlert(val);
                                          setOpen(false);
                                          hideOptions();
                                        }}
                                        buttonName="delete"
                                    />
                                :
                                    null
                            }
                          </Box>
                      ) : (
                          <Box sx={{flex: '33%'}}>
                            <Tooltip title={
                              props.versions.length > 0?
                                <div style={{ whiteSpace: 'pre-line',fontSize:"14px",fontWeight:'bold' }}>{props.desktopViewToolTip}</div>                              
                              :
                              <div style={{fontSize:"14px",fontWeight:'bold' }}>No information available</div>
                            } placement="top" arrow>
                              <InfoOutlined
                                  onClick={() => {}}
                                  className="cardIcons"
                                  style={{
                                    fontSize: '18px',
                                    paddingLeft: '6px',
                                    paddingTop: '6px',
                                    color: 'grey',
                                  }}
                              />
                            </Tooltip>
                          </Box>
                      )}
                    </Box>
                ) : (
                        props.status !== "DELETE_PENDING" ?
                            <div className="cardIcoMenunBlock">
                              <MoreVert
                                  ref={anchorRef}
                                  id="composition-button"
                                  aria-controls={open ? 'composition-menu' : undefined}
                                  aria-expanded={open ? 'true' : undefined}
                                  aria-haspopup="true"
                                  onClick={handleToggle}
                                  className="cardIcons"
                              />
                              <Popper
                                  id="menuList"
                                  open={open}
                                  anchorEl={anchorRef.current}
                                  role={undefined}
                                  placement="bottom-start"
                                  transition
                              >
                                {({TransitionProps, placement}) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                          transformOrigin:
                                              placement === 'bottom-start'
                                                  ? 'left top'
                                                  : 'left bottom',
                                        }}
                                    >
                                      <Paper>
                                        <ClickAwayListener onClickAway={handleClose}>
                                          <MenuList
                                              autoFocusItem={open}
                                              id="composition-menu"
                                              aria-labelledby="composition-button"
                                              style={{width: '200px'}}
                                              onKeyDown={handleListKeyDown}
                                          >
                                            <MenuItem className="menuItems">
                                              {props.name.toLowerCase() !== 'freetier' ? (
                                                  <DraggableHomePageComponent
                                                      data={props.data}
                                                      iconColor="rgba(0,0,0,0.54)"
                                                      title="Update Tenant"
                                                      scopeId={props.scopeId}
                                                      dimentionWidth={dimensions.width}
                                                      iconClicked={() => setOpenDialog(true)}
                                                      openDialogBox={openDialog}
                                                      handleCloseDialogBox={(val) => {
                                                        setOpenDialog(val);
                                                        setOpen(false);
                                                        hideOptions();
                                                      }}
                                                      buttonName="update"
                                                  />
                                              ) : (
                                                  <div
                                                      onClick={() => {
                                                        // window.location.href = '/admin/migrate-locks';
                                                        nav("/admin/migrate-locks");
                                                      }}
                                                      style={{display: JSON.parse(sessionStorage.userDetails).permissions.includes("IOT_DEVICE_SCOPE_MIGRATE") ? 'contents' : 'none'}}
                                                  >
                                                    <Tooltip title="Migrate locks" placement='top' arrow>
                                                      <OpenInNewOutlined
                                                          style={{margin: 'auto 16px auto 0',color:'grey'}}
                                                      />
                                                    </Tooltip>
                                                    <span
                                                        style={{
                                                          marginTop: 'auto',
                                                          marginBottom: 'auto',
                                                        }}
                                                    >
                                              Migrate Locks
                                            </span>
                                                  </div>
                                              )}
                                            </MenuItem>
                                            {props.name.toLowerCase() !== 'freetier' &&
                                            props.name.toLowerCase() !== 'wesko' ? (
                                                <MenuItem className="menuItems">
                                                  <DraggableHomePageComponent
                                                      iconColor="rgba(0,0,0,0.54)"
                                                      title="Delete Customer"
                                                      dimentionWidth={dimensions.width}
                                                      scopeId={props.scopeId}
                                                      iconClicked={() => setDeleteAlert(true)}
                                                      openDialogBox={deleteAlert}
                                                      handleCloseDialogBox={(val) => {
                                                        setDeleteAlert(val);
                                                        setOpen(false);
                                                        hideOptions();
                                                      }}
                                                      buttonName="delete"
                                                  />
                                                </MenuItem>
                                            ) : null}
                                            <MenuItem onClick={handleClose} className="menuItems" sx={{display:"block",fontSize:"14px"}}>
                                              {
                                                props.versions.length > 0 ?
                                                  props.versions.map((data)=>{
                                                    return (
                                                      <Box sx={{display:"flex"}}>
                                                        <Box>
                                                          {data.name === "img" ? data.type.replace("WeskoGateway","gateway").toUpperCase() : data.name.replace("WeskoGateway","gateway").replace("OSpatch","Partial os").toUpperCase()} :
                                                        </Box>
                                                        <Box
                                                            style={{marginLeft:'auto',fontWeight:"bold"}}
                                                        >
                                                          {data.version}
                                                        </Box>  
                                                      </Box>
                                                    )
                                                  })
                                                :
                                                <>
                                                  <span style={{margin: 'auto 16px auto 0'}}>
                                                    Version:
                                                  </span>
                                                        <span
                                                            style={{marginTop: 'auto', marginBottom: 'auto'}}
                                                        >
                                                    Not Available
                                                  </span>
                                                </>
                                              }
                                            </MenuItem>
                                          </MenuList>
                                        </ClickAwayListener>
                                      </Paper>
                                    </Grow>
                                )}
                              </Popper>
                            </div>
                    :
                        <Tooltip placement="top" title="Permanent delete" arrow>
                            <DeleteForever
                                onClick={() => showpermanentDeleteDialog(props.data)}
                                className="cardIcons"
                                style={{
                                  fontSize: '18px',
                                  paddingLeft: '6px',
                                  paddingTop: '6px',
                                  color: 'grey',
                                  marginTop: '-63px',
                                  marginRight: '16px'
                                }}
                            />
                        </Tooltip>
                )}                
              </div>
                {
                  showPcToolHistoryDialog ?
                    <DraggableComponent
                      showDialog={showPcToolHistoryDialog}
                      handleCloseDialogBox={(val) => {
                        setShowPcToolHistoryDialog(false);
                        setOpen(false);
                        hideOptions();
                      }}
                      dialog="pcToolHistory"
                    />
                  :
                    null
                }
                {
                  showDeletePermanentDialog ?
                    <DraggableDialogComponent
                      showDialog ={showDeletePermanentDialog}
                      tenant={permanentDeleteTenant}
                      closeDialog={(val) =>{
                        setShowDeletePermanentDialog(false);
                        window.location.reload();
                      }}
                      dialog="deletePermanantDialog"
                    />
                  :
                    null
                }
            </div>
          </div>
  );
}