import React from 'react';
import ReactDOM from 'react-dom';
import Login from './components/login/Login.js';
import reportWebVitals from './reportWebVitals';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Appheader from './components/header/Appheader';
import LogoHeader from './components/header/LogoHeader';
import HomePage from './components/HomePage';
import SideNav from './components/SideNav.js';
import ConfigureEmail from './components/ConfigureEmail';
import UpdateFirmware from './components/updateFirmware/UpdateFirmware';
import ChangePassword from './components/changepassword/ChangePassword';
import MigrateLocks from './components/MigrateLocks';
import EngineeringView from './components/engineeringview/EngineeringView'
import GlobalDashboard from './components/GlobalDashboardPage';
import { progressBarFetch, setOriginalFetch, ProgressBar } from 'react-fetch-progressbar';
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';
import Slide, { SlideProps } from '@mui/material/Slide';

setOriginalFetch(window.fetch);
window.fetch = progressBarFetch;

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

    // : TODO NEED TO ADD HEADER COMPONENT/ELEMENT
    ReactDOM.render(
      <React.StrictMode>
        <ProgressBar style={{position: 'absolute',top: 0,zIndex: 5000}}/>
        <Router>
          <Routes>
              <Route path="/*" element={
                window.location.href.indexOf("portal-user/reset-password/") === -1 && window.location.href.indexOf("com/reset-password/") === -1 && (window.location.hash !== "/" && window.location.hash !== "#/login" && window.location.hash !== "#/" && window.location.hash !== "#/logout") ?
                  <Appheader />
                :
                  <LogoHeader />                
              } />
          </Routes>
        </Router>
      </React.StrictMode>,
      document.getElementById('rootHeader')
    );
    ReactDOM.render(
      <React.StrictMode>
        {
          window.location.href.indexOf("portal-user/reset-password/") === -1 && window.location.href.indexOf("com/reset-password/") === -1  ?
            <Router>
              <Routes>
                <Route exact path="/" element={<Login />} />
                <Route exact path="/login" element={
                <Login />} />
                <Route exact path="/global-search" element={<GlobalDashboard />} />
                <Route exact path="/change-password" element={
                  <SnackbarProvider autoHideDuration={2000} maxSnack={3} TransitionComponent={TransitionUp} anchorOrigin={{vertical: 'bottom',horizontal: 'right'}}>
                    <ChangePassword />
                  </SnackbarProvider>
                } />
                <Route exact path="/reset-password/:activationCode" element={<ChangePassword isResetPassword={true}/>}  />
                <Route exact path="/admin/tenants" element={
                  document.cookie.indexOf("mqtt-auth-token") === -1 || !sessionStorage.hasOwnProperty("userDetails") ?
                    <Login />
                  :
                    <SnackbarProvider autoHideDuration={2000} maxSnack={3} TransitionComponent={TransitionUp} anchorOrigin={{vertical: 'bottom',horizontal: 'right'}}>
                      <HomePage />
                    </SnackbarProvider>
                } />
                <Route exact path="/cli/*" element={
                  document.cookie.indexOf("mqtt-auth-token") === -1 || !sessionStorage.hasOwnProperty("userDetails") ?
                    <Login />
                  :
                    <SnackbarProvider autoHideDuration={2000} maxSnack={3} TransitionComponent={TransitionUp} anchorOrigin={{vertical: 'bottom',horizontal: 'right'}}>
                      <SideNav />
                    </SnackbarProvider>
                }
                />
                <Route exact path="/configure" element={
                  document.cookie.indexOf("mqtt-auth-token") === -1 || !sessionStorage.hasOwnProperty("userDetails") ?
                    <Login />
                  :
                    <ConfigureEmail />
                } />
                <Route exact path="/locks" element={
                  document.cookie.indexOf("mqtt-auth-token") === -1 || !sessionStorage.hasOwnProperty("userDetails") ?
                    <Login />
                  :
                    <SnackbarProvider autoHideDuration={2000} maxSnack={3} TransitionComponent={TransitionUp} anchorOrigin={{vertical: 'bottom',horizontal: 'right'}}>
                      <UpdateFirmware />
                    </SnackbarProvider>
                } />
                <Route exact path="/admin/migrate-locks" element={
                  document.cookie.indexOf("mqtt-auth-token") === -1 || !sessionStorage.hasOwnProperty("userDetails") ?
                    <Login />
                  :
                    <SnackbarProvider autoHideDuration={2000} maxSnack={3} TransitionComponent={TransitionUp} anchorOrigin={{vertical: 'bottom',horizontal: 'right'}}>
                      <MigrateLocks />
                    </SnackbarProvider>
                } />
                <Route exact path="/logout" element={<Login />} />
                <Route exact path="/engineering-View" element={                  
                  document.cookie.indexOf("mqtt-auth-token") === -1 || !sessionStorage.hasOwnProperty("userDetails") ?
                    <Login />
                  :
                    <SnackbarProvider autoHideDuration={2000} maxSnack={3} TransitionComponent={TransitionUp} anchorOrigin={{vertical: 'bottom',horizontal: 'right'}}>
                      <EngineeringView />
                    </SnackbarProvider>
                }/>
              </Routes>
            </Router>
          :            
            <Router>
              <Routes>
              <Route path="/*" element={
                <SnackbarProvider autoHideDuration={2000} maxSnack={3} TransitionComponent={TransitionUp} anchorOrigin={{vertical: 'bottom',horizontal: 'right'}}>
                  <ChangePassword isResetPassword={true} resetIotUserPassword={window.location.href.indexOf("com/reset-password/") !== -1 ? true : false} navigatedThroughMail={true} />
                </SnackbarProvider>
              }/>
              </Routes>
            </Router>
        }
      </React.StrictMode>,
      document.getElementById('root')
    );
reportWebVitals();
