import React from 'react';
import '../stylesheet/ConfigureEmail.css';
import { ArrowBack,AddCircleOutline,RemoveCircleOutlined } from '@mui/icons-material';
import { Radio,RadioGroup,FormControlLabel,FormControl,Button,Avatar,Chip,CircularProgress } from '@mui/material';
import ModifyTemplate from './ModifyTemplate';
import Appheader from './header/Appheader';
import MyEditor from './MyEditor';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {Link} from 'react-router-dom';
import AuthClient from '../clients/AuthClient';
import { Box } from '@mui/system';

export default class ConfigureEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTenant: [],
      searchText: '',
      selectedTemplate:"welcomeEmailTemplate",
      allTenants: [],
      isLoading: true,
      selectedScope :[]
    };
    this.fetchTenantData();
    this.handleChange = this.handleChange.bind(this);
  }

  fetchTenantData() {
    AuthClient.getHirarchy().then((data) => {
      this.setState({
        allTenants: data["children"],
        isLoading: false,
      })
    })
  }

  handleChange(e) {
    this.setState({searchText: e.target.value});
  }  

  render() {
    const getBackgroundColor = (tenant) => {
      let name = tenant + 's';
      var hash = 0;
      for (var i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
      }
      var color = (hash & 0x00ffffff).toString(16).toUpperCase();
      return '00000'.substring(0, 6 - color.length) + color;
    };

    let changeIcon = (name,scopeId) => {
      let element = document.getElementById(name);
      let removeElement = document.getElementById(
        name + 'RemoveCircleOutlineIcon'
      );
      sessionStorage.checkIq = scopeId;
      if (name.lastIndexOf('RemoveCircleOutlineIcon') === -1) {
        if (element) element.style.display = 'none';
        if (removeElement) removeElement.style.display = 'flex';
        // show selected tenant logic
        if (this.state.selectedTenant.length < 1) {
          this.setState({selectedTenant: [name],selectedScope :[scopeId]});
        } else {
          if (
            document.getElementById(
              this.state.selectedTenant[0] + 'RemoveCircleOutlineIcon'
            )
          )
            document.getElementById(
              this.state.selectedTenant[0] + 'RemoveCircleOutlineIcon'
            ).style.display = 'none';
          if (document.getElementById(this.state.selectedTenant[0]))
            document.getElementById(
              this.state.selectedTenant[0]
            ).style.display = 'flex';
          this.setState({selectedTenant: [],selectedScope :[]});
          this.setState({selectedTenant: [name],selectedScope :[scopeId]});
        }
      } else {
        if (element) element.style.display = 'none';
        name = name.replaceAll('RemoveCircleOutlineIcon', '');
        if (document.getElementById(name))
          document.getElementById(name).style.display = 'flex';
        this.setState({selectedTenant: [],selectedScope :[]});
      }
    };

    const handleDelete = () => {
      changeIcon(this.state.selectedTenant,this.state.selectedScope);
      this.setState({selectedTenant: [],selectedScope :[]});
    };

    return (
      <>
        <div style={{height: '80%'}}>
          <div style={{height: '56px'}}>
              <ArrowBack onClick={() => {
                if(sessionStorage.isRootUser === "true"){
                  window.location.hash= "/admin/tenants";
                }else {
                  window.location.hash= "/cli/dashboard";
                }
                window.location.reload();
              }}              
              className="backArrow" />
          </div>
          <div className="container" style={{maxHeight: '90%'}}>
            <div
              className="subContainer"
              style={{minHeight: '100%', margin: ' 0px auto 16px auto'}}
            >
              <div
                style={{
                  flex: '20',
                  padding: '0 12px',
                  borderBottom: '1px solid rgba(0,0,0,0.12)',
                }}
              >
                <h5 style={{fontSize: '16px'}}>
                  Select Tenants to Configure Email Type

                </h5>
              </div>

              <div className="subBlock" style={{flex: '60'}}>
                <div className="portions" style={{width: '100%'}}>
                  {this.state.selectedTenant.length > 0 ? (
                    <div className="selectedBlockExist">
                      <Chip
                        label={this.state.selectedTenant}
                        onDelete={handleDelete}
                      />
                    </div>
                  ) : (
                    <div className="selectedBlock">
                      <span>Select Email Type to Configure</span>
                    </div>
                  )}
                  <div className="radioGroup" style={{paddingLeft: '12px'}}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        defaultValue="welcomeEmailTemplate"
                        row
                        aria-label="emailType"
                        name="row-radio-buttons-group"
                        onChange={(event)=>this.setState({selectedTemplate: event.target.value})}
                      >
                        <FormControlLabel
                          value="welcomeEmailTemplate"
                          control={<Radio color="secondary" />}
                          style={{width: '100%'}}
                          label="Invitation Email for Portal Users"
                        />
                        <FormControlLabel
                          value="welcomeLockEmailTemplate"
                          control={<Radio color="secondary" />}
                          style={{width: '100%'}}
                          label="Invitation Email With Lock Details"
                        />
                        <FormControlLabel
                          value="welcomeRfidLockEmailTemplate"
                          control={<Radio color="secondary" />}
                          style={{width: '100%'}}
                          label="Invitation Email With Lock Details RFID"
                        />
                        <FormControlLabel
                          value="welcomeSelfUserEmailTemplate"
                          control={<Radio color="secondary" />}
                          style={{width: '100%'}}
                          label="Invitation Email With Lock Details(self)"
                        />
                        <FormControlLabel
                          value="registrationEmailTemplate"
                          control={<Radio color="secondary" />}
                          style={{width: '100%'}}
                          label="Registration Email For Mobile App"
                        />
                        <FormControlLabel
                          value="passwordEmailTemplate"
                          control={<Radio color="secondary" />}
                          style={{width: '100%'}}
                          label="Password Reset Email"
                        />
                        <FormControlLabel
                            value="scheduleLockEmailTemplate"
                            control={<Radio color="secondary" />}
                            style={{width: '100%'}}
                            label="Invitation Email For Scheduled Add User"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
                <div
                  className="portions tenantsBlock"
                  style={{
                    width: '100%',
                    maxHeight: '326px',
                    minHeight: '0px',
                    height: 'auto',
                    display: sessionStorage.tenantId ? "none" : null
                  }}
                >
                  <div className="searchBlock" style={{padding: '7px'}}>
                    <input
                      type="text"
                      value={this.state.value}
                      onChange={this.handleChange}
                      placeholder="Search"
                      style={{
                        textIndent: '4px',
                        width: '100%',
                        height: '34px',
                        fontSize: '100%',
                        border: '2px solid black',
                      }}
                    />
                  </div>
                  <div className="allTenants">
                    {
                      // eslint-disable-next-line array-callback-return
                      this.state.isLoading ?
                        <Box  sx={{width:'100%',display:'flex',justifyContent: 'center',alignItems:'center',height:"448px"}}>
                          <CircularProgress thickness="4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                        </Box> 
                    :

                      this.state.allTenants
                        .sort((first, next) =>
                          first['scope'].name.localeCompare(next['scope'].name)
                        )
                        .map((element, index) => {
                          if (index > 0) {
                            if (
                              element['scope'].name
                                .toLowerCase()
                                .lastIndexOf(
                                  this.state.searchText.toLowerCase()
                                ) !== -1
                            ) {
                              return (
                                <div
                                  key={element['scope'].name}
                                  className="tenantItems"
                                  style={{height: '60px'}}
                                >
                                  <div
                                    style={{
                                      width: '96px',
                                      height: '100%',
                                      alignItems: 'center',
                                      display: 'flex',
                                    }}
                                  >
                                    <Avatar
                                      id="avatar"
                                      style={{
                                        margin: ' 10px 4px 10px 24px',
                                        padding: '4px',
                                        background:
                                          '#' +
                                          getBackgroundColor(
                                            element['scope'].name
                                          ),
                                      }}
                                    >
                                      {element['scope'].name[0]}
                                    </Avatar>
                                  </div>
                                  <div
                                    style={{
                                      marginTop: 'auto',
                                      marginBottom: 'auto',
                                      width: '80%',
                                    }}
                                  >
                                    {element['scope'].name}
                                  </div>
                                  <div style={{margin: 'auto 30px auto 0px'}}>
                                    {
                                      <>
                                        <AddCircleOutline
                                          id={element['scope'].name}
                                          onClick={() =>
                                            changeIcon(element['scope'].name,element['scope'].id)
                                          }
                                        />
                                        <RemoveCircleOutlined
                                          className="removeCircle"
                                          style={{display: 'none'}}
                                          id={
                                            element['scope'].name +
                                            'RemoveCircleOutlineIcon'
                                          }
                                          onClick={() =>
                                            changeIcon(
                                              element['scope'].name +
                                                'RemoveCircleOutlineIcon',element['scope'].id
                                            )
                                          }
                                        />
                                      </>
                                    }
                                  </div>
                                </div>
                              );
                            }
                          }
                        })
                    }
                  </div>
                </div>
              </div>

              <div className="bottomDiv" style={{}}>
                <Button color="secondary" style={{color: 'red', margin: '2px'}} onClick={() => {
                  if(sessionStorage.isRootUser === "true"){
                    window.location.hash= "/admin/tenants";
                  }else {
                    window.location.hash= "/cli/dashboard";
                  }
                  window.location.reload();
                }}>
                  BACK TO TENANTS
                </Button>
                {this.state.selectedTenant.length > 0 || sessionStorage.tenantId ? (
                  <MyEditor tenant={this.state.selectedScope ? this.state.selectedScope : [sessionStorage.tenantId]} selectedTemplate ={this.state.selectedTemplate} />
                ) : null}
              </div>
              <div style={{height: '10%'}}>&nbsp;&nbsp;</div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
