/* eslint-disable no-undef */
import axios from "axios";

const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({})
};

let allAbortControlllers = {};

function addAbortController (page) {
    let abortController = new AbortController();
    if (!allAbortControlllers.hasOwnProperty(page)){
        allAbortControlllers[page] = [abortController];
    }else {
        allAbortControlllers[page].push(abortController);
    }
    return abortController;
}

function getIotDevices(scopeId) {
    return fetch('/iotdevices?scopeId=' + scopeId)
        .then(res => res.json())
        .then((json) => {
            console.info(json);
            return json;
        })
        .catch((err) => {
            console.error(err);
            return err;
        });
}


function getReachableIotDevices(scopeId) {
    return fetch('/api/iot-service/reachable?scopeId=' + scopeId)
        .then(res => res.json())
        .then((json) => {
            console.info(json);
            return json;
        })
        .catch((err) => {
            console.error(err);
            return err;
        });
}

function getAlerts(scopeId, page) {
    let abortController = addAbortController(page);
    return fetch('/api/alerts/all?scopeId=' + scopeId, {signal: abortController.signal})
        .then(res => res.json())
        .then((json) => {
            console.info(json);
            return json;
        })
        .catch((err) => {
            console.error(err);
            return err;
        });
}

function deleteAlert(alertId) {
    return fetch('/api/alert/delete?alertId=' + alertId,{
        method:"DELETE"
    })
        .then((json) => {
            console.info(json);
            return json;
        })
        .catch((err) => {
            console.error(err);
            return err;
        });
}

function deleteRfidAlert(scopeId) {
    return fetch('/api/rfid-delete-alert/delete?scopeId=' + scopeId,{
        method:"DELETE"
    })
        .then((json) => {
            console.info(json);
            return json;
        })
        .catch((err) => {
            console.error(err);
            return err;
        });
}

function updateAlert(alert) {
    return fetch("/api/alert/update",{
        method:"PUT",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(alert)
    }).then((res)=>{
        return res;
    }).catch((err)=>{
        return err;
    })
}

function updateRfidAlert(alert) {    
    return fetch("/api/rfid-alert/update",{
        method:"PUT",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(alert)
    }).then((res)=>{
        return res;
    }).catch((err)=>{
        return err;
    })
}

function createAlert(callback, user_details) {
    return fetch('/api/alert/createAlert', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user_details)
    }).then(callback)
    // .then(data => this.setState({ postId: data.id }));
}

function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }
    const error = new Error(`HTTP Error ${response.statusText}`);
    error.status = response.statusText;
    error.response = response;
    throw error;
}

function parseJSON(response) {
    return response.json();
}

function abortSignal(page) {
    let abortSignals = allAbortControlllers.hasOwnProperty(page) ? allAbortControlllers[page] : [];
    _.forEach(abortSignals, (abortController)=> {
        abortController.abort()
    })
}

const JobSchedulerClient = { getIotDevices, updateAlert, updateRfidAlert, deleteRfidAlert,getReachableIotDevices, getAlerts ,deleteAlert,createAlert, abortSignal};
export default JobSchedulerClient;
