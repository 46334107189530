import * as React from 'react';
import PropTypes from 'prop-types';
import { AppBar,CssBaseline,Divider,Box,Drawer,IconButton,List,ListItem,ListItemText,Typography,Toolbar,Fab,Tooltip } from '@mui/material';
import { red } from '@mui/material/colors';
import { Menu,Lock,Group,Notifications,ArrowBack,Cloud,LocationCity,ArrowLeft,ArrowRight } from '@mui/icons-material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import '../stylesheet/SideNav.css';
import {createTheme} from '@material-ui/core/styles';
import DashBoardGatewayPage from './DashBoardGatewayPage';
import Appheader from './header/Appheader.js';
import Locks from './locks/locks.js';
import FreeTierLocks from './locks/FreeTierLocks.js';
import { useNavigate } from "react-router-dom";
// import LocksGrid from './locks/locks_grid_view';
import Alerts from './DashboardAlertsPage';
import Users from './DashboardUsersPage';
import Dashboard from './dashboard/Dashboard.js';
// import Dashboard1 from './dashboard1/Dashboard1.js';
import LockUsersPage from './DashboardLockUsersPage';
import CaribouLogo from '../cariboutechnologieslogo.png';
import {ProgressBar} from "react-fetch-progressbar";
import UserSessionStorage from '../userSession/UserSessionStorage';
import MqttEventHandler from './Mqtt/MqttEventHandler';
import PubSubChannels from '../pubsub/PubSubChannels';
import IotServiceClient from '../clients/IotServiceClient';
import AuthClient from '../clients/AuthClient';
import GatewayServiceClient from '../clients/GatewayServiceClient';
import frontendServiceClient from '../clients/FrontendClient';
import { indexOf } from 'lodash';

function SideNav(props) {

  let adminViews = [];
  if (JSON.parse(sessionStorage.userDetails).permissions.includes("PORTAL_USER_READ")) {
    adminViews.push("Users");
  }
  if (JSON.parse(sessionStorage.userDetails).permissions.includes("ALERTS_READ")) {
     adminViews.push("Alerts");
  }

  const checkIsFreeTier = () => {        
    if (!sessionStorage.sidenavAlreadySelectedItem){      
      if (sessionStorage.tenantName === "FreeTier"){
          return "Locks";
      }else {
          return "Dashboard";
      }
    }else {
      return "windowSelectedItem";
    }
  };

  const {windows} = props;
  const [selectedListItem, setSelectedListIem] = React.useState(!sessionStorage.sidenavAlreadySelectedItem ? checkIsFreeTier() : sessionStorage.sidenavAlreadySelectedItem);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [drawerWidth, setDrawerWidth] = React.useState(240);
  const [mqttFrontend, setMqttFrontend] = React.useState(false);
  const [mqttIOT, setMqttIOT] = React.useState(false);
  const [mqttGateway, setMqttGateway] = React.useState(false);
  const [mqttAuth, setMqttAuth] = React.useState(false);
  const [tenantName, setTenantName] = React.useState(sessionStorage.tenantName);
  const [menuItemIconColor, setMenuItemIconColor] = React.useState(
    checkIsFreeTier() === "Dashboard" ? 'DashboardSideNavItem' : (!sessionStorage.sidenavAlreadySelectedItem ? 'LocksSideNavItem' : (sessionStorage.sidenavAlreadySelectedItem === "Users" || sessionStorage.sidenavAlreadySelectedItem === "Alerts" ?  sessionStorage.sidenavAlreadySelectedItem + "AdminSideNavItem" : sessionStorage.sidenavAlreadySelectedItem  + "SideNavItem"))
  );
  const [menuItemTextColor, setMenuItemTextColor] = React.useState(
    checkIsFreeTier() === "Dashboard" ? 'DashboardSideNavItemText' : (!sessionStorage.sidenavAlreadySelectedItem ? 'LocksSideNavItemText' : (sessionStorage.sidenavAlreadySelectedItem === "Users" || sessionStorage.sidenavAlreadySelectedItem === "Alerts" ?  sessionStorage.sidenavAlreadySelectedItem + "AdminSideNavItemText" : sessionStorage.sidenavAlreadySelectedItem  + "SideNavItemText"))
  );
  const [menuItemLeftBorder, setMenuItemLeftBorder] = React.useState(
    checkIsFreeTier() === "Dashboard" ? 'DashboardSideNavItemDiv' : (!sessionStorage.sidenavAlreadySelectedItem ? "LocksSideNavItemDiv" : (sessionStorage.sidenavAlreadySelectedItem === "Users" || sessionStorage.sidenavAlreadySelectedItem === "Alerts" ?  sessionStorage.sidenavAlreadySelectedItem + "AdminSideNavItemDiv" : sessionStorage.sidenavAlreadySelectedItem  + "SideNavItemDiv"))
  );
  const [firstListBlock, setFirstListBlock] = React.useState([]);

  const [frontEndVersion, setFrontEndVersion] = React.useState("");

  const [iotVersion, setIotVersion] = React.useState("");

  const [gatewayVersion, setGatewayVersion] = React.useState("");

  const [authVersion, setAuthVersion] = React.useState("");

  const isConnected = (status) => {
    return status.toString() === "connected" ? true : false;
  }

  const updateMqttSTatus = () => {
    let status = UserSessionStorage.getMqttStatus();
    setMqttFrontend(status)
    if (!status){
      setMqttAuth(status);
      setMqttGateway(status);
      setMqttIOT(status);
    }
  }

  let checkMqttInterval = setInterval(() => {
     updateMqttSTatus();
  }, 2000);

  let gatewayServiceStatusEventChannel, iotServiceStatusEventChannel, authServiceStatusEventChannel = null;


  const gatewayServiceStatusEventHandler = (message) => {
    if(isConnected(message)){
      setMqttGateway(true)
    }else {
      setMqttGateway(false)
    }
  } 

  const iotServiceStatusEventHandler = (message) => {
    if(isConnected(message)){
      setMqttIOT(true)
    }else {
      setMqttIOT(false)
    }
  }

  const authServiceStatusEventHandler = (message) => {
    if(isConnected(message)){
      setMqttAuth(true)
    }else {
      setMqttAuth(false)
    }
  }

  const checkMqqttSTatusForFIGA = () => {
    gatewayServiceStatusEventChannel = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.GATEWAY_SERVICE_STATUS, "sideNav", gatewayServiceStatusEventHandler);
  
    iotServiceStatusEventChannel = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.IOT_SERVICE_STATUS, "sideNav" , iotServiceStatusEventHandler);
  
    authServiceStatusEventChannel = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.AUTH_SERVICE_STATUS, "sideNav", authServiceStatusEventHandler);
  }

  const getFigaVersions = () => {

    frontendServiceClient.getFrontendAppDetails().then((response)=>{      
      setFrontEndVersion(response.version);
    }).catch((error)=>{})
    
    IotServiceClient.getIotAppDetails("sideNav").then((response)=>{      
      setIotVersion(response.version);
    }).catch((error)=>{})

    AuthClient.getAuthAppDetails("sideNav").then((response)=>{      
      setAuthVersion(response.version);
    }).catch((error)=>{})

    GatewayServiceClient.getGatewayAppDetails("sideNav").then((response)=>{      
      setGatewayVersion(response.version);
    }).catch((error)=>{})
  }


  const handleHashChange = () => {
    window.addEventListener('popstate', (event) => {
      let hash = window.location.hash;
      if (sessionStorage.sidenavAlreadySelectedItem && hash !== "" && hash){
        let locationHash = "";

        if (hash.indexOf("list_view") !== -1 || hash.indexOf("grid_view") !== -1){
          locationHash = "Locks"
        }else if (hash.indexOf("/users") !== -1){
          locationHash = "Lock Users";
        }else {
          locationHash = hash;
        }

        if (locationHash.toLowerCase().indexOf(sessionStorage.sidenavAlreadySelectedItem.toLowerCase()) === -1){
          setTimeout(() => {
            if (window.location.hash.toLowerCase().indexOf("dashboard") !== -1) {
              sessionStorage.sidenavAlreadySelectedItem = "Dashboard";
              window.location.reload();
            }else if (window.location.hash.toLowerCase().indexOf("list_view") !== -1) {
              sessionStorage.lockView = "listView"
              sessionStorage.sidenavAlreadySelectedItem = "Locks";
              window.location.reload();
            }else if (window.location.hash.toLowerCase().indexOf("grid_view") !== -1) {
              sessionStorage.lockView = "gridView"
              sessionStorage.sidenavAlreadySelectedItem = "Locks";
              window.location.reload();
            }else if (window.location.hash.toLowerCase().indexOf("/users") !== -1) {
              sessionStorage.sidenavAlreadySelectedItem = "Lock Users";
              window.location.reload();
            }else if (window.location.hash.toLowerCase().indexOf("gateways") !== -1) {
              sessionStorage.sidenavAlreadySelectedItem = "Gateways";
              window.location.reload();
            }else if (window.location.hash.toLowerCase().indexOf("/portal_users") !== -1) {
              sessionStorage.sidenavAlreadySelectedItem = "Users";
              window.location.reload();
            }else if (window.location.hash.toLowerCase().indexOf("alerts") !== -1) {
              sessionStorage.sidenavAlreadySelectedItem = "Alerts";
              window.location.reload();
            }
          }, 1500);
        }
      }
    });
  }

  React.useEffect(() => {
    if (sessionStorage.tenantName !== 'FreeTier') {
      setFirstListBlock([
        'Dashboard',
        // 'Dashboard1',
        'Locks',
        // 'LocksGrid',
        'Lock Users',
        'Gateways',
      ]);
    } else if (sessionStorage.tenantName === 'FreeTier') {
      setFirstListBlock(['Locks', 'Lock Users']);
    }
    setTimeout(() => {
      setTenantName(sessionStorage.tenantName);
      changeUrlHash(sessionStorage.sidenavAlreadySelectedItem);
    }, 1500);

    handleHashChange();
    getFigaVersions();
    checkMqqttSTatusForFIGA();

    return () => {
      if (gatewayServiceStatusEventChannel){
        gatewayServiceStatusEventChannel.unsubscribe();
      }
      if (iotServiceStatusEventChannel) {
        iotServiceStatusEventChannel.unsubscribe();
      }
      if (authServiceStatusEventChannel) {
        authServiceStatusEventChannel.unsubscribe();
      }
      clearInterval(checkMqttInterval);
      IotServiceClient.abortSignal("sideNav");
    }
  }, []);

  const changeUrlHash = (listItem) => {
    if (listItem === "Dashboard"){
      window.location.hash = "/cli/dashboard" + "?scopeId="+ sessionStorage.tenantId;
    }else if (listItem === "Locks") {
      let urlHash = "/cli/devices/list_view" + "?scopeId="+ sessionStorage.tenantId;
      if (!sessionStorage.lockView || sessionStorage.lockView === "listView"){        
        window.location.hash = urlHash.replace("grid_view","list_view");
      }else {
        window.location.hash = urlHash.replace("list_view","grid_view");
      }
    }else if (listItem === "Lock Users"){
      window.location.hash = "/cli/users" + "?scopeId="+ sessionStorage.tenantId;
    }else if (listItem === "Gateways") {
      window.location.hash = "/cli/gateways" + "?scopeId="+ sessionStorage.tenantId;
    }else if (listItem === "Users") {
      window.location.hash = "/cli/portal_users" + "?scopeId="+ sessionStorage.tenantId;
    }else if (listItem === "Alerts") {
      window.location.hash = "/cli/alerts" + "?scopeId="+ sessionStorage.tenantId;
    }
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const changeDrawerWidth = (presentWidth) => {
    presentWidth === 240 ? setDrawerWidth(80) : setDrawerWidth(240);
  };

  const checkDrawerWidth = () => {
    let checkCondition;
    drawerWidth !== 240 ? (checkCondition = true) : (checkCondition = false);
    return checkCondition;
  };

  const changeSideNavTextColor = (id, listItem) => {
    setMenuItemIconColor(id);
    setMenuItemTextColor(id + 'Text');
    setMenuItemLeftBorder(id + 'Div');
    sessionStorage.sidenavAlreadySelectedItem = listItem;
    setSelectedListIem(listItem);
    changeUrlHash(listItem);
    handleDrawerToggle();
    if (listItem !== "Locks"){
      delete sessionStorage.navigatedFromDashboard
      delete sessionStorage.lockView;
    }
  };

  const drawer = (
    <div
      style={{
        height:
          checkDrawerWidth() === true
            ? `calc(100% - ${240}px)`
            : `calc(100% - ${84}px)`,
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <Toolbar />
      <Toolbar />
      <Toolbar className="LockManagerHeader">
        {checkDrawerWidth() === false ? (
          <Typography>LOCKS MANAGER</Typography>
        ) : (
          <Typography>IOT</Typography>
        )}
      </Toolbar>
      <List>
        {firstListBlock.map((text, index) => (
          <ListItem
            selected={text === selectedListItem}
            button
            key={text}
            className={text + 'SideNavItemDiv'}
            sx={{
              borderLeft:
                menuItemLeftBorder === text + 'SideNavItemDiv'
                  ? '6px solid #f39c12'
                  : '6px solid white',
              height: '48px'
            }}
            onClick={() => changeSideNavTextColor(text + 'SideNavItem', text)}
          >
            <ListItem
              className={text + 'SideNavItem'}
              sx={{
                color:
                  menuItemIconColor === text + 'SideNavItem'
                    ? '#f39c12'
                    : '#000000DE',
                    width:"56px",paddingLeft:"0px"
              }}
            >
              {/*{text === 'Dashboard' || text === 'Dashboard1' ? (*/}
                {text === 'Dashboard' ? (
                <DashboardIcon />
              ) : text === 'Locks' ? (
                <Lock />
              ) : text === 'Lock Users' ? (
                <Group/>
              ) : text === 'Gateways' ? (
                <Cloud />
              ) : null}
            </ListItem>
            <ListItemText
              sx={{
                visibility: checkDrawerWidth() === true ? 'hidden' : 'visible',
                color:
                  menuItemTextColor === text + 'SideNavItemText'
                    ? '#f39c12'
                    : '#000000DE'
              }}
              primary={text}
              className={text + 'SideNavItemText'}
            />
          </ListItem>
        ))}
      </List>
      <Divider />
      {sessionStorage.tenantName !== 'FreeTier' && adminViews.length > 0 ? (
        <>
          {checkDrawerWidth() === false ? (
            <Typography
              sx={{
                margin: 'auto',
                paddingLeft: '12px',
                marginTop: '6px',
                color: 'gray',
              }}
            >
              ADMINISTRATOR
            </Typography>
          ) : (
            <Typography
              sx={{
                margin: 'auto',
                paddingLeft: '10px',
                marginTop: '6px',
                color: 'gray',
              }}
            >
              ADMIN
            </Typography>
          )}
          <List>
            {
            adminViews.map((text, index) => (
              <ListItem
                selected={text === selectedListItem}
                button
                key={text}
                className={text + 'AdminSideNavItemDiv'}
                sx={{
                  borderLeft:
                    menuItemLeftBorder === text + 'AdminSideNavItemDiv'
                      ? '6px solid #f39c12'
                      : '6px solid white'
                }}
                onClick={() =>
                  changeSideNavTextColor(text + 'AdminSideNavItem', text)
                }
              >
                <ListItem
                  className={text + 'AdminSideNavItem'}
                  sx={{
                    color:
                      menuItemIconColor === text + 'AdminSideNavItem'
                        ? '#f39c12'
                        : '#000000DE',
                        width:"56px",paddingLeft:"0px",height:"10px"
                  }}
                >
                  {text === 'Users' ? (
                    <Group />
                  ) : text === 'Alerts' ? (
                    <Notifications />
                  ) : null}
                </ListItem>
                <ListItemText
                  sx={{
                    visibility:
                      checkDrawerWidth() === true ? 'hidden' : 'visible',
                    color:
                      menuItemTextColor === text + 'AdminSideNavItemText'
                        ? '#f39c12'
                        : 'black',
                  }}
                  primary={text}
                  className={text + 'AdminSideNavItemText'}
                />
              </ListItem>
            ))}
          </List>
        </>
      ) : null}
      <Box
        sx={{
          display: 'flex',
          flexDirection: checkDrawerWidth() === false ? 'row' : 'column',
          marginTop: '4px',
          alignItems: 'center',
          marginLeft: '4px',
          padding: '2px 14px 2px 14px',
          position: 'fixed',
          bottom: checkDrawerWidth() === true ? '40px' : '40px',
          background: '#fff',
          width: checkDrawerWidth() !== true ? '234px' : '74px',
          borderTop: '1px solid #ccc',
          borderBottom: '1px solid #ccc',
        }}
      >
        <Tooltip
          title={mqttFrontend ? 'Online' : 'Offline'}
          placement={checkDrawerWidth() === true ? 'right' : 'top'}
          arrow
        >
          <Box
            sx={{
              flex: '25',
              textAlign: 'center',
              borderRight:
                checkDrawerWidth() === false ? '1px solid #ccc' : null,
            }}
          >
            F<span className="versionNumber">{frontEndVersion}</span>
            <br />
            {mqttFrontend ? (
              <span className="greenDot"></span>
            ) : (
              <span className="redDot"></span>
            )}
          </Box>
        </Tooltip>
        <Tooltip
          title={mqttIOT ? 'Online' : 'Offline'}
          placement={checkDrawerWidth() === true ? 'right' : 'top'}
          arrow
        >
          <Box
            sx={{
              flex: '25',
              textAlign: 'center',
              borderRight:
                checkDrawerWidth() === false ? '1px solid #ccc' : null,
            }}
          >
            I<span className="versionNumber">{iotVersion}</span>
            <br />
            {mqttIOT ? (
              <span className="greenDot"></span>
            ) : (
              <span className="redDot"></span>
            )}
          </Box>
        </Tooltip>
        <Tooltip
          title={mqttGateway ? 'Online' : 'Offline'}
          placement={checkDrawerWidth() === true ? 'right' : 'top'}
          arrow
        >
          <Box
            sx={{
              flex: '25',
              textAlign: 'center',
              borderRight:
                checkDrawerWidth() === false ? '1px solid #ccc' : null,
            }}
          >
            G<span className="versionNumber">{gatewayVersion}</span>
            <br />
            {mqttGateway ? (
              <span className="greenDot"></span>
            ) : (
              <span className="redDot"></span>
            )}
          </Box>
        </Tooltip>
        <Tooltip
          title={mqttAuth ? 'Online' : 'Offline'}
          placement={checkDrawerWidth() === true ? 'right' : 'top'}
          arrow
        >
          <Box sx={{flex: '25', textAlign: 'center'}}>
            A<span className="versionNumber">{authVersion}</span>
            <br />
            {mqttAuth ? (
              <span className="greenDot"></span>
            ) : (
              <span className="redDot"></span>
            )}
          </Box>
        </Tooltip>
      </Box>
      <Box
        className="cursorPointer"
        sx={{background: '#fff', position: 'fixed', bottom: '0px'}}
        onClick={() => changeDrawerWidth(drawerWidth)}
      >
        {checkDrawerWidth() === false ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: checkDrawerWidth() === true ? '80px' : '240px',
              fontSize: '12px',
              color: 'gray',
            }}
          >
            <img
              src={CaribouLogo}
              alt=""
              style={{height: '26px', width: '26px', margin: '5px'}}
            />
            <div
              style={{display: 'flex', flexDirection: 'column', width: `176px`}}
            >
              <div>Powered by</div>
              <div>
                <b> Caribou Technologies </b>
              </div>
            </div>
            <ArrowLeft
              className="sideNavMiniIcon"
              sx={{width: '40px', margin: 'auto'}}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: checkDrawerWidth() === true ? '80px' : '240px',
              fontSize: '12px',
              color: 'gray',
            }}
          >
            <img
              src={CaribouLogo}
              alt=""
              style={{height: '26px', width: '26px', margin: '5px 10px'}}
            />
            <ArrowRight
              className="sideNavMiniIcon"
              sx={{
                width: checkDrawerWidth() === true ? '28px' : '240px',
                paddingLeft: checkDrawerWidth() === false ? '200px' : '',
                marginTop: checkDrawerWidth() === true ? "3px" : "",

              }}
            />
            </Box>
        )}
      </Box>
    </div>
  );

  const container =
    windows !== undefined ? () => windows().document.body : undefined;

  const fabRedStyle = {
    color: 'common.white',
    bgcolor: '#14138b',
    '&:hover': {
      bgcolor: '#14138b',
    },
  };

  const customBreakPointtheme = createTheme({
    breakpoints: {
      values: {
        mobile: 0,
        lg: 1179,
        desktop: 1280,
      },
    },
  });
    const nav = useNavigate();
  return (
    <>
      <Box sx={{display: 'flex', overflowX: 'hidden'}}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <Toolbar className="header_nav">
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              theme={customBreakPointtheme}
              sx={{
                mr: 2,
                display: {
                  xs: 'block',
                  desktop: 'none',
                  width: '35px',
                  marginRight: '-1px',
                },
                color:"#fff"
              }}
            >
              <Tooltip title="Top Navbar" placement="right" arrow>
                <Menu />
              </Tooltip>
            </IconButton>
            <ProgressBar style={{position: 'absolute',top: 0,left:0,zIndex: 5000}}/>
            <Appheader parent="SideNav" />
          </Toolbar>
          <Box
            sx={{
              height: '56px',
              background: 'white',
              color: 'black',
              display: 'flex',
              flex: '100',
            }}
          >
              {
                     sessionStorage.isRootUser == 'true' ?
                  <span
                      style={{margin: '10px', marginLeft: '18px', marginRight: '12px'}}
                  >

              <a onClick={() => nav("/admin/tenants")}>
                <Tooltip title="Back to Admin" arrow>
                  <Fab
                      className="fabArrowIcon"
                      color="inherit"
                      size="small"
                      aria-label="add"
                      sx={{...fabRedStyle, cursor: 'pointer'}}
                  >
                    <ArrowBack/>
                  </Fab>
                </Tooltip>
              </a>
            </span>
             : <span
                          style={{margin: '10px', marginLeft: '18px', marginRight: '12px'}}
                      ></span> }
            <span style={{margin: '10px', fontSize: '28px'}}>
              <LocationCity />
            </span>
            <span
              style={{
                fontSIze: '18px',
                fontWeight: '500',
                margin: '14px',
                marginTop: '16px',
              }}
            >
              {tenantName}
            </span>
          </Box>
        </AppBar>
        <Box
          component="nav"
          sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
          aria-label="mailbox folders"
          className="sideNavigation"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              [`& .MuiDrawer-paper`]: {
                boxSizing: 'border-box',
                width: drawerWidth,
              },
            }}
            className="smallDevicesDrawer"
          >
            {mobileOpen ? drawer : null}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              [`& .MuiDrawer-paper`]: {
                boxSizing: 'border-box',
                width: drawerWidth,
              },
            }}
            className="largeDevicesDrawer"
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          theme={customBreakPointtheme}
          sx={{
            flexGrow: 1,
            p: 3,
            width: {
              mobile: '100%',
              desktop: `calc(100% - ${drawerWidth}px)`,
              padding: '0px',
            },
          }}
        >
          <Toolbar />

          {selectedListItem === 'Gateways' ? (
            <div style={{background: '#fff', margin: '10px'}}>
              <DashBoardGatewayPage />
            </div>
          ) : selectedListItem === 'Locks' ? (
            <div style={{background: '#fff', margin: '10px'}}>
              {
                sessionStorage.tenantName !== "FreeTier" ?
                    <Locks />
                :
                    <FreeTierLocks />
              }
            </div>
          // ) : selectedListItem === 'LocksGrid' ? (
          //   <div style={{background: '#fff', margin: '10px'}}>
          //     <LocksGrid />
          //   </div>
          ) : selectedListItem === 'Users' ? (
            <div style={{background: '#fff', margin: '10px'}}>
              <Users />
            </div>
          ) : selectedListItem === 'Alerts' ? (
            <div style={{background: '#fff', margin: '10px'}}>
              <Alerts />
            </div>
          ) : selectedListItem === 'Dashboard' ? (
            <div style={{margin: '10px'}}>
              <Dashboard changePage={()=> {
                 window.clickEvent=0
                 changeSideNavTextColor("LocksSideNavItem", "Locks")
              }}
               />
            </div>
          // ) : selectedListItem === 'Dashboard1' ? (
          //   <div style={{background: '#fff', margin: '10px'}}>
          //     <Dashboard1 />
          //   </div>
          // )
          ): selectedListItem === 'Lock Users' ? (
            <div style={{background: '#fff', margin: '10px'}}>
              <LockUsersPage />
            </div>
          ) : null}
        </Box>
      </Box>
    </>
  );
}

SideNav.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  windows: PropTypes.func,
};

export default SideNav;
