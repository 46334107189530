import React from 'react';
import {Box} from '@material-ui/core';
import { FirstPage,KeyboardArrowLeft,KeyboardArrowRight,LastPage,ArrowBack,Wifi,SignalWifiOff } from '@mui/icons-material';
import { TextField, Tooltip,Tab,Tabs,Table,TableBody, TableCell,TableHead, Button,TableContainer,TablePagination,TableRow,TableSortLabel,Select,MenuItem,ListItem,ListItemButton,ListItemText } from '@mui/material';
import '../stylesheet/common.css';
import { TabContext,TabPanel } from '@mui/lab';
import '../stylesheet/DashboardGatewayPage.css';
import {visuallyHidden} from '@mui/utils';
import paginationStyle from "../stylesheet/pagination.module.css";
import { useNavigate } from "react-router-dom";
import AuthClient from "../clients/AuthClient";
import IotServiceClient from "../clients/IotServiceClient";
import GatewayServiceClient from "../clients/GatewayServiceClient";
import _ from 'lodash';
import { FixedSizeList, ListChildComponentProps } from "react-window";




function TablePaginationActions(props) {
  const {count, page, rowsPerPage, onPageChange} = props;

  function handleFirstPageButtonClick(event) {
    onPageChange(event, 0);
  }

  function handleBackButtonClick(event) {
    onPageChange(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onPageChange(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={paginationStyle.paginationActionIcons}>
      <FirstPage
        onClick={() => {
          if (page > 0) handleFirstPageButtonClick();
        }}
        sx={{
          color: page === 0 ? 'rgba(0,0,0,0.26)' : 'black',
          cursor: page === 0 ? 'auto' : 'pointer',
          marginRight: '8px'
        }}
      />
      <KeyboardArrowLeft
        onClick={() => {
          if (page > 0) handleBackButtonClick();
        }}
        sx={{
          color: page === 0 ? 'rgba(0,0,0,0.26)' : 'black',
          cursor: page === 0 ? 'auto' : 'pointer',
          marginRight: '8px'
        }}
      />
      <KeyboardArrowRight
        onClick={() => {
          if (page !== Math.ceil(count / rowsPerPage) - 1)
            handleNextButtonClick();
        }}
        sx={{
          color:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0
              ? 'black'
              : 'rgba(0,0,0,0.26)',
          cursor:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0 ? 'pointer' : 'auto',
            marginRight: '8px'
        }}
      />
      <LastPage
        onClick={() => {
          if (page !== Math.ceil(count / rowsPerPage) - 1)
            handleLastPageButtonClick();
        }}
        sx={{
          color:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0
              ? 'black'
              : 'rgba(0,0,0,0.26)',
          cursor:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0 ? 'pointer' : 'auto',
        }}
      />
    </div>
  );
}

function EnhancedTableHead(props) {
  const {order, orderBy, onRequestSort} = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: 'tenantName',
      label: 'Tenant Name',
    },
    {
      id: props.tab === 'locks' || props.tab === 'gateways' ? 'name' : 'email',
      label:
        props.tab === 'locks' || props.tab === 'gateways' ? 'Name' : 'Email',
    },
    {
      id:
        props.tab === 'locks'
          ? 'macId'
          : props.tab === 'lockUsers'
          ? 'mergedName'
          : 'osVersion',
      label:
        props.tab === 'locks'
          ? 'Mac ID'
          : props.tab === 'lockUsers'
          ? 'Lock User'
          : 'Os Version',
    },
    {
      id:
        props.tab === 'locks'
          ? 'serialNumber'
          : props.tab === 'lockUsers'
          ? 'employeeId'
          : 'appVersion',
      label:
        props.tab === 'locks'
          ? 'Serial Number'
          : props.tab === 'lockUsers'
          ? 'Employee ID'
          : 'App Version',
    },
    {
      id:
        props.tab === 'locks'
          ? 'type'
          : props.tab === 'lockUsers'
          ? 'mobileNo'
          : 'status',
      label:
        props.tab === 'locks'
          ? 'Lock Type'
          : props.tab === 'lockUsers'
          ? 'Mobile No'
          : 'Status',
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="left">
            <Box
              sx={{
                width:
                  headCell.label === 'Edit' || headCell.label === 'Locks'
                    ? '90px'
                    : '150px',
                maxWidth:
                  headCell.label === 'Edit' || headCell.label === 'Locks'
                    ? '90px'
                    : '150px',
                overflowWrap: 'anywhere',
              }}
            >
              <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                  sx={{
                    width: headCell.width,
                    fontSize: '13px !important',
                    overflowWrap: 'break-word',
                    color: orderBy !== headCell.id ? '#0000008A !important' : '#000000DE !important',
                  }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                    </Box>
                ) : null}
              </TableSortLabel>
              {/*{headCell.label}*/}
              {/*{orderBy === headCell.id ? (*/}
              {/*    <Box component="span" sx={visuallyHidden}>*/}
              {/*      {order === 'desc'*/}
              {/*          ? 'sorted descending'*/}
              {/*          : 'sorted ascending'}*/}
              {/*    </Box>*/}
              {/*) : null}*/}
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function GlobalDashboardPage() {
  //height and width logic

  const getWindowDimensions = () => {
    const {innerWidth: width, innerHeight: height} = window;
    return {
      width,
      height,
    };
  };

  const [Dimensions, setDimensions] = React.useState(getWindowDimensions());
  const [searchText, setSearchText] = React.useState('');
  const [tab, setTab] = React.useState('locks');
  const [rowsPerPage, setRowsPerPage] = React.useState(sessionStorage.globalDashboardRPP ? Number(sessionStorage.globalDashboardRPP) : 10);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('tenantName');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [dense, setDense] = React.useState(false);
  const [allLocks, setAllLocks] = React.useState([]);
  const [allLockUsers, setAllLockUsers] = React.useState([]);
  const [allGateways, setAllGateways] = React.useState([]);
  const [scopeWithName, setScopeWithName] = React.useState({});
  const [selectValue, setSelectValue] = React.useState(0);
  const [showDropDown, setShowDropDown] = React.useState(false);
  let globalSearchListData = [];
  let tenantIdWithNames = {};
  let hierarchyresponseReceived = false;
  let lockResponseReceived = false;
  let lockResponseData = [];
  let lockUsersResponseData = [];
  let gatewayResponseData = [];


  const mergeTenantName = (data) => {
    globalSearchListData = [];    
    setRows([]);
    _.forEach(data, (value) => {
      value.tenantName = Object.keys(scopeWithName).length > 0 ? scopeWithName[value.scopeId] : tenantIdWithNames[value.scopeId];      
      if (window.tabName === "lockUsers"){
        value.mergedName = value.firstName ? value.lastName ? value.firstName + ' ' + value.lastName : value.firstName : "No Name";
      }      
    })
    setRows(data);
    setPage(0);
    setPageNumber(0);
    setSelectValue(0);
    window.globalSearchListData = data;
  }

  const fetchData = () => {
    AuthClient.getHirarchy().then((res) => {
      tenantIdWithNames[res.scope.id] = res.scope.name;
      _.forEach(res.children,(value)=>{
        tenantIdWithNames[value.scope.id] = value.scope.name;
      })
      setScopeWithName(tenantIdWithNames);
      hierarchyresponseReceived = true;
    })

    IotServiceClient.getAllLocks("globalDashboardPage").then((res) => {
      setAllLocks(res);
      lockResponseReceived = true;
      lockResponseData = res;      
    })

    IotServiceClient.getAllLockUsers("globalDashboardPage").then((res) => {
      lockUsersResponseData = res;
      setAllLockUsers(res);
      if (window.tabName === "lockUsers")
        mergeTenantName(res);
    })

    GatewayServiceClient.getAllGateways("globalDashboardPage").then((res) => {
      gatewayResponseData = res;
      setAllGateways(res);
      if (window.tabName === "gateways")
        mergeTenantName(res);
    })

    window.globalSearchCombineData = setInterval(() => {
      if (lockResponseReceived && hierarchyresponseReceived && lockResponseData.length > 0) {
        if (window.tabName === "locks"){
          mergeTenantName(lockResponseData);
        }
        lockResponseReceived = false;
        hierarchyresponseReceived = false;
        clearInterval(window.globalSearchCombineData);
      }
    }, 400);
  }

  function handleResize() {
    setDimensions(getWindowDimensions());
  }

  React.useEffect(() => {
    window.tabName = "locks";
    window.oldPreservedGlobalDashboardPageNumber = 0;
    fetchData();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      if (window.globalSearchCombineData) {
        clearInterval(window.globalSearchCombineData);
        delete window.globalSearchCombineData;
      }
      IotServiceClient.abortSignal("globalDashboardPage");
      GatewayServiceClient.abortSignal("globalDashboardPage");
    }
  }, []);

  const modifyListData = (tabName) => {
    window.tabName = tabName;
    setSearchText(null);    
    let textField = document.getElementById("searchTextField");
    if (textField){
      textField.value = "";
    }
    if (tabName === 'locks') {
      if (allLocks.length > 0){
        mergeTenantName(allLocks);
      }      
    } else if (tabName === 'lockUsers') {
      if (allLockUsers.length > 0){
        mergeTenantName(allLockUsers);
      }
    } else if (tabName === 'gateways') {
      if (allGateways.length > 0){
        mergeTenantName(allGateways);
      }
    }    
  };

  const handleTabChange = (event, newValue) => {
    if (typeof newValue !== 'undefined') {
      modifyListData(newValue);
      setTab(newValue);
    } else {
      modifyListData('locks');
      setTab('locks');
    }
  };

  function numberCharacterOrganizer(array, orderOfsort, orderByOfSort){
    let numbers = [], characters = [];
    _.forEach(array, (element) => {
        if(typeof(element[orderByOfSort]) === 'string'  ? Number(element[orderByOfSort] ? element[orderByOfSort].replaceAll(" ","") : element[orderByOfSort]) : Number(element[orderByOfSort])){
          numbers.push(element);
        }
        else{
          orderByOfSort === 'email' && element[orderByOfSort]!== null && Number(element[orderByOfSort].replaceAll(" ","").substring(0,element[orderByOfSort].indexOf('@'))) ? numbers.push(element) : characters.push(element);
        }
    })
    array = orderOfsort === 'asc' ? characters.concat(numbers): numbers.concat(characters);
    return array;
  }

  function stableSort(array, orderOfsort, orderByOfSort){
    array = numberCharacterOrganizer(array, orderOfsort, orderByOfSort);
    if(orderByOfSort !== 'email' && orderByOfSort !== 'osVersion'){
        array = _.orderBy(array, [obj => obj[orderByOfSort] !== null ? typeof(obj[orderByOfSort]) === 'string' ? !Number(_.get(obj, orderByOfSort, '').replaceAll(" ","")) ? _.get(obj, orderByOfSort, '').toString().toLowerCase() : Number(_.get(obj, orderByOfSort).replaceAll(" ","")) : Number(_.get(obj, orderByOfSort)) : ""], [orderOfsort]);
    }else if(orderByOfSort === 'osVersion'){
        array = _.orderBy(array, [obj => obj[orderByOfSort] !== null ? _.get(obj, orderByOfSort,'').indexOf('.') !== -1 ? Number(_.get(obj, orderByOfSort,'').substring(0,obj[orderByOfSort].indexOf('.'))) : Number(_.get(obj, orderByOfSort,'')) : ''], orderOfsort)
    }
    else{
        array = _.orderBy(array, [obj => obj[orderByOfSort] !== null ? typeof(obj[orderByOfSort]) === 'string' ? !Number(_.get(obj, orderByOfSort, '').replaceAll(" ","").substring(0,obj[orderByOfSort].indexOf('@'))) ? _.get(obj, orderByOfSort, '').toString().toLowerCase() : Number(_.get(obj, orderByOfSort).replaceAll(" ","").substring(0,obj[orderByOfSort].indexOf('@'))) : Number(_.get(obj, orderByOfSort,'')) : ""], [orderOfsort]);
    }
    return array;
  }

  function stableOldSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPageNumber(newPage);
    setSelectValue(newPage);
    window.oldPreservedGlobalDashboardPageNumber = newPage;
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [pageNumber, setPageNumber] = React.useState(0);

  const handleChange = (event) => {
    setPage(event.target.value);
    setPageNumber(event.target.value);
    setSelectValue(event.target.value);
    window.oldPreservedGlobalDashboardPageNumber = event.target.value;
  };

  const handleRowPerPage = (event) => {
    let rowPerPageValue = event.target.value;    
    if (rows.length > 0) {
      let pageNumberCount = Math.ceil((rows.length/rowPerPageValue)) - 1;                
      if (window.oldPreservedGlobalDashboardPageNumber > pageNumberCount) {
        setPage(pageNumberCount);
        setPageNumber(pageNumberCount);        
        setSelectValue(pageNumberCount);
        window.oldPreservedGlobalDashboardPageNumber = pageNumberCount;
      }else {
        setPage(window.oldPreservedGlobalDashboardPageNumber);
        setPageNumber(window.oldPreservedGlobalDashboardPageNumber);
        setSelectValue(window.oldPreservedGlobalDashboardPageNumber);
      }
    }
    sessionStorage.globalDashboardRPP = rowPerPageValue;
    setRowsPerPage(rowPerPageValue);
  };

  function gotoAdminPage(tab, device) {
    sessionStorage.tenantName = scopeWithName[device.scopeId];
    if (tab === "locks") {
      sessionStorage.sidenavAlreadySelectedItem = "Locks";
      sessionStorage.navigatedValueFromGlobalSearch = "gs";
      sessionStorage.navigatedLockName = device.name;
      sessionStorage.gsMacId = device.macId;
      sessionStorage.tenantId = device.scopeId;
      window.location.hash = "/cli/devices/list_view?scopeId="+ device.scopeId;
    } else if (tab === "lockUsers") {
      sessionStorage.sidenavAlreadySelectedItem = "Lock Users";
      sessionStorage.navigatedValueOfLUFromGlobalSearch = "gsLU";
      sessionStorage.gsLockUsersEmail = device.email;
      sessionStorage.tenantId = device.scopeId;
      window.location.hash = "/cli/users?scopeId="+ device.scopeId;
    } else if(tab === "gateways") {
      sessionStorage.sidenavAlreadySelectedItem = "Gateways";
      sessionStorage.navigatedValueOfGtFromGlobalSearch = "gsG";
      sessionStorage.gatewayMacId = device.macId;
      sessionStorage.navigatedGatewayName = device.name;
      sessionStorage.tenantId = device.scopeId;
      window.location.hash = "/cli/gateways?scopeId="+ device.scopeId;
    }
  }  

  function renderRow(props) {
    const { index, style } = props;

    return (
      <ListItem
        style={style}
        key={index}
        component="div"
        disablePadding
        onClick={() => {
          setSelectValue(index);          
          setPage(index);
          setPageNumber(index);
          setShowDropDown(false);
        }}
      >
        <ListItemButton>
          <ListItemText primary={index + 1} />
        </ListItemButton>
      </ListItem>
    );
  }

  const rowPerPageLabel = (    
    <div className={paginationStyle.rowPerPageLabelDiv}>
      <Box className={paginationStyle.page}>
        <span style={{marginRight: '32px'}}>Page</span>
      </Box> 
      <Box className={paginationStyle.pageSelectBox} sx={{position:"relative !important"}}>
        <Select value={selectValue} onChange={handleChange} sx={{
          width: selectValue ? selectValue.toString().length <=2 ? "60px" : selectValue.toString().length > 4 ? "80px" : "70px" : "60px"
        }}
        open={showDropDown}
        onClick={()=>{
          setShowDropDown(!showDropDown)
        }}
        >
          {            
            // eslint-disable-next-line array-callback-return
            Math.ceil(rows.length / rowsPerPage) > 0 ?
              <FixedSizeList
                height={100}
                width={100}
                itemSize={46}
                itemCount={Math.ceil(rows.length / rowsPerPage)}
                overscanCount={5}
              >
                {renderRow}
              </FixedSizeList>
            :
              <MenuItem value={0} sx={{minWidth: '64px'}}>
                1
              </MenuItem>
          }
        </Select>
        {
          Math.ceil(rows.length / rowsPerPage) > 0 ?
            <Box sx={{ position: "absolute", right: "33px", top: "4px" }}>
              {selectValue + 1}
            </Box>
          :
            null
        }        
        </Box>
      <Box className={paginationStyle.rowPerPageLabelDiv}>
        <span style={{marginRight: '32px'}}>Rows Per Page</span>
      </Box>
      <Box className={paginationStyle.pageSelectBox}>
        <Select value={rowsPerPage} onChange={handleRowPerPage}>
          <MenuItem id={10} value={10}>
            10
          </MenuItem>
          <MenuItem id={25} value={25}>
            25
          </MenuItem>
          <MenuItem id={50} value={50}>
            50
          </MenuItem>
          <MenuItem id={100} value={100}>
            100
          </MenuItem>
        </Select>
      </Box>
    </div>
  );

  const nav = useNavigate();


  const checkSearchTextExist = (data, key,  text) => {
    let value = data[key];
    return value !== null && typeof value !== 'undefined' && value !== "" ? key === "macId" ? value.toString().replace(/:/g,"").toLowerCase().replaceAll(" ","").indexOf(text) !== -1 || value.toString().toLowerCase().replaceAll(" ","").indexOf(text) !== -1 : value.toString().toLowerCase().replaceAll(" ","").indexOf(text) !== -1 : false;
  };

  const executeSearchLogic = (event) => {
    let searchText = event.target.value;
    let sortedDevices = [];
    setSearchText(event.target.value);
    if (searchText !== "" && searchText !== undefined && searchText !==  null){
        if ( searchText.length < 3) {
         return;
        }
      searchText = searchText.toLowerCase().replaceAll(" ","");
      _.forEach(window.globalSearchListData,(value)=>{
        if (checkSearchTextExist(value, "tenantName", searchText) || checkSearchTextExist(value, "email", searchText) ||
            checkSearchTextExist(value, "mergedName", searchText) || checkSearchTextExist(value, "employeeId", searchText) || checkSearchTextExist(value, "mobileNo", searchText)||
            checkSearchTextExist(value, "name", searchText) || checkSearchTextExist(value, "osVersion", searchText) || checkSearchTextExist(value, "appVersion", searchText) ||
            checkSearchTextExist(value, "macId", searchText) || checkSearchTextExist(value, "serialNumber", searchText) || checkSearchTextExist(value, "type", searchText)){
          sortedDevices.push(value);
        }
      })
      sortedDevices = _.uniq(sortedDevices);
      setRows(sortedDevices);
    }else {
      setRows(window.globalSearchListData);
    }
    resetPaginationData(sortedDevices)
  }

  const resetPaginationData = (data) => {
    if (window.oldPreservedGlobalDashboardPageNumber) {
      if (data.length > 0) {
        let pageNumberCount = Math.ceil((data.length/rowsPerPage)) - 1;                
        if (window.oldPreservedGlobalDashboardPageNumber > pageNumberCount) {
          setPage(pageNumberCount);
          setPageNumber(pageNumberCount);
          setSelectValue(pageNumberCount);
          window.oldPreservedGlobalDashboardPageNumber = pageNumberCount;
        }
      }else {
        let pageNumberCount = Math.ceil((window.globalSearchListData.length/rowsPerPage)) - 1;   
        if (window.oldPreservedGlobalDashboardPageNumber > pageNumberCount){
          setPage(pageNumberCount);
          setPageNumber(pageNumberCount);
          setSelectValue(pageNumberCount);
          window.oldPreservedGlobalDashboardPageNumber = pageNumberCount;
        }else if (window.oldPreservedGlobalDashboardPageNumber <= pageNumberCount && pageNumberCount > 0) {
          setPage(window.oldPreservedGlobalDashboardPageNumber);
          setPageNumber(window.oldPreservedGlobalDashboardPageNumber);        
          setSelectValue(window.oldPreservedGlobalDashboardPageNumber);
        }else {
          setPage(0);
          setPageNumber(0);
          setSelectValue(0);
          window.oldPreservedGlobalDashboardPageNumber = 0;
        }        
      }
    }else {
      setPageNumber(0);
      setPage(0);  
      setSelectValue(0); 
      window.oldPreservedGlobalDashboardPageNumber = 0;
    }
  }
  
  const handleTextCange = (event) => {
    if (window.globalSearchLogic){
      clearInterval(window.globalSearchLogic);
    }
    setTimeout(() => {
      executeSearchLogic(event)
    }, 2500);
  };

  return (
    <Box sx={{display: 'flex', flexDirection: 'column'}}>
      <Box sx={{display: 'flex', height: '56px'}}>
        <ArrowBack
          sx={{padding: '14px', cursor: 'pointer'}}
          // onClick={() => (window.location.href = '/admin/tenants')}
          onClick={() => (nav("/admin/tenants"))}
        />
      </Box>
      <Box sx={{width: '90%', margin: '0px auto', paddingBottom: '10%'}}>
        <Box
          sx={{
            height: '64px',
            fontSize: '20px',
            background: '#C2185B',
            paddingTop: 'auto',
          }}
        >
          <Box className="globalSearchTitleSubBlock" sx={{height: '15px'}}>
            &nbsp;
          </Box>
          <Box id="globalSearchTitleBlock">
            <Box sx={{marginTop: 'auto', marginBottom: 'auto', color: 'white'}}>
              Global Search
            </Box>
            <TextField
              id="searchTextField"
              onChange={handleTextCange}
              variant="standard"
              type="search"
              placeholder="Type minimum 3 charecters Search"
              autoFocus
              sx={{
                background: '#fff',
                color: '#000',
                marginLeft: 'auto',
                textIndent: '4px',
                '& .MuiInput-input': {textIndent: '6px', marginRight: '4px'},
                '& .MuiInput-underline:after': {
                  borderBottomColor: 'transparent',
                },
              }}
            />
          </Box>
        </Box>
        <Box
          className="globalSearchContent"
          sx={{width: '100%', typography: 'body1', background: '#fff'}}
        >
          <TabContext value={tab}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
              <Tabs
                value={tab}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
              >
                <Tab
                  label="Locks"
                  value="locks"
                  sx={{color: '#C2185B', '&.Mui-selected': {color: '#C2185B'}}}
                />
                <Tab
                  label="Lock Users"
                  value="lockUsers"
                  sx={{color: '#C2185B', '&.Mui-selected': {color: '#C2185B'}}}
                />
                <Tab
                  label="Gateways"
                  value="gateways"
                  sx={{color: '#C2185B', '&.Mui-selected': {color: '#C2185B'}}}
                />
              </Tabs>
            </Box>
            <TabPanel value={tab}>
              <Box style={{width: '100%', height: 'auto'}}>
                <TableContainer>
                  <Table>
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={rows.length}
                      tab={tab}
                    />
                    <TableBody sx={{background: '#fff'}}>
                      {stableSort(rows, order, orderBy)
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        // eslint-disable-next-line array-callback-return
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.name);
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              // onClick={callbackDemo}
                              onClick={(event) => gotoAdminPage(tab, row)}
                            >
                              <TableCell sx={{overflowWrap: 'anywhere'}}>
                                {row.tenantName}
                              </TableCell>
                              <TableCell sx={{overflowWrap: 'anywhere'}}>
                                {tab === 'locks'
                                  ? row.name
                                  : tab === 'lockUsers'
                                  ? row.email
                                  : row.name}
                              </TableCell>
                              <TableCell sx={{overflowWrap: 'anywhere'}}>
                                {tab === 'locks'
                                  ? row.macId
                                  : tab === 'lockUsers'
                                  ? row.firstName
                                    ? row.lastName
                                      ? row.firstName + ' ' + row.lastName
                                      : row.firstName
                                    : row.lastName
                                    ? row.lastName
                                    : 'No Name'
                                  : row.osVersion}
                              </TableCell>
                              <TableCell sx={{overflowWrap: 'anywhere'}}>
                                {tab === 'locks'
                                  ? row.serialNumber
                                  : tab === 'lockUsers'
                                  ? row.employeeId === '' ||
                                    row.employeeId === null
                                    ? '-'
                                    : row.employeeId
                                  : row.appVersion}
                              </TableCell>
                              <TableCell sx={{overflowWrap: 'anywhere'}}>
                                {tab === 'locks' ? (
                                  row.type
                                ) : tab === 'lockUsers' ? (
                                  row.mobileNo === '' ||
                                  row.mobileNo === null ? (
                                    '-'
                                  ) : (
                                    row.mobileNo
                                  )
                                ) : row.status === 'CONNECTED' ? (
                                  <Tooltip
                                    title="CONNECTED"
                                    placement="top"
                                    arrow
                                  >
                                    <Wifi
                                      style={{
                                        color: '#2196f3',
                                        cursor: 'pointer',
                                      }}
                                    />
                                  </Tooltip>
                                ) : (
                                  <span>
                                    <Tooltip
                                      title="INACTIVE"
                                      placement="top"
                                      arrow
                                    >
                                      <SignalWifiOff
                                        style={{cursor: 'pointer'}}
                                      />
                                    </Tooltip>
                                  </span>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  sx={{width: '100%', overflowX: 'hidden'}}
                  labelRowsPerPage={rowPerPageLabel}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  className="pagination"
                  page={pageNumber}
                  SelectProps={{
                    inputProps: {'aria-label': 'rows per page'},
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </Box>
  );
}
