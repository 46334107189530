import * as React from 'react';
import { Box,Dialog,DialogTitle,DialogActions,DialogContent,Radio,RadioGroup,Table,useMediaQuery,useTheme,TableBody,TableCell,TableContainer,TableHead,TablePagination,TableRow,TableSortLabel,Checkbox,LinearProgress,Tooltip,Select,MenuItem } from '@mui/material';
import { Close,CheckCircle,AlarmAdd,ArrowDropDown,ReportProblem,People,Lock,LockOpen,Check,FirstPage,LastPage,KeyboardArrowLeft,KeyboardArrowRight,Search,Refresh,Download,HelpOutline } from '@mui/icons-material';
import { AdapterDateFns,LocalizationProvider,DateTimePicker } from '@mui/x-date-pickers';
import {visuallyHidden} from '@mui/utils';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import styles from '../../stylesheet/users.module.css';
import paginationStyle from '../../stylesheet/pagination.module.css';
import IotServiceClient from "../../clients/IotServiceClient";
import GatewayServiceClient from "../../clients/GatewayServiceClient";
import HistoryDialog from './HistoryDialog';
import "../../stylesheet/common.css";
import _ from "lodash";
import { Button, CircularProgress, DialogContentText, FormControl, Paper, Popover, requirePropFactory, TextField } from '@mui/material';
import moment from 'moment';
import Enums from '../../constants/Enums';
import { useSnackbar } from 'notistack';
import MqttEventHandler from '../Mqtt/MqttEventHandler';
import PubSubChannels from '../../pubsub/PubSubChannels';
import Draggable from 'react-draggable';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';


function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}



export default function ShowRemoveUserLoadingDialog(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));    
    const getWindowDimensions = () => {
        const {innerWidth: width, innerHeight: height} = window;
        return {
        width,
        height,
        };
    };

    const [Dimensions, setDimensions] = React.useState(getWindowDimensions());

    const [loader, setLoader] = React.useState(props.closeLoader);

    React.useEffect(()=>{
        setTimeout(() => {        
           setLoader(true)
           props.changeLoader();
        }, props.instuctionsData * 60 * 1000);
        return () => {
            props.closeDialog();
            props.closeAndAssignDefaultValue();
            window.failedUserAdditionOrDeletionUsers = "";
        }
    },[])

    return (   
        <Dialog
            fullWidth={true}
            fullScreen={fullScreen}
            open={props.openDialog}    
            onClose={()=> props.closeDialog()}                            
            PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
            aria-labelledby="draggable-dialog-title"
            >
            <DialogTitle style={{
                background: 'rgb(20,19,139)',
                display: 'flex',
                flexDirection: 'row',
                color: 'white',
            }}>
                <Box sx={{flex: '95'}}>Removing Users From {window.openedDialogData.name}</Box>
                <Box sx={{flex: '5', marginTop: '6px'}}>
                    <Close onClick={() => props.closeDialog()} />
                </Box>
            </DialogTitle>
            <DialogContent sx={{fontSize:"18px !important"}}>
                {
                    !props.closeLoader || (props.completedData + props.failedData < props.instuctionsData) ?
                        <Box sx={{display:"flex",flexDirection:"row",width:"100%",height:"80px",alignItems:"center",justifyContent:"center"}}>
                            <Box sx={{position:"relative",top:"10px"}}>
                                <CircularProgress thickness="4" sx={{height:'40px !important',width: '40px !important',color: 'rgb(20,19,139) !important'}}/>
                            </Box>
                        </Box>
                    :
                        null
                }
                <Box sx={{display: props.completedData + props.failedData < props.instuctionsData || props.completedData > 0 ? "flex" : "none",flexDirection:"row",width:"100%",height:"30px",alignItems: props.completedData + props.failedData < props.instuctionsData || props.failedData === 0 ?"center" : "left",justifyContent: props.completedData + props.failedData < props.instuctionsData || props.failedData === 0 ? "center" : "left", marginTop: props.closeLoader ? "40px" : "0px", marginBottom: '18px'}}>
                    {
                        props.completedData + props.failedData < props.instuctionsData ? 
                            props.instuctionsData > 1 ? 
                                "Removing " + (props.completedData + props.failedData + 1) + " /  " + props.instuctionsData + " Users" 
                            : 
                                "Removing " + (props.completedData + props.failedData + 1) + " /  " + props.instuctionsData + " User" 
                        : 
                            props.completedData > 0 ?
                                props.instuctionsData > 0 && props.instuctionsData === 1 ?
                                    <>
                                        <span style={{color:"green",marginRight:'5px'}}>Success! </span> {props.completedData + " /  " + props.instuctionsData + " User" }
                                    </>
                                :   
                                    <>
                                        <span style={{color:"green",marginRight:'5px'}}>Success! </span> {props.completedData + " /  " + props.instuctionsData + " Users"}
                                    </>
                            :
                                null
                    }
                </Box>
                {
                    props.failedData > 0 && props.completedData + props.failedData === props.instuctionsData ?                
                        <Box sx={{display:"flex",marginTop: props.completedData + props.failedData < props.instuctionsData || props.completedData > 0 ? "0px" :"40px",flexDirection:"row",width:"100%",height:"30px",alignItems:"center",justifyContent:"center"}}>
                            <span> <span style={{color:"red",marginRight:'5px'}}>Failed </span>
                            ( 
                                { _.keysIn(_.countBy(_.split(window.failedUserAdditionOrDeletionUsers, '\,'))).length <=2 ? 
                                        props.addOrRemoveUserName 
                                    : 
                                        _.keysIn(_.countBy(_.split(window.failedUserAdditionOrDeletionUsers, ',')))[0] +", "+ _.keysIn(_.countBy(_.split(window.failedUserAdditionOrDeletionUsers, ',')))[1] + ", " }  
                                    { 
                                            _.keysIn(_.countBy(_.split(window.failedUserAdditionOrDeletionUsers, ','))).length > 2 ? 
                                                <Tooltip 
                                                    title={
                                                        _.split(window.failedUserAdditionOrDeletionUsers, ',').map((item, index)=>{ 
                                                            return ( 
                                                                <> 
                                                                    {
                                                                        index >=2 ?                                                                    
                                                                            <>
                                                                                {item}<br/>
                                                                            </>
                                                                        :
                                                                            null
                                                                    }
                                                                </>
                                                            )}
                                                        )
                                                    }
                                                    placement='top' arrow> 
                                                    <Box sx={{display: 'inline-flex', position: 'relative', top: "9px"}}>
                                                        <MoreHorizIcon /> 
                                                    </Box>
                                                </Tooltip> 
                                            : null
                                    }) {props.instuctionsData > 1 ? "" + props.failedData + " /  " + props.instuctionsData + " Users" : "" + props.failedData + " /  " + props.instuctionsData + " User" }.The lock is currently unreachable. Please retry after some time</span>
                        </Box>
                    :
                        null
                }
            </DialogContent>
            <DialogActions>            
            <Button onClick={() => props.closeDialog()}>
                ok
            </Button>                                  
            </DialogActions>
        </Dialog>
    )
}
